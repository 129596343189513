import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

interface ExportFileSettingProductsProps {
  title?: string;
  data: any[];
}

const ExportFileSettingProducts = React.forwardRef((props: ExportFileSettingProductsProps, ref) => {

  return (
    <Box ref={ref} sx={{ textAlign: 'center', padding: '20px', '&@page': { size: 'A4' }, }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer >
            <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center', }}>
                    <Typography fontWeight={'bold'}>{props.title}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={10}>ลำดับ</TableCell>
                  {/*NOTE - Keep it in consideration for future use
                  <TableCell width={100}>ประเภทสินค้า</TableCell>
  <TableCell width={100}>หมวดหมู่สินค้า</TableCell>*/}
                  <TableCell width={300}>ชื่อสินค้า</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 && props?.data?.map((row: any, index: number) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell>{index + 1}</TableCell>
                    {/*NOTE - Keep it in consideration for future use
                    <TableCell>{row.productCategory?.name || '-'}</TableCell>
                <TableCell>{row.productGroup?.name || '-'}</TableCell>*/}
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                ))}
                {props.data.length === 0 && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ExportFileSettingProducts;