import { Chip } from "@mui/material";
import { green, grey, indigo, lime, orange, purple, red, teal } from "@mui/material/colors";

interface ChipCustomProps {
  lable?: string,
  width?: number;
}
const ChipCustom: React.FC<ChipCustomProps> = (props: ChipCustomProps) => {
  let color = "";
  switch (props.lable) {
    case 'กำลังดำเนินการ':
      color = orange[300];
      break;
    case 'ธนาคารกำลังดำเนินการ':
      color = lime[300];
      break;
    case 'ขายแล้ว':
      color = green[300];
      break;
    case 'กู้ไม่ผ่าน':
      color = red[200];
      break;
    case 'กู้ผ่าน':
      color = teal[200];
      break;
    case 'ซื้อสด':
      color = indigo[200];
      break;
    case 'ส่งข้อมูลธนาคาร':
      color = purple[100];
      break;
    default:
      color = grey[100];
      break;
  }
  return (
    <Chip label={props.lable} sx={{ bgcolor: color, width: props.width }} />
  );
};

export default ChipCustom;