import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class PurchasesStore {
  purchases: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/purchases");
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting purchases:", error);
      return {
        error: true,
        message: "An error occurred while getting purchases",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/purchases/${id}`);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting purchases:", error);
      return {
        error: true,
        message: "An error occurred while getting purchases",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/purchases", data);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating purchases:", error);
      return {
        error: true,
        message: "An error occurred while creating purchases",
      };
    }
  }

  async createDetail(data: any) {
    try {
      const response = await apiService.post("/purchases/detail", data);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating purchases detail:", error);
      return {
        error: true,
        message: "An error occurred while creating purchases detail",
      };
    }
  }

  async createItem(data: any) {
    try {
      const response = await apiService.post("/purchases/item", data);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating purchase item:", error);
      return {
        error: true,
        message: "An error occurred while creating purchase item",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/purchases/${id}`, data);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating purchase:", error);
      return {
        error: true,
        message: "An error occurred while updating purchase",
      };
    }
  }

  async updateDetail(id: string, data: any) {
    try {
      const response = await apiService.put(`/purchases/detail/${id}`, data);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating purchase detail:", error);
      return {
        error: true,
        message: "An error occurred while updating purchase detail",
      };
    }
  }

  async updateItem(id: string, data: any) {
    try {
      const response = await apiService.put(`/purchases/item/${id}`, data);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating purchase item:", error);
      return {
        error: true,
        message: "An error occurred while updating purchase item",
      };
    }
  }

  async delete(id: any) {
    try {
      const response = await apiService.delete(`/purchases/${id}`);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting purchase:", error);
      return {
        error: true,
        message: "An error occurred while deleting purchase",
      };
    }
  }

  async deleteDetail(id: any) {
    try {
      const response = await apiService.delete(`/purchases/detail/${id}`);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting purchase detail:", error);
      return {
        error: true,
        message: "An error occurred while deleting purchase detail",
      };
    }
  }
  async deleteItemDetail(id: string) {
    try {
      const response = await apiService.delete(`/purchases/item/${id}`);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting purchase detail:", error);
      return {
        error: true,
        message: "An error occurred while deleting purchase detail",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post(`/purchases/criteria`, criteria);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting purchases by criteria:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting purchases by criteria",
      };
    }
  }

  async checkExpenseAlreadyExist(object: any) {
    try {
      const response = await apiService.post(`/purchases/already-exist`, object);
      this.purchases = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while check purchases already exist:",
        error
      );
      return {
        error: true,
        message: "An error occurred while check purchases already exist:",
      };
    }
  }

}
const purchasesStore = new PurchasesStore();
export default purchasesStore;
