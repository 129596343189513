import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

interface ExportFileHouseProductCategoriesProps {
  title?: string;
  subTitle?: string;
  summary?: string;
  data: any[];
}

const ExportFileHouseProductCategories = React.forwardRef(
  (props: ExportFileHouseProductCategoriesProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{ textAlign: "center", padding: "20px", "&@page": { size: "A4" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650, overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                      <Typography fontWeight={"bold"}>
                        {props.subTitle}
                      </Typography>
                      <Typography fontWeight={"bold"}>
                        มูลค่ารวม: {props.summary} บาท
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={10}>ลำดับ</TableCell>
                    <TableCell width={300}>รายการ</TableCell>
                    <TableCell width={100} sx={{ textAlign: "center" }}>
                      หน่วย
                    </TableCell>
                    <TableCell width={100} sx={{ textAlign: "center" }}>
                      จำนวน
                    </TableCell>
                    <TableCell width={100} sx={{ textAlign: "center" }}>
                      ราคาต่อหน่วย
                    </TableCell>
                    <TableCell width={100} sx={{ textAlign: "center" }}>
                      ราคา
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.length > 0 &&
                    props?.data?.map((row: any, index: number) => {
                      return (
                        <React.Fragment
                          key={`item-house-product-category-detail-${index.toString()}- ${
                            row?.id
                          }-print`}
                        >
                          <TableRow
                            key={`c-item-house-product-category-${index.toString()}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={{ textAlign: "center" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              {row?.productCategoryName || "ยังไม่ระบุ"}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              {" "}
                              {parseFloat(row?.total ?? 0).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                          </TableRow>
                          {row?.groups?.length > 0 &&
                            row?.groups?.map((group: any, gIndex: number) => {
                              return (
                                <React.Fragment
                                  key={`g-${gIndex.toString()}-${
                                    group?.id
                                  }-print`}
                                >
                                  {group?.productGroupName && (
                                    <TableRow
                                      key={`g-${gIndex.toString()}`}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell></TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        {index + 1}.{gIndex + 1}{" "}
                                        {group?.productGroupName}
                                      </TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell
                                        sx={{
                                          fontWeight: "bold",
                                          textAlign: "right",
                                        }}
                                      >
                                        {parseFloat(
                                          group?.total ?? 0
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  {group?.products?.length > 0 &&
                                    group?.products?.map(
                                      (product: any, pIndex: number) => {
                                        return (
                                          <TableRow
                                            key={`p-${pIndex.toString()}`}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell></TableCell>
                                            <TableCell>
                                              - {product?.product}
                                            </TableCell>
                                            <TableCell
                                              sx={{ textAlign: "center" }}
                                            >
                                              {product?.unit}
                                            </TableCell>
                                            <TableCell
                                              sx={{ textAlign: "center" }}
                                            >
                                              {parseFloat(
                                                product?.quantity ?? 0
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </TableCell>
                                            <TableCell
                                              sx={{ textAlign: "center" }}
                                            >
                                              {" "}
                                              {parseFloat(
                                                product?.price ?? 0
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </TableCell>
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              {" "}
                                              {parseFloat(
                                                product?.total ?? 0
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                </React.Fragment>
                              );
                            })}
                          <TableRow
                            key={`c-${index.toString()}-total-${
                              row?.id
                            }-print-house-product-category`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              colSpan={5}
                              sx={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              รวม
                            </TableCell>
                            <TableCell
                              sx={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {parseFloat(row?.total ?? 0).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  {props.data && props.data.length === 0 && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell colSpan={10} align="center">
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default ExportFileHouseProductCategories;
