import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class ContractStore {
  contracts: any = {};
  periods: any = {};
  template: any = {};
  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/document-contracts");
      this.contracts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting contracts:", error);
      return {
        error: true,
        message: "An error occurred while getting contracts",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/document-contracts/${id}`);
      this.contracts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting contract", error);
      return {
        error: true,
        message: "An error occurred while getting contract",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/document-contracts/criteria", criteria);
      this.contracts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting contracts", error);
      return {
        error: true,
        message: "An error occurred while getting contracts",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/document-contracts", data);
      this.contracts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating contract", error);
      return {
        error: true,
        message: "An error occurred while creating contract",
      };
    }
  }

  async createPeriod(data: any) {
    try {
      const response = await apiService.post("/document-contracts/periods", data);
      this.periods = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating periods", error);
      return {
        error: true,
        message: "An error occurred while creating periods",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/document-contracts/${id}`, data);
      this.contracts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating contract", error);
      return {
        error: true,
        message: "An error occurred while updating contract",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/document-contracts/${id}`);
      this.contracts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting contract", error);
      return {
        error: true,
        message: "An error occurred while deleting contract",
      };
    }
  }

  async deletePeriod(id: string) {
    try {
      const response = await apiService.delete(`/document-contracts/periods/${id}`);
      this.periods = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting periods ", error);
      return {
        error: true,
        message: "An error occurred while deleting periods ",
      };
    }
  }

  async getByHouseId(id: string) {
    try {
      const response = await apiService.get(`/document-contracts/house/${id}`);
      this.contracts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting contract", error);
      return {
        error: true,
        message: "An error occurred while getting contract",
      };
    }
  }

  async getAllTemplateContracts() {
    try {
      const response = await apiService.get(`/document-contracts/templates/contract`);
      this.template = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting contract templates", error);
      return {
        error: true,
        message: "An error occurred while getting contract templates",
      };
    }
  }

  async createTemplateContracts(data: any) {
    try {
      const response = await apiService.post("/document-contracts/templates/contract", data);
      this.template = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating contract templates", error);
      return {
        error: true,
        message: "An error occurred while creating contract templates",
      };
    }
  }
}
const contractStore = new ContractStore();
export default contractStore;
