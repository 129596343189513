import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class ExpensesStore {
  expenses: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/expenses");
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting expenses:", error);
      return {
        error: true,
        message: "An error occurred while getting expenses",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/expenses/${id}`);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting expense:", error);
      return {
        error: true,
        message: "An error occurred while getting expense",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/expenses", data);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating expense:", error);
      return {
        error: true,
        message: "An error occurred while creating expense",
      };
    }
  }

  async createDetail(data: any) {
    try {
      const response = await apiService.post("/expenses/detail", data);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating expense detail:", error);
      return {
        error: true,
        message: "An error occurred while creating expense detail",
      };
    }
  }

  async createItem(data: any) {
    try {
      const response = await apiService.post("/expenses/item", data);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating expense item:", error);
      return {
        error: true,
        message: "An error occurred while creating expense item",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/expenses/${id}`, data);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating expense:", error);
      return {
        error: true,
        message: "An error occurred while updating expense",
      };
    }
  }

  async updateDetail(id: string, data: any) {
    try {
      const response = await apiService.put(`/expenses/detail/${id}`, data);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating expense detail:", error);
      return {
        error: true,
        message: "An error occurred while updating expense detail",
      };
    }
  }

  async updateItem(id: string, data: any) {
    try {
      const response = await apiService.put(`/expenses/item/${id}`, data);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating expense item:", error);
      return {
        error: true,
        message: "An error occurred while updating expense item",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/expenses/${id}`);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting expense:", error);
      return {
        error: true,
        message: "An error occurred while deleting expense",
      };
    }
  }

  async deleteDetail(id: string, user: string) {
    try {
      const response = await apiService.delete(`/expenses/detail?id=${id}&user=${user}`);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting expense detail:", error);
      return {
        error: true,
        message: "An error occurred while deleting expense detail",
      };
    }
  }
  async deleteItemDetail(id: string, user: string) {
    try {
      const response = await apiService.delete(`/expenses/item?id=${id}&user=${user}`);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting expense detail:", error);
      return {
        error: true,
        message: "An error occurred while deleting expense detail",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post(`/expenses/criteria`, criteria);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting expenses by criteria:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting expenses by criteria",
      };
    }
  }

  async checkExpenseAlreadyExist(object: any) {
    try {
      const response = await apiService.post(`/expenses/already-exist`, object);
      this.expenses = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while check expense already exist:",
        error
      );
      return {
        error: true,
        message: "An error occurred while check expense already exist:",
      };
    }
  }
}
const expensesStore = new ExpensesStore();
export default expensesStore;
