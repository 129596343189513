import { Box, Breadcrumbs, Button, Card, CardContent, Divider, Grid, Link, Stack, TextField, Typography, ButtonProps, IconButton, FormControl, Autocomplete } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import authStore from "../../stores/AuthStore";
import workersStore from "../../stores/WorkersStore";
import { GridDeleteIcon } from "@mui/x-data-grid";
import DialogConfirm from "../../components/DialogConfirm";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import imagesStore from "../../stores/ImagesStore";
import masterDataStore from "../../stores/MasterDataStore";

const SettingWorkerCreate: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [tel, setTel] = useState('');
    const [note, setNote] = useState('');
    const [banks, setBanks] = useState<any[]>([]);
    const [bank, setBank] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [openConfirm, setOpenConfirm] = useState({
        open: false,
        title: '',
        message: '',
        color: 'primary' as ButtonProps['color'],
        type: 'create' as 'create' | 'edit' | 'delete'
    });
    const [isLoading, setIsLoading] = useState(false);
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/setting-workers');
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [images, setImages] = useState<any[]>([]);
    const [deleteImages, setDeleteImages] = useState<any[]>([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const [address, setAddress] = useState('');
    const [alley, setAlley] = useState('');
    const [street, setStreet] = useState('');
    const [moo, setMoo] = useState('');
    const [province, setProvince] = useState('');
    const [district, setDistrict] = useState('');
    const [subDistrict, setSubDistrict] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [subDistrictId, setSubDistrictId] = useState('');
    const [workerZipcode, setWorkerZipcode] = useState('');
    const [provinceData, setProvinceData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
    const [districtOptions, setDistrictOptions] = useState<any>([]);
    const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);

    const handleSave = () => {
        setIsLoading(true);
        if (!id) {
            workersStore.create({
                firstName: firstName,
                lastName: lastName,
                tel: tel,
                note: note,
                address: address,
                alley: alley,
                street: street,
                moo: moo,
                district: districtId !== '' ? districtId : null,
                province: provinceId !== '' ? provinceId : null,
                subDistrict: subDistrictId !== '' ? subDistrictId : null,
                zipCode: workerZipcode,
                bank: bank !== '' ? bank : null,
                bankAccount: bankAccount,
                createBy: authStore.user?.id,
                createAt: new Date()
            }).then((res: any) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                handleFileUpload(res?.id);
                enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
                navigate(`/setting-worker-edit/${res.id}`);
            });
        } else {
            workersStore.update(id, {
                firstName: firstName,
                lastName: lastName,
                note: note,
                tel: tel,
                bank: bank,
                bankAccount: bankAccount,
                updateBy: authStore.user?.id,
                updateAt: new Date()
            }).then((res) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
                ;
            });
        }
    };

    const handleEdit = async () => {
        if (id) {
            handleEditFileUpload(id);
            workersStore.update(id, {
                firstName: firstName,
                lastName: lastName,
                tel: tel,
                note: note,
                address: address,
                alley: alley,
                street: street,
                moo: moo,
                district: districtId !== '' ? districtId : null,
                province: provinceId !== '' ? provinceId : null,
                subDistrict: subDistrictId !== '' ? subDistrictId : null,
                zipCode: workerZipcode,
                bank: bank !== '' ? bank : null,
                bankAccount: bankAccount,
                updateBy: authStore.user?.id,
                updateAt: new Date()
            }).then((res) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
                ;
            });
        }
    };
    const handleDelete = async () => {
        setIsLoading(true);
        if (id) {
            try {
                await workersStore.update(id, {
                    isDeleted: true,
                    updateBy: authStore.user?.id,
                    updateAt: new Date()
                });
                await imagesStore.update(id, {
                    isDeleted: true,
                    updateBy: authStore.user?.id,
                    updateAt: new Date()
                });
                enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
                setIsLoading(false);
                navigate('/setting-workers');
            }
            catch (err) {
                setIsLoading(false);
                console.error(err);
            }
        }
    };
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const newSelectedFiles = Array.from(files);
            setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
        }
    };

    const handleFileRemove = (index: number) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };
    const handleFileRemoveImageList = (file: any) => {
        const updatedImages = images.filter((image) => image.id !== file.id);
        setImages(updatedImages);
        setDeleteImages([...deleteImages, file]);
    };

    const handleFileUpload = (refId: string) => {
        if (selectedFiles.length > 0) {
            selectedFiles.forEach(async (file: any, index: number) => {
                const formdata = new FormData();
                formdata.append("refType", "worker");
                formdata.append("refId", refId);
                formdata.append('file', file);
                formdata.append('createBy', authStore.user?.id);
                formdata.append("createAt", new Date(Date.now() + index * 1000).toISOString());

                await imagesStore.upload(formdata)
                    .catch(err => console.error("err", err));
            });
        }
    };

    const handleEditFileUpload = async (id: string) => {
        if (selectedFiles.length > 0) {
            selectedFiles.forEach(async (file: any, index: number) => {
                const formdata = new FormData();
                formdata.append("refType", "worker");
                formdata.append("refId", id);
                formdata.append('file', file);
                formdata.append('updateBy', authStore.user?.id);
                formdata.append("updateAt", new Date(Date.now() + index * 1000).toISOString());
                await imagesStore.upload(formdata)
                    .catch(err => console.error("err", err));
            });
        }
        if (deleteImages.length > 0) {
            await deleteImages.forEach(async (item: any) => {
                await imagesStore.update(item.id, {
                    updateBy: authStore.user?.id,
                    updateAt: new Date(),
                    isDeleted: true
                }).catch(err => console.error("err deleteImages", err));
            });
        }
    };

    const addRef = (el: any) => {
        if (el && !inputRefs.current.includes(el)) {
            inputRefs.current.push(el);
        }
    };

    const handleKeyDown = (event: any, index: number) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index + 1 < inputRefs.current.length) {
                inputRefs.current[index + 1].focus();
            }
        }
    };
    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            masterDataStore.getAllProvince(),
            masterDataStore.getAllAmphur(),
            masterDataStore.getAllTambon(),
            workersStore.getBanksAll()
        ]).then((res: any) => {
            setProvinceData(res[0]);
            setDistrictData(res[1]);
            setSubDistrictData(res[2]);
            setBanks(res[3]);
            setIsLoading(false);
        }).catch((err) => {
            console.error(err);
            enqueueSnackbar('เกิดข้อผิดพลาด', { variant: 'error' });
        });
        if (id) {
            workersStore.get(id).then((res) => {
                if (res.error) {
                    enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
                    return;
                }
                setFirstName(res?.firstName);
                setLastName(res?.lastName);
                setTel(res?.tel);
                setNote(res?.note);
                setBank(res?.bank ?? '');
                setBankAccount(res?.bankAccount);
                setImages(res?.images);
                setAddress(res?.address ?? '');
                setProvince(res?.province ?? '');
                setProvinceId(res?.province?.id ?? '');
                setDistrict(res?.district ?? '');
                setDistrictId(res?.district?.id ?? '');
                setSubDistrict(res?.subDistrict);
                setSubDistrictId(res?.subDistrict?.id ?? '');
                setAlley(res?.alley);
                setStreet(res?.street);
                setMoo(res?.moo);
                setWorkerZipcode(res?.zipCode);
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box component={'div'}>
            <Grid container>
                <Grid item xs={0.5}>
                    <IconButton aria-label="delete" onClick={() => navigate('/setting-workers')}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Typography>การจัดการ</Typography>
                        <Link component="button" onClick={() => navigate('/setting-workers')}>คนงาน</Link>
                        <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={3} display={'flex'} justifyContent={'end'}>
                    {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
                        <Button variant="contained" color="error" startIcon={<GridDeleteIcon />} onClick={() => setOpenConfirm({
                            open: true,
                            title: 'ลบข้อมูล',
                            message: 'คุณต้องการลบคนงานนี้ใช่หรือไม่',
                            color: 'error',
                            type: 'delete'
                        })}>ลบข้อมูล</Button>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} >
                                <Grid item xs={4.5} >
                                    <TextField id="outlined-basic" label="ชื่อ" variant="outlined" fullWidth
                                        onChange={(event) => setFirstName(event.target.value)}
                                        value={firstName}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 0)}
                                    />
                                </Grid>
                                <Grid item xs={4.5} >
                                    <TextField id="outlined-basic" label="นามสกุล" variant="outlined" fullWidth
                                        onChange={(event) => setLastName(event.target.value)} value={lastName}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 1)}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="outlined-basic" label="เบอร์โทรศัพท์" variant="outlined" fullWidth
                                        onChange={(event) => setTel(event.target.value)} value={tel}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 2)}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='ที่อยู่' variant='outlined' fullWidth value={address} onChange={(e) => setAddress(e.target.value)} inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 3)} />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='หมู่' variant='outlined' fullWidth multiline value={moo} onChange={(e) => setMoo(e.target.value)} inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 4)} />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='ตรอก/ซอย' variant='outlined' fullWidth value={alley} onChange={(e) => setAlley(e.target.value)}
                                        inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 5)}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='ถนน' variant='outlined' fullWidth value={street} onChange={(e) => setStreet(e.target.value)} inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 6)} />
                                </Grid>
                                <Grid item xs={3} >
                                    <Autocomplete
                                        value={province}
                                        id='province'
                                        onChange={(e: any, value: any) => {
                                            if (value?.id) {
                                                setProvinceId(value?.id);
                                                setProvince(value?.nameTh);
                                                const _districtOptions = districtData.filter((district: any) => district?.province?.id === value?.id
                                                );
                                                setDistrictOptions(_districtOptions);
                                            }
                                        }}
                                        disablePortal
                                        options={provinceData}
                                        sx={{ width: '100%' }}
                                        renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                                        getOptionLabel={(option: any) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.nameTh;
                                        }}
                                        renderInput={(params: any) => <TextField inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 7)}  {...params} label='จังหวัด' />}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <Autocomplete
                                        value={district}
                                        id='district'
                                        onChange={(e: any, value: any) => {
                                            setDistrictId(value);
                                            setDistrict(value?.nameTh);
                                            if (value?.id) {
                                                const _subDistrictOptions = subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                                                setSubDistrictOptions(_subDistrictOptions);
                                            }
                                        }}
                                        disablePortal
                                        options={districtOptions}
                                        sx={{ width: '100%' }}
                                        renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                                        getOptionLabel={(option: any) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.nameTh;
                                        }}
                                        renderInput={(params) => <TextField inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 8)}  {...params} label='อำเภอ' />}
                                        disabled={(districtOptions.length > 0 || district !== '') ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <Autocomplete
                                        value={subDistrict ?? ''}
                                        id='district'
                                        onChange={(e: any, value: any) => {
                                            if (value?.id) {
                                                setSubDistrictId(value?.id);
                                                setSubDistrict(value?.nameTh);
                                            }
                                            if (value?.zipCode) { setWorkerZipcode(value?.zipCode); }
                                        }}
                                        disablePortal
                                        options={subDistrictOptions}
                                        sx={{ width: '100%' }}
                                        renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                                        getOptionLabel={(option: any) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.nameTh;
                                        }}
                                        renderInput={(params) => <TextField inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 9)}  {...params} label='ตำบล' />}
                                        disabled={(subDistrictOptions.length > 0 || subDistrict !== '') ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='รหัสไปรษณีย์' variant='outlined' fullWidth value={workerZipcode} onChange={(e) => setWorkerZipcode(e.target.value)} inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 10)} />
                                </Grid>
                                <Grid item xs={5} >
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="bank-select"
                                            options={banks} //เก็บข้อมูลธนาคารทั้งหมด
                                            getOptionLabel={(option: any) => {
                                                if (option.nameTh) { return option.nameTh; }
                                                else { return ''; }
                                            }} //เลือกวิธีดึงข้อมูลโดยดึงข้อมูลของ
                                            value={!bank ? '' : bank} //หาค่าที่ให้ตรงกับ id
                                            onChange={(event, newValue) => {

                                                if (newValue) {
                                                    setBank(newValue);
                                                } else {
                                                    setBank('all');
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="ธนาคาร" inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 11)} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={7} >
                                    <TextField id="outlined-basic" label="เลขที่บัญชี" variant="outlined" fullWidth
                                        onChange={(event) => setBankAccount(event.target.value)} value={bankAccount}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 12)}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField id="outlined-basic" label="หมายเหตุ" variant="outlined" fullWidth multiline
                                        value={note}
                                        onChange={(event) => setNote(event.target.value)}
                                        inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 13)}
                                    />
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>
                                <Grid item xs={8} >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography>บัตรประจำตัวประชาชน</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                                                เลือกไฟล์
                                                <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                                                    onChange={handleFileChange}
                                                />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {selectedFiles.length > 0 && (
                                                <Box component={'div'} mt={1}>
                                                    {selectedFiles.map((file: any, index: number) => (
                                                        <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                                                            <Grid container >
                                                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                                    <IconButton size="small" color="error"
                                                                        onClick={() => handleFileRemove(index)}>
                                                                        <RemoveCircleIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={11.5}>
                                                                    <img
                                                                        src={URL.createObjectURL(file)}
                                                                        alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                                                        width="500"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Box>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {images && (
                                                <Box component={'div'} mt={1}>
                                                    {images.map((file: any, index: number) => (
                                                        <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                                                            <Grid container >
                                                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                                    <IconButton size="small" color="error"
                                                                        onClick={() => handleFileRemoveImageList(file)}>
                                                                        <RemoveCircleIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={11.5}>
                                                                    <img
                                                                        src={`${apiUrl}/uploads/${file.name}`}
                                                                        alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                                                        width="500"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} display={"flex"} alignItems={"flex-end"}  >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}   >
                                            <Stack spacing={2} direction="row">
                                                <Button variant="outlined" fullWidth onClick={() => navigate('/setting-workers')}>ยกเลิก</Button>
                                                {!id && <Button variant="contained" fullWidth onClick={() => {
                                                    setOpenConfirm({
                                                        open: true,
                                                        title: 'บันทึกข้อมูล',
                                                        message: 'คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่',
                                                        color: 'primary',
                                                        type: 'create'
                                                    });
                                                }}>บันทึก</Button>}
                                                {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                                                    setOpenConfirm({
                                                        open: true,
                                                        title: 'แก้ไขข้อมูล',
                                                        message: 'คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่',
                                                        color: 'primary',
                                                        type: 'edit'
                                                    });
                                                }}>แก้ไข</Button>}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === 'delete') {
                        handleDelete();
                    } else if (openConfirm.type === 'create') {
                        handleSave();
                    } else if (openConfirm.type === 'edit') {
                        handleEdit();
                    }
                }} />
        </Box>
    );
};

export default SettingWorkerCreate;
