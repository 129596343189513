import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography } from "@mui/material";
import ESTATE_LOGO from '../../res/images/ESTATE_LOGO.png';
import authStore from '../../stores/AuthStore';
const Welcome: React.FC = observer(() => {
  return (
    <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box component={'img'} src={ESTATE_LOGO} width={400} />
      <Typography variant="h4">ยินดีต้อนรับ</Typography>
      <Typography variant="h5">คุณ {(authStore.user?.name || authStore.user?.firstName)
        ? (authStore.user?.name || authStore.user?.firstName)
        : authStore.user?.username}</Typography>
    </Box>
  );
});

export default Welcome;
