import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class WorkersStore {
    workers: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }
    async getAll() {
        try {
            const response = await apiService.get("/workers");
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting workers:", error);
            return {
                error: true,
                message: "An error occurred while getting workers",
            };
        }
    }

    async get(id: string) {
        try {
            const response = await apiService.get(`/workers/${id}`);
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting workers:", error);
            return {
                error: true,
                message: "An error occurred while getting workers",
            };
        }
    }

    async getByFirstName(firstName: string) {
        try {
            const response = await apiService.get(`/workers/name/${firstName}`);
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting workers:", error);
            return {
                error: true,
                message: "An error occurred while getting workers",
            };
        }
    }

    async getByLastName(lastName: string) {
        try {
            const response = await apiService.get(`/workers/name/${lastName}`);
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting workers:", error);
            return {
                error: true,
                message: "An error occurred while getting workers",
            };
        }
    }

    async getByNameLike(name: string) {
        try {
            const response = await apiService.get(`/workers/name/like/${name}`);
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting workers:", error);
            return {
                error: true,
                message: "An error occurred while getting workers",
            };
        }
    }

    async getByProjectId(id: string) {
        try {
            const response = await apiService.get(`/houses/project/${id}`);
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting workers:", error);
            return {
                error: true,
                message: "An error occurred while getting workers",
            };
        }
    }

    async getByFirstNameAndgetByLastName(firstName: string, lastName: string) {
        try {
            const response = await apiService.get(
                `/workers/projectname/${firstName}/${lastName}`
            );
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting workers:", error);
            return {
                error: true,
                message: "An error occurred while getting workers",
            };
        }
    }

    async create(data: any) {
        try {
            const response = await apiService.post("/workers", data);
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while creating workers:", error);
            return {
                error: true,
                message: "An error occurred while creating workers",
            };
        }
    }

    async update(id: string, data: any) {
        try {
            const response = await apiService.put(`/workers/${id}`, data);
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while updating workers:", error);
            return {
                error: true,
                message: "An error occurred while updating workers",
            };
        }
    }

    async delete(id: string) {
        try {
            const response = await apiService.delete(`/workers/${id}`);
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while deleting workers:", error);
            return {
                error: true,
                message: "An error occurred while deleting workers",
            };
        }
    }

    async getByCriteria(criteria: any) {
        try {
            const response = await apiService.post(`/workers/criteria`, criteria);
            this.workers = response;
            return response;
        } catch (error) {
            console.error(
                "An error occurred while getting expenses by criteria:",
                error
            );
            return {
                error: true,
                message: "An error occurred while getting expenses by criteria",
            };
        }
    }

    async getBanksAll() {
        try {
            const response = await apiService.get("/banks");
            this.workers = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting banks:", error);
            return {
                error: true,
                message: "An error occurred while getting banks",
            };
        }
    }

}
const workersStore = new WorkersStore();
export default workersStore;