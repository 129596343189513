import React, { useState } from 'react';
import { Box, Container, CssBaseline, Typography } from '@mui/material';
import LoginForm from '../../components/LoginForm';
import { observer } from 'mobx-react-lite';
import authStore from '../../stores/AuthStore';
import { useNavigate } from "react-router-dom";
import ESTATE_LOGO from '../../res/images/ESTATE_LOGO.png';

const Login: React.FC = observer(() => {
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const handleLoginFormSubmit = async (username: string, password: string, isRemember: boolean) => {
    setHasError(false);
    try {
      const response = await authStore.login(username, password, isRemember);
      if (authStore.isAuthenticated) {
        setHasSuccess(true);
        setTimeout(() => {
          const hasDashboard = authStore.user?.roles?.findIndex((role: any) => role.path === '/dashboard') !== -1;
          if (hasDashboard || authStore.user?.role === 'super-admin') {
            navigate('/dashboard');
          } else {
            navigate('/welcome');
          }
        }, 1500);

      } else {
        console.error(response.message);
        setHasError(true);
      }
    } catch (error) {
      setHasError(true);
      console.error('Error during login:', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <CssBaseline />
      <div>
        <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <Box component={'img'} src={ESTATE_LOGO} sx={{ width: '80%', height: '80%' }} />
        </Box>
        <Typography component="h1" variant="h5" textAlign={'center'}>
          เข้าสู่ระบบ
        </Typography>
        <LoginForm onSubmit={handleLoginFormSubmit} hasError={hasError} hasSuccess={hasSuccess} />
      </div>
    </Container>
  );
});

export default Login;
