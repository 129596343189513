import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import dayjs from "dayjs";
import 'dayjs/locale/th';


interface ExportFileUsersProps {
    title?: string;
    rows: any[];
}

const ExportFileUsers = React.forwardRef((props: ExportFileUsersProps, ref) => {

    return (
        <Box ref={ref} sx={{ textAlign: 'center', padding: '20px', '&@page': { size: 'A4' }, }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={300} align="center">ชื่อ-นามสกุล</TableCell>
                                    <TableCell width={300} align="center" >ชื่อผู้ใช้งาน</TableCell>
                                    <TableCell width={200} align="center">ตำแหน่ง</TableCell>
                                    <TableCell width={200} align="center">ผู้บันทึก</TableCell>
                                    <TableCell width={200} align="center">วันที่บันทึก</TableCell>
                                    <TableCell width={200} align="center">ผู้แก้ไขล่าสุด</TableCell>
                                    <TableCell width={200} align="center">วันที่แก้ไขล่าสุด</TableCell>
                                    <TableCell ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.rows.length > 0 && props.rows.map((row) => (
                                    <TableRow key={row?.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">{row.firstName + ' ' + row.lastName}</TableCell>
                                        <TableCell width={300}>{row.username || ''}</TableCell>
                                        <TableCell width={200} align="center">{row.userGroup?.name || ''}</TableCell>
                                        <TableCell width={200} align="center">{row.createBy?.name || ''}</TableCell>
                                        <TableCell width={200} align="center">{row.createAt ? dayjs(row.createAt).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                                        <TableCell width={200} align="center">{row.updateBy ? row.updateBy?.name : ''}</TableCell>
                                        <TableCell width={200} align="center">{row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
});

export default ExportFileUsers;