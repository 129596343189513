import { Box, Breadcrumbs, Typography, Grid, Card, CardContent, TextField, Button, Stack, ButtonProps, Divider, Autocomplete } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authStore from "../../stores/AuthStore";
import DialogConfirm from "../../components/DialogConfirm";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import companyStore from "../../stores/CompanyStore";
import { enqueueSnackbar } from "notistack";
import imagesStore from "../../stores/ImagesStore";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import masterDataStore from "../../stores/MasterDataStore";
const Company: React.FC = () => {
  const navigate = useNavigate();
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/company');
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: '',
    message: '',
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyBranch, setCompanyBranch] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const [deleteImages, setDeleteImages] = useState<any>();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [srcImg, setSrcImg] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [alley, setAlley] = useState("");
  const [street, setStreet] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [provinceData, setProvinceData] = useState<any>([]);
  const [districtData, setDistrictData] = useState<any>([]);
  const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);
  const [provinceValue, setProvinceValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [subDistrictValue, setSubDistrictValue] = useState("");
  const [moo, setMoo] = useState("");
  const [tel, setTel] = useState("");
  const handleSave = () => {
    if (!companyId || companyId === '') {
      setIsLoading(true);
      companyStore.create({
        name: companyName,
        ownerName: ownerName,
        branch: companyBranch,
        address: companyAddress,
        alley: alley,
        street: street,
        subDistrict: subDistrict !== "" ? subDistrict : null,
        province: province !== "" ? province : null,
        district: district !== "" ? district : null,
        zipCode: zipCode,
        moo: moo,
        taxId: companyTaxId,
        email: companyEmail,
        tel: tel,
        createBy: authStore.user?.id,
        createAt: new Date(),
      }).then((res) => {
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        handleFileUpload(res.id);
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      });
    } else {
      if (companyId) { handleFileUpload(companyId); }
      companyStore.update(companyId, {
        name: companyName,
        branch: companyBranch,
        address: companyAddress,
        alley: alley,
        street: street,
        subDistrict: subDistrict !== "" ? subDistrict : null,
        province: province !== "" ? province : null,
        district: district !== "" ? district : null,
        zipCode: zipCode,
        moo: moo,
        taxId: companyTaxId,
        ownerName: ownerName,
        email: companyEmail,
        tel: tel,
        updateBy: authStore.user?.id,
        updateAt: new Date(),
      }).then((res) => {
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      });
    }
  };

  const handleEdit = () => {
    if (companyId) {
      setIsLoading(true);
      if (companyId) { handleFileUpload(companyId); }
      companyStore.update(companyId, {
        name: companyName,
        branch: companyBranch,
        address: companyAddress,
        alley: alley,
        street: street,
        subDistrict: subDistrict !== "" ? subDistrict : null,
        province: province !== "" ? province : null,
        district: district !== "" ? district : null,
        zipCode: zipCode,
        moo: moo,
        taxId: companyTaxId,
        email: companyEmail,
        ownerName: ownerName,
        tel: tel,
        updateBy: authStore.user?.id,
        updateAt: new Date(),
      }).then((res) => {
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      });
    }
  };

  const handleFileUpload = async (company: any) => {
    if (selectedFiles) {
      const formdata = new FormData();
      formdata.append("refType", "company");
      formdata.append("refId", company);
      formdata.append('file', selectedFiles);
      formdata.append('createBy', authStore.user?.id);
      formdata.append("createAt", new Date().toISOString());

      await imagesStore.upload(formdata)
        .catch(err => console.error("err", err));
    }
    if (deleteImages) {
      await imagesStore.delete(deleteImages.id);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setSelectedFiles(files[0]);
      setSrcImg(URL.createObjectURL(files[0]));
    }
  };
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      masterDataStore.getAllProvince(),
      masterDataStore.getAllAmphur(),
      masterDataStore.getAllTambon(),
    ]).then((res) => {
      setProvinceData(res[0]);
      setDistrictData(res[1]);
      setSubDistrictData(res[2]);
      setIsLoading(false);
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
    companyStore.getAll().then((res) => {
      if (res.error) {
        enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
        setIsLoading(false);
        return;
      }
      if (res && res[0]) {
        setCompanyName(res[0]?.name ?? "");
        setCompanyBranch(res[0]?.branch ?? "");
        setCompanyAddress(res[0]?.address ?? "");
        setCompanyEmail(res[0]?.email ?? "");
        setCompanyTaxId(res[0]?.taxId ?? "");
        setCompanyId(res[0]?.id ?? "");
        setOwnerName(res[0]?.ownerName);
        if (res[0].images) { setSrcImg(`${apiUrl}/uploads/${res[0].images.name}`); }
        setDeleteImages(res[0].images);
        setMoo(res[0].moo);
        setAlley(res[0]?.alley ?? "");
        setStreet(res[0]?.street ?? "");
        setSubDistrict(res[0]?.subDistrict ?? "");
        setSubDistrictValue(res[0]?.subDistrict?.nameTh ?? "");
        setDistrict(res[0]?.district ?? "");
        setDistrictValue(res[0]?.district?.nameTh ?? "");
        setProvince(res[0]?.province ?? "");
        setProvinceValue(res[0]?.province?.nameTh ?? "");
        setZipCode(res[0]?.zipCode ?? "");
        setTel(res[0]?.tel ?? "");
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>การจัดการ</Typography>
        <Typography variant="h6" color="text.primary">ข้อมูลบริษัท</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card >
            <CardContent >
              <Grid container spacing={2} >
                <Grid item xs={8}  >
                  <TextField id="company-name" label="ชื่อบริษัท" variant="outlined" fullWidth
                    onChange={(event) => setCompanyName(event.target.value)} value={companyName}
                  />
                </Grid>
                <Grid item xs={4} >
                  <TextField id="companyBranch" label="สาขา" variant="outlined" fullWidth
                    onChange={(event) => setCompanyBranch(event.target.value)} value={companyBranch}
                  />
                </Grid>
                <Grid item xs={6} >
                  <TextField id="ownerName" label="ชื่อ-นามสกุล" variant="outlined" value={ownerName} onChange={(event) => setOwnerName(event.target.value)} fullWidth />
                </Grid>
                <Grid item xs={6} >
                  <TextField id="companyTaxId" label="เลขประจำตัวผู้เสียภาษี" variant="outlined" fullWidth
                    onChange={(event) => setCompanyTaxId(event.target.value)} value={companyTaxId}
                  />
                </Grid>
                <Grid item xs={3} >
                  <TextField id="companyAddress" label="ที่อยู่" variant="outlined" fullWidth multiline
                    maxRows={4}
                    onChange={(event) => setCompanyAddress(event.target.value)} value={companyAddress}
                  />
                </Grid>
                <Grid item xs={3} >
                  <TextField label="หมู่" variant="outlined" fullWidth multiline value={moo} onChange={(e) => setMoo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3} >
                  <TextField label="ตรอก/ซอย" variant="outlined" fullWidth value={alley} onChange={(e) => setAlley(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3} >
                  <TextField label="ถนน" variant="outlined" fullWidth value={street} onChange={(e) => setStreet(e.target.value)} />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={provinceValue}
                    id="province"
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setProvince(value?.id);
                        setProvinceValue(value?.nameTh);
                        const _districtOptions = districtData.filter((district: any) => district?.province?.id === value?.id
                        );
                        setDistrictOptions(_districtOptions);
                      }
                    }}
                    disablePortal
                    options={provinceData}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="จังหวัด" />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={districtValue}
                    id="district"
                    onChange={(e: any, value: any) => {
                      setDistrict(value);
                      setDistrictValue(value?.nameTh);
                      if (value?.id) {
                        const _subDistrictOptions = subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                        setSubDistrictOptions(_subDistrictOptions);
                      }
                    }}
                    disablePortal
                    options={districtOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="อำเภอ" />}
                    disabled={(districtOptions.length > 0 || districtValue !== "") ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={subDistrictValue ?? ""}
                    id="district"
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setSubDistrict(value?.id);
                        setSubDistrictValue(value?.nameTh);
                      }
                      if (value?.zipCode) { setZipCode(value?.zipCode); }
                    }}
                    disablePortal
                    options={subDistrictOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="ตำบล" />}
                    disabled={(subDistrictOptions.length > 0 || subDistrictValue !== "") ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รหัสไปรษณีย์" variant="outlined" fullWidth value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                </Grid>
                <Grid item xs={6} >
                  <TextField id="companyEmail" label="อีเมล์" variant="outlined" fullWidth
                    onChange={(event) => setCompanyEmail(event.target.value)} value={companyEmail}
                  />
                </Grid>
                <Grid item xs={6} >
                  <TextField id="tel" label="เบอร์โทร" variant="outlined" fullWidth
                    onChange={(event) => setTel(event.target.value)} value={tel}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} mt={2}  ><Divider /></Grid>

              <Grid item xs={12} >
                <Grid container justifyContent="flex-end" >
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>โลโก้</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                          เลือกไฟล์
                          <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                            onChange={handleFileChange} />
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {srcImg !== '' && (
                          <Box component={'div'} mt={1}>
                            <Grid item xs={12} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  {/* <IconButton size="small" color="error"
                                    onClick={() => handleFileRemove(selectedFiles?.id)}>
                                    <RemoveCircleIcon />
                                  </IconButton> */}
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    // src={URL.createObjectURL(selectedFiles)}
                                    src={srcImg}
                                    alt={`ตัวอย่างไฟล์ ${selectedFiles?.id}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {/* {images && (
                          <Box component={'div'} mt={1}>
                            <Grid item xs={12} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small" color="error"
                                    onClick={() => handleFileRemoveImageList(images)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={`${apiUrl}/uploads/${images.name}`}
                                    alt={`ตัวอย่างไฟล์ ${images?.id}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        )} */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} mt={2} >
                    <Stack spacing={2} direction="row" >
                      <Button variant="outlined" fullWidth onClick={() => navigate('/dashboard')}>ยกเลิก</Button>
                      {!companyId && companyId === '' && <Button variant="contained" fullWidth onClick={() => {
                        setOpenConfirm({
                          open: true,
                          title: 'บันทึกเอกสาร',
                          message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                          color: 'primary',
                          type: 'create'
                        });
                      }}>บันทึก</Button>}
                      {((permission && permission.edit) || authStore.user?.isSuperAdmin) && companyId && companyId !== '' && <Button variant="contained" fullWidth onClick={() => {
                        setOpenConfirm({
                          open: true,
                          title: 'แก้ไขเอกสาร',
                          message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                          color: 'primary',
                          type: 'edit'
                        });
                      }}>แก้ไข</Button>}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'create') {
            handleSave();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          }
        }} />
    </Box>
  );
};

export default Company;
