import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

interface PrintSettingVendorsProps {
    title?: string;
    data: any[];
}

const PrintSettingVendors = React.forwardRef((props: PrintSettingVendorsProps, ref) => {
    return (
        <Box ref={ref} sx={{
            margin: 0,
            width: "100%",
            borderCollapse: "collapse",
            textAlign: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
            '&@page': {
                size: 'A4 portrait',
            },
            overflow: 'hidden',
            fontSize: 8,
            display: 'block',
            pageBreakBefore: 'auto',
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ textAlign: 'center', }}>
                                        <Typography fontWeight={'bold'}>{props.title}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width={10}>ลำดับ</TableCell>
                                    <TableCell width={100}>ชื่อผู้ขาย</TableCell>
                                    <TableCell width={300}>ที่อยู่</TableCell>
                                    <TableCell width={100}>เบอร์โทร</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props?.data?.length > 0 && props?.data?.map((row: any, index: number) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.address}</TableCell>
                                        <TableCell>{row.tel}</TableCell>
                                    </TableRow>
                                ))}
                                {props.data.length === 0 && (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box >
    );
});

export default PrintSettingVendors;