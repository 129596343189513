import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React from "react";

interface ExportFileInventoryHistoryProps {
  title?: string;
  date?: string;
  inventories: any[];
}

const ExportFileInventoryHistory = React.forwardRef(
  (props: ExportFileInventoryHistoryProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{ textAlign: "center", padding: "20px", "&@page": { size: "A4" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={100} align="center">
                      วันที่เอกสาร
                    </TableCell>
                    <TableCell align="center">เลขเอกสาร</TableCell>
                    <TableCell align="center">รายการ</TableCell>
                    <TableCell align="center">ประเภท</TableCell>
                    <TableCell align="center">จำนวนเข้า</TableCell>
                    <TableCell align="center">จำนวนออก</TableCell>
                    <TableCell align="center">คงเหลือ</TableCell>
                    <TableCell width={100} align="center">
                      ผู้ทำรายการ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.inventories &&
                    props.inventories.length > 0 &&
                    props.inventories?.map((row: any) => (
                      <TableRow>
                        <TableCell>
                          {dayjs(row?.inventory?.docDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row?.inventory?.docNo}</TableCell>
                        <TableCell>
                          {row?.product?.name}
                          <Box>
                            <b>โครงการ: </b> {row?.project?.name} <b>บ้าน: </b>{" "}
                            {row?.house?.name}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {row?.inventory?.type === "add"
                            ? "เพิ่ม"
                            : row?.inventory?.type === "return"
                            ? "คืน"
                            : "เบิก"}
                        </TableCell>
                        <TableCell align="right">
                          {row?.inventory?.type === "add" ||
                          row?.inventory?.type === "return"
                            ? parseFloat(row?.quantity || 0).toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }
                              )
                            : "0.00"}
                        </TableCell>
                        <TableCell align="right">
                          {row?.inventory?.type === "requisition"
                            ? parseFloat(row?.quantity || 0).toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }
                              )
                            : "0.00"}
                        </TableCell>
                        <TableCell align="right">
                          {parseFloat(row?.remain || 0).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                        <TableCell>
                          {row?.inventory?.worker?.firstName}{" "}
                          {row?.inventory?.worker?.lastName}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default ExportFileInventoryHistory;
