import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

interface PrintProjectHousesProps {
  title?: string;
  summary?: number;
  data: any[];
}

const PrintProjectHouses = React.forwardRef(
  (props: PrintProjectHousesProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          margin: 0,
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          "&@page": {
            size: "A4 portrait",
          },
          overflow: "hidden",
          fontSize: 8,
          display: "block",
          pageBreakBefore: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650, overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={200}>โครงการ</TableCell>
                    <TableCell>ชื่อบ้าน</TableCell>
                    <TableCell>ราคาขาย</TableCell>
                    <TableCell>รายละเอียด</TableCell>
                    <TableCell>สถานะ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.length > 0 &&
                    props?.data?.map((row: any, index: number) => {
                      let status: any = { name: "ว่าง", color: "default" };
                      if (row?.bookings?.length > 0) {
                        row?.bookings.forEach((item: any) => {
                          if (item?.sell) {
                            status.name = "ขายแล้ว";
                            status.color = "success";
                          } else if (
                            item?.status?.code === "cash" ||
                            item?.status?.code === "sold"
                          ) {
                            status.name = item?.status?.name;
                            status.color = "success";
                          } else if (
                            item?.status?.code === "send_data_bank" ||
                            item?.status?.code === "bank_in_progress" ||
                            item?.status?.code === "in_progress" ||
                            item?.status?.code === "loaned"
                          ) {
                            status.name = "ติดจอง";
                            status.color = "warning";
                          } else {
                            status.name = "ว่าง";
                            status.color = "default";
                          }
                        });
                      }
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.project?.name || ""}
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            {parseFloat(row.sellPrice || 0).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }
                            )}
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{status.name || ""} </TableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      <b>รวมทั้งหมด</b>
                    </TableCell>
                    <TableCell colSpan={1} align="right">
                      {parseFloat(
                        (props?.summary || 0).toString()
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                  {props.data && props.data.length === 0 && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell colSpan={10} align="center">
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default PrintProjectHouses;
