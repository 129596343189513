import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
interface PrintCustomerProps {
  title?: string;
  date?: string;
  data: any[];
}

const PrintCustomer = React.forwardRef((props: PrintCustomerProps, ref) => {
  return (
    <Box ref={ref} sx={{
      margin: 0,
      width: "100%",
      borderCollapse: "collapse",
      textAlign: "center",
      paddingLeft: "20px",
      paddingRight: "20px",
      '&@page': {
        size: "A4 portrait",
      },
      overflow: "hidden",
      fontSize: 8,
      display: "block",
      pageBreakBefore: "auto", // Allow page break before this element 
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={10} sx={{ textAlign: "center", }}>
                    <Typography fontWeight={"bold"}>{props.title}</Typography>
                    <Typography>{props.date}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={150}>รายชื่อลูกค้า</TableCell>
                  <TableCell>เบอร์โทรศัพท์</TableCell>
                  <TableCell>วันที่เกิดลูกค้า</TableCell>
                  <TableCell>โครงการ</TableCell>
                  <TableCell>บ้าน</TableCell>
                  <TableCell>เลขที่ใบจอง</TableCell>
                  <TableCell>สถานะ</TableCell>
                  <TableCell width={100}>บันทึก</TableCell>
                  <TableCell width={100}>แก้ไข</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data && props.data?.length > 0 && props.data?.map((row, index) => (
                  <TableRow
                    key={row.id + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                    <TableCell component="th" scope="row" style={{ textDecoration: row.isDelete ? "line-through" : "none" }} >
                      {row?.firstname || "-"} {row?.lastname || ""}
                    </TableCell>
                    <TableCell>{row?.tel || "-"}</TableCell>
                    <TableCell align="center">{dayjs(row?.birthdate).format("DD/MM/YYYY") !== "Invalid Date" ? dayjs(row?.birthdate).format("DD/MM/YYYY") : "-"}</TableCell>
                    <TableCell>{row?.booking?.project?.name || "-"}</TableCell>
                    <TableCell>{row?.booking?.house?.name || "-"}</TableCell>
                    <TableCell>{row?.booking?.bookingNo || "-"}</TableCell>
                    <TableCell>
                      {row?.booking?.status?.name}
                    </TableCell>
                    <TableCell >{<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                  </TableRow>
                ))}
                {props.data?.length === 0 && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                  </TableRow>
                )}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </Box>
  );
});

export default PrintCustomer;