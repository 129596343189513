import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class LandStore {
  lands: any = {};
  landTaxs: any = {};
  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/lands");
      this.lands = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting lands", error);
      return {
        error: true,
        message: "An error occurred while getting lands",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/lands/${id}`);
      this.lands = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting land", error);
      return {
        error: true,
        message: "An error occurred while getting land",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/lands/criteria", criteria);
      this.lands = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting lands", error);
      return {
        error: true,
        message: "An error occurred while getting lands",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/lands", data);
      this.lands = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating land", error);
      return {
        error: true,
        message: "An error occurred while creating land",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/lands/${id}`, data);
      this.lands = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating land", error);
      return {
        error: true,
        message: "An error occurred while updating land",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/lands/${id}`);
      this.lands = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting land", error);
      return {
        error: true,
        message: "An error occurred while deleting land",
      };
    }
  }

  async getByHouseId(id: string) {
    try {
      const response = await apiService.get(`/lands/house/${id}`);
      this.lands = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting lands", error);
      return {
        error: true,
        message: "An error occurred while getting lands",
      };
    }
  }

  async getByProjectId(id: string) {
    try {
      const response = await apiService.get(`/lands/project/${id}`);
      this.lands = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting lands", error);
      return {
        error: true,
        message: "An error occurred while getting lands",
      };
    }
  }

  async createLandTax(data: any) {
    try {
      const response = await apiService.post(`/lands/land-tax`, data);
      this.landTaxs = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating land tax", error);
      return {
        error: true,
        message: "An error occurred while creating land tax",
      };
    }
  }

  async updateLandTax(id: string, data: any) {
    try {
      const response = await apiService.put(`/lands/land-tax/${id}`, data);
      this.landTaxs = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating land tax", error);
      return {
        error: true,
        message: "An error occurred while updating land tax",
      };
    }
  }
}
const landStore = new LandStore();
export default landStore;
