import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";

interface PrintInventoriesProps {
  title?: string;
  date?: string;
  data: any[];
}

const PrintInventories = React.forwardRef(
  (props: PrintInventoriesProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          margin: 0,
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "center",
          "&@page": {
            size: "A4 portrait",
          },
          overflow: "hidden",
          fontSize: 8,
          display: "block",
          pageBreakBefore: "auto", // Allow page break before this element
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650, overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                      <Typography>{props.date}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>วันที่เอกสาร</TableCell>
                    <TableCell>เลขเอกสาร</TableCell>
                    <TableCell>ชื่อผู้ทำรายการ</TableCell>
                    <TableCell align="center">ประเภท</TableCell>
                    <TableCell>เอกสารอ้างอิง</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                    <TableCell width={100}>บันทึก</TableCell>
                    <TableCell width={100}>แก้ไข</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.length > 0 &&
                    props?.data?.map((row: any, index: number) => (
                      <TableRow
                        key={`bill-row-${index}`}
                        sx={{ "&:last-child ": { borderBottom: 1 } }}
                      >
                        <TableCell component="th" scope="row">
                          {dayjs(row.docDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{row.docNo}</TableCell>
                        <TableCell>
                          {row.worker?.firstName} {row.worker?.lastName}
                        </TableCell>
                        <TableCell align="center">
                          {(() => {
                            switch (row.type) {
                              case "return":
                                return "คืนวัสดุ";
                              case "requisition":
                                return "เบิกวัสดุ";
                              case "add":
                                return "เพิ่มวัสดุ";
                              default:
                                return null;
                            }
                          })()}
                        </TableCell>
                        <TableCell>{row.docRef}</TableCell>
                        <TableCell>{row.isCancelled ? "ยกเลิก" : ""}</TableCell>
                        <TableCell>
                          {<b>{row.createBy?.name}</b>}{" "}
                          {dayjs(row.createAt).format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                        <TableCell>
                          {<b>{row.updateBy?.name}</b>}{" "}
                          {row.updateAt
                            ? dayjs(row.updateAt).format("DD/MM/YYYY HH:mm")
                            : ""}{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  {props.data.length === 0 && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell colSpan={8} align="center">
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default PrintInventories;
