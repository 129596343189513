import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class ProjectsStore {
  projects: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/projects");
      this.projects = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting projects:", error);
      return {
        error: true,
        message: "An error occurred while getting projects",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/projects/${id}`);
      this.projects = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting project:", error);
      return {
        error: true,
        message: "An error occurred while getting project",
      };
    }
  }

  async getByNameLike(name: string) {
    try {
      const response = await apiService.get(`/projects/name/like/${name}`);
      this.projects = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting project:", error);
      return {
        error: true,
        message: "An error occurred while getting project",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/projects/criteria", criteria);
      this.projects = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting projects:", error);
      return {
        error: true,
        message: "An error occurred while getting projects",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/projects", data);
      this.projects = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating project:", error);
      return {
        error: true,
        message: "An error occurred while creating project",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/projects/${id}`, data);
      this.projects = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating project:", error);
      return {
        error: true,
        message: "An error occurred while updating project",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/projects/${id}`);
      this.projects = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting project:", error);
      return {
        error: true,
        message: "An error occurred while deleting project",
      };
    }
  }

  async getWithExpenses() {
    try {
      const response = await apiService.get(`/projects/expense/total`);
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting projects with expenses:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting projects with expenses",
      };
    }
  }

  async getWithExpensesByName(name: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/total/name/${name}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting projects with expenses:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting projects with expenses",
      };
    }
  }

  async getWithExpensesByProject(id: string) {
    try {
      const response = await apiService.get(`/projects/expense/total/${id}`);
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting projects with expenses:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting projects with expenses",
      };
    }
  }

  async getDetailWithExpensesByProject(id: string) {
    try {
      const response = await apiService.get(`/projects/expense/detail/${id}`);
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting projects with expenses:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting projects with expenses",
      };
    }
  }

  async getDetailWithExpensesByProjectOnlyHouseNull(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/detail/only/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting projects with expenses:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting projects with expenses",
      };
    }
  }

  async getDetailWithExpensesByHouse(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/detail/house/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting projects with expenses:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting projects with expenses",
      };
    }
  }

  async getExpenseWithProductGroupByProjectId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/product/group/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting product group by project id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting product group by project id",
      };
    }
  }

  async getExpenseWithProductGroupByHouseId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/product/group/house/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting product group by house id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting product group by house id",
      };
    }
  }

  async getExpenseWithProductCategoryByProjectId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/product/category/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting product category by project id:",
        error
      );
      return {
        error: true,
        message:
          "An error occurred while getting product category by project id",
      };
    }
  }

  async getExpenseWithProductCategoryByHouseId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/product/category/house/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting product category by house id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting product category by house id",
      };
    }
  }

  async getExpenseWithWorkerByProjectId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/worker/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting worker by project id:",
        error
      );
      return {
        error: true,
        message:
          "An error occurred while getting worker by project id",
      };
    }
  }

  async getExpenseWithWorkerByHouseId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/worker/house/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting worker by house id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting worker by house id",
      };
    }
  }

  async getExpenseWithLandByHouseId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/land/house/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting land by house id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting land by house id",
      };
    }
  }

  async getExpenseWithLandByProjectId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/land/project/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting land by project id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting land by project id",
      };
    }
  }

  async getExpenseWithLandTaxByHouseId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/land-tax/house/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting land by house id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting land by house id",
      };
    }
  }

  async getExpenseWithLandTaxByProjectId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/land-tax/project/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting land by project id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting land by project id",
      };
    }
  }

  async getExpenseAllByProjectId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/all/project/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting land by project id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting land by project id",
      };
    }
  }

  async getExpenseAllByHouseId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/all/house/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting all expense by house id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting all expense by house id",
      };
    }
  }

  async getExpenseAllByHouseIdAndProductId(criteria: any) {
    try {
      const response = await apiService.post(
        `/projects/expense/all/house/product`, criteria
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting all expense by house id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting all expense by house id",
      };
    }
  }

  async getExpenseWithCommonByProjectId(id: string) {
    try {
      const response = await apiService.get(
        `/projects/expense/all/project/common-fee/${id}`
      );
      this.projects = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting all common fee by project id:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting all common fee by project id",
      };
    }
  }

}
const projectsStore = new ProjectsStore();
export default projectsStore;
