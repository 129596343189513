import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { } from "react";
import dayjs from "dayjs";
interface PrintInventoryProps {
  title?: string;
  date?: string;
  data: any;
  total?: string;
}

const PrintInventory = React.forwardRef((props: PrintInventoryProps, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        margin: 0,
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        paddingBottom: "20px",
        '@page': {
          size: "A4 portrait", // Default page size is portrait
        },
        overflow: "hidden",
        fontSize: 8,
        display: "block",
        pageBreakBefore: "auto", // Allow page break before this element 
        pageBreakAfter: "auto", // Allow page break before this element 
        '@media print': {
          '@page': {
            size: "A4 portrait", // Subsequent pages are landscape
            margin: "60px",
          },
          footer: {
            display: 'none'
          }
          , header: {
            display: 'none'
          }
        },
      }}
      className='print-contract'>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <Grid item xs={9} display={'flex'} justifyContent={'center'} alignItems={'center'} >
            <Typography sx={{ paddingLeft: '200px' }} className="po-title">{props.title}</Typography>
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'end'} alignItems={'center'}>
            <Grid item xs={5} >
              <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                <Grid item xs={5} border={1} p={1} >
                  <Typography className="po-body" fontWeight={'bold'} >เลขที่</Typography>
                </Grid>
                <Grid item xs={7} p={1} borderTop={1} borderRight={1} borderBottom={1}>
                  <Typography className="po-body"  >{props.data?.docNo}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                <Grid item xs={5} borderLeft={1} borderRight={1} borderBottom={1} p={1}>
                  <Typography className="po-body" fontWeight={'bold'} >วันที่</Typography>
                </Grid>
                <Grid item xs={7} p={1} borderRight={1} borderBottom={1}>
                  <Typography className="po-body"  >{dayjs(props.data.docDate).format('DD/MM/YYYY')}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                <Grid item xs={5} borderLeft={1} borderRight={1} borderBottom={1} p={1}>
                  <Typography className="po-body" fontWeight={'bold'} >เอกสารอ้างอิง</Typography>
                </Grid>
                <Grid item xs={7} p={1} borderRight={1} borderBottom={1}>
                  <Typography className="po-body"  >{(props?.data?.refNo?.docNo ? props?.data?.refNo?.docNo : (props?.data?.refNo || '-'))}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Table sx={{ border: 1 }}>
              <TableHead>
                <TableRow sx={{ padding: 0 }}>
                  <TableCell align="center" width={10} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>ลำดับ</Typography>
                  </TableCell>
                  <TableCell align="center" width={260} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>รายการ</Typography>
                  </TableCell>
                  <TableCell align="center" width={30} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>จำนวน</Typography>
                  </TableCell>
                  <TableCell align="center" width={100} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>หมายเหตุ</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data.items?.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="po-body" align="center" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 1, paddingBottom: 1 }} >
                      {index + 1}
                    </TableCell>
                    <TableCell className="po-body" align="left" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                      {item?.product?.name ?? ''} (<b>โครงการ:</b> {item?.project?.name ?? ''} <b>บ้าน:</b> {item?.house?.name ?? ''})
                    </TableCell>
                    <TableCell className="po-body" align="right" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                      {parseFloat((item.quantity ?? 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell className="po-body" align="left" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                      {item?.remark && <Box>{item?.remark}</Box>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid container mt={1} >
              <Grid item xs={12} border={1} sx={{ textAlign: 'left', padding: 1 }}>
                <Grid item xs={12} >
                  <Typography className="po-body" sx={{ fontWeight: 'bold' }}>หมายเหตุ</Typography>
                </Grid>
                <Grid item xs={12} >
                  <Typography className="po-body" >{props.data.remark ? props.data.remark : ''}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          < Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
              <Typography className="po-body">............................................................................</Typography>
              <Typography className="po-body">{props?.data?.workerValue ? props?.data?.workerValue : '\u00A0'}</Typography>
              <Typography className="po-body" >ผู้ทำรายการ</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} ml={5}>
              <Typography className="po-body">............................................................................</Typography>
              <Typography className="po-body" >&nbsp;</Typography>
              <Typography className="po-body" >ผู้อนุมัติ</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>);
});
export default PrintInventory;