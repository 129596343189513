import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/th';

interface ExportFileExpensesHouseDetailProps {
  title?: string;
  date?: string;
  data: any;
  companyInfo?: any;
  totalMaterial?: any;
  totalWage?: any;
  totalExpense?: any;
}

const ExportFileExpensesHouseDetail = React.forwardRef((props: ExportFileExpensesHouseDetailProps, ref) => {

  return (
    <Box ref={ref} sx={{ textAlign: 'center', padding: '20px', '&@page': { size: 'A4' }, }}>
      <Grid container spacing={2} className="detail">
        <Grid item xs={12}>
          <TableContainer >
            <Table   >
              <TableHead>
                <TableRow >
                  <TableCell colSpan={10} sx={{ fontWeight: 'bold', borderBottom: 1, lineHeight: 0.5, textAlign: 'center' }}>  <Typography fontWeight={"bold"}>รายการประมาณราคา</Typography>
                    <Typography fontWeight={"bold"}>{props.title}</Typography>
                    <Typography fontWeight={"bold"}>วันที่ {dayjs(props.date).format('DD')}  {dayjs(props?.data?.docDate !== 'Invalid Date' ? props?.data?.docDate : new Date()).format('MMMM')} พ.ศ. {dayjs(props?.data?.docDate !== 'Invalid Date' ? props?.data?.docDate : new Date()).format('BBBB')}</Typography></TableCell>
                </TableRow>
                <TableRow sx={{ borderColor: 'text.primary' }}>
                  <TableCell rowSpan={2} sx={{ fontWeight: 'bold', borderBottom: 1, lineHeight: 0.5, borderLeft: 1 }}>Item</TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, borderLeft: 1 }}>
                    รายการ/Description
                  </TableCell>
                  <TableCell colSpan={2} sx={{ fontWeight: 'bold', borderBottom: 1, lineHeight: 0.5, textAlign: "center", borderLeft: 1 }}>
                    ปริมาณ/Quantity
                  </TableCell>
                  <TableCell colSpan={2} sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, borderLeft: 1 }}>
                    ค่าวัสดุ/Material cost
                  </TableCell>
                  <TableCell colSpan={2} sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, borderLeft: 1 }}>
                    ค่าแรง/Labour cost
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, borderLeft: 1 }}>
                    Total
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, borderLeft: 1, borderRight: 1 }}>
                    หมายเหตุ
                  </TableCell>
                </TableRow>
                <TableRow sx={{ borderColor: 'text.primary' }}>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 1, lineHeight: 0.5, borderLeft: 1 }}>
                    จำนวน
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, borderLeft: 1 }}>
                    Unit
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 1, lineHeight: 0.5, borderLeft: 1 }}>
                    Unit price
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, borderLeft: 1 }}>
                    Total
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, width: 100, borderLeft: 1 }}>
                    Unit price
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 1, textAlign: 'center', lineHeight: 0.5, borderLeft: 1, borderRight: 1 }}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props.data?.categories && props.data?.categories.length > 0 && props.data?.categories?.map((row: any, index: number) => (
                    <React.Fragment key={`export-file-expense-house-detail-${index.toString()}- ${row?.id}-file-box`}>
                      <TableRow sx={{ borderColor: 'text.primary' }} key={`export-file-expense-house-detail-${index.toString()}- ${row?.id}-file-table-row`}>
                        <TableCell sx={{ borderBottom: 1, fontWeight: "bold", borderLeft: 1 }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}>
                          หมวด{row?.productCategoryName || ''}
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1, borderRight: 1 }}>
                        </TableCell>
                      </TableRow>
                      {
                        row?.groups?.length > 0 && row?.groups?.map((group: any, indexGroup: number) => (
                          <React.Fragment key={`export-file-expense-house-detail-group-${index.toString()}- ${row?.productCategoryId}- ${group?.productGroupId}-file-box`}>
                            <TableRow sx={{ borderColor: 'text.primary', borderLeft: 1 }} key={`export-file-expense-house-detail-group-${index.toString()}- ${row?.id}- ${group?.id}-file-table-row`}>
                              <TableCell sx={{ borderBottom: 1 }}>
                                {indexGroup + 1}
                              </TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'left', borderLeft: 1 }}>
                                {group?.productGroupName || ''}
                              </TableCell>
                              <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>  </TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>  {parseFloat(group?.totalWage || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'right', borderLeft: 1 }}>{parseFloat(group?.totalWage || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1, borderRight: 1 }}></TableCell>
                            </TableRow>
                            {
                              group?.products?.length > 0 && group?.products?.map((product: any, indexProduct: number) => (
                                <TableRow sx={{ borderColor: 'text.primary' }} key={`export-file-expense-house-detail-group-product-${index.toString()}- ${row?.productCategoryId}- ${indexProduct}- ${indexProduct}-file-box`}>
                                  <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'left', borderLeft: 1 }}>
                                    {product?.product || ''}
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'right', borderLeft: 1 }}>
                                    {parseFloat(product?.quantity).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>
                                    {product?.unit || ''}
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'right', borderLeft: 1 }}>
                                    {(parseFloat(product?.total) / parseFloat(product?.quantity)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'right', borderLeft: 1 }}>
                                    {parseFloat(product?.total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>
                                    {/* TODO: ค่าแรงตาม Product */}
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'right', borderLeft: 1 }}>
                                    {parseFloat(product?.total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1, borderRight: 1 }}></TableCell>
                                </TableRow>
                              ))
                            }
                            <TableRow sx={{ borderColor: 'text.primary' }}>
                              <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>  </TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}>
                                รวมงาน{group?.productGroupName || ''}
                              </TableCell>
                              <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}></TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>  </TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'right', fontWeight: "bold", borderLeft: 1 }}>
                                {parseFloat(group?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                              </TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'right', fontWeight: "bold", borderLeft: 1 }}>
                                {parseFloat(group?.totalWage || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                              </TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'right', fontWeight: "bold", borderLeft: 1 }}>
                                {(parseFloat(group?.totalWage || 0) + parseFloat(group?.total || 0)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                              </TableCell>
                              <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1, borderRight: 1 }}></TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      }
                      <TableRow sx={{ borderColor: 'text.primary' }}>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>  </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}>
                          รวมงาน{row?.productCategoryName || ''}
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>  </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1, borderRight: 1 }}></TableCell>
                      </TableRow>
                      <TableRow sx={{ borderColor: 'text.primary' }}>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>  </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}>
                          รวมเงิน ค่าวัสดุ+ค่าแรง /Total Material+Labour
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>  </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'right', fontWeight: "bold", borderLeft: 1 }}>
                          {parseFloat(row?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'right', fontWeight: "bold", borderLeft: 1 }}>
                          {parseFloat(row?.groups[index]?.totalWage || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1, borderRight: 1 }}></TableCell>
                      </TableRow>
                      <TableRow sx={{ borderColor: 'text.primary' }}>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}>  </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}>
                          รวมเงิน{row?.productCategoryName || ''}
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}>  </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', fontWeight: "bold", borderLeft: 1 }}></TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'right', fontWeight: "bold", borderLeft: 1 }}>
                          {(parseFloat(row?.groups[index]?.totalWage || 0) + parseFloat(row?.total || 0)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </TableCell>
                        <TableCell sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1, borderRight: 1 }}></TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ExportFileExpensesHouseDetail;