import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
interface PrinWorkersProps {
  title?: string;
  summary?: string;
  data: any[];
}

const PrintWorkers = React.forwardRef((props: PrinWorkersProps, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        margin: 0,
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
        "&@page": {
          size: "A4 portrait",
        },
        overflow: "hidden",
        fontSize: 8,
        display: "block",
        pageBreakBefore: "auto",
      }}
    >
      <Grid container spacing={2}>
        {" "}
        <Grid item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650, overflow: "hidden" }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                    <Typography fontWeight={"bold"}>{props.title}</Typography>
                    <Typography fontWeight={"bold"}>
                      มูลค่ารวม: {props.summary} บาท
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={10} sx={{ textAlign: "center" }}>
                    ลำดับ
                  </TableCell>
                  <TableCell width={300} sx={{ textAlign: "center" }}>
                    รายการ
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>ค่าแรง</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    จำนวนเงิน OT
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    รวมค่าแรง + OT
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>เบิก</TableCell>
                  <TableCell width={100} sx={{ textAlign: "end" }}>
                    คงเหลือ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 &&
                  props?.data
                    .slice() // Create a copy to avoid modifying the original array
                    .sort((a: any, b: any) => {
                      return dayjs(a.periodDateFrom).diff(b.periodDateFrom);
                    })
                    .map((item: any, index: number) => {
                      return (
                        <React.Fragment key={`print-woker-${item.id}`}>
                          <TableRow key={item.id}>
                            <TableCell sx={{ textAlign: "center" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                            >{`ค่าแรงงวดวันที่ ${dayjs(
                              item?.periodDateFrom
                            ).format("DD/MM/YYYY")} ถึง ${dayjs(
                              item?.periodDateTo
                            ).format("DD/MM/YYYY")}`}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              align="right"
                              sx={{ fontWeight: "bold" }}
                            >
                              {parseFloat(item?.total ?? 0).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                          </TableRow>
                          {item?.wageDetails?.length > 0 &&
                            item?.wageDetails?.map(
                              (detail: any, indexDetail: number) => {
                                return (
                                  <React.Fragment
                                    key={`wageDetails-${detail?.id}-${indexDetail}`}
                                  >
                                    <TableRow
                                      key={`print-wageDetails-detail-${detail?.id}-${indexDetail}`}
                                    >
                                      <TableCell></TableCell>
                                      <TableCell>
                                        -{detail?.worker?.firstName}{" "}
                                        {detail?.worker?.lastName}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        {parseFloat(
                                          detail?.totalLaborCost ?? 0
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        {parseFloat(
                                          detail?.totalNet ?? 0
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        {parseFloat(
                                          detail?.totalLaborAndCostOt ?? 0
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        {parseFloat(
                                          detail?.withdraw ?? 0
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        {parseFloat(
                                          detail?.totalNet ?? 0
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                );
                              }
                            )}
                        </React.Fragment>
                      );
                    })}
                <TableRow>
                  <TableCell colSpan={6} align="right">
                    <b>รวมทั้งหมด</b>
                  </TableCell>
                  <TableCell colSpan={1} align="right">
                    {props.summary}
                  </TableCell>
                </TableRow>
                {props.data && props.data.length === 0 && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell colSpan={10} align="center">
                      ไม่พบข้อมูล
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

export default PrintWorkers;
