export default function checkPermission(
    user: any,
    menu: string,
    crud?: string
): boolean {

    if (!crud) {
        return user.some((role: any) => role.menu.name === menu);
    } else {
        return user.some((role: any) => role.menu.name === menu).some((role: any) => role.crud);
    }
}