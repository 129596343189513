import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class CashflowStore {
  cashflow: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/cashflow");
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting cashflow:", error);
      return {
        error: true,
        message: "An error occurred while getting cashflow",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/cashflow/${id}`);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting cashflow:", error);
      return {
        error: true,
        message: "An error occurred while getting cashflow",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/cashflow", data);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating cashflow:", error);
      return {
        error: true,
        message: "An error occurred while creating cashflow",
      };
    }
  }

  async createDetail(data: any) {
    try {
      const response = await apiService.post("/cashflow/detail", data);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating cashflow detail:", error);
      return {
        error: true,
        message: "An error occurred while creating cashflow detail",
      };
    }
  }

  async createItem(data: any) {
    try {
      const response = await apiService.post("/cashflow/item", data);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating purchase item:", error);
      return {
        error: true,
        message: "An error occurred while creating purchase item",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/cashflow/${id}`, data);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating purchase:", error);
      return {
        error: true,
        message: "An error occurred while updating purchase",
      };
    }
  }

  async updateDetail(id: string, data: any) {
    try {
      const response = await apiService.put(`/cashflow/detail/${id}`, data);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating purchase detail:", error);
      return {
        error: true,
        message: "An error occurred while updating purchase detail",
      };
    }
  }

  async updateItem(id: string, data: any) {
    try {
      const response = await apiService.put(`/cashflow/item/${id}`, data);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating purchase item:", error);
      return {
        error: true,
        message: "An error occurred while updating purchase item",
      };
    }
  }

  async delete(id: any) {
    try {
      const response = await apiService.delete(`/cashflow/${id}`);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting purchase:", error);
      return {
        error: true,
        message: "An error occurred while deleting purchase",
      };
    }
  }

  async deleteDetail(id: any) {
    try {
      const response = await apiService.delete(`/cashflow/detail/${id}`);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting purchase detail:", error);
      return {
        error: true,
        message: "An error occurred while deleting purchase detail",
      };
    }
  }
  async deleteItemDetail(id: string) {
    try {
      const response = await apiService.delete(`/cashflow/item/${id}`);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting purchase detail:", error);
      return {
        error: true,
        message: "An error occurred while deleting purchase detail",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post(`/cashflow/criteria`, criteria);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting cashflow by criteria:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting cashflow by criteria",
      };
    }
  }

  async dashboard(data: any) {
    try {
      const response = await apiService.post(`/cashflow/dashboard`, data);
      this.cashflow = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting cashflow:", error);
      return {
        error: true,
        message: "An error occurred while getting cashflow",
      };
    }

  }

}
const cashflowStore = new CashflowStore();
export default cashflowStore;