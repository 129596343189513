import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import 'dayjs/locale/th';

interface PrintWagesProps {
  title?: string;
  data: any[];
}

const PrintWages = React.forwardRef((props: PrintWagesProps, ref) => {

  return (
    <Box ref={ref} sx={{
      margin: 0,
      width: "100%",
      borderCollapse: "collapse",
      textAlign: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
      '&@page': {
        size: 'A4 portrait',
      },
      overflow: 'hidden',
      fontSize: 8,
      display: 'block',
      pageBreakBefore: 'auto', // Allow page break before this element    
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer >
            <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
              <TableHead>
                <TableCell colSpan={10} sx={{ textAlign: 'center', }}>
                  <Typography fontWeight={'bold'}>{props.title}</Typography>
                </TableCell>
                <TableRow>
                  <TableCell width={10}>ลำดับ</TableCell>
                  <TableCell width={20}>ช่วงวันที่</TableCell>
                  <TableCell width={200}>คนงาน</TableCell>
                  <TableCell width={50}>ยอดรวม</TableCell>
                  <TableCell width={50}>ยอดเบิก</TableCell>
                  <TableCell width={50}>ยอดคงเหลือ</TableCell>
                  <TableCell width={30}>บันทึก</TableCell>
                  <TableCell width={30}>แก้ไข</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 && props?.data?.map((row: any, index: number) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell component="th" width={100}>
                      {`${dayjs(row.periodDateFrom).format('DD/MM/YYYY')} - ${dayjs(row.periodDateTo).format('DD/MM/YYYY')}`}
                    </TableCell>
                    <TableCell width={200} align="left">
                      {row?.wageDetails?.sort((a: any, b: any) => dayjs(a.createAt).diff(b.createAt)).map((wageDetail: any, index: number) => {
                        return <span key={wageDetail.id}>{`${wageDetail.worker?.firstName || ''}${index < row?.wageDetails.length - 1 ? ', ' : ''} `}</span>;

                      })}
                    </TableCell>
                    <TableCell>{parseFloat(row.total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                    <TableCell>{parseFloat(row.withdraw).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                    <TableCell>{parseFloat(row.netTotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                    <TableCell width={30} align="center">{<b>{row.createBy?.name || row.createBy?.firstName || ''}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell width={30} align="center">{<b>{row.updateBy?.name || row.updateBy?.firstName || ''}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                  </TableRow>
                ))}
                {props.data.length === 0 && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box >
  );
});

export default PrintWages;