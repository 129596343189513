import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface PrintHouseProductsProps {
    title?: string;
    summary?: string;
    data: any[];
}

const PrintHouseProducts = React.forwardRef((props: PrintHouseProductsProps, ref) => {
    const [grandTotal, setGrandTotal] = useState(0);
    const calculateGrandTotal = () => {
        return props.data ? props.data.reduce((accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue?.total || 0);
        }, 0) : 0;
    };
    useEffect(() => {
        const calculatedTotal = calculateGrandTotal();
        setGrandTotal(calculatedTotal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);
    return (
        <Box ref={ref} sx={{
            margin: 0,
            width: "100%",
            borderCollapse: "collapse",
            textAlign: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
            '&@page': {
                size: 'A4 portrait',
            },
            overflow: 'hidden',
            fontSize: 8,
            display: 'block',
            pageBreakBefore: 'auto',
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ textAlign: 'center', }}>
                                        <Typography fontWeight={'bold'}>{props.title}</Typography>
                                        <Typography fontWeight={'bold'}>
                                            มูลค่ารวม: {props.summary} บาท</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width={10}>ลำดับ</TableCell>
                                    <TableCell width={300}>สินค้า</TableCell>
                                    <TableCell width={50}>จำนวน</TableCell>
                                    <TableCell width={100} sx={{ textAlign: 'end' }}>มูลค่ารวม</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props?.data?.length > 0 && props?.data?.map((row: any, index: number) => (
                                    <TableRow
                                        key={`pd-${index.toString()}`}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.product}</TableCell>
                                        <TableCell>{row.quantity}</TableCell>
                                        <TableCell align="right">{parseFloat(row.total.toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={3} align="right"><b>รวมทั้งหมด</b></TableCell>
                                    <TableCell colSpan={1} align="right">{parseFloat(grandTotal.toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                </TableRow>
                                {props.data && props.data.length === 0 && (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box >
    );
});

export default PrintHouseProducts;