import axios from "axios";

class ApiService {
  apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  async setAccessToken() {
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    const access_token = user?.access_token || "";
    if (access_token) {
      this.apiInstance.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    }
  }

  async get(path: string) {
    try {
      await this.setAccessToken();
      const response = await this.apiInstance.get(path);
      return response.data;
    } catch (error: any) {
      console.error(
        "An error occurred while making the GET API request:",
        error
      );
      this.checkError(error);
      throw error;
    }
  }

  async post(path: string, data: any) {
    try {
      await this.setAccessToken();
      const response = await this.apiInstance.post(path, data);
      return response.data;
    } catch (error: any) {
      console.error(
        "An error occurred while making the POST API request:",
        error
      );
      this.checkError(error);
      throw error;
    }
  }

  async postFormData(path: string, data: any) {
    try {
      await this.setAccessToken();
      const response = await this.apiInstance.post(path, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error: any) {
      console.error(
        "An error occurred while making the POST API request:",
        error
      );
      this.checkError(error);
      throw error;
    }
  }
  async putFormData(path: string, data: any) {
    try {
      await this.setAccessToken();
      const response = await this.apiInstance.put(path, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error: any) {
      console.error(
        "An error occurred while making the PUT API request:",
        error
      );
      this.checkError(error);
      throw error;
    }
  }

  async put(path: string, data: any) {
    try {
      await this.setAccessToken();
      const response = await this.apiInstance.put(path, data);
      return response.data;
    } catch (error: any) {
      console.error(
        "An error occurred while making the PUT API request:",
        error
      );
      this.checkError(error);
      throw error;
    }
  }

  async delete(path: string) {
    try {
      await this.setAccessToken();
      const response = await this.apiInstance.delete(path);
      return response.data;
    } catch (error: any) {
      console.error(
        "An error occurred while making the DELETE API request:",
        error
      );
      this.checkError(error);
      throw error;
    }
  }

  checkError(error: any) {
    if (error.response.status === 401) {
      alert("เซสชั่นหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
  }
}

const apiService = new ApiService();
export default apiService;
