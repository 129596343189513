import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import updateLocale from 'dayjs/plugin/updateLocale';
import '../../../../print.css';
interface PrintOneContractProps {
  title?: string;
  date?: string;
  companyInfo?: any;
  data: any;
  details?: any[];
  type?: string;
  image?: any;
  employerTypes?: any;
  employer?: any;
}

const PrintOneContract = React.forwardRef((props: PrintOneContractProps, ref) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  dayjs.extend(buddhistEra);
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    months: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ],
    weekdays: [
      'อาทิตย์',
      'จันทร์',
      'อังคาร',
      'พุธ',
      'พฤหัสบดี',
      'ศุกร์',
      'เสาร์',
    ]
  });

  return (
    <Box
      ref={ref}
      sx={{
        margin: 0,
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        paddingBottom: "40px",
        '@page': {
          size: "A4 portrait", // Default page size is portrait
        },
        overflow: "hidden",
        fontSize: 8,
        display: "block",
        pageBreakBefore: "auto", // Allow page break before this element 
        pageBreakAfter: "auto", // Allow page break before this element 
        '@media print': {
          '@page': {
            size: "A4 portrait", // Subsequent pages are landscape
            margin: "60px",
          },
        },
      }}
      className='print-contract'
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <>
            {props?.companyInfo?.images && props?.employerTypes === 'company' &&
              <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                <img style={{ height: '100%', position: 'relative' }}
                  src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                  alt='Centered'
                /></Grid>
            }
            {props?.employerTypes === 'company' &&
              <>
                <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
                <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
                <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
                <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
                <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              </>

            }
            <div dangerouslySetInnerHTML={{ __html: props?.data }} style={{ fontSize: '14px' }} />
          </>
          {/* NOTE:May use in the future */}
          {/* NOTE: สัญญาว่าจ้างติดกระจก */}
          {/* {(props.type === 'glass') &&
            <>
              {props?.companyInfo?.images &&
                <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                  <img style={{ height: '100%', position: 'relative' }}
                    src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                    alt='Centered'
                  /></Grid>
              }
              <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'>ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างติดกระจกบ้านเดี่ยวชั้นเดียว จำนวน <b>{props?.data?.amountOfHouse ?? ''}</b> หลัง เป็นจำนวนเงิน </Box>
                <Box><b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท (รวมค่าของและค่าแรง) ดังมีข้อความต่อไปนี้</Box>
                <Box className='text-box'>ข้อ 1 ผู้ว่าจ้างตกลงว่าจ้าง  และผู้รับจ้างตกลงรับจ้างติดกระจกบ้านเดี่ยวชั้นเดียวบนที่ดินแปลงเลขที่</Box>
                <Box><b>{props?.data?.house?.land?.deedNo}</b> ระวาง <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ <b>{props?.data?.house?.land?.dealingFileNo}</b> ตำบล<b>{props?.data?.house?.land?.landSubDistrict?.nameTh}</b> อำเภอ<b>{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''}</b> จังหวัด<b>{props?.data?.house?.land?.landProvince?.nameTh ?? ''} {props?.data?.house?.name}</b>
                </Box>
                <Box>ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>
                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการติดกระจกบ้านตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด <b>{props?.data?.amountOfMonth ?? ''}</b> เดือน</Box>
                <Box>นับแต่วันทำสัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงัก</Box>
                <Box>ลงโดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุ
                  ดังกล่าว</Box>
                <Box className='text-box'>ข้อ 3 ก่อนลงมือก่อสร้าง ผู้รับจ้างต้องทำการตรวจสอบสถานที่และสภาพที่เป็นอยู่ รังวัดตรวจสอบ
                </Box>
                <Box>หมุดหลักเขต จัดทำระดับแนวและระยะต่างๆ ในแบบก่อสร้างและเสนอผลการตรวจสอบให้แก่ผู้ว่าจ้างพร้อมทั้งแสดง
                  สภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงานความเคลื่อน ไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป
                </Box>
                <Box className='text-box'>ข้อ 4 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด</Box>
                <Box>จะต้องแสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด</Box>
                <Box className='text-box'>ข้อ 5 ผู้ว่าจ้างตกลงชำระค่าจ้างให้แก่ผู้รับจ้างเป็นเงินทั้งสิ้น <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)})</b> </Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} {period?.description} ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>
                  <b>
                    ({props?.data?.remark ?? ''})
                  </b>
                </Box>
                <Box className='text-box'>ข้อ 6 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการดำเนินงานรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้</Box>
                <Box>จัดหา ซึ่งจะต้องใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างต้องทำงานทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้ ผู้ว่าจ้างไม่</Box>
                <Box>ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไปตามแบบ และมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการทำงาน ได้ทุกเมื่อทุกเวลา แต่ผู้ว่าจ้าง</Box>
                <Box>ต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <Box className='text-box'>ข้อ 9 ผู้รับจ้างต้องปฏิบัติงานด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>
                <Box className='text-box' >ข้อ 10 ในขณะปฏิบัติงานผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุอันเกิดจากการ</Box>
                <Box>ปฏิบัติงาน</Box>
                <Box className='text-box'>ข้อ 11 เมื่อผู้รับจ้างได้ทำการมุงหลังคาเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันที</Box>
                <Box>เพื่อให้ผู้ว่าจ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป </Box>
                <Box className='text-box'>ข้อ 12 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงาน</Box>
                <Box>งวดสุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ</Box>
                <Box>  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม</Box>
                <Box className='text-box'>ข้อ 13 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่</Box>
                <Box>ระบุไว้ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ <b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 14 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญา</Box>
                <Box>นี้ ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน <b>{props?.data?.notifyDate ?? ''}</b> วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย</Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>

              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>

                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>({props?.companyInfo?.ownerName ?? ''})</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* NOTE: สัญญาว่าจ้างเหมาก่อฉาบพร้อมติดตั้งวงกบ */}
          {/* {(props.type === 'plastering_frames') &&
            <>
              {props?.companyInfo?.images && <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                <img style={{ height: '100%', position: 'relative' }}
                  src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                  alt='Centered'
                /></Grid>
              }
              <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'>ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างเหมาโครงสร้างบ้านเดี่ยว รวมเป็นจำนวนเงิน <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ดังมี
                </Box>
                <Box>ข้อความต่อไปนี้</Box>
                <Box className='text-box'>ข้อ 1 ผู้ว่าจ้างตกลงว่าจ้าง และผู้รับจ้างตกลงรับจ้างทำโครงสร้างบ้านเดี่ยว <b>{props?.data?.house?.name ?? ''}</b>  โฉนดที่ดินเลขที</Box>
                <Box><b>{props?.data?.house?.land?.deedNo}</b> ระวาง <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ <b>{props?.data?.house?.land?.dealingFileNo}</b> ตำบล<b>{props?.data?.house?.land?.landSubDistrict?.nameTh}</b> อำเภอ<b>{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''}</b> จังหวัด<b>{props?.data?.house?.land?.landProvince?.nameTh ?? ''} {props?.data?.house?.name}</b>ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>
                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการก่อสร้างตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด <b>{props?.data?.amountOfMonth ?? ''}</b> เดือน นับแต่วันทำ</Box>
                <Box>สัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงักลง</Box>
                <Box>โดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุดังกล่าว</Box>
                <Box className='text-box'>ข้อ 3 ก่อนลงมือก่อสร้าง ผู้รับจ้างต้องทำการตรวจสอบสถานที่และสภาพที่เป็นอยู่ รังวัดตรวจสอบหมุดหลัก
                </Box>
                <Box>
                  เขต จัดทำระดับแนวและระยะต่างๆ ในแบบก่อสร้างและเสนอผลการตรวจสอบให้แก่ผู้ว่าจ้างพร้อมทั้งแสดงสภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงานความ
                  เคลื่อนไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป
                </Box>
                <Box className='text-box'>ข้อ 4 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด</Box>
                <Box>จะต้องแสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด</Box>
                <Box className='text-box'>ข้อ 5 ผู้ว่าจ้างตกลงชำระค่าจ้างให้แก่ผู้รับจ้างเป็นเงินทั้งสิ้น <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b>  บาท <b>({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)})</b> </Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} {period?.description} ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>
                  <b>
                    ({props?.data?.remark ?? ''})
                  </b>
                </Box>
                <Box className='text-box'>ข้อ 6 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการก่อสร้างรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้จัดหาซึ่งจะ</Box>
                <Box>ต้องใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างต้องทำงานก่อสร้างทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้ ผู้ว่าจ้าง</Box>
                <Box>ไม่ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไป
                  ตามแบบและมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการก่อสร้างรั้ว ได้ทุกเมื่อทุกเวลา แต่ผู้ว่าจ้าง</Box>
                <Box>ต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <div className='page-break' />
                <Box className='text-box'>ข้อ 9 ผู้รับจ้างต้องปฏิบัติงานก่อสร้างด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>

                <Box className='text-box'>ข้อ 10 ในขณะก่อสร้างผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุอันเกิดจากการปฏิบัติงาน</Box>
                <Box></Box>
                <Box className='text-box'>ข้อ 11 เมื่อผู้รับจ้างได้ทำการก่อสร้างเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันทีเพื่อให้ผู้ว่า</Box>
                <Box>จ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป</Box>
                <Box className='text-box'>ข้อ 12 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงานงวด</Box>
                <Box>สุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ
                  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม
                </Box>
                <Box className='text-box'>ข้อ 13 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่ระบุไว้
                </Box>
                <Box>ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ <b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b>  บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 14 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญานี้</Box>
                <Box> ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน <b>{props?.data?.notifyDate ?? ''}</b> วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย</Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>

              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>

                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>({props?.companyInfo?.ownerName ?? ''})</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* NOTE: สัญญาว่าจ้างเหมาโครงสร้างบ้านเดี่ยว */}
          {/* {(props.type === 'single_house_structure') &&
            <>
              {props?.companyInfo?.images && <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                <img style={{ height: '100%', position: 'relative' }}
                  src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                  alt='Centered'
                /></Grid>
              }
              <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'>ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างเหมาโครงสร้างบ้านเดี่ยว จำนวน <b>{parseFloat(props?.data?.amountOfArea || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> ตารางเมตร ตารางเมตร
                  ละ
                </Box>
                <Box><b>{parseFloat(props?.data?.pricePerArea || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท รวมเป็นจำนวนเงิน <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ข้อความต่อไปนี้</Box>
                <Box className='text-box'>ข้อ 1 ผู้ว่าจ้างตกลงว่าจ้าง และผู้รับจ้างตกลงรับจ้างทำโครงสร้างบ้านเดี่ยว <b>{props?.data?.house?.name}</b> โฉนดที่ดิน</Box>
                <Box>เลขที <b>{props?.data?.house?.land?.deedNo}</b> ระวาง <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ <b>{props?.data?.house?.land?.dealingFileNo} </b> ตำบล<b>{props?.data?.house?.land?.landSubDistrict?.nameTh}</b> อำเภอ<b>{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''}</b> จังหวัด<b>{props?.data?.house?.land?.landProvince?.nameTh ?? ''}</b>ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>

                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการก่อสร้างตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด <b>{props?.data?.amountOfMonth ?? ''}</b> เดือน</Box>
                <Box>นับแต่วันทำสัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงักลง</Box>
                <Box>โดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุดังกล่าว</Box>
                <Box className='text-box'>ข้อ 3 ก่อนลงมือก่อสร้าง ผู้รับจ้างต้องทำการตรวจสอบสถานที่และสภาพที่เป็นอยู่ รังวัดตรวจสอบหมุดหลัก
                </Box>
                <Box>
                  เขต จัดทำระดับแนวและระยะต่างๆ ในแบบก่อสร้างและเสนอผลการตรวจสอบให้แก่ผู้ว่าจ้างพร้อมทั้งแสดง
                  สภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงานความ
                  เคลื่อนไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป

                </Box>
                <Box className='text-box'>ข้อ 4 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด</Box>
                <Box>จะต้องแสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด</Box>
                <Box className='text-box'>ข้อ 5 ผู้ว่าจ้างตกลงชำระค่าจ้างให้แก่ผู้รับจ้างเป็นเงินทั้งสิ้น <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b>  บาท <b>({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)})</b></Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} เมื่อติดกระจกเสร็จงาน ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>ข้อ 6 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการก่อสร้างรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้จัดหา
                  ซึ่งจะต้อง</Box>
                <Box>ใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างต้องทำงานก่อสร้างทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้ ผู้ว่าจ้าง</Box>
                <Box>ไม่ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไป
                  ตามแบบและมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการก่อสร้างรั้ว ได้ทุกเมื่อทุกเวลา แต่ผู้ว่าจ้าง</Box>
                <Box>ต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <div className='page-break' />
                <Box className='text-box'>ข้อ 9 ผู้รับจ้างต้องปฏิบัติงานก่อสร้างด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>
                <Box className='text-box'>ข้อ 10 ในขณะก่อสร้างผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุ อันเกิดจากการปฏิบัติงาน</Box>
                <Box></Box>
                <Box className='text-box'>ข้อ 11 เมื่อผู้รับจ้างได้ทำการก่อสร้างเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันทีเพื่อให้ผู้ว่า</Box>
                <Box>จ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป</Box>
                <Box className='text-box'>ข้อ 12 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงานงวด</Box>
                <Box>สุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ
                  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม
                </Box>
                <Box className='text-box'>ข้อ 13 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่ระบุไว้
                </Box>
                <Box>ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ <b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 14 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญานี้</Box>
                <Box> ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน..... วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย</Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>
              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* NOTE: สัญญาว่าจ้างก่อสร้างบ้าน (โครงหลังคา) */}
          {/* {(props.type === 'roof_frame') &&
            <>
              {props?.companyInfo?.images && <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                <img style={{ height: '100%', position: 'relative' }}
                  src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                  alt='Centered'
                /></Grid>
              }
              <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'>ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างก่อสร้างบ้านเดี่ยวชั้นเดียว (โครงหลังคา) จำนวน <b>{props?.data?.amountOfHouse ?? ''}</b> หลัง หลังละ

                </Box>
                <Box><b>{parseFloat(props?.data?.pricePerHouse || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? ''}</b> ตารางเมตร ตารางเมตรละ <b>{parseFloat(props?.data?.pricePerHouse || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? ''}</b> บาท รวมเป็นจำนวน <b>{props?.data?.amountOfHouse ?? ''}</b> ตารางเมตร ยิงเชิงชาย <b>{props?.data?.amountOfEave ?? ''}</b> เมตรเมตรละ <b>{parseFloat(props?.data?.pricePerEave || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? ''}</b> บาท รวม
                  เป็นจำนวนเงิน <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? ''}</b>  บาท</Box>
                <Box className='text-box'>ข้อ 1 ผู้ว่าจ้างตกลงว่าจ้าง และผู้รับจ้างตกลงรับจ้างทำโครงสร้างบ้านเดี่ยว <b>{props?.data?.house?.name}</b> โฉนดที่ดิน</Box>
                <Box>เลขที<b>{props?.data?.house?.land?.deedNo}</b> ระวาง <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ <b>{props?.data?.house?.land?.dealingFileNo}</b> ตำบล<b>{props?.data?.house?.land?.landSubDistrict?.nameTh}</b> อำเภอ<b>{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''}</b> จังหวัด<b>{props?.data?.house?.land?.landProvince?.nameTh ?? ''}</b>  ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้
                  และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>

                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการก่อสร้างตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน</Box>
                <Box>นับแต่วันทำสัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงักลง</Box>
                <Box>โดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุดังกล่าว</Box>
                <Box className='text-box'>สภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงาน
                </Box>
                <Box>ความเคลื่อนไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป  </Box>
                <Box className='text-box'>ข้อ 3 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด
                  จะต้อง
                </Box>
                <Box>
                  แสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด

                </Box>
                <Box className='text-box'>ข้อ 4 ผู้ว่าจ้างตกลงชำระค่าจ้างให้แก่ผู้รับจ้างเป็นเงินทั้งสิ้น <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)})</b></Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} {period?.description} ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>ข้อ 5 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการก่อสร้างรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้จัดหา
                  ซึ่งจะ</Box>
                <Box>ต้องใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 6 ผู้รับจ้างต้องทำงานก่อสร้างทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้
                  ผู้ว่าจ้าง</Box>
                <Box>ไม่ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไป
                  ตามแบบและมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการก่อสร้างบ้าน (โครงหลังคา) ได้ทุกเมื่อทุกเวลา</Box>
                <Box> แต่ผู้ว่าจ้างต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <div className='page-break' />
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างต้องปฏิบัติงานก่อสร้างด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>
                <Box className='text-box'>ข้อ 9 ในขณะก่อสร้างผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุ อันเกิดจากการปฏิบัติงาน</Box>
                <Box></Box>
                <Box className='text-box'>ข้อ 10 เมื่อผู้รับจ้างได้ทำการก่อสร้างเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันทีเพื่อให้ผู้ว่า</Box>
                <Box>จ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? '-'}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป</Box>
                <Box className='text-box'>ข้อ 11 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงาน
                  งวด</Box>
                <Box>สุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ
                  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม
                </Box>
                <Box className='text-box'>ข้อ 12 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่
                  ระบุไว้
                </Box>
                <Box>ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ <b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 13 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญา
                  นี้</Box>
                <Box>ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน <b>{props?.data?.notifyDate ?? ''}</b> วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย
                </Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>
              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>({props?.companyInfo?.ownerName ?? ''})</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* NOTE: สัญญาว่าจ้างมุงหลังคาบ้าน */}
          {/* {(props.type === 'house_roofing') &&
            <>
              {props?.companyInfo?.images && <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                <img style={{ height: '100%', position: 'relative' }}
                  src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                  alt='Centered'
                /></Grid>
              }
              <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'>ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างมุงหลังคาบ้านเดี่ยวชั้นเดียว จำนวน <b>{props?.data?.amountOfHouse ?? ''}</b> หลัง จำนวน <b>{props?.data?.amountOfArea ?? ''}</b> ตาราง
                  เมตร  </Box>
                <Box>ตารางเมตรละ <b>{parseFloat(props?.data?.pricePerArea || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b>  บาท รวมเป็นจำนวนเงิน <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ดังมีข้อความต่อไปนี้</Box>
                <Box className='text-box'>ข้อ 1  ผู้ว่าจ้างตกลงว่าจ้าง และผู้รับจ้างตกลงรับจ้างมุงหลังคาบ้านเดี่ยวชั้นเดียวบนที่ดินแปลงเลขที่</Box>
                <Box><b>{props?.data?.house?.land?.deedNo}</b> ระวาง <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ <b>{props?.data?.house?.land?.dealingFileNo}</b> ตำบล<b>{props?.data?.house?.land?.landSubDistrict?.nameTh}</b> อำเภอ<b>{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''}</b> จังหวัด<b>{props?.data?.house?.land?.landProvince?.nameTh ?? ''} {props?.data?.house?.name}</b>
                </Box>
                <Box>ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>
                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการมุงหลังคาบ้านตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด  <b>{props?.data?.amountOfDate ?? ''}</b> วัน</Box>
                <Box>นับแต่วันทำสัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงัก</Box>
                <Box>ลงโดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุ
                  ดังกล่าว</Box>
                <Box className='text-box'>ข้อ 3 ก่อนลงมือก่อสร้าง ผู้รับจ้างต้องทำการตรวจสอบสถานที่และสภาพที่เป็นอยู่ รังวัดตรวจสอบ
                </Box>
                <Box>หมุดหลักเขต จัดทำระดับแนวและระยะต่างๆ ในแบบก่อสร้างและเสนอผลการตรวจสอบให้แก่ผู้ว่าจ้างพร้อมทั้งแสดง
                  สภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงานความ
                  เคลื่อนไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป
                </Box>
                <Box className='text-box'>ข้อ 4 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด
                </Box>
                <Box>จะต้องแสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด</Box>
                <Box className='text-box'>ข้อ 5 ผู้ว่าจ้างตกลงชำระค่าจ้างให้แก่ผู้รับจ้างเป็นเงินทั้งสิ้น <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)})</Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} {period?.description} ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>
                  <b>
                    ({props?.data?.remark ?? ''})
                  </b>
                </Box>
                <Box className='text-box'>ข้อ 6 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการก่อสร้างรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้</Box>
                <Box>จัดหา
                  ซึ่งจะต้องใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างต้องทำงานทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้ ผู้ว่าจ้างไม่</Box>
                <Box>ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไปตามแบบ
                  และมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการทำงาน ได้ทุกเมื่อทุกเวลา แต่ผู้ว่าจ้าง</Box>
                <Box>ต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <Box className='text-box'>ข้อ 9 ผู้รับจ้างต้องปฏิบัติงานด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>
                <Box className='text-box'>ข้อ 10 ในขณะปฏิบัติงานผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุอันเกิดจากการ</Box>
                <Box>ปฏิบัติงาน</Box>
                <Box className='text-box'>ข้อ 11 เมื่อผู้รับจ้างได้ทำการมุงหลังคาเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันที</Box>
                <Box>เพื่อให้ผู้ว่าจ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป </Box>
                <div className='page-break' />
                <Box className='text-box'>ข้อ 12 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงาน</Box>
                <Box>งวดสุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ</Box>
                <Box>  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม</Box>
                <Box className='text-box'>ข้อ 13 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่</Box>
                <Box>ระบุไว้ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ <b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 14 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญา</Box>
                <Box>นี้ ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน <b>{props?.data?.notifyDate ?? ''}</b> วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย</Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>

              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>

                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>({props?.companyInfo?.ownerName ?? ''})</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* NOTE: สัญญาว่าจ้างก่อสร้างรั้ว */}
          {/* {(props.type === 'fence') &&
            <>
              {props?.companyInfo?.images && <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                <img style={{ height: '100%', position: 'relative' }}
                  src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                  alt='Centered'
                /></Grid>
              }
              <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'>ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างก่อสร้างทำรั้ว จำนวน <b>{props?.data?.amountOfArea ?? ''}</b>  เมตร เมตรละ <b>{parseFloat(props?.data?.pricePerArea || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? ''}</b>  บาท รวมเป็น
                  จำนวน
                </Box>
                <Box>เงิน <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? ''}</b>  บาท ดังมีข้อความต่อไปนี้</Box>
                <Box className='text-box'>ข้อ 1 ผู้ว่าจ้างตกลงว่าจ้าง และผู้รับจ้างตกลงรับจ้างก่อสร้างรั้ว {props?.data?.project?.name ?? ''}</Box>
                <Box>ตำบล{props?.data?.house?.land?.landSubDistrict?.nameTh} อำเภอ{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''} จังหวัด{props?.data?.house?.land?.landProvince?.nameTh ?? ''} ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>

                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการก่อสร้างตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด {props?.data?.amountOfMonth ?? ''} เดือน</Box>
                <Box>นับแต่วันทำสัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงักลง</Box>
                <Box>โดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุดังกล่าว</Box>
                <Box className='text-box'>ข้อ 3 ก่อนลงมือก่อสร้าง ผู้รับจ้างต้องทำการตรวจสอบสถานที่และสภาพที่เป็นอยู่ รังวัดตรวจสอบ
                  หมุดหลัก
                </Box>
                <Box>
                  เขต จัดทำระดับแนวและระยะต่างๆ ในแบบก่อสร้างและเสนอผลการตรวจสอบให้แก่ผู้ว่าจ้างพร้อมทั้งแสดง
                  สภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงานความ
                  เคลื่อนไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป
                </Box>
                <Box className='text-box'>ข้อ 4 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด</Box>
                <Box>จะต้องแสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด</Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} {period?.description} ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>
                  <b>
                    ({props?.data?.remark ?? ''})
                  </b>
                </Box>
                <Box className='text-box'>ข้อ 6 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการก่อสร้างรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้จัดหา
                  ซึ่งจะ</Box>
                <Box>ต้องใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างต้องทำงานก่อสร้างทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้ ผู้ว่าจ้าง</Box>
                <Box>ไม่ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไป
                  ตามแบบและมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการก่อสร้างรั้ว ได้ทุกเมื่อทุกเวลา แต่ผู้ว่าจ้าง</Box>
                <Box>ต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <div className='page-break' />
                <Box className='text-box'>ข้อ 9 ผู้รับจ้างต้องปฏิบัติงานก่อสร้างด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>
                <Box className='text-box'>ข้อ 10 ในขณะก่อสร้างผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุ อันเกิดจากการปฏิบัติงาน</Box>
                <Box></Box>
                <Box className='text-box'>ข้อ 11 เมื่อผู้รับจ้างได้ทำการก่อสร้างเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันทีเพื่อให้ผู้ว่า</Box>
                <Box>จ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป</Box>
                <Box className='text-box'>ข้อ 12 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงานงวด</Box>
                <Box>สุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ
                  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม
                </Box>
                <Box className='text-box'>ข้อ 13 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่ระบุไว้
                </Box>
                <Box>ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ <b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 14 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญานี้</Box>
                <Box> ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน  <b>{props?.data?.notifyDate ?? ''}</b> วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย</Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>
              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>({props?.companyInfo?.ownerName ?? ''})
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* NOTE: สัญญาว่าจ้างรับเหมาค่าแรงเดินระบบไฟฟ้าทั้งหลัง */}
          {/* {(props.type === 'electric') &&
            <>
              {props?.companyInfo?.images && <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                <img style={{ height: '100%', position: 'relative' }}
                  src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                  alt='Centered'
                /></Grid>
              }
              <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'>ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างเหมาเดินระบบไฟฟ้าภายในและภายนอก จำนวน <b>{props?.data?.amountOfHouse ?? ''}</b> หลัง หลังละ
                </Box>
                <Box>เป็นจำนวนเงิน <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)}) ดังมีข้อความต่อไปนี้</Box>
                <Box className='text-box'>ข้อ 1 ผู้ว่าจ้างตกลงว่าจ้าง และผู้รับจ้างตกลงรับจ้างก่อสร้างบ้านเดี่ยวชั้นเดียว บนที่ดินแปลงเลขที่โฉนดเลข</Box>
                <Box>ที่ <b>{props?.data?.house?.land?.deedNo}</b> ระวาง <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ <b>{props?.data?.house?.land?.dealingFileNo}</b> ตำบล<b>{props?.data?.house?.land?.landSubDistrict?.nameTh}</b> อำเภอ<b>{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''}</b> จังหวัด<b>{props?.data?.house?.land?.landProvince?.nameTh ?? ''} {props?.data?.house?.name}</b>ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>

                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการก่อสร้างตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด <b>{props?.data?.amountOfDate ?? ''}/{props?.data?.amountOfMonth ?? ''}</b> วัน/เดือน</Box>
                <Box>นับแต่วันทำสัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงักลง</Box>
                <Box>โดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุดังกล่าว</Box>
                <Box className='text-box'>ข้อ 3 ก่อนลงมือก่อสร้าง ผู้รับจ้างต้องทำการตรวจสอบสถานที่และสภาพที่เป็นอยู่ รังวัดตรวจสอบ
                  หมุดหลัก
                </Box>
                <Box>
                  เขต จัดทำระดับแนวและระยะต่างๆ ในแบบก่อสร้างและเสนอผลการตรวจสอบให้แก่ผู้ว่าจ้างพร้อมทั้งแสดง
                  สภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงานความ
                  เคลื่อนไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป
                </Box>
                <Box className='text-box'>ข้อ 4 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด</Box>
                <Box>จะต้องแสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด</Box>
                <Box className='text-box'>ข้อ 5 ผู้ว่าจ้างตกลงชำระค่าจ้างให้แก่ผู้รับจ้างเป็นเงินทั้งสิ้น <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)})</Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} {period?.description} ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>
                  <b>
                    ({props?.data?.remark ?? ''})
                  </b>
                </Box>
                <Box className='text-box'>ข้อ 6 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการก่อสร้างรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้จัดหา
                  ซึ่งจะ</Box>
                <Box>ต้องใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างต้องทำงานก่อสร้างทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้ ผู้ว่าจ้าง</Box>
                <Box>ไม่ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไป
                  ตามแบบและมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการก่อสร้างรั้ว ได้ทุกเมื่อทุกเวลา แต่ผู้ว่าจ้าง</Box>
                <Box>ต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <div className='page-break' />
                <Box className='text-box'>ข้อ 9 ผู้รับจ้างต้องปฏิบัติงานก่อสร้างด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>
                <Box className='text-box'>ข้อ 10 ในขณะก่อสร้างผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุ อันเกิดจากการปฏิบัติงาน</Box>
                <Box></Box>
                <Box className='text-box'>ข้อ 11 เมื่อผู้รับจ้างได้ทำการก่อสร้างเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันทีเพื่อให้ผู้ว่า</Box>
                <Box>จ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป</Box>
                <Box className='text-box'>ข้อ 12 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงานงวด</Box>
                <Box>สุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ
                  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม
                </Box>
                <Box className='text-box'>ข้อ 13 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่ระบุไว้
                </Box>
                <Box>ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ <b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 14 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญานี้</Box>
                <Box> ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน <b>{props?.data?.notifyDate ?? ''}</b> วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย</Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>
              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>({props?.companyInfo?.ownerName ?? ''})
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* NOTE: สัญญาว่าจ้างเหมาฉาบพร้อมติดตั้งวงกบ */}
          {/* {(props.type === 'plastering_installation_frames') &&
            <>
              {props?.companyInfo?.images && <Grid item xs={12} height={'5%'} sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexWrap={'wrap'}  >
                <img style={{ height: '100%', position: 'relative' }}
                  src={`${apiUrl}/uploads/${props?.companyInfo?.images?.name} `}
                  alt='Centered'
                /></Grid>
              }
              <Typography align='center' fontWeight={'bold'} marginTop={2}>บริษัท{props?.companyInfo?.name ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>{props?.companyInfo?.address ?? ''} หมู่ที่ {props?.companyInfo?.moo ?? ''} ตำบล{props?.companyInfo?.subDistrict?.nameTh ?? ''} อำเภอ{props?.companyInfo?.district?.nameTh ?? ''} จังหวัด{props?.companyInfo?.province?.nameTh ?? ''} {props?.companyInfo?.zipCode ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี {props?.companyInfo?.taxId ?? ''}</Typography>
              <Typography align='center' fontWeight={'bold'}>Tell. {props?.companyInfo?.tel ?? ''}</Typography>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'>ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างเหมาโครงสร้างบ้านเดี่ยว รวมเป็นจำนวนเงิน <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ดังมี
                </Box>
                <Box>ข้อความต่อไปนี้</Box>
                <Box className='text-box'>ข้อ 1 ผู้ว่าจ้างตกลงว่าจ้าง และผู้รับจ้างตกลงรับจ้างทำโครงสร้างบ้านเดี่ยว <b>{props?.data?.house?.name ?? ''}</b> โฉนดที่ดิน</Box>
                <Box><b>{props?.data?.house?.land?.deedNo}</b> ระวาง <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ <b>{props?.data?.house?.land?.dealingFileNo}</b>  ตำบล<b>{props?.data?.house?.land?.landSubDistrict?.nameTh}</b> อำเภอ<b>{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''}</b> จังหวัด<b>{props?.data?.house?.land?.landProvince?.nameTh ?? ''}</b>ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>

                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการก่อสร้างตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด <b>{props?.data?.amountOfMonth ?? ''} </b>เดือน</Box>
                <Box>นับแต่วันทำสัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงักลง</Box>
                <Box>โดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุดังกล่าว</Box>
                <Box className='text-box'>ข้อ 3 ก่อนลงมือก่อสร้าง ผู้รับจ้างต้องทำการตรวจสอบสถานที่และสภาพที่เป็นอยู่ รังวัดตรวจสอบ
                  หมุดหลัก
                </Box>
                <Box>
                  เขต จัดทำระดับแนวและระยะต่างๆ ในแบบก่อสร้างและเสนอผลการตรวจสอบให้แก่ผู้ว่าจ้างพร้อมทั้งแสดง
                  สภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงานความ
                  เคลื่อนไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป
                </Box>
                <Box className='text-box'>ข้อ 4 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด</Box>
                <Box>จะต้องแสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด</Box>
                <Box className='text-box'>ข้อ 5 ผู้ว่าจ้างตกลงชำระค่าจ้างให้แก่ผู้รับจ้างเป็นเงินทั้งสิ้น <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)})</Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} {period?.description} ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>
                  <b>
                    ({props?.data?.remark ?? ''})
                  </b>
                </Box>
                <Box className='text-box'>ข้อ 6 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการก่อสร้างรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้จัดหา
                  ซึ่งจะ</Box>
                <Box>ต้องใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างต้องทำงานก่อสร้างทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้ ผู้ว่าจ้าง</Box>
                <Box>ไม่ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไป
                  ตามแบบและมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการก่อสร้างรั้ว ได้ทุกเมื่อทุกเวลา แต่ผู้ว่าจ้าง</Box>
                <Box>ต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <div className='page-break' />
                <Box className='text-box'>ข้อ 9 ผู้รับจ้างต้องปฏิบัติงานก่อสร้างด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>
                <Box className='text-box'>ข้อ 10 ในขณะก่อสร้างผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุ อันเกิดจากการปฏิบัติงาน</Box>
                <Box></Box>
                <Box className='text-box'>ข้อ 11 เมื่อผู้รับจ้างได้ทำการก่อสร้างเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันทีเพื่อให้ผู้ว่า</Box>
                <Box>จ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป</Box>
                <Box className='text-box'>ข้อ 12 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงานงวด</Box>
                <Box>สุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ
                  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม
                </Box>
                <Box className='text-box'>ข้อ 13 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่ระบุไว้
                </Box>
                <Box>ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ<b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 14 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญานี้</Box>
                <Box> ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน <b>{props?.data?.notifyDate ?? ''}</b> วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย</Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>
              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>({props?.companyInfo?.ownerName ?? ''})
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* NOTE: สัญญาว่าจ้างทำฝ้า */}
          {/* {(props.type === 'ceiling') &&
            <>
              <Typography align='center' fontSize={'18px'} fontWeight={'bold'} lineHeight={2}>{props?.title ?? ''}</Typography>
              <Typography align='right' mb={2}>
                วันที่ <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('DD')}</b> เดือน <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('MMMM')}</b> พ.ศ. <b>{dayjs(props?.data?.contractDate !== 'Invalid Date' ? props?.data?.contractDate : new Date()).format('BBBB')}</b></Typography>
              <Typography lineHeight={2}>
                <Box className='text-box' lineHeight={2}>สัญญานี้ทำขึ้นระหว่าง บริษัท <b>{props?.companyInfo?.name ?? ''}</b> ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address ?? ''}</b> หมู่ที่ <b>{props?.companyInfo?.moo ?? ''}</b> ตำบล<b>{props?.companyInfo?.subDistrict?.nameTh ?? ''}</b> </Box>
                <Box>อำเภอ<b>{props?.companyInfo?.district?.nameTh ?? ''}</b> จังหวัด<b>{props?.companyInfo?.province?.nameTh ?? ''}</b> <b>{props?.companyInfo?.zipCode ?? ''}</b> โทรศัพท์ <b>{props?.companyInfo?.tel ?? ''}</b> ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า <b>“ผู้ว่าจ้าง”</b> ฝ่ายหนึ่ง </Box>
                <Box className='text-box'>กับ <b>{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''} </b> ตั้งอยู่เลขที่ <b>{props?.data?.worker?.address ?? '-'}</b> หมู่ที่ <b>{props?.data?.worker?.moo ?? '-'}</b> ถ.<b>{props?.data?.worker?.street ?? '-'}</b> ตำบล<b>{props?.data?.worker?.subDistrict?.nameTh ?? '-'}</b> อำเภอ<b>{props?.data?.worker?.district?.nameTh ?? '-'}</b> </Box>
                <Box>จังหวัด<b>{props?.data?.worker?.province?.nameTh ?? '-'}</b> โทรศัพท์ <b>{props?.data?.worker?.tel ?? '-'} </b>ซึ่งต่อไปนี้ในสัญญาจะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่ง</Box>
                <Box className='text-box'> ทั้งสองฝ่ายได้ตกลงทำสัญญาว่าจ้างทำฝ้าบ้านเดี่ยวชั้นเดียวภายนอกภายในและโรงรถ <b>{props?.data?.house?.name ?? ''}</b>
                </Box>
                <Box>เหมาค่าของค่าแรง จำนวน <b>{props?.data?.amountOfArea ?? ''}</b> ตารางเมตร ตารางเมตรละ <b>{parseFloat(props?.data?.pricePerArea || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท รวมเป็นจำนวนเงิน <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท</Box>
                <Box className='text-box'>ข้อ 1 ผู้ว่าจ้างตกลงว่าจ้าง และผู้รับจ้างตกลงรับจ้างทำโครงสร้างบ้านเดี่ยว {props?.data?.house?.name ?? ''} โฉนดที่ดิน</Box>
                <Box><b>{props?.data?.house?.land?.deedNo}</b> ระวาง <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ <b>{props?.data?.house?.land?.dealingFileNo} {props?.data?.project?.project?.name ?? ''}</b> ตำบล<b>{props?.data?.house?.land?.landSubDistrict?.nameTh}</b> อำเภอ<b>{props?.data?.house?.land?.landDistrict
                  ?.nameTh ?? ''}</b> จังหวัด<b>{props?.data?.house?.land?.landProvince?.nameTh ?? ''} </b>ตามแบบแปลนและรายละเอียดของงานที่แนบท้ายสัญญานี้และให้ถือเป็นส่วนหนึ่งของสัญญานี้
                </Box>

                <Box className='text-box'>ข้อ 2 ผู้รับจ้างตกลงจะทำการก่อสร้างตามสัญญาข้อ 1 ให้เสร็จสิ้นภายในกำหนด <b>{props?.data?.amountOfMonth ?? ''}</b> เดือน</Box>
                <Box>นับแต่วันทำสัญญานี้</Box>
                <Box className='text-box'>ในกรณีที่เกิดเหตุสุดวิสัยใดๆ ที่ไม่อาจหลีกเลี่ยงได้และเป็นเหตุให้การก่อสร้างดังกล่าวต้องหยุดชะงักลง</Box>
                <Box>โดยมิใช่ความผิดของฝ่ายผู้รับจ้างก็ให้ยืดกำหนดเวลาดังกล่าวในวรรคก่อนออกไปเท่ากับเวลาที่สูญเสียไปเพราะเหตุดังกล่าว</Box>
                <Box className='text-box'>ข้อ 3 ก่อนลงมือก่อสร้าง ผู้รับจ้างต้องทำการตรวจสอบสถานที่และสภาพที่เป็นอยู่ รังวัดตรวจสอบ
                  หมุดหลัก
                </Box>
                <Box>
                  เขต จัดทำระดับแนวและระยะต่างๆ ในแบบก่อสร้างและเสนอผลการตรวจสอบให้แก่ผู้ว่าจ้างพร้อมทั้งแสดง
                  สภาวะของสภาพดังกล่าวอันจะทำให้เกิดการกระทบกระเทือนยุ่งยากแก่งานที่ระบุในสัญญาและรายงานความ
                  เคลื่อนไหวอันเกิดขึ้นระหว่างแผนก่อสร้างกับสถานที่จริงเป็นลายลักษณ์อักษรก่อนดำเนินงานต่อไป
                </Box>
                <Box className='text-box'>ข้อ 4 ผู้รับจ้างต้องตรวจสอบและศึกษารายละเอียดโดยผู้รับจ้างต้องเสนอแบบขยายรายละเอียด</Box>
                <Box>จะต้องแสดงถึงวิธีการ ตำแหน่งและระยะต่างๆ ในการปฏิบัติงานโดยละเอียด</Box>
                <Box className='text-box'>ข้อ 5 ผู้ว่าจ้างตกลงชำระค่าจ้างให้แก่ผู้รับจ้างเป็นเงินทั้งสิ้น <b>{parseFloat(props?.data?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท ({(!props?.data?.total || parseInt(props?.data?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.total || 0)}) </Box>
                <Box>โดยจะชำระค่าจ้างให้เป็นจำนวน <b>{props?.data?.periods && props?.data?.periods.length}</b> งวด ตามรายละเอียดการชำระค่าจ้าง ดังนี้</Box>
                {props?.data?.periods && props?.data?.periods.map((period: any) => (
                  <Box className='text-box'>
                    <b>
                      งวดที่ {period?.order} {period?.description} ชำระจำนวน {parseFloat(period?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท ({(!period?.total || parseInt(period?.total) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(period?.total || 0)})
                    </b>
                  </Box>
                ))}
                <Box className='text-box'>
                  <b>
                    ({props?.data?.remark ?? ''})
                  </b>
                </Box>
                <Box className='text-box'>ข้อ 6 วัสดุเครื่องมืออุปกรณ์ที่ใช้ในการก่อสร้างรวมทั้งสัมภาระต่างๆ ที่จำเป็นให้ผู้รับจ้างเป็นผู้จัดหา
                  ซึ่งจะ</Box>
                <Box>ต้องใช้วัสดุอุปกรณ์ตามขนาดและคุณภาพดังที่แจ้งไว้ในรายละเอียดแนบท้ายสัญญานี้</Box>
                <Box className='text-box'>ข้อ 7 ผู้รับจ้างต้องทำงานก่อสร้างทั้งหมดให้ถูกต้องตามแบบแผนและรายละเอียดการก่อสร้าง ทั้งนี้ ผู้ว่าจ้าง</Box>
                <Box>ไม่ยินยอมให้ผู้รับจ้างนำงานส่วนใดส่วนหนึ่งหรืองานทั้งหมดแห่งสัญญานี้ไปให้ผู้อื่นรับจ้างช่วงก่อสร้างให้เป็นไป
                  ตามแบบและมาตรฐานของผู้รับจ้างอีกต่อหนึ่งได้
                </Box>
                <Box className='text-box'>ข้อ 8 ผู้รับจ้างยินยอมให้ผู้ว่าจ้างหรือตัวแทนเข้าตรวจตราการก่อสร้างรั้ว ได้ทุกเมื่อทุกเวลา แต่ผู้ว่าจ้าง</Box>
                <Box>ต้องไม่ขัดขวางหรือรบกวนการก่อสร้าง</Box>
                <Box className='text-box'>ข้อ 9 ผู้รับจ้างต้องปฏิบัติงานก่อสร้างด้วยความระมัดระวัง ป้องกันความเสียหายและอุบัติเหตุอันเกิดจาก</Box>
                <Box>บุคคลภายนอกด้วย หากเกิดอุบัติเหตุหรือความเสียหายแก่บุคคลภายนอกผู้รับจ้างต้องรับผิดเพียงฝ่ายเดียว</Box>
                <Box className='text-box'>ข้อ 10 ในขณะก่อสร้างผู้รับจ้างต้องรักษาสถานที่ให้สะอาด ปราศจากเศษวัสดุ อันเกิดจากการปฏิบัติงาน</Box>
                <Box></Box>
                <Box className='text-box'>ข้อ 11 เมื่อผู้รับจ้างได้ทำการก่อสร้างเสร็จแล้ว ผู้รับจ้างจะต้องมีหนังสือแจ้งให้ผู้ว่าจ้างทราบทันทีเพื่อให้ผู้ว่า</Box>
                <Box>จ้างหรือตัวแทนของของผู้ว่าจ้างมาตรวจรับมอบงานภายในเวลาที่กำหนด <b>{props?.data?.amountOfDate ?? ''}</b> วัน นับแต่วันที่ผู้รับจ้างได้มี
                  หนังสือแจ้งไป</Box>
                <Box className='text-box'>ข้อ 12 ภายในระยะ 1 ปีนับจากวันที่อาคารที่ว่าจ้างตามสัญญานี้ก่อสร้างเสร็จและมีการส่งมอบงานงวด</Box>
                <Box>สุดท้ายให้กับผู้ว่าจ้างหรือผู้ว่าจ้างได้รับมอบงานเป็นที่เรียบร้อยแล้วนั้น หากส่วนใดของอาคารเกิดชำรุดเสียหาย
                  เนื่องจากความชำรุดบกพร่องในการก่อสร้างหรือเพราะเหตุวัสดุอุปกรณ์ในการก่อสร้างตามสัญญานี้ผู้รับจ้างจะต้องทำ
                  การซ่อมแซมให้เรียบร้อยอยู่ในสภาพเดิมโดยไม่คิดค่าใช้จ่ายใดๆ ทั้งสิ้น เว้นแต่ความเสียหายนั้นเกิดจากภัยธรรมชาติ
                  เช่น วาตภัย อุทกภัย อัคคีภัย แผ่นดินไหว ความเสียหายจากสัตว์ แมลง หรือความเสียหายจากการจลาจล โจรกรรม
                </Box>
                <Box className='text-box'>ข้อ 13 หากผู้รับจ้างไม่ทำการส่งมอบงานทั้งหมด โดยมิใช่ความผิดของผู้ว่าจ้างภายในกำหนดเวลาที่ระบุไว้
                </Box>
                <Box>ผู้รับจ้างยินยอมให้ผู้ว่าจ้างเรียกร้องให้ชำระค่าปรับวันละ <b>{parseFloat(props?.data?.mulct || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b> บาท <b>({(!props?.data?.mulct || parseInt(props?.data?.mulct) === 0) ? 'ศูนย์บาทศูนย์สตางค์' : ThaiBaht(props?.data?.mulct || 0)})</b> จนกว่า
                  จะส่งมอบงานที่เสร็จสิ้นทั้งหมด</Box>
                <Box className='text-box'>ข้อ 14 บรรดาเอกสารหนังสือและหนังสือบอกกล่าวใดๆ ของคู่สัญญาตามภูมิลำเนาที่ปรากฏในสัญญานี้</Box>
                <Box> ให้ถือว่าคู่สัญญาอีกฝ่ายหนึ่งได้รับทราบข้อความนั้นตลอดแล้ว กรณีที่คู่สัญญาย้ายที่อยู่หรือภูมิลำเนาให้เป็นหน้าที่
                  ของผู้ว่าจ้างนั้นต้องแจ้งคู่สัญญาอีกฝ่ายหนึ่งทราบเป็นหนังสือ ภายใน <b>{props?.data?.notifyDate ?? ''}</b> วัน นับแต่วันย้าย มิฉะนั้นให้ถือว่าบรรดา
                  เอกสารหนังสือ และหนังสือบอกกล่าวที่ส่งไปตามที่อยู่ของคู่สัญญาตามสัญญานี้เป็นการส่งโดยชอบด้วยกฎหมาย</Box>
                <Box className='text-box'>สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน โดยคู่สัญญายึดถือไว้ฝ่ายละหนึ่งฉบับ คู่สัญญา</Box>
                <Box>ได้อ่านและเข้าใจข้อความโดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นพยานหลักฐาน
                </Box>
              </Typography>
              <Grid container marginTop={'40px'} >
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'}>
                  <Grid item xs={12} >
                    <Typography>ลงชื่อ...................................................ผู้ว่าจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} alignItems={'center'} marginTop={'10px'}>
                    <Typography>({props?.companyInfo?.ownerName ?? ''})
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ..................................................ผู้รับจ้าง</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(.....{props?.data?.worker?.firstName ?? ''} {props?.data?.worker?.lastName ?? ''}.....)</Typography>
                  </Grid>

                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} alignItems={'center'} marginTop={'20px'}>
                  <Grid item xs={12}>
                    <Typography>ลงชื่อ.....................................................พยาน</Typography>
                  </Grid>
                  <Grid item xs={12} marginTop={'10px'}>
                    <Typography>(..................................................)</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </>
          } */}
          {/* <Typography>{convertToRaw(props?.data)}</Typography> */}

        </Grid>
      </Grid>
    </Box >
  );
});

export default PrintOneContract;;
