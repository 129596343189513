import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

interface PrintExpensesProjectHousesProps {
  title?: string;
  summary?: string;
  data: any[];
}

const PrintExpensesProjectHouses = React.forwardRef(
  (props: PrintExpensesProjectHousesProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          margin: 0,
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          "&@page": {
            size: "A4 portrait",
          },
          overflow: "hidden",
          fontSize: 8,
          display: "block",
          pageBreakBefore: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650, overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>บ้าน</TableCell>
                    <TableCell>มูลค่ารวม</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.length > 0 &&
                    props?.data?.map((row: any, index: number) => {
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            {parseFloat(row.total || 0).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow>
                    <TableCell colSpan={1} align="right">
                      <b>รวมทั้งหมด</b>
                    </TableCell>
                    <TableCell colSpan={1} align="right">
                      {props?.summary || "0.00"}
                    </TableCell>
                  </TableRow>
                  {props.data && props.data.length === 0 && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell colSpan={2} align="center">
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default PrintExpensesProjectHouses;
