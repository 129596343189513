import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { } from "react";

interface PrintBookingProps {
  title?: string;
  date?: string;
  data: any[];
}

const PrintBooking = React.forwardRef((props: PrintBookingProps, ref) => {
  return (
    <Box ref={ref} sx={{
      margin: 0,
      width: "100%",
      borderCollapse: "collapse",
      textAlign: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
      marginTop: '20px',
      '&@page': {
        size: 'A4 portrait',
      },
      overflow: 'hidden',
      fontSize: 8,
      display: 'block',
      pageBreakBefore: 'auto', // Allow page break before this element 
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer >
            <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={11} sx={{ textAlign: 'center', }}>
                    <Typography fontWeight={'bold'}>{props.title}</Typography>
                    <Typography>{props.date}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>วันที่จอง</TableCell>
                  <TableCell width={100}>เลขที่จอง</TableCell>
                  <TableCell width={150}>ชื่อ-นามสกุล</TableCell>
                  <TableCell>ชื่อโครงการ</TableCell>
                  <TableCell>บ้าน</TableCell>
                  <TableCell>ราคาขาย</TableCell>
                  <TableCell>ค่าสัญญา</TableCell>
                  <TableCell>ชำระวันโอน</TableCell>
                  <TableCell width={100}>บันทึก</TableCell>
                  <TableCell width={100}>แก้ไข</TableCell>
                  <TableCell>สถานะ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 && props?.data?.map((row: any) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell>{dayjs(row.docDate).format('DD/MM/YYYY') === 'Invalid Date' ? '-' : dayjs(row.docDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell component="th" scope="row" style={{ textDecoration: row.isDelete ? 'line-through' : 'none' }} >
                      {row?.bookingNo || ''}
                    </TableCell>
                    <TableCell>{row?.customer?.firstname || ''} {row?.customer?.lastname || ''}</TableCell>
                    <TableCell>{row?.project?.name || ''}</TableCell>
                    <TableCell>{row?.house?.name || ''}</TableCell>
                    <TableCell>{parseFloat(row?.sellingPrice || 0)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                    <TableCell>{parseFloat(row?.contractFee || 0)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                    <TableCell>{parseFloat(row?.arrearage || 0)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                    <TableCell>
                      {<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      {<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}
                    </TableCell>
                    <TableCell>{row?.status?.name || ''}</TableCell>
                  </TableRow>
                ))}
                {props.data.length === 0 && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={11} align="center">ไม่พบข้อมูล</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

export default PrintBooking;