import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class AuthStore {
  isAuthenticated = false;
  user: any = null;
  userGroup: any = null;
  constructor() {
    makeAutoObservable(this);
  }

  async login(username: string, password: string, isRemember: boolean) {
    try {
      const data = {
        username,
        password,
        isRemember,
      };

      const response = await apiService.post("/auth/login", data);
      if (response.access_token) {
        this.isAuthenticated = true;
        this.user = response;
        localStorage.setItem("user", JSON.stringify(this.user));
        return response;
      } else {
        throw new Error("An error occurred while logging in");
      }
    } catch (error) {
      console.error("An error occurred while logging in:", error);
      return { error: true, message: "An error occurred while logging in" };
    }
  }

  logout() {
    this.isAuthenticated = false;
    this.user = null;
    this.userGroup = null;
    localStorage.removeItem("user");
    localStorage.removeItem("menus");
  }

  setUser(user: any) {
    this.isAuthenticated = true;
    this.user = user;
    this.userGroup = user.userGroup;
  }
}

const authStore = new AuthStore();
export default authStore;
