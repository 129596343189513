import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface DialogLandProps {
  open: boolean;
  title: string;
  data?: any;
  onClose: () => void;
}

const DialogLand: React.FC<DialogLandProps> = (props: DialogLandProps) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <Dialog open={props.open} fullWidth maxWidth={'lg'}>
      <DialogContent >
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Typography mt={1} mb={1}>โฉนดที่ดิน</Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography>
                  <b>เลขที่:</b>  {props.data?.volumn || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>เล่มที่:</b>  {props.data?.volumn || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>หน้า:</b>  {props.data?.page || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>อำเภอ:</b> {props.data?.landDistrict?.nameTh || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ระวาง:</b>  {props.data?.mapsheet || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>เลขที่ดิน:</b>  {props.data?.dealingFileNo || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ตำบล:</b> {props.data?.landSubDistrict?.nameTh || ''}
                </Typography>
              </Grid>
              <Grid item xs={12}><Divider /></Grid>
              <Grid item xs={12}>
                <Typography mb={1}>เนื้อที่ดิน</Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ไร่:</b> {props.data?.rai ? parseFloat(props.data?.rai) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>งาน:</b>  {props.data?.ngan ? parseFloat(props.data?.ngan) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ตร.วา:</b>  {props.data?.squareWah ? parseFloat(props.data?.squareWah) : ''}
                </Typography>
              </Grid>
              <Grid item xs={12}><Divider /></Grid>
              <Grid item xs={12}>
                <Typography mb={1}>ข้อมูลการจดทะเบียนสิทธิ</Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>วันที่ออกโฉนด:</b> {props.data?.deedDate ? dayjs(props.data?.deedDate).format('DD/MM/YYYY') : ''}
                </Typography>
              </Grid>
              <Grid item xs={4.5} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>โครงการ:</b>  {props.data?.project?.name || ''}
                </Typography>
              </Grid>
              <Grid item xs={4.5} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>บ้าน:</b> {props.data?.house?.name || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ผู้ให้สัญญา:</b>  {props.data?.transferer || ''}
                </Typography>
              </Grid>
              <Grid item xs={4.5} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>สัญชาติ:</b>  {props.data?.nationality || ''}
                </Typography>
              </Grid>
              <Grid item xs={4.5} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ที่อยู่:</b>  {props.data?.address || '-'} หมู่ {props.data?.village || '-'} ต.{props.data?.subDistric?.nameTh || '-'} อ.{props.data?.district?.nameTh || '-'} จ.{props.data?.province?.nameTh || '-'} {props.data?.zipCode || '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ผู้รับสัญญา:</b>  {props.data?.transferee || ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>มูลค่าที่ดิน:</b> {props.data?.landPrice ? parseFloat(props.data?.landPrice) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ค่าโอนที่ดิน:</b>  {props.data?.registrationFee ? parseFloat(props.data?.registrationFee) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ค่าจดจำนอง:</b>  {props.data?.mortgageFee ? parseFloat(props.data?.mortgageFee) : ''}
                </Typography>
              </Grid>
              <Grid item xs={12}><Divider /></Grid>
              <Grid item xs={8} >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>เอกสาร</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {props.data?.images && props.data?.images.length > 0 && (
                      <Box component={'div'} mt={1}>
                        {props.data?.images.map((file: any, index: number) => (
                          <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                            <Grid container >
                              <Grid item xs={12}>
                                <img
                                  src={file.id ? `${apiUrl}/uploads/${file.name}` : URL.createObjectURL(file)}
                                  alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                  width="500"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button variant="text" onClick={() => props.onClose()}>ตกลง</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogLand;