import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class WagesStore {
    wages: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }
    async getAll() {
        try {
            const response = await apiService.get("/wages");
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting wages:", error);
            return {
                error: true,
                message: "An error occurred while getting wages",
            };
        }
    }

    async get(id: string) {
        try {
            const response = await apiService.get(`/wages/${id}`);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting wages:", error);
            return {
                error: true,
                message: "An error occurred while getting wages",
            };
        }
    }

    async getByFirstName(firstName: string) {
        try {
            const response = await apiService.get(`/wages/name/${firstName}`);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting wages:", error);
            return {
                error: true,
                message: "An error occurred while getting wages",
            };
        }
    }

    async getByLastName(lastName: string) {
        try {
            const response = await apiService.get(`/wages/name/${lastName}`);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting wages:", error);
            return {
                error: true,
                message: "An error occurred while getting wages",
            };
        }
    }

    async getByNameLike(name: string) {
        try {
            const response = await apiService.get(`/wages/name/like/${name}`);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting wages:", error);
            return {
                error: true,
                message: "An error occurred while getting wages",
            };
        }
    }

    async getByProjectId(id: string) {
        try {
            const response = await apiService.get(`/houses/project/${id}`);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting wages:", error);
            return {
                error: true,
                message: "An error occurred while getting wages",
            };
        }
    }

    async getByFirstNameAndgetByLastName(firstName: string, lastName: string) {
        try {
            const response = await apiService.get(
                `/wages/projectname/${firstName}/${lastName}`
            );
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting wages:", error);
            return {
                error: true,
                message: "An error occurred while getting wages",
            };
        }
    }

    async create(wageData: any

    ) {
        try {


            const response = await apiService.post("/wages", wageData);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while creating wages:", error);
            return {
                error: true,
                message: "An error occurred while creating wages",
            };
        }
    }
    async createDetail(data: any) {
        try {
            const response = await apiService.post("/wages/detail", data);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while creating wages:", error);
            return {
                error: true,
                message: "An error occurred while creating wages",
            };
        }
    }
    async update(id: string, data: any) {
        try {
            const response = await apiService.put(`/wages/${id}`, data);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while updating wages:", error);
            return {
                error: true,
                message: "An error occurred while updating wages",
            };
        }
    }

    async updateDetail(id: string, data: any) {
        try {
            const response = await apiService.put(`/wages/detail/${id}`, data);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while updating wages:", error);
            return {
                error: true,
                message: "An error occurred while updating wages",
            };
        }
    }

    async deleteDetail(id: string) {
        try {
            const response = await apiService.delete(`/wages/detail/${id}`);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while deleting wages detail:", error);
            return {
                error: true,
                message: "An error occurred while deleting wages detail:",
            };
        }
    }

    async delete(id: string) {
        try {
            const response = await apiService.delete(`/wages/${id}`);
            this.wages = response;
            return response;
        } catch (error) {
            console.error("An error occurred while deleting wages:", error);
            return {
                error: true,
                message: "An error occurred while deleting wages",
            };
        }
    }

    async getByCriteria(criteria: any) {
        try {
            const response = await apiService.post(`/wages/criteria`, criteria);
            this.wages = response;
            return response;
        } catch (error) {
            console.error(
                "An error occurred while getting expenses by criteria:",
                error
            );
            return {
                error: true,
                message: "An error occurred while getting expenses by criteria",
            };
        }
    }

    async getLastestLaborCost(id: string) {
        try {
            const response = await apiService.get(`/wages/lastLabor/${id}`);
            return response;
        } catch (error) {
            console.error("An error occurred while getting last Labor", error);
            return {
                error: true,
                message: "An error occurred while getting last Labor",
            };
        }
    }
}
const wagesStore = new WagesStore();
export default wagesStore;