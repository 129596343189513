import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  MenuProps,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import projectsStore from "../../stores/ProjectsStore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DialogHouseProduct from "../../components/DialogHouseProduct";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import PrintProjectProductGroups from "../../components/Report/Pdf/PrintProjectProductGroups";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportFileProjectProductGroups from "../../components/Report/Excel/ExportFileProjectProductGroups";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintProjectProductCategories from "../../components/Report/Pdf/PrintProjectProductCategories";
import ExportFileProjectProductCategories from "../../components/Report/Excel/ExportFileProjectProductCategories";
import PrintWorkers from "../../components/Report/Pdf/PrintWorker";
import ExportWorkers from "../../components/Report/Excel/ExportWorker";
import DialogLand from "../../components/DialogLand";
import DialogLandTax from "../../components/DialogLandTax";
import PrintExpensesProject from "../../components/Report/Pdf/PrintExpensesProject";
import companyStore from "../../stores/CompanyStore";
import ExportFileExpensesProject from "../../components/Report/Excel/ExportFileExpensesProject";
import PrintExpensesProjectDetail from "../../components/Report/Pdf/PrintExpenseProjectDetail";
import ExportFileExpensesProjectDetail from "../../components/Report/Excel/ExportFileExpensesProjectDetail";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PrintExpensesProjectLand from "../../components/Report/Pdf/PrintExpensesProjectLand";
import ExportFileExpensesProjectLand from "../../components/Report/Excel/ExportFileExpensesProjectLand";
import PrintExpensesProjectLandTax from "../../components/Report/Pdf/PrintExpensesProjectLandTax";
import ExportFileExpensesProjectLandTax from "../../components/Report/Excel/ExportFileExpensesProjectLandTax";
import PrintExpensesProjectHouses from "../../components/Report/Pdf/PrintExpensesProjectHouses";
import ExportFileExpensesProjectHouses from "../../components/Report/Excel/ExportFileExpensesProjectHouses";
import PrintExpensesProjectCommonFees from "../../components/Report/Pdf/PrintExpensesProjectCommonFees";
import ExportFileExpensesProjectCommonFees from "../../components/Report/Excel/ExportFileExpensesProjectCommonFees";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ProjectDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [houseProduct, setHouseProduct] = useState<any>({
    open: false,
    title: "",
    data: [],
    productGroup: [],
    productCategory: [],
    worker: [],
    land: [],
    landTax: [],
    commonFee: [],
    companyInfo: [],
  });

  const [value, setValue] = useState("home");
  const [productGroup, setProductGroup] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [worker, setWorker] = useState([]);
  const [landTax, setLandTax] = useState([]);
  const [land, setLand] = useState([]);
  const [commonFee, setCommonFee] = useState([]);
  const [allValue, setAllValue] = useState<any>([]);
  const [landDialog, setLandDialog] = useState<any>({
    open: false,
    title: "",
    data: [],
  });
  const [landTaxDialog, setLandTaxDialog] = useState<any>({
    open: false,
    title: "",
    data: [],
  });
  const [companyInfo, setCompanyInfo] = useState<any>([]);
  const [totalMaterial, setTotalMaterial] = useState("");
  const [totalWage, setTotalWage] = useState("");
  const [totalMaterialAndWage, setTotalMaterialAndWage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElExport, setAnchorElExport] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openExport = Boolean(anchorElExport);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      Promise.all([
        projectsStore.getExpenseAllByProjectId(id),
        companyStore.getAll(),
        projectsStore.getExpenseWithProductCategoryByProjectId(id),
      ]).then((res: any) => {
        setAllValue(res[0]);
        setData(res[0].resultExpense);
        setCompanyInfo(res[1][0]);
        calculateWageTotal(res[2].categories);
        calculateMaterialTotal(res[2].categories);
        calculateMaterialAndWageTotal(res[2].categories);
        setProductCategories(res[2]?.categories);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleGetHouseProduct = (
    houseId: string,
    title: string,
    total: string,
    isActive: boolean
  ) => {
    setIsLoading(true);
    Promise.all([
      projectsStore.getExpenseAllByHouseId(houseId),
      id && projectsStore.getExpenseWithCommonByProjectId(id),
    ]).then((res: any) => {
      setHouseProduct({
        open: true,
        title: title,
        total: parseFloat(total).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        data: res[0].result,
        isActive: isActive,
        productGroup: res[0].productGroup,
        productCategory: res[0].productCategory,
        worker: res[0].worker,
        land: res[0].land,
        landTax: res[0].landTax,
        commonFee: res[1] || [],
        companyInfo: companyInfo,
        totalWage: calculateWageTotal(
          res[0].productCategory?.categories,
          "house"
        ),
        totalMaterial: calculateMaterialTotal(
          res[0].productCategory?.categories,
          "house"
        ),
        totalMaterialAndWage: calculateMaterialAndWageTotal(
          res[0].productCategory?.categories,
          "house"
        ),
        house: houseId,
      });
      setIsLoading(false);
    });
  };

  const handleOpenDialogLand = (value: any) => {
    setLandDialog({
      open: true,
      title: "ที่ดิน",
      data: value,
    });
  };

  const handleOpenDialogLandTax = (value: any) => {
    setLandTaxDialog({
      open: true,
      title: "ภาษีที่ดิน",
      data: value,
    });
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    switch (newValue) {
      case "home":
        setData(allValue?.resultExpense);
        break;
      case "land":
        if (id && land?.length === 0) {
          setIsLoading(true);
          projectsStore.getExpenseWithLandByProjectId(id).then((res: any) => {
            setLand(res?.land);
            setIsLoading(false);
          });
        }
        break;
      case "land-tax":
        if (id && landTax?.length === 0) {
          setIsLoading(true);
          projectsStore
            .getExpenseWithLandTaxByProjectId(id)
            .then((res: any) => {
              setLandTax(res?.landTax);
              setIsLoading(false);
            });
        }
        break;
      case "productGroup":
        if (id && productGroup?.length === 0) {
          setIsLoading(true);
          projectsStore
            .getExpenseWithProductGroupByProjectId(id)
            .then((res: any) => {
              setProductGroup(res?.productGroups);
              setIsLoading(false);
            });
        }
        break;
      case "productCategory":
        if (id && productCategories?.length === 0) {
          setIsLoading(true);
          projectsStore
            .getExpenseWithProductCategoryByProjectId(id)
            .then((res: any) => {
              setProductCategories(res?.categories);
              setIsLoading(false);
            });
        }
        break;
      case "worker":
        if (id && worker?.length === 0) {
          setIsLoading(true);
          projectsStore.getExpenseWithWorkerByProjectId(id).then((res: any) => {
            setWorker(res?.wages);
            setIsLoading(false);
          });
        }
        break;
      case "common":
        if (id && commonFee?.length === 0) {
          setIsLoading(true);
          projectsStore.getExpenseWithCommonByProjectId(id).then((res: any) => {
            setIsLoading(false);
            setCommonFee(res?.commonFee?.commonFeeCategoriesResult);
          });
        }
        break;
      default:
        setIsLoading(false);
    }
  };

  const printHousesRef = useRef(null);
  const printProductGroupsRef = useRef(null);

  const exportHousesRef = useRef(null);
  const exportProductGroupsRef = useRef(null);

  const printProductCategoriesRef = useRef(null);
  const exportProductCategoriesRef = useRef(null);

  const printWorkersRef = useRef(null);
  const exportWorkersRef = useRef(null);

  const exportLandRef = useRef(null);
  const printLandRef = useRef(null);

  const exportLandTaxRef = useRef(null);
  const printLandTaxRef = useRef(null);

  const exportCommonRef = useRef(null);
  const printCommonRef = useRef(null);

  const exportExpensesProjectRef = useRef(null);
  const printExpensesProjectRef = useRef(null);
  const exportExpensesProjectDetailRef = useRef(null);
  const printExpensesProjectDetailRef = useRef(null);

  const calculateGrandTotal = (data: any[]) => {
    return data
      ? data.reduce((accumulator, currentValue) => {
          return parseFloat(accumulator) + parseFloat(currentValue?.total || 0);
        }, 0)
      : 0;
  };

  const calculateLandTotal = (data: any) => {
    const sum = (
      parseFloat(data?.landPrice ?? 0) +
      parseFloat(data?.registrationFee ?? 0) +
      parseFloat(data?.mortgageFee ?? 0)
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return sum;
  };

  const calculateWageTotal = (data: any, type = "project") => {
    const sum = data
      .reduce((accumulator = 0, currentValue: any) => {
        return accumulator + parseFloat(currentValue?.totalWage || 0);
      }, 0)
      .toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    if (type === "project") {
      setTotalWage(sum);
    }
    if (type === "house") {
      return sum;
    }
  };

  const calculateMaterialTotal = (data: any, type = "project") => {
    const sum = data
      ?.reduce((accumulator = 0, currentValue: any) => {
        return accumulator + parseFloat(currentValue?.total || 0);
      }, 0)
      .toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    if (type === "project") {
      setTotalMaterial(sum);
    }
    if (type === "house") {
      return sum;
    }
  };

  const calculateMaterialAndWageTotal = (data: any, type = "project") => {
    const sum = data?.reduce((accumulator = 0, currentValue: any) => {
      return (
        accumulator +
        parseFloat(currentValue?.total || 0) +
        parseFloat(currentValue?.totalWage || 0)
      );
    }, 0);
    if (type === "project") {
      setTotalMaterialAndWage(sum);
    }
    if (type === "house") {
      return sum;
    }
  };
  const handleClick = async (
    event: React.MouseEvent<HTMLElement>,
    type = "print"
  ) => {
    if (type === "print") {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorElExport(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElExport(null);
  };

  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/projects-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11.5} display={"flex"} alignItems={"center"}>
          <Grid item xs={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography>โครงการ</Typography>
              <Link
                component="button"
                onClick={() => navigate("/projects-list")}
              >
                รายการ
              </Link>
              <Typography variant="h6" color="text.primary">
                รายละเอียด
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={8.5} display={"flex"} justifyContent={"flex-end"}>
            <Grid
              item
              xs={1.5}
              display={"flex"}
              flexDirection={"column"}
              mb={0.5}
            >
              <Button
                id="demo-customized-button-export"
                aria-controls={openExport ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openExport ? "true" : undefined}
                sx={{ decoration: "none", color: "gray" }}
                disableElevation
                onClick={(e) => handleClick(e, "export")}
                endIcon={<KeyboardArrowDownIcon />}
              >
                <DownloadIcon />
              </Button>
              <StyledMenu
                id="demo-customized-menu-export"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorElExport}
                open={openExport}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} disableRipple>
                  <DownloadTableExcel
                    filename={`project-cost-${dayjs().format("DD-MM-YYYY")}`}
                    sheet={`project-cost-${dayjs().format("DD-MM-YYYY")}`}
                    currentTableRef={exportExpensesProjectRef.current}
                  >
                    <Box>
                      <DownloadIcon />
                      สรุปค่าใช้จ่าย
                    </Box>
                  </DownloadTableExcel>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                  <DownloadTableExcel
                    filename={`project-detail-cost-${dayjs().format(
                      "DD-MM-YYYY"
                    )}`}
                    sheet={`project-detail-cost-${dayjs().format(
                      "DD-MM-YYYY"
                    )}`}
                    currentTableRef={exportExpensesProjectDetailRef.current}
                  >
                    <Box>
                      <DownloadIcon />
                      รายละเอียดค่าใช้จ่าย
                    </Box>
                  </DownloadTableExcel>
                </MenuItem>
              </StyledMenu>
            </Grid>
            <Grid
              item
              xs={1.5}
              display={"flex"}
              flexDirection={"column"}
              mb={0.5}
            >
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{ decoration: "none", color: "gray" }}
                disableElevation
                onClick={(e) => {
                  handleClick(e, "print");
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                <PrintIcon />
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} disableRipple>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Box>
                          <PrintIcon />
                          สรุปค่าใช้จ่าย
                        </Box>
                      );
                    }}
                    content={() => {
                      return printExpensesProjectRef.current;
                    }}
                  />
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Box>
                          <PrintIcon />
                          รายละเอียดค่าใช้จ่าย
                        </Box>
                      );
                    }}
                    content={() => {
                      return printExpensesProjectDetailRef.current;
                    }}
                  />
                </MenuItem>
              </StyledMenu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    style={{
                      textDecoration: !data?.isActive ? "line-through" : "none",
                    }}
                  >
                    {data?.isActive ? (
                      data?.name || ""
                    ) : (
                      <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                        <span>{data?.name || ""}</span>
                      </Tooltip>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    มูลค่ารวม:{" "}
                    {parseFloat(
                      calculateGrandTotal(
                        allValue?.resultExpenseProductGroup?.expense || []
                      ) + parseFloat(allValue?.total || 0)
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    บาท
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange}>
                  <Tab label="บ้าน" value="home" />
                  {/* NOTE: May use in the future */}
                  {/* <Tab label="หมวดหมู่สินค้า" value="productGroup" /> */}
                  <Tab label="ที่ดิน" value="land" />
                  <Tab label="ภาษี" value="land-tax" />
                  <Tab label="งานก่อสร้าง" value="productCategory" />
                  <Tab label="งานส่วนกลาง" value="common" />
                  <Tab label="คนงาน" value="worker" />
                </TabList>
              </Box>
              <TabPanel value="home">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด {data?.houses?.length.toLocaleString()} รายการ
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`project-house-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`project-house-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportHousesRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printHousesRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>บ้าน</TableCell>
                            <TableCell>มูลค่ารวม</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.houses.map((row: any) => (
                            <TableRow
                              key={`house-project-detail-${row.id}-project-detail`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                scope="row"
                                style={{
                                  textDecoration: !row?.isActive
                                    ? "line-through"
                                    : "none",
                                }}
                              >
                                {row?.isActive ? (
                                  row.name
                                ) : (
                                  <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                                    {row?.name}
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell>
                                {parseFloat(row?.total ?? 0).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-label="detail"
                                  onClick={() => {
                                    handleGetHouseProduct(
                                      row.id,
                                      row.name,
                                      row.total,
                                      row?.isActive
                                    );
                                  }}
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="land">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด {land?.length.toLocaleString()} รายการ
                        </Typography>
                        <Typography>
                          มูลค่าที่ดินทั้งหมด{" "}
                          {parseFloat(allValue?.totalLand ?? 0).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}{" "}
                          บาท
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`project-land-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`project-land-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportLandRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printLandRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>เลขที่โฉนด</TableCell>
                            <TableCell>บ้าน</TableCell>
                            <TableCell>ที่อยู่</TableCell>
                            <TableCell>เนื้อที่</TableCell>
                            <TableCell>มูลค่ารวม</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {land?.map((row: any) => (
                            <TableRow
                              key={`land-project-detail-${row.id}-project-detail`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell scope="row">{row?.deedNo}</TableCell>
                              <TableCell
                                scope="row"
                                style={{
                                  textDecoration: !row?.house?.isActive
                                    ? "line-through"
                                    : "none",
                                }}
                              >
                                {row?.house?.isActive ? (
                                  row?.children?.length > 0 ? (
                                    ""
                                  ) : (
                                    row?.house?.name
                                  )
                                ) : (
                                  <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                                    {row?.house?.name}
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell>{`ต.${
                                row?.landSubDistrict?.nameTh || "-"
                              } อ.${row?.landDistrict?.nameTh || "-"} จ.${
                                row?.landProvince?.nameTh || "-"
                              }`}</TableCell>
                              <TableCell>{`${
                                row?.rai
                                  ? parseFloat(row?.rai || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )
                                  : "-"
                              } ไร่ ${
                                row?.ngan
                                  ? parseFloat(row?.ngan).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )
                                  : "-"
                              } งาน ${
                                row?.squareWah
                                  ? parseFloat(row?.squareWah).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )
                                  : "-"
                              } ตร.วา`}</TableCell>
                              <TableCell>{calculateLandTotal(row)}</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-label="detail"
                                  onClick={() => {
                                    handleOpenDialogLand(row);
                                  }}
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="land-tax">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด {landTax?.length.toLocaleString()} รายการ
                        </Typography>
                        <Typography>
                          มูลค่าภาษีทั้งหมด{" "}
                          {parseFloat(allValue?.totalTax ?? 0).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}{" "}
                          บาท
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`land-tax-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`land-tax-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportLandTaxRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printLandTaxRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>วันที่จ่ายภาษี</TableCell>
                            <TableCell>บ้าน</TableCell>
                            <TableCell>มูลค่ารวม</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landTax?.map((row: any, index: number) => (
                            <TableRow
                              key={`landTax-project-detail-${row.id}-project-detail-${index}-project-detail`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {dayjs(row?.taxDate).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell
                                scope="row"
                                style={{
                                  textDecoration: !row?.land?.house?.isActive
                                    ? "line-through"
                                    : "none",
                                }}
                              >
                                {row?.land?.house?.isActive ? (
                                  row?.land?.house?.name
                                ) : (
                                  <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                                    {row?.land?.house?.name}
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell>
                                {parseFloat(
                                  row?.grandTotalTax ?? 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-label="detail"
                                  onClick={() => {
                                    handleOpenDialogLandTax(row);
                                  }}
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              {/* NOTE: May use in the future */}
              {/* <TabPanel value="productGroup">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={'end'}>
                      <Grid item xs={10} display={'flex'} gap={1.5}>
                        <Typography fontWeight={'bold'}>รายละเอียด</Typography>
                        <Typography>ทั้งหมด {productGroup?.expense.length.toLocaleString()} รายการ</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`project-product-group-cost-${dayjs().format('DD-MM-YYYY')}`}
                          sheet={`project-product-group-cost-${dayjs().format('DD-MM-YYYY')}`}
                          currentTableRef={exportProductGroupsRef.current}
                        >
                          <IconButton aria-label="download"  >
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printProductGroupsRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {productGroup?.expense && productGroup.expense.map((item: any, index: number) => {
                      return (
                        <Accordion style={{ width: '100%' }} key={`productGroup-${index.toString()}`}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Grid container display={'flex'} justifyContent={'space-between'}>
                              <Typography><b>หมวดหมู่สินค้า: </b> {item?.productGroupName || 'ยังไม่ระบุ'}</Typography>
                              <Typography mr={10}>{parseFloat(item?.total ?? 0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}</Typography>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>รายการ</TableCell>
                                    <TableCell>เลขที่เอกสาร</TableCell>
                                    <TableCell>จำนวน</TableCell>
                                    <TableCell>ราคาต่อหน่วย</TableCell>
                                    <TableCell>มูลค่ารวม</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {item.items.length > 0 && item.items
                                    .slice() // Create a copy to avoid modifying the original array
                                    .sort((a: any, b: any) => a.product.localeCompare(b.product)).sort((a: any, b: any) => { return dayjs(b.docDate).diff(a.docDate); })
                                    .map((product: any, indexProduct: number) => {
                                      return (
                                        <TableRow key={`productGroup-expense-${indexProduct.toString()}`}>
                                          <TableCell>{product.product}</TableCell>
                                          <TableCell>{product.docNo}</TableCell>
                                          <TableCell>{parseFloat(product?.quantity ?? 0).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</TableCell>
                                          <TableCell> {parseFloat(product?.price ?? 0).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</TableCell>
                                          <TableCell> {(product.quantity * product.price).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</TableCell>
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}

                  </Grid>
                </Grid>
              </TabPanel> */}
              <TabPanel value="productCategory">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด {productCategories?.length.toLocaleString()}{" "}
                          รายการ
                        </Typography>
                        <Typography>
                          มูลค่างานก่อสร้างทั้งหมด{" "}
                          {parseFloat(
                            allValue?.totalCategories ?? 0
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          บาท
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`project-product-category-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`project-product-category-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportProductCategoriesRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printProductCategoriesRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {productCategories?.length > 0 &&
                      productCategories?.map((item: any, index: number) => {
                        return (
                          <Accordion
                            style={{ width: "100%" }}
                            key={`productCategories-categories-project-detail-${index.toString()}-project-detail`}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Grid
                                container
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Typography fontWeight={"bold"}>
                                  {index + 1}.{" "}
                                  {item?.productCategoryName || "ยังไม่ระบุ"}
                                </Typography>
                                <Typography mr={10}>
                                  {parseFloat(item?.total ?? 0).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Typography>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>รายการ</TableCell>
                                      <TableCell>จำนวน</TableCell>
                                      <TableCell>ราคาต่อหน่วย</TableCell>
                                      <TableCell>ราคา</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item.groups.length > 0 &&
                                      item.groups.map(
                                        (group: any, indexGroup: number) => {
                                          return (
                                            <React.Fragment
                                              key={`groups-project-detail-${indexGroup.toString()}-project-detail-${
                                                group?.id
                                              }`}
                                            >
                                              {group?.productGroupName && (
                                                <TableRow
                                                  key={`item-groups-project-detail-${indexGroup.toString()}-project-detail`}
                                                >
                                                  <TableCell
                                                    sx={{
                                                      fontWeight: "bold",
                                                    }}
                                                    colSpan={3}
                                                  >
                                                    {index + 1}.{indexGroup + 1}{" "}
                                                    {group?.productGroupName}
                                                  </TableCell>
                                                  <TableCell>
                                                    {" "}
                                                    {parseFloat(
                                                      group?.total ?? 0
                                                    ).toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              )}
                                              {group.products.length > 0 &&
                                                group.products.map(
                                                  (
                                                    product: any,
                                                    indexProduct: number
                                                  ) => {
                                                    return (
                                                      <TableRow
                                                        key={`group-products-project-detail-${indexProduct.toString()}-project-detail`}
                                                      >
                                                        <TableCell>
                                                          - {product.product}
                                                        </TableCell>
                                                        <TableCell>
                                                          {parseFloat(
                                                            product?.quantity ??
                                                              0
                                                          ).toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </TableCell>
                                                        <TableCell>
                                                          {" "}
                                                          {parseFloat(
                                                            product?.price ?? 0
                                                          ).toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </TableCell>
                                                        <TableCell>
                                                          {" "}
                                                          {parseFloat(
                                                            product?.total ?? 0
                                                          ).toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </TableCell>
                                                      </TableRow>
                                                    );
                                                  }
                                                )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="common">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด {commonFee?.length.toLocaleString()} รายการ
                        </Typography>
                        <Typography>
                          มูลค่างานส่วนกลางทั้งหมด{" "}
                          {parseFloat(
                            allValue?.totalCommonFee ?? 0
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          บาท
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`common-fee-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`common-fee-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportCommonRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printCommonRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {commonFee.length > 0 &&
                      commonFee.map((item: any, index: number) => {
                        return (
                          <Accordion
                            style={{ width: "100%" }}
                            key={`common-fee-productCategories-categories-project-detail-${index.toString()}-project-detail`}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Grid
                                container
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Typography fontWeight={"bold"}>
                                  {index + 1}.{" "}
                                  {item?.productCategoryName || "ยังไม่ระบุ"}
                                </Typography>
                                <Typography mr={10}>
                                  {parseFloat(item?.total ?? 0).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Typography>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box>
                                {item?.group?.map(
                                  (group: any, indexGroup: number) => {
                                    return (
                                      <Accordion
                                        style={{ width: "100%" }}
                                        key={`common-fee-productCategories-categories-project-detail-group-${indexGroup.toString()}-project-detail-${
                                          index + index
                                        }`}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Grid
                                            container
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                          >
                                            <Typography fontWeight={"bold"}>
                                              {index + 1}.{indexGroup + 1}{" "}
                                              {group?.name || "ยังไม่ระบุ"}
                                            </Typography>
                                            <Typography mr={10}>
                                              {parseFloat(
                                                group?.total ?? 0
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </Typography>
                                          </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }}>
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell>รายการ</TableCell>
                                                  <TableCell>จำนวน</TableCell>
                                                  <TableCell>
                                                    ราคาต่อหน่วย
                                                  </TableCell>
                                                  <TableCell>ราคา</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {group.detail.length > 0 &&
                                                  group.detail.map(
                                                    (
                                                      detail: any,
                                                      indexdetail: number
                                                    ) => {
                                                      return (
                                                        <React.Fragment
                                                          key={`common-fee-item-groups-project-detail-${indexdetail.toString()}-project-detail- ${
                                                            detail?.id
                                                          }`}
                                                        >
                                                          <TableRow>
                                                            <TableCell>
                                                              {detail?.product
                                                                ?.name || ""}
                                                            </TableCell>
                                                            <TableCell>
                                                              {detail?.quantity
                                                                ? parseFloat(
                                                                    detail?.quantity
                                                                  ).toLocaleString(
                                                                    undefined,
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )
                                                                : ""}
                                                            </TableCell>
                                                            <TableCell>
                                                              {detail?.price
                                                                ? (detail?.price).toLocaleString(
                                                                    undefined,
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )
                                                                : ""}
                                                            </TableCell>
                                                            <TableCell>
                                                              {detail?.total
                                                                ? parseFloat(
                                                                    detail?.total
                                                                  ).toLocaleString(
                                                                    undefined,
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )
                                                                : ""}
                                                            </TableCell>
                                                          </TableRow>
                                                        </React.Fragment>
                                                      );
                                                    }
                                                  )}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </AccordionDetails>
                                      </Accordion>
                                    );
                                  }
                                )}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="worker">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด {worker?.length.toLocaleString()} รายการ
                        </Typography>
                        <Typography>
                          ค่าแรงทั้งหมด{" "}
                          {parseFloat(allValue?.totalWage ?? 0).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}{" "}
                          บาท
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`project-worker-wage-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`project-worker-wage${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportWorkersRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printWorkersRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {worker &&
                      worker
                        .slice() // Create a copy to avoid modifying the original array
                        .sort((a: any, b: any) => {
                          return dayjs(a.periodDateFrom).diff(b.periodDateFrom);
                        })
                        .map((item: any, index: number) => {
                          return (
                            <Accordion
                              style={{ width: "100%" }}
                              key={`worker-wages-project-detail-${index.toString()}-project-detail`}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Grid
                                  container
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                >
                                  <Typography fontWeight={"bold"}>{`${
                                    index + 1
                                  }. ค่าแรงงวดวันที่ ${dayjs(
                                    item?.periodDateFrom
                                  ).format("DD/MM/YYYY")} ถึง ${dayjs(
                                    item?.periodDateTo
                                  ).format("DD/MM/YYYY")}`}</Typography>
                                  <Typography mr={10}>
                                    {parseFloat(
                                      item?.total ?? 0
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TableContainer component={Paper}>
                                  <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>ชื่อ - นามสกุล</TableCell>
                                        <TableCell>ค่าแรง</TableCell>
                                        <TableCell>จำนวนเงิน OT</TableCell>
                                        <TableCell>รวมค่าแรง + OT</TableCell>
                                        <TableCell>เบิก</TableCell>
                                        <TableCell>คงเหลือ</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {item.wageDetails?.length > 0 &&
                                        item.wageDetails
                                          .slice() // Create a copy to avoid modifying the original array
                                          .sort((a: any, b: any) =>
                                            dayjs(a.createAt).diff(b.createAt)
                                          )
                                          .map(
                                            (
                                              detail: any,
                                              indexDetail: number
                                            ) => {
                                              return (
                                                <React.Fragment
                                                  key={`item-wageDetails-project-detail-${indexDetail.toString()}- ${
                                                    detail?.id
                                                  }`}
                                                >
                                                  {detail?.worker && (
                                                    <TableRow
                                                      key={`item-wageDetails-project-detail-${indexDetail.toString()}`}
                                                    >
                                                      <TableCell
                                                        sx={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {
                                                          detail?.worker
                                                            ?.firstName
                                                        }{" "}
                                                        {
                                                          detail?.worker
                                                            ?.lastName
                                                        }
                                                      </TableCell>
                                                      <TableCell>
                                                        {" "}
                                                        {parseFloat(
                                                          detail?.totalLaborCost ??
                                                            0
                                                        ).toLocaleString(
                                                          undefined,
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </TableCell>
                                                      <TableCell>
                                                        {" "}
                                                        {parseFloat(
                                                          detail?.totalNet ?? 0
                                                        ).toLocaleString(
                                                          undefined,
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </TableCell>
                                                      <TableCell>
                                                        {" "}
                                                        {parseFloat(
                                                          detail?.totalLaborAndCostOt ??
                                                            0
                                                        ).toLocaleString(
                                                          undefined,
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </TableCell>
                                                      <TableCell>
                                                        {" "}
                                                        {parseFloat(
                                                          detail?.withdraw ?? 0
                                                        ).toLocaleString(
                                                          undefined,
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </TableCell>
                                                      <TableCell>
                                                        {" "}
                                                        {parseFloat(
                                                          detail?.totalNet ?? 0
                                                        ).toLocaleString(
                                                          undefined,
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </TableCell>
                                                    </TableRow>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogHouseProduct
        open={houseProduct.open}
        onClose={() =>
          setHouseProduct({
            open: false,
            title: "",
            data: [],
            productGroup: [],
            productCategory: [],
            worker: [],
            land: [],
            landTax: [],
            commonFee: [],
            companyInfo: [],
            house: "",
          })
        }
        title={houseProduct.title || ""}
        total={houseProduct.total}
        data={houseProduct.data}
        isActive={houseProduct.isActive}
        productGroup={houseProduct.productGroup}
        productCategory={houseProduct.productCategory}
        worker={houseProduct.worker}
        land={houseProduct.land}
        landTax={houseProduct.landTax}
        commonFee={houseProduct.commonFee}
        companyInfo={houseProduct.companyInfo}
        totalWage={houseProduct.totalWage}
        totalMaterial={houseProduct.totalMaterial}
        totalMaterialAndWage={houseProduct.totalMaterialAndWage}
        house={houseProduct.house}
      />
      <DialogLand
        open={landDialog.open}
        onClose={() => setLandDialog({ open: false, title: "", data: [] })}
        title={landDialog.title || ""}
        data={landDialog.data}
      />
      <DialogLandTax
        open={landTaxDialog.open}
        onClose={() => setLandTaxDialog({ open: false, title: "", data: [] })}
        title={landTaxDialog.title || ""}
        data={landTaxDialog.data}
      />
      <Box sx={{ display: "none" }}>
        <PrintExpensesProjectHouses
          ref={printHousesRef}
          title={data?.name}
          data={data?.houses}
          summary={parseFloat(
            calculateGrandTotal(
              allValue?.resultExpenseProductGroup?.expense || []
            ) + parseFloat(allValue?.total || 0)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintProjectProductGroups
          ref={printProductGroupsRef}
          title={data?.name}
          data={productGroup}
          summary={calculateGrandTotal(productGroup || []).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesProjectHouses
          ref={exportHousesRef}
          title={data?.name}
          data={data?.houses}
          summary={parseFloat(
            calculateGrandTotal(
              allValue?.resultExpenseProductGroup?.expense || []
            ) + parseFloat(allValue?.total || 0)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileProjectProductGroups
          ref={exportProductGroupsRef}
          title={data?.name}
          data={productGroup}
          summary={calculateGrandTotal(productGroup || []).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintProjectProductCategories
          ref={printProductCategoriesRef}
          title={data?.name}
          data={productCategories}
          summary={calculateGrandTotal(productCategories || []).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileProjectProductCategories
          ref={exportProductCategoriesRef}
          title={data?.name}
          data={productCategories}
          summary={calculateGrandTotal(productCategories || []).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintExpensesProjectCommonFees
          ref={printCommonRef}
          title={data?.name}
          data={commonFee}
          summary={parseFloat(allValue?.totalCommonFee ?? 0).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesProjectCommonFees
          ref={exportCommonRef}
          title={data?.name}
          data={commonFee}
          summary={parseFloat(allValue?.totalCommonFee ?? 0).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintWorkers
          ref={printWorkersRef}
          title={data?.name}
          data={worker}
          summary={parseFloat(allValue?.totalWage).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportWorkers
          ref={exportWorkersRef}
          title={data?.name}
          data={worker}
          summary={parseFloat(allValue?.totalWage).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintExpensesProject
          ref={printExpensesProjectRef}
          title={data?.name}
          data={productCategories}
          companyInfo={companyInfo}
          totalMaterial={totalMaterial}
          totalWage={totalWage}
          totalExpense={totalMaterialAndWage}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesProject
          ref={exportExpensesProjectRef}
          title={data?.name}
          data={productCategories}
          companyInfo={companyInfo}
          totalMaterial={totalMaterial}
          totalWage={totalWage}
          totalExpense={totalMaterialAndWage}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintExpensesProjectDetail
          ref={printExpensesProjectDetailRef}
          title={data?.name}
          data={productCategories}
          companyInfo={companyInfo}
          totalMaterial={totalMaterial}
          totalWage={totalWage}
          totalExpense={totalMaterialAndWage}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesProjectDetail
          ref={exportExpensesProjectDetailRef}
          title={data?.name}
          data={productCategories}
          companyInfo={companyInfo}
          totalMaterial={totalMaterial}
          totalWage={totalWage}
          totalExpense={totalMaterialAndWage}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintExpensesProjectLand
          ref={printLandRef}
          title={data?.name}
          data={land}
          total={allValue?.totalLand}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesProjectLand
          ref={exportLandRef}
          title={data?.name}
          data={land}
          total={allValue?.totalLand}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintExpensesProjectLandTax
          ref={printLandTaxRef}
          title={data?.name}
          data={landTax}
          total={allValue?.totalTax}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesProjectLandTax
          ref={exportLandTaxRef}
          title={data?.name}
          data={landTax}
          total={allValue?.totalTax}
        />
      </Box>
    </Box>
  );
};
export default ProjectDetail;
