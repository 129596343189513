import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface DialogLandTaxProps {
  open: boolean;
  title: string;
  data?: any;
  onClose: () => void;
}

const DialogLandTax: React.FC<DialogLandTaxProps> = (props: DialogLandTaxProps) => {
  return (
    <Dialog open={props.open} fullWidth maxWidth={'lg'}>
      <DialogContent >
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography mb={1}>ข้อมูลภาษีและค่าธรรมเนียมที่ดิน</Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>วันที่จ่ายภาษี:</b> {props.data?.taxDate ? dayjs(props.data?.taxDate).format('DD/MM/YYYY') : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ค่าคำขอ:</b> {props.data?.requestFee ? parseFloat(props.data?.requestFee) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ค่าธรรมเนียม อปท. :</b>  {props.data?.lgoFee ? parseFloat(props.data?.lgoFee) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>รวมทั้งสิ้นค่าธรรมเนียม:</b>  {props.data?.totalFee ? parseFloat(props.data?.totalFee) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ค่าคำขอ:</b> {props.data?.requestFee ? parseFloat(props.data?.requestFee) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ค่าธรรมเนียม อปท. :</b>  {props.data?.lgoFee ? parseFloat(props.data?.lgoFee) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>รวมค่าธรรมเนียม:</b>  {props.data?.totalFee ? parseFloat(props.data?.totalFee) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ภาษีเงินได้บุคคลธรรมดา:</b> {props.data?.personalTaxIncome ? parseFloat(props.data?.personalTaxIncome) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ภาษีเงินได้นิติบุคคล:</b>  {props.data?.corporateIncomeTax ? parseFloat(props.data?.corporateIncomeTax) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ค่าอากรแสตมป์:</b>  {props.data?.stampDuty ? parseFloat(props.data?.stampDuty) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>ภาษีธุรกิจเฉพาะ:</b> {props.data?.specificBusinessTax ? parseFloat(props.data?.specificBusinessTax) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>รายได้ส่วนท้องถิ่น:</b>  {props.data?.localIncome ? parseFloat(props.data?.localIncome) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>รวมทั้งสิ้นค่าภาษีอากร:</b>  {props.data?.totalTax ? parseFloat(props.data?.totalTax) : ''}
                </Typography>
              </Grid>
              <Grid item xs={4} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>รวมทั้งสิ้นค่าธรรมเนียมและค่าภาษีอากร:</b> {props.data?.grandTotalTax ? parseFloat(props.data?.grandTotalTax) : ''}
                </Typography>
              </Grid>
              <Grid item xs={5} display={'flex'} alignItems={'center'}>
                <Typography>
                  <b>หมายเหตุ:</b>  {props.data?.remarkText || ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button variant="text" onClick={() => props.onClose()}>ตกลง</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogLandTax;