import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class InventoryStore {
  inventories: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/inventories");
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting inventories:", error);
      return {
        error: true,
        message: "An error occurred while getting inventories",
      };
    }
  }

  async getAllRefNo() {
    try {
      const response = await apiService.get("/inventories/refNo");
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting inventories:", error);
      return {
        error: true,
        message: "An error occurred while getting inventories",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/inventories/${id}`);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting inventories:", error);
      return {
        error: true,
        message: "An error occurred while getting inventories",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/inventories", data);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating inventories:", error);
      return {
        error: true,
        message: "An error occurred while creating inventories",
      };
    }
  }

  async createDetail(data: any) {
    try {
      const response = await apiService.post("/inventories/detail", data);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating inventories detail:", error);
      return {
        error: true,
        message: "An error occurred while creating inventories detail",
      };
    }
  }

  async createItem(data: any) {
    try {
      const response = await apiService.post("/inventories/item", data);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating inventory item:", error);
      return {
        error: true,
        message: "An error occurred while creating inventory item",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/inventories/${id}`, data);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating inventory:", error);
      return {
        error: true,
        message: "An error occurred while updating inventory",
      };
    }
  }

  async updateDetail(id: string, data: any) {
    try {
      const response = await apiService.put(`/inventories/detail/${id}`, data);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating inventory detail:", error);
      return {
        error: true,
        message: "An error occurred while updating inventory detail",
      };
    }
  }

  async updateItem(id: string, data: any) {
    try {
      const response = await apiService.put(`/inventories/item/${id}`, data);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating inventory item:", error);
      return {
        error: true,
        message: "An error occurred while updating inventory item",
      };
    }
  }

  async delete(id: any) {
    try {
      const response = await apiService.delete(`/inventories/${id}`);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting inventory:", error);
      return {
        error: true,
        message: "An error occurred while deleting inventory",
      };
    }
  }

  async deleteDetail(id: any) {
    try {
      const response = await apiService.delete(`/inventories/detail/${id}`);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting inventory detail:", error);
      return {
        error: true,
        message: "An error occurred while deleting inventory detail",
      };
    }
  }
  async deleteItemDetail(id: string) {
    try {
      const response = await apiService.delete(`/inventories/item/${id}`);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting inventory detail:", error);
      return {
        error: true,
        message: "An error occurred while deleting inventory detail",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post(`/inventories/criteria`, criteria);
      this.inventories = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting inventories by criteria:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting inventories by criteria",
      };
    }
  }
  async getDetailByProductId(object: any) {
    try {
      const response = await apiService.post(`/inventories/detail/product`, object);
      this.inventories = response;
      return response;
    }
    catch (error) {
      console.error(
        "An error occurred while getting inventory details by product id",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting inventory details by product id",
      };
    }
  }
}
const inventoryStore = new InventoryStore();
export default inventoryStore;
