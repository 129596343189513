import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
interface ExportFileProductDetailProps {
  title: string;
  total: string;
  data?: any;  
  house:string
}

const ExportFileProductDetail = React.forwardRef((props: ExportFileProductDetailProps, ref) => {
return (
  <Box ref={ref} sx={{ textAlign: 'center', padding: '20px', '&@page': { size: 'A4' }, }}>
  <Grid container spacing={2}>
      <Grid item xs={12}>
          <TableContainer >
              <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
                  <TableHead>
                      <TableRow>
                          <TableCell colSpan={3} sx={{ textAlign: 'center', }}>
                              <Typography fontWeight={'bold'}>{props.title}</Typography>
                          </TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell >วันที่เอกสาร </TableCell>
                        <TableCell>เลขเอกสาร</TableCell>
                        <TableCell>จำนวน</TableCell>
                        <TableCell>ราคาต่อหน่วย</TableCell>
                        <TableCell>จำนวนเงิน</TableCell>
                        <TableCell width={100} align="center">บันทึก</TableCell>
                        <TableCell width={100} align="center">แก้ไข</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                          {  props.data?.houses && props.data?.houses?.length>0 &&  props.data?.houses?.map((item: any, index: number) => {
                           return (
                                  <TableRow
                                      key={`${item.id}- export -${index} `}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
 <TableCell>{item?.expenseDetail?.expense?.docDate ? dayjs(item?.expenseDetail?.expense?.docDate).format('DD/MM/YYYY'):''}</TableCell>
                          <TableCell>{item?.expenseDetail?.expense?.docNo||""}</TableCell>
                          <TableCell>{parseFloat(item?.quantity).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                          <TableCell>{parseFloat(item?.expenseDetail?.price).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                          <TableCell>{(parseFloat(item?.quantity||0)* parseFloat(item?.expenseDetail?.price||0) ).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                          <TableCell align="center">{<b>{item?.createBy?.name}</b>} {item?.createdAt?dayjs(item?.createdAt).format('DD/MM/YYYY'):''}</TableCell>
                          <TableCell align="center">{<b>{item?.updateBy?.name}</b>} {item?.updateAt?dayjs(item?.updateAt).format('DD/MM/YYYY'):''}</TableCell>
                                  </TableRow>
                              );
                          })}
                          <TableRow>
                              <TableCell colSpan={6} align="right"><b>รวมทั้งหมด</b></TableCell>
                              <TableCell colSpan={1} align="right">{props?.total}</TableCell>
                          </TableRow>
                          { props.data?.houses && props.data?.houses?.length === 0 && (
                              <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                  <TableCell colSpan={7} align="center">ไม่พบข้อมูล</TableCell>
                              </TableRow>
                          )}
                      </TableBody>
              </Table>
          </TableContainer>
      </Grid>
  </Grid>
</Box>
)
});
export default ExportFileProductDetail;