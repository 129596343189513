import { Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, Grid, IconButton, Link, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import vendorsStore from "../../stores/VendorsStore";
import authStore from "../../stores/AuthStore";
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleBackdrop from "../../components/SimpleBackdrop";
import DialogConfirm from "../../components/DialogConfirm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const VendorCreate: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [vendorName, setVendorName] = useState('');
    const [vendorAddress, setVendorAddress] = useState('');
    const [vendorTel, setVendorTel] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openConfirm, setOpenConfirm] = useState({
        open: false,
        title: '',
        message: '',
        color: 'primary' as ButtonProps['color'],
        type: 'create' as 'create' | 'edit' | 'delete'
    });
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/vendors');
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const addRef = (el: any) => {
        if (el && !inputRefs.current.includes(el)) {
            inputRefs.current.push(el);
        }
    };

    const handleKeyDown = (event: any, index: number) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index + 1 < inputRefs.current.length) {
                inputRefs.current[index + 1].focus();
            }
        }
    };
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            vendorsStore.get(id).then((res) => {
                if (res.error) {
                    enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
                    setIsLoading(false);
                    return;
                }
                setVendorName(res.name);
                setVendorAddress(res.address);
                setVendorTel(res.tel);
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = () => {
        if (!id) {
            setIsLoading(true);
            vendorsStore.create({
                name: vendorName,
                address: vendorAddress,
                tel: vendorTel,
                createBy: authStore.user?.id,
                createAt: new Date(),
            }).then((res) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
                navigate(`/vendor-edit/${res.id}`);
            });
        }
    };

    const handleEdit = () => {
        if (id) {
            setIsLoading(true);
            vendorsStore.update(id, {
                name: vendorName,
                address: vendorAddress,
                tel: vendorTel,
                updateBy: authStore.user?.id,
                updateAt: new Date(),
            }).then((res) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
            });
        }
    };

    const handleDelete = () => {
        if (id) {
            setIsLoading(true);
            vendorsStore.update(id, {
                isActive: false,
                updateBy: authStore.user?.id,
                updateAt: new Date(),
            }).then((res) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('ลบผู้ขายไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                enqueueSnackbar('ลบผู้ขายสำเร็จ', { variant: 'success' });
                navigate('/vendors');
            });
        }
    };
    return (
        <Box component={'div'}>
            <Grid container>
                <Grid item xs={0.5}>
                    <IconButton aria-label="delete" onClick={() => navigate('/vendors')}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Typography>การจัดการ</Typography>
                        <Link component="button" onClick={() => navigate('/vendors')}>ผู้ขาย</Link>
                        <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={3} display={'flex'} justifyContent={'end'}>
                    {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
                        <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
                            open: true,
                            title: 'ลบข้อมูล',
                            message: 'คุณต้องการลบผู้ขายนี้ใช่หรือไม่',
                            color: 'error',
                            type: 'delete'
                        })}>ลบข้อมูล</Button>
                    }
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4} >
                                    <TextField id="outlined-basic" label="ชื่อผู้ขาย" variant="outlined" fullWidth
                                        onChange={(event) => setVendorName(event.target.value)} value={vendorName}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 0)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" label="ที่อยู่" variant="outlined" fullWidth
                                        onChange={(event) => setVendorAddress(event.target.value)} value={vendorAddress}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 1)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="outlined-basic" label="เบอร์โทร" variant="outlined" fullWidth
                                        onChange={(event) => setVendorTel(event.target.value)} value={vendorTel}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 2)}
                                    />
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>
                                <Grid item xs={12} >
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={4} >
                                            <Stack spacing={2} direction="row">
                                                <Button variant="outlined" fullWidth onClick={() => navigate('/vendors')}>ยกเลิก</Button>
                                                {!id && <Button variant="contained" fullWidth onClick={() => {
                                                    setOpenConfirm({
                                                        open: true,
                                                        title: 'บันทึกเอกสาร',
                                                        message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                                                        color: 'primary',
                                                        type: 'create'
                                                    });
                                                }}>บันทึก</Button>}
                                                {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                                                    setOpenConfirm({
                                                        open: true,
                                                        title: 'แก้ไขเอกสาร',
                                                        message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                                                        color: 'primary',
                                                        type: 'edit'
                                                    });
                                                }}>แก้ไข</Button>}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === 'delete') {
                        handleDelete();
                    } else if (openConfirm.type === 'create') {
                        handleSave();
                    } else if (openConfirm.type === 'edit') {
                        handleEdit();
                    }
                }} />
        </Box>
    );
};

export default VendorCreate;
