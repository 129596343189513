import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class CompanyStore {
  company: any[] = [];
  construnctor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/company");
      this.company = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting company:", error);
      return {
        error: true,
        message: "An error occurred while getting company",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/company/${id}`);
      this.company = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting company:", error);
      return {
        error: true,
        message: "An error occurred while getting company",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/company", data);
      this.company = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating company:", error);
      return {
        error: true,
        message: "An error occurred while creating company",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/company/${id}`, data);
      this.company = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating company:", error);
      return {
        error: true,
        message: "An error occurred while updating company",
      };
    }
  }
}
const companyStore = new CompanyStore();
export default companyStore;