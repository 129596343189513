import { Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from 'react';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import authStore from "../../stores/AuthStore";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import cashflowStore from "../../stores/CashflowStore";
import dayjs, { Dayjs } from "dayjs";
import { v4 as uuid4 } from 'uuid';
import { enqueueSnackbar } from "notistack";
import DialogConfirm from "../../components/DialogConfirm";
import imagesStore from "../../stores/ImagesStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";



const CashflowCreate: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/cashflow');
    const [openConfirm, setOpenConfirm] = useState({
        open: false,
        title: '',
        message: '',
        color: 'primary' as ButtonProps['color'],
        type: 'create' as 'create' | 'edit' | 'delete'
    });


    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [docDate, setDocDate] = useState<Dayjs | null>(dayjs());
    const [docType, setDocType] = useState('');
    const [note, setNote] = useState('');
    const [total, setTotal] = useState(0);
    const [items, setItems] = useState<any[]>([{ id: uuid4() }]);
    const [itemsOld, setItemsOld] = useState<any[]>([]);
    const [images, setImages] = useState<any[]>([]);
    const [deleteImages, setDeleteImages] = useState<any[]>([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const newSelectedFiles = Array.from(files);
            setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
        }
    };

    const handleFileRemove = (index: number) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    const handleFileRemoveImageList = (file: any) => {
        const updatedImages = images.filter((image) => image.id !== file.id);
        setImages(updatedImages);
        setDeleteImages([...deleteImages, file]);
    };

    const handleFileUpload = (refId: string) => {
        if (selectedFiles.length > 0) {
            selectedFiles.forEach(async (file: any, index: number) => {
                const formdata = new FormData();
                formdata.append("refType", "bill");
                formdata.append("refId", refId);
                formdata.append('file', file);
                formdata.append('createBy', authStore.user?.id);
                formdata.append("createAt", new Date(Date.now() + index * 1000).toISOString());

                await imagesStore.upload(formdata)
                    .catch(err => console.error("err", err));
            });
        }
    };

    const handleEditFileUpload = async (id: string) => {
        if (selectedFiles.length > 0) {
            selectedFiles.forEach(async (file: any, index: number) => {
                const formdata = new FormData();
                formdata.append("refType", "bill");
                formdata.append("refId", id);
                formdata.append('file', file);
                formdata.append('updateBy', authStore.user?.id);
                formdata.append("updateAt", new Date(Date.now() + index * 1000).toISOString());
                await imagesStore.upload(formdata)
                    .catch(err => console.error("err", err));
            });
        }
        if (deleteImages.length > 0) {
            await deleteImages.forEach(async (item: any) => {
                await imagesStore.update(item.id, {
                    updateBy: authStore.user?.id,
                    updateAt: new Date(),
                    isDeleted: true
                }).catch(err => console.error("err deleteImages", err));
            });
        }
    };

    const calculatorSummary = () => {
        const total = items.reduce((a: any, b: any) => a + parseFloat((b?.total) || 0), 0);
        setTotal(total);
    };

    const { id } = useParams();
    const handleChangeDocType = (event: ChangeEvent<HTMLInputElement>) => {
        setDocType((event.target as HTMLInputElement).value);
    };
    const handleRemoveItem = (id: string) => {
        setItems(items.filter((item: any) => item.id !== id));
    };

    const handleAddItem = () => {
        setItems([...items, {
            id: uuid4()
            , isNew: true
        }]);
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            if (!docDate) {
                enqueueSnackbar('กรุณาเลือกวันที่', { variant: 'warning' });
                setIsLoading(false);
                return;
            }

            if (!docType) {
                enqueueSnackbar('กรุณาเลือกประเภทเอกสาร', { variant: 'warning' });
                setIsLoading(false);
                return;
            }


            if (!items[0].description) {
                enqueueSnackbar('กรุณาระบุรายการ', { variant: 'warning' });
                setIsLoading(false);
                return;
            }

            if (!items[0].total) {
                enqueueSnackbar('กรุณากรอกจำนวนเงิน', { variant: 'warning' });
                setIsLoading(false);
                return;
            }
            const details = await items.map((item: any) => {
                return {
                    id: item.id,
                    total: item.total,
                    description: item.description,
                    createBy: authStore.user?.id,
                    createAt: new Date(),
                };
            });

            await cashflowStore.create({
                docDate: docDate,
                docType: docType,
                total: total,
                note: note,
                createBy: authStore.user?.id,
                createAt: new Date(),
                details: details,
            }).then((res: any) => {
                if (res.error) {
                    enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                    setIsLoading(false);
                }
                handleFileUpload(res);
                navigate(`/cashflow-edit/${res.id}`);
            });
            setIsLoading(false);
            enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
        } catch (error) {
            console.error(error);
            enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
            setIsLoading(false);
        }
    };

    const handleEdit = async () => {
        setIsLoading(true);
        try {
            if (!docDate) {
                enqueueSnackbar('กรุณาเลือกวันที่', { variant: 'warning' });
                setIsLoading(false);
                return;
            }

            if (!docType) {
                enqueueSnackbar('กรุณาเลือกประเภทเอกสาร', { variant: 'warning' });
                setIsLoading(false);
                return;
            }
            if (!items[0].description) {
                enqueueSnackbar('กรุณาระบุรายการ', { variant: 'warning' });
                setIsLoading(false);
                return;
            }
            if (!total) {
                enqueueSnackbar('กรุณากรอกจำนวนเงิน', { variant: 'warning' });
                setIsLoading(false);
                return;
            }

            if (id) {
                const itemsUpdate = items.filter((item: any) => {
                    return itemsOld.find((itemOld: any) => itemOld.id === item.id);
                });
                const itemsCreate = items.filter((item: any) => {
                    return !itemsOld.find((itemOld: any) => itemOld.id === item.id);
                });

                const itemsDetele = itemsOld.filter((itemOld: any) => {
                    return !items.find((item: any) => itemOld.id === item.id);
                });

                handleEditFileUpload(id);

                const details = await itemsUpdate.map((item: any) => {

                    return {
                        id: item.id,
                        description: item.description,
                        total: item.total,
                        cashflow: id,
                        updateBy: authStore.user?.id,
                        updateAt: new Date(),
                    };
                });

                cashflowStore.update(id, {
                    docDate: docDate,
                    docType: docType,
                    total: total,
                    note: note,
                    updateBy: authStore.user?.id,
                    updateAt: new Date(),
                    details: details,
                }).catch((err) => {
                    console.error(err);
                });
                if (itemsCreate.length > 0) {
                    const createDetails = await itemsCreate.map((item: any) => {
                        return {
                            description: item.description,
                            total: item.total,
                            cashflow: id,
                            createBy: authStore.user?.id,
                            createAt: new Date(),
                        };
                    });
                    await cashflowStore.createDetail(createDetails).catch((err) => {
                        console.error(err);
                        setIsLoading(false);
                    });
                }
                if (itemsDetele.length > 0) {
                    await itemsDetele.forEach((item: any) => {
                        cashflowStore.deleteDetail(item.id).catch((err) => {
                            enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                            console.error(err);
                            setIsLoading(false);
                        });
                    });
                }
                setIsLoading(false);
                navigate('/cashflow');
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };


    const handleDelete = async () => {
        if (id) {
            try {
                await cashflowStore.update(id, {
                    isDeleted: true,
                    updateBy: authStore.user?.id,
                    updateAt: new Date(),
                });
                enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
                setIsLoading(false);
                navigate('/cashflow');

            }
            catch (err) {
                setIsLoading(false);
                console.error(err);
            }
        }
    };

    useEffect(() => {
        setIsLoading(true);
        if (id) {
            cashflowStore.get(id).then((res) => {
                setDocDate(dayjs(res.docDate));
                setDocType(res.docType);
                setTotal(res.total);
                setItems(res?.cashflowDetail || []);
                setNote(res.note || '');
                setItemsOld(res?.cashflowDetail || []);
                setIsLoading(false);
                setImages(res.images);

            }).catch((err) => {
                console.error(err);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        calculatorSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
        <Box>
            <Box component={'div'}>
                <Grid container>
                    <Grid item xs={0.5}>
                        <IconButton aria-label="delete" onClick={() => navigate('/cashflow')}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            <Typography>บันทึกค่าใช้จ่าย</Typography>
                            <Link component="button" onClick={() => navigate('/cashflow')}>กระแสเงินสด</Link>
                            <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={3} display={'flex'} justifyContent={'end'}>
                        {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
                            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
                                open: true,
                                title: 'ลบเอกสาร',
                                message: 'คุณต้องการลบเอกสารนี้ใช่หรือไม่',
                                color: 'error',
                                type: 'delete'
                            })}>ลบเอกสาร</Button>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={9} >
                                        <FormControl>
                                            <FormLabel id="row-radio-buttons-type-label" sx={{ color: 'black' }}>ประเภท</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="row-radio-buttons-type-label"
                                                name="row-radio-buttons-type"
                                                value={docType}
                                                onChange={handleChangeDocType}
                                            >
                                                <FormControlLabel value="received" control={<Radio />} label="รายรับ" />
                                                <FormControlLabel value="paid" control={<Radio />} label="รายจ่าย" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                            <DatePicker
                                                label="วันที่เอกสาร"
                                                sx={{ width: '100%' }}
                                                value={docDate}
                                                onChange={(newValue) => setDocDate(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>รายละเอียด</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                            </Grid>
                                            <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                <Typography>ลำดับ</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography>รายการ</Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography>จำนวนเงิน</Typography>
                                            </Grid>
                                        </Grid>

                                        {items.map((item: any, index: number) => (
                                            <Grid item xs={12} mt={2} key={`i-${index.toString()}`} >
                                                <Grid container spacing={2} alignItems={'end'}>
                                                    <Grid item xs={0.5} mb={1.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                        <IconButton aria-label={`delete-${id}`} size="small" color="error" onClick={() => handleRemoveItem(items[index].id)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={0.5} mb={2} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                        <Typography>{index + 1}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} >
                                                        <TextField id="description" variant="outlined" fullWidth
                                                            value={items[index].description}
                                                            onChange={(e) => {
                                                                const _items = [...items];
                                                                _items[index].description = e.target.value;
                                                                setItems(_items);
                                                            }
                                                            } />
                                                    </Grid>
                                                    <Grid item xs={3} >
                                                        <TextField id="detailTotal" variant="outlined" fullWidth type="number"
                                                            inputProps={{
                                                                min: 0,
                                                            }}
                                                            value={items[index].total}
                                                            onChange={(e) => {
                                                                const _items = [...items];
                                                                _items[index].total = e.target.value;
                                                                setItems(_items);
                                                            }
                                                            } />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="outlined" sx={{ width: '100%' }} startIcon={<AddCircleIcon />} onClick={handleAddItem} >เพิ่มรายการ</Button>
                                    </Grid>
                                    <Grid item xs={12}><Divider /></Grid>
                                    <Grid item xs={8} >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography>เอกสาร</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                                                    เลือกไฟล์
                                                    <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                                                        onChange={handleFileChange}
                                                    />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {selectedFiles.length > 0 && (
                                                    <Box component={'div'} mt={1}>
                                                        {selectedFiles.map((file: any, index: number) => (
                                                            <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                                                                <Grid container >
                                                                    <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                                        <IconButton size="small" color="error"
                                                                            onClick={() => handleFileRemove(index)}>
                                                                            <RemoveCircleIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={11.5}>
                                                                        <img
                                                                            src={URL.createObjectURL(file)}
                                                                            alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                                                            width="500"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Box>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {images && (
                                                    <Box component={'div'} mt={1}>
                                                        {images.map((file: any, index: number) => (
                                                            <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                                                                <Grid container >
                                                                    <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                                        <IconButton size="small" color="error"
                                                                            onClick={() => handleFileRemoveImageList(file)}>
                                                                            <RemoveCircleIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={11.5}>
                                                                        <img
                                                                            src={`${apiUrl}/uploads/${file.name}`}
                                                                            alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                                                            width="500"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography>รวมเป็นเงิน</Typography>
                                            </Grid>
                                            <Grid item xs={6} textAlign={'end'}>
                                                <Typography>
                                                    {parseFloat((total || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="หมายเหตุ"
                                                    fullWidth

                                                > note1</TextField>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} mt={2}>
                                            <Stack spacing={2} direction="row">
                                                <Button variant="outlined" fullWidth onClick={() => navigate('/cashflow')}>ยกเลิก</Button>
                                                {!id && <Button variant="contained" fullWidth onClick={() => {
                                                    setOpenConfirm({
                                                        open: true,
                                                        title: 'บันทึกเอกสาร',
                                                        message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                                                        color: 'primary',
                                                        type: 'create'
                                                    });
                                                }}>บันทึก</Button>}
                                                {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                                                    setOpenConfirm({
                                                        open: true,
                                                        title: 'แก้ไขเอกสาร',
                                                        message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                                                        color: 'primary',
                                                        type: 'edit'
                                                    });
                                                }}>แก้ไข</Button>}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box >
            <SimpleBackdrop open={isLoading} />
            <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === 'delete') {
                        handleDelete();
                    } else if (openConfirm.type === 'create') {
                        handleSave();
                    } else if (openConfirm.type === 'edit') {
                        handleEdit();
                    }
                }} />
        </Box>
    );
};

export default CashflowCreate;

