import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React from "react";

interface ExportFileSellsProps {
  title?: string;
  date?: string;
  data: any[];
}

const ExportFileSells = React.forwardRef((props: ExportFileSellsProps, ref) => {
  return (
    <Box ref={ref} sx={{ textAlign: 'center', padding: '20px', '&@page': { size: 'A4' }, }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer >
            <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={9} sx={{ textAlign: 'center', }}>
                    <Typography fontWeight={'bold'}>{props.title}</Typography>
                    <Typography>{props.date}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>วันที่</TableCell>
                  <TableCell>ชื่อ-นามสกุล</TableCell>
                  <TableCell>ชื่อโครงการ</TableCell>
                  <TableCell>บ้าน</TableCell>
                  <TableCell width={150}>บันทึก</TableCell>
                  <TableCell width={150}>แก้ไข</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 && props?.data?.map((row: any) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell>{dayjs(row.createDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{row?.booking?.customer?.firstname || ''} {row?.booking?.customer?.lastname || ''}</TableCell>
                    <TableCell>{row?.booking?.project?.name || ''}</TableCell>
                    <TableCell>{row?.booking?.house?.name || ''}</TableCell>
                    <TableCell>
                      {<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      {<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}
                    </TableCell>
                  </TableRow>
                ))}

                {props.data.length === 0 && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={9} align="center">ไม่พบข้อมูล</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ExportFileSells; 