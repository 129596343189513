import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class UserGroupsStore {
    userGroups: any[] = [];
    construnctor() {
        makeAutoObservable(this);
    }
    async getAll() {
        try {
            const response = await apiService.get("/user-groups");
            this.userGroups = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting user group:", error);
            return {
                error: true,
                message: "An error occurred while getting user group",
            };
        }
    }

    async getAllWithRoles() {
        try {
            const response = await apiService.get("/user-groups/roles");
            this.userGroups = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting user group with roles:", error);
            return {
                error: true,
                message: "An error occurred while getting user group with roles",
            };
        }
    }
}
const userGroupsStore = new UserGroupsStore();
export default userGroupsStore;

