import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface PrintWarehouseProps {
  title?: string;
  data: any[];
}

const PrintWarehouse = React.forwardRef((props: PrintWarehouseProps, ref) => {
  const [grandTotal, setGrandTotal] = useState(0);
  const calculateGrandTotal = () => {
    return props.data.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator ?? 0) + parseFloat(currentValue.total ?? 0);
    }, 0);
  };
  useEffect(() => {
    const calculatedTotal = calculateGrandTotal();
    setGrandTotal(calculatedTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <Box ref={ref} sx={{
      margin: 0,
      width: "100%",
      borderCollapse: "collapse",
      textAlign: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
      '&@page': {
        size: 'A4 portrait',
      },
      overflow: 'hidden',
      fontSize: 8,
      display: 'block',
      pageBreakBefore: 'auto',
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer >
            <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} sx={{ textAlign: 'center', }}>
                    <Typography fontWeight={'bold'}>{props.title}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={10}>ลำดับ</TableCell>
                  <TableCell width={300}>ชื่อสินค้า</TableCell>
                  <TableCell>สี</TableCell>
                  <TableCell>ขนาด</TableCell>
                  <TableCell width={100} sx={{ textAlign: 'end' }}>จำนวน</TableCell>
                  <TableCell>หน่วย</TableCell>
                  <TableCell width={100} sx={{ textAlign: 'end' }}>มูลค่ารวม</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 && props?.data?.map((row: any, index: number) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row?.name || ''}</TableCell>
                    <TableCell component="th" scope="row"  >{row?.color || ''}
                    </TableCell>
                    <TableCell component="th" scope="row"  >{row?.size || ''}
                    </TableCell>
                    <TableCell align="right">{parseFloat((row.quantity ?? 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                    <TableCell>{row?.unit || ''}</TableCell>
                    <TableCell align="right">{parseFloat((row.total ?? 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                  </TableRow>
                ))}
                {props.data.length === 0 && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={13} align="center">ไม่พบข้อมูล</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={6} align="right"><b>รวมทั้งหมด</b></TableCell>
                  <TableCell colSpan={1} align="right">{parseFloat((grandTotal ?? 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box >
  );
});

export default PrintWarehouse;