import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import PrintHouseProducts from "./Report/Pdf/PrintHouseProducts";
import PrintHouseProductGroups from "./Report/Pdf/PrintHouseProductGroups";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportFileHouseProducts from "./Report/Excel/ExportFileHouseProducts";
import ExportFileHouseProductGroups from "./Report/Excel/ExportFileHouseProductGroups";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintHouseProductCategories from "./Report/Pdf/PrintHouseProductCategories";
import ExportFileHouseProductCategories from "./Report/Excel/ExportFileHouseProductCategories";
import PrintWorkers from "./Report/Pdf/PrintWorker";
import ExportWorkers from "./Report/Excel/ExportWorker";
import PrintExpensesHouse from "./Report/Pdf/PrintExpensesHouse";
import ExportFileExpensesHouse from "./Report/Excel/ExportFileExpensesHouse";
import PrintExpensesHouseDetail from "./Report/Pdf/PrintExpenseHouseDetail";
import ExportFileExpensesHouseDetail from "./Report/Excel/ExportFileExpenseHouseDetail";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DialogProductDetail from "./DialogProductDetail";
import projectsStore from "../stores/ProjectsStore";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import TerrainIcon from "@mui/icons-material/Terrain";
import { MoneyOff, ScaleSharp } from "@mui/icons-material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { orange, pink } from "@mui/material/colors";
import { PieChart } from "@mui/x-charts/PieChart";
import PrintExpensesHouseCommonFees from "./Report/Pdf/PrintExpensesHouseCommonFees";
import ExportFileExpensesHouseCommonFees from "./Report/Excel/ExportFileExpensesHouseCommonFees";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface DialogHouseProductProps {
  open: boolean;
  title: string;
  total: string;
  data?: any;
  isActive?: boolean;
  productGroup?: any;
  productCategory?: any;
  worker?: any;
  land?: any;
  landTax?: any;
  commonFee?: any;
  companyInfo?: any;
  totalWage?: string;
  totalMaterial?: string;
  totalMaterialAndWage?: string;
  onClose: () => void;
  house: string;
}

const DialogHouseProduct: React.FC<DialogHouseProductProps> = (
  props: DialogHouseProductProps
) => {
  const [value, setValue] = useState("home");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElExport, setAnchorElExport] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openExport = Boolean(anchorElExport);
  const printProductsRef = useRef(null);
  const printProductGroupsRef = useRef(null);

  const exportProductsRef = useRef(null);
  const exportProductGroupsRef = useRef(null);

  const printProductCategoriesRef = useRef(null);
  const exportProductCategoriesRef = useRef(null);

  const printWorkersRef = useRef(null);
  const exportWorkersRef = useRef(null);

  // NOTE:May use later
  // const exportLandRef = useRef(null);
  // const printLandRef = useRef(null);

  // const exportLandTaxRef = useRef(null);
  // const printLandTaxRef = useRef(null);

  const exportCommonRef = useRef(null);
  const printCommonRef = useRef(null);

  const exportExpensesHouseRef = useRef(null);
  const printExpensesHouseRef = useRef(null);

  const exportExpensesHouseDetailRef = useRef(null);
  const printExpensesHouseDetailRef = useRef(null);
  const [productDetails, setProductDetails] = useState<any>({
    open: false,
    data: [],
  });
  const handleChange = (event?: React.SyntheticEvent, newValue?: string) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    type = "print"
  ) => {
    if (type === "print") {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorElExport(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElExport(null);
  };

  const handleGetProductDetails = (
    productId: string,
    total: string,
    product: string
  ) => {
    projectsStore
      .getExpenseAllByHouseIdAndProductId({
        productId: productId,
        houseId: props.house,
      })
      .then((res: any) => {
        setProductDetails({
          open: true,
          data: res,
          total: parseFloat(total).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          title: product,
          house: props.title,
        });
      });
  };
  return (
    <Dialog open={props.open} fullWidth maxWidth={"lg"}>
      <DialogTitle>
        <Grid container justifyContent={"end"}>
          <Grid item xs={10}>
            <Box
              component={"span"}
              style={{
                textDecoration: !props.isActive ? "line-through" : "none",
              }}
            >
              {props.isActive ? (
                props.title
              ) : (
                <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                  <Box component={"span"}>{props?.title || ""}</Box>
                </Tooltip>
              )}
            </Box>
            <Box component={"span"} ml={1}>
              มูลค่ารวม: {props.total} บาท
            </Box>
          </Grid>
          <Grid item xs={1} display={"flex"} flexDirection={"column"}>
            <Button
              id="demo-customized-button-export"
              aria-controls={openExport ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openExport ? "true" : undefined}
              sx={{ decoration: "none", color: "gray" }}
              disableElevation
              onClick={(e) => handleClick(e, "export")}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <DownloadIcon />
            </Button>
            <StyledMenu
              id="demo-customized-menu-export"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorElExport}
              open={openExport}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <DownloadTableExcel
                  filename={`house-cost-${dayjs().format("DD-MM-YYYY")}`}
                  sheet={`house-cost-${dayjs().format("DD-MM-YYYY")}`}
                  currentTableRef={exportExpensesHouseRef.current}
                >
                  <Box>
                    <DownloadIcon />
                    สรุปค่าใช้จ่าย
                  </Box>
                </DownloadTableExcel>
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <DownloadTableExcel
                  filename={`house-detail-cost-${dayjs().format("DD-MM-YYYY")}`}
                  sheet={`house-detail-cost-${dayjs().format("DD-MM-YYYY")}`}
                  currentTableRef={exportExpensesHouseDetailRef.current}
                >
                  <Box>
                    <DownloadIcon />
                    รายละเอียดค่าใช้จ่าย
                  </Box>
                </DownloadTableExcel>
              </MenuItem>
            </StyledMenu>
          </Grid>
          <Grid item xs={1} display={"flex"} flexDirection={"column"}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ decoration: "none", color: "gray" }}
              disableElevation
              onClick={(e) => handleClick(e, "print")}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <PrintIcon />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <Box>
                        <PrintIcon />
                        สรุปค่าใช้จ่าย
                      </Box>
                    );
                  }}
                  content={() => {
                    return printExpensesHouseRef.current;
                  }}
                />
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <Box>
                        <PrintIcon />
                        รายละเอียดค่าใช้จ่าย
                      </Box>
                    );
                  }}
                  content={() => {
                    return printExpensesHouseDetailRef.current;
                  }}
                />
              </MenuItem>
            </StyledMenu>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Card>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange}>
                  <Tab label="สรุป" value="home" />
                  <Tab label="สินค้า" value="product" />
                  {/* May use in the future */}
                  {/* <Tab label="หมวดหมู่สินค้า" value="productGroup" /> */}
                  <Tab label="ที่ดิน" value="land" />
                  <Tab label="ภาษี" value="land-tax" />
                  <Tab label="งานก่อสร้าง" value="productCategory" />
                  <Tab label="งานส่วนกลาง" value="common" />
                  <Tab label="คนงาน" value="worker" />
                </TabList>
              </Box>
              <TabPanel value="home">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent sx={{ height: 100 }}>
                        <Grid container display={"flex"} alignItems={"center"}>
                          <Grid item xs={9}>
                            <Typography fontWeight={"bold"}>สินค้า</Typography>
                            {props?.data && (
                              <Typography variant="h5" noWrap>
                                {parseFloat(
                                  props.data?.total ?? 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            )}
                            {!props?.data && (
                              <Skeleton
                                animation="wave"
                                variant="text"
                                sx={{ fontSize: "1.333rem" }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <IconButton
                              onClick={(event) =>
                                handleChange(event, "product")
                              }
                            >
                              <InventoryIcon
                                sx={{ fontSize: "2em", color: "darkblue" }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent sx={{ height: 100 }}>
                        <Grid container display={"flex"} alignItems={"center"}>
                          <Grid item xs={9}>
                            <Typography fontWeight={"bold"}>ที่ดิน</Typography>
                            {props?.data && (
                              <Typography variant="h5" noWrap>
                                {parseFloat(
                                  props.land?.total?.totalLand ?? 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            )}
                            {!props?.data && (
                              <Skeleton
                                animation="wave"
                                variant="text"
                                sx={{ fontSize: "1.333rem" }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <IconButton
                              onClick={(event) => handleChange(event, "land")}
                            >
                              <TerrainIcon
                                sx={{ fontSize: "2em", color: "darkgreen" }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent sx={{ height: 100 }}>
                        <Grid container display={"flex"} alignItems={"center"}>
                          <Grid item xs={9}>
                            <Typography fontWeight={"bold"}>ภาษี</Typography>
                            {props?.data && (
                              <Typography variant="h5" noWrap>
                                {parseFloat(
                                  props.landTax?.total?.totalLandTax ?? 0
                                )?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </Typography>
                            )}
                            {!props?.data && (
                              <Skeleton
                                animation="wave"
                                variant="text"
                                sx={{ fontSize: "1.333rem" }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <IconButton
                              onClick={(event) =>
                                handleChange(event, "land-tax")
                              }
                            >
                              <ScaleSharp
                                sx={{ fontSize: "2em", color: "purple" }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent sx={{ height: 100 }}>
                        <Grid container display={"flex"} alignItems={"center"}>
                          <Grid item xs={9}>
                            <Typography fontWeight={"bold"}>
                              งานก่อสร้าง
                            </Typography>
                            {props?.data && (
                              <Typography variant="h5" noWrap>
                                {parseFloat(
                                  props.productCategory?.total ?? 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            )}
                            {!props?.data && (
                              <Skeleton
                                animation="wave"
                                variant="text"
                                sx={{ fontSize: "1.333rem" }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <IconButton
                              onClick={(event) =>
                                handleChange(event, "productCategory")
                              }
                            >
                              <ApartmentIcon
                                sx={{ fontSize: "2em", color: "orange" }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent sx={{ height: 100 }}>
                        <Grid container display={"flex"} alignItems={"center"}>
                          <Grid item xs={9}>
                            <Typography fontWeight={"bold"}>
                              งานส่วนกลาง
                            </Typography>
                            {props?.data && (
                              <Typography variant="h5" noWrap>
                                {parseFloat(
                                  props?.commonFee?.totalCommonFee ?? 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            )}
                            {!props?.data && (
                              <Skeleton
                                animation="wave"
                                variant="text"
                                sx={{ fontSize: "1.333rem" }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <IconButton
                              onClick={(event) => handleChange(event, "common")}
                            >
                              <MoneyOff
                                sx={{ fontSize: "2em", color: pink[500] }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent sx={{ height: 100 }}>
                        <Grid container display={"flex"} alignItems={"center"}>
                          <Grid item xs={9}>
                            <Typography fontWeight={"bold"}>คนงาน</Typography>
                            {props?.data && (
                              <Typography variant="h5" noWrap>
                                {parseFloat(
                                  props.worker?.total?.totalWages ?? 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            )}
                            {!props?.data && (
                              <Skeleton
                                animation="wave"
                                variant="text"
                                sx={{ fontSize: "1.333rem" }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <IconButton
                              onClick={(event) => handleChange(event, "worker")}
                            >
                              <PeopleIcon
                                sx={{ fontSize: "2em", color: orange[900] }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid display={"flex"} justifyContent={"center"}>
                          <PieChart
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value: parseFloat(props.data?.total ?? 0),
                                    label: "สินค้า",
                                  },
                                  {
                                    id: 1,
                                    value: parseFloat(
                                      props.land?.total?.totalLand ?? 0
                                    ),
                                    label: "ที่ดิน",
                                  },
                                  {
                                    id: 2,
                                    value: parseFloat(
                                      props.landTax?.total?.totalLandTax ?? 0
                                    ),
                                    label: "ภาษี",
                                  },
                                  {
                                    id: 3,
                                    value: parseFloat(
                                      props.productCategory?.total ?? 0
                                    ),
                                    label: "งานก่อสร้าง",
                                  },
                                  {
                                    id: 4,
                                    value: parseFloat(
                                      props?.commonFee?.totalCommonFee ?? 0
                                    ),
                                    label: "งานส่วนกลาง",
                                  },
                                  {
                                    id: 5,
                                    value: parseFloat(
                                      props?.worker?.total?.totalWages ?? 0
                                    ),
                                    label: "คนงาน",
                                  },
                                ],
                              },
                            ]}
                            width={400}
                            height={200}
                          />
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="product">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด {props.data?.detail?.length.toLocaleString()}{" "}
                          รายการ
                        </Typography>
                        <Typography>
                          มูลค่าวัสดุทั้งหมด{" "}
                          {parseFloat(props.data?.total ?? 0).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}{" "}
                          บาท
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`house-product-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`house-product-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportProductsRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printProductsRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>สินค้า</TableCell>
                            <TableCell>จำนวน</TableCell>
                            <TableCell>มูลค่า</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.data?.detail?.map(
                            (row: any, index: number) => (
                              <TableRow
                                key={`props-data-dialog-${
                                  row.id + index.toString()
                                }`}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: !row.isActive
                                      ? "line-through"
                                      : "none",
                                  }}
                                >
                                  {row.isActive ? (
                                    row.product
                                  ) : (
                                    <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                                      <Box component="span">{row?.product}</Box>
                                    </Tooltip>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {parseFloat(row.quantity).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </TableCell>
                                <TableCell>
                                  {parseFloat(row.total).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    aria-label="detail"
                                    onClick={() => {
                                      handleGetProductDetails(
                                        row.productId,
                                        row.total,
                                        row.product
                                      );
                                    }}
                                  >
                                    <HelpOutlineIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="land">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    มูลค่าที่ดินทั้งหมด{" "}
                    {parseFloat(
                      props.land?.total?.totalLand ?? 0
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    บาท
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography mb={1}>โฉนดที่ดิน</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          <b>เลขที่:</b> {props.land?.land?.volumn || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>เล่มที่:</b> {props.land?.land?.volumn || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>หน้า:</b> {props.land?.land?.page || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>อำเภอ:</b>{" "}
                          {props.land?.land?.landDistrict?.nameTh || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ระวาง:</b> {props.land?.land?.mapsheet || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>เลขที่ดิน:</b>{" "}
                          {props.land?.land?.dealingFileNo || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ตำบล:</b>{" "}
                          {props.land?.land?.landSubDistrict?.nameTh || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography mb={1}>เนื้อที่ดิน</Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ไร่:</b>{" "}
                          {props.land?.land?.rai
                            ? parseFloat(props.land?.land?.rai)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>งาน:</b>{" "}
                          {props.land?.land?.ngan
                            ? parseFloat(props.land?.land?.ngan)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ตร.วา:</b>{" "}
                          {props.land?.land?.squareWah
                            ? parseFloat(props.land?.land?.squareWah)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography mb={1}>ข้อมูลการจดทะเบียนสิทธิ</Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>วันที่ออกโฉนด:</b>{" "}
                          {props.land?.land?.deedDate
                            ? dayjs(props.land?.land?.deedDate).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4.5}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Typography>
                          <b>โครงการ:</b>{" "}
                          {props.land?.land?.project?.name || ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4.5}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Typography>
                          <b>บ้าน:</b> {props.land?.land?.house?.name || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ผู้ให้สัญญา:</b>{" "}
                          {props.land?.land?.transferer || ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4.5}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Typography>
                          <b>สัญชาติ:</b> {props.land?.land?.nationality || ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4.5}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Typography>
                          <b>ที่อยู่:</b> {props.land?.land?.address || "-"}{" "}
                          หมู่ {props.land?.land?.village || "-"} ต.
                          {props.land?.land?.subDistric?.nameTh || "-"} อ.
                          {props.land?.land?.district?.nameTh || "-"} จ.
                          {props.land?.land?.province?.nameTh || "-"}{" "}
                          {props.land?.land?.zipCode || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ผู้รับสัญญา:</b>{" "}
                          {props.land?.land?.transferee || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>มูลค่าที่ดิน:</b>{" "}
                          {props.land?.land?.landPrice
                            ? parseFloat(props.land?.land?.landPrice)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ค่าโอนที่ดิน:</b>{" "}
                          {props.land?.land?.registrationFee
                            ? parseFloat(props.land?.land?.registrationFee)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ค่าจดจำนอง:</b>{" "}
                          {props.land?.land?.mortgageFee
                            ? parseFloat(props.land?.land?.mortgageFee)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography>เอกสาร</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {props.land?.land?.images &&
                              props.land?.land?.images.length > 0 && (
                                <Box component={"div"} mt={1}>
                                  {props.land?.land?.images.map(
                                    (file: any, index: number) => (
                                      <Grid
                                        item
                                        xs={12}
                                        key={`im-${index.toString()}`}
                                        mt={1}
                                      >
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <img
                                              src={
                                                file.id
                                                  ? `${apiUrl}/uploads/${file.name}`
                                                  : URL.createObjectURL(file)
                                              }
                                              alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                              width="500"
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )
                                  )}
                                </Box>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="land-tax">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {`มูลค่าภาษีและค่าธรรมเนียมที่ดินทั้งหมด 
                        ${parseFloat(
                          props.landTax?.total?.totalLandTax || "0"
                        )?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })} 
                        บาท`}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography mb={1}>
                          ข้อมูลภาษีและค่าธรรมเนียมที่ดิน
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>วันที่จ่ายภาษี:</b>{" "}
                          {props.landTax?.landTax?.taxDate
                            ? dayjs(props.landTax?.landTax?.taxDate).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ค่าคำขอ:</b>{" "}
                          {props.landTax?.landTax?.requestFee
                            ? parseFloat(props.landTax?.landTax?.requestFee)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ค่าธรรมเนียม อปท. :</b>{" "}
                          {props.landTax?.landTax?.lgoFee
                            ? parseFloat(props.landTax?.landTax?.lgoFee)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>รวมทั้งสิ้นค่าธรรมเนียม:</b>{" "}
                          {props.landTax?.landTax?.totalFee
                            ? parseFloat(props.landTax?.landTax?.totalFee)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ค่าคำขอ:</b>{" "}
                          {props.landTax?.landTax?.requestFee
                            ? parseFloat(props.landTax?.landTax?.requestFee)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ค่าธรรมเนียม อปท. :</b>{" "}
                          {props.landTax?.landTax?.lgoFee
                            ? parseFloat(props.landTax?.landTax?.lgoFee)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>รวมค่าธรรมเนียม:</b>{" "}
                          {props.landTax?.landTax?.totalFee
                            ? parseFloat(props.landTax?.landTax?.totalFee)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ภาษีเงินได้บุคคลธรรมดา:</b>{" "}
                          {props.landTax?.landTax?.personalTaxIncome
                            ? parseFloat(
                                props.landTax?.landTax?.personalTaxIncome
                              )
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ภาษีเงินได้นิติบุคคล:</b>{" "}
                          {props.landTax?.landTax?.corporateIncomeTax
                            ? parseFloat(
                                props.landTax?.landTax?.corporateIncomeTax
                              )
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ค่าอากรแสตมป์:</b>{" "}
                          {props.landTax?.landTax?.stampDuty
                            ? parseFloat(props.landTax?.landTax?.stampDuty)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>ภาษีธุรกิจเฉพาะ:</b>{" "}
                          {props.landTax?.landTax?.specificBusinessTax
                            ? parseFloat(
                                props.landTax?.landTax?.specificBusinessTax
                              )
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>รายได้ส่วนท้องถิ่น:</b>{" "}
                          {props.landTax?.landTax?.localIncome
                            ? parseFloat(props.landTax?.landTax?.localIncome)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>รวมทั้งสิ้นค่าภาษีอากร:</b>{" "}
                          {props.landTax?.landTax?.totalTax
                            ? parseFloat(props.landTax?.landTax?.totalTax)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>รวมทั้งสิ้นค่าธรรมเนียมและค่าภาษีอากร:</b>{" "}
                          {props.landTax?.landTax?.grandTotalTax
                            ? parseFloat(props.landTax?.landTax?.grandTotalTax)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} display={"flex"} alignItems={"center"}>
                        <Typography>
                          <b>หมายเหตุ:</b>{" "}
                          {props.landTax?.landTax?.remarkText || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              {/* NOTE: May use in the future */}
              {/* <TabPanel value="productGroup">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent={'end'}>
                                            <Grid item xs={10} display={'flex'} gap={1.5}>
                                                <Typography fontWeight={'bold'}>รายละเอียด</Typography>
                                                <Typography>ทั้งหมด {props.productGroup?.expense?.length.toLocaleString()}  รายการ</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <DownloadTableExcel
                                                    filename={`house-product-group-cost-${dayjs().format('DD-MM-YYYY')}`}
                                                    sheet={`house-product-group-cost-${dayjs().format('DD-MM-YYYY')}`}
                                                    currentTableRef={exportProductGroupsRef.current}
                                                >
                                                    <IconButton aria-label="download"  >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </DownloadTableExcel>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <ReactToPrint
                                                    trigger={() => {
                                                        return (
                                                            <IconButton aria-label="print">
                                                                <PrintIcon />
                                                            </IconButton>
                                                        );
                                                    }}
                                                    content={() => {
                                                        return printProductGroupsRef.current;
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            props.productGroup?.expense && props.productGroup?.expense.map((item: any) => {
                                                return (
                                                    <Accordion style={{ width: '100%' }} >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Grid container display={'flex'} justifyContent={'space-between'}>
                                                                <Typography><b>หมวดหมู่สินค้า: </b> {item?.productGroupName || 'ยังไม่ระบุ'}</Typography>
                                                                <Typography mr={10}>{parseFloat(item?.total ?? 0).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })}</Typography>
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <TableContainer component={Paper}>
                                                                <Table sx={{ minWidth: 650 }}>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>รายการ</TableCell>
                                                                            <TableCell>เลขที่เอกสาร</TableCell>
                                                                            <TableCell>จำนวน</TableCell>
                                                                            <TableCell>ราคาต่อหน่วย</TableCell>
                                                                            <TableCell>มูลค่ารวม</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {item.items.length > 0 && item.items
                                                                            .slice() // Create a copy to avoid modifying the original array
                                                                            .sort((a: any, b: any) => a.product.localeCompare(b.product)).sort((a: any, b: any) => { return dayjs(b.docDate).diff(a.docDate); })
                                                                            .map((product: any) => {
                                                                                return (
                                                                                    <TableRow>
                                                                                        <TableCell>{product.product}</TableCell>
                                                                                        <TableCell>{product.docNo}</TableCell>
                                                                                        <TableCell>{parseFloat(product?.quantity ?? 0).toLocaleString(undefined, {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                        })}</TableCell>
                                                                                        <TableCell> {parseFloat(product?.price ?? 0).toLocaleString(undefined, {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                        })}</TableCell>
                                                                                        <TableCell> {(product.quantity * product.price).toLocaleString(undefined, {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                        })}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            })}
                                    </Grid>
                                </Grid>
                            </TabPanel> */}
              <TabPanel value="productCategory">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด{" "}
                          {props.productCategory?.categories?.length.toLocaleString()}{" "}
                          รายการ
                        </Typography>
                        <Typography>
                          มูลค่างานก่อสร้างทั้งหมด{" "}
                          {parseFloat(
                            props.productCategory?.total ?? 0
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          บาท
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`house-product-category-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`house-product-category-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportProductCategoriesRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printProductCategoriesRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {props.productCategory?.categories &&
                      props.productCategory.categories.map(
                        (item: any, index: number) => {
                          return (
                            <Accordion
                              style={{ width: "100%" }}
                              key={`props-productCategory-categories-dialog-${index.toString()}`}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Grid
                                  container
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                >
                                  <Typography fontWeight={"bold"}>
                                    {index + 1}.{" "}
                                    {item?.productCategoryName || "ยังไม่ระบุ"}
                                  </Typography>
                                  <Typography mr={10}>
                                    {parseFloat(
                                      item?.total ?? 0
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TableContainer component={Paper}>
                                  <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>รายการ</TableCell>
                                        <TableCell>จำนวน</TableCell>
                                        <TableCell>ราคาต่อหน่วย</TableCell>
                                        <TableCell>มูลค่ารวม</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {item.groups.length > 0 &&
                                        item.groups.map(
                                          (group: any, indexGroup: number) => {
                                            return (
                                              <React.Fragment
                                                key={`groups-dialog-${indexGroup.toString()}`}
                                              >
                                                {group?.productGroupName && (
                                                  <TableRow
                                                    key={`item-groups-dialog-${indexGroup.toString()}`}
                                                  >
                                                    <TableCell
                                                      sx={{
                                                        fontWeight: "bold",
                                                      }}
                                                      colSpan={3}
                                                    >
                                                      {index + 1}.
                                                      {indexGroup + 1}{" "}
                                                      {group?.productGroupName}
                                                    </TableCell>
                                                    <TableCell>
                                                      {" "}
                                                      {parseFloat(
                                                        group?.total ?? 0
                                                      ).toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                                {group.products.length > 0 &&
                                                  group.products.map(
                                                    (
                                                      product: any,
                                                      indexProduct: number
                                                    ) => {
                                                      return (
                                                        <TableRow
                                                          key={`group-products-dialog-${indexProduct.toString()}`}
                                                        >
                                                          <TableCell>
                                                            - {product.product}
                                                          </TableCell>
                                                          <TableCell>
                                                            {parseFloat(
                                                              product?.quantity ??
                                                                0
                                                            ).toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}
                                                          </TableCell>
                                                          <TableCell>
                                                            {" "}
                                                            {parseFloat(
                                                              product?.price ??
                                                                0
                                                            ).toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}
                                                          </TableCell>
                                                          <TableCell>
                                                            {" "}
                                                            {parseFloat(
                                                              product?.total ??
                                                                0
                                                            ).toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}
                                                          </TableCell>
                                                        </TableRow>
                                                      );
                                                    }
                                                  )}
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                      )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="common">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด{" "}
                          {props?.commonFee?.commonFee?.commonFeeCategoriesResult?.length?.toLocaleString()}{" "}
                          รายการ
                        </Typography>
                        <Typography>
                          {`มูลค่างานส่วนกลางทั้งหมด  
                          ${parseFloat(
                            props?.commonFee?.totalCommonFee ?? 0
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} 
                          บาท (รวมโครงการทั้งหมด)`}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`house-common-fee-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`house-common-fee-cost-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportCommonRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printCommonRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {props?.commonFee?.commonFee?.commonFeeCategoriesResult
                      ?.length > 0 &&
                      props?.commonFee?.commonFee?.commonFeeCategoriesResult?.map(
                        (item: any, index: number) => {
                          return (
                            <Accordion
                              style={{ width: "100%" }}
                              key={`common-fee-productCategories-categories-project-detail-${index.toString()}-project-detail`}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Grid
                                  container
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                >
                                  <Typography fontWeight={"bold"}>
                                    {index + 1}.{" "}
                                    {item?.productCategoryName || "ยังไม่ระบุ"}
                                  </Typography>
                                  <Typography mr={10}>
                                    {parseFloat(
                                      item?.total ?? 0
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box>
                                  {item?.group?.map(
                                    (group: any, indexGroup: number) => {
                                      return (
                                        <Accordion
                                          style={{ width: "100%" }}
                                          key={`common-fee-productCategories-categories-project-detail-group-${indexGroup.toString()}-project-detail-${
                                            index + index
                                          }`}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                          >
                                            <Grid
                                              container
                                              display={"flex"}
                                              justifyContent={"space-between"}
                                            >
                                              <Typography fontWeight={"bold"}>
                                                {index + 1}.{indexGroup + 1}{" "}
                                                {group?.name || "ยังไม่ระบุ"}
                                              </Typography>
                                              <Typography mr={10}>
                                                {parseFloat(
                                                  group?.total ?? 0
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                              </Typography>
                                            </Grid>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <TableContainer component={Paper}>
                                              <Table sx={{ minWidth: 650 }}>
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>
                                                      รายการ
                                                    </TableCell>
                                                    <TableCell>จำนวน</TableCell>
                                                    <TableCell>
                                                      ราคาต่อหน่วย
                                                    </TableCell>
                                                    <TableCell>ราคา</TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {group.detail.length > 0 &&
                                                    group.detail.map(
                                                      (
                                                        detail: any,
                                                        indexdetail: number
                                                      ) => {
                                                        return (
                                                          <React.Fragment
                                                            key={`common-fee-item-groups-project-detail-${indexdetail.toString()}-project-detail- ${
                                                              detail?.id
                                                            }`}
                                                          >
                                                            <TableRow>
                                                              <TableCell>
                                                                {detail?.product
                                                                  ?.name || ""}
                                                              </TableCell>
                                                              <TableCell>
                                                                {detail?.quantity ||
                                                                  ""}
                                                              </TableCell>
                                                              <TableCell>
                                                                {detail?.price ||
                                                                  ""}
                                                              </TableCell>
                                                              <TableCell>
                                                                {detail?.total ||
                                                                  ""}
                                                              </TableCell>
                                                            </TableRow>
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </AccordionDetails>
                                        </Accordion>
                                      );
                                    }
                                  )}
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                      )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="worker">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"end"}>
                      <Grid item xs={10} display={"flex"} gap={1.5}>
                        <Typography fontWeight={"bold"}>รายละเอียด</Typography>
                        <Typography>
                          ทั้งหมด {props.worker?.wages?.length.toLocaleString()}{" "}
                          รายการ
                        </Typography>
                        <Typography>
                          ค่าแรงทั้งหมด{" "}
                          {parseFloat(
                            props.worker?.total?.totalWages ?? 0
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          บาท
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DownloadTableExcel
                          filename={`project-worker-wage-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet={`project-worker-wage${dayjs().format(
                            "DD-MM-YYYY"
                          )}`}
                          currentTableRef={exportWorkersRef.current}
                        >
                          <IconButton aria-label="download">
                            <DownloadIcon />
                          </IconButton>
                        </DownloadTableExcel>
                      </Grid>
                      <Grid item xs={1}>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <IconButton aria-label="print">
                                <PrintIcon />
                              </IconButton>
                            );
                          }}
                          content={() => {
                            return printWorkersRef.current;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {props.worker?.wages &&
                      props.worker?.wages.map((item: any, index: number) => {
                        return (
                          <Accordion
                            style={{ width: "100%" }}
                            key={`props-worker-wages-dialog-${index.toString()}`}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Grid
                                container
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Typography fontWeight={"bold"}>{`${
                                  index + 1
                                }. ค่าแรงงวดวันที่ ${dayjs(
                                  item?.periodDateFrom
                                ).format("DD/MM/YYYY")} ถึง ${dayjs(
                                  item?.periodDateTo
                                ).format("DD/MM/YYYY")}`}</Typography>
                                <Typography mr={10}>
                                  {parseFloat(item?.total ?? 0).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Typography>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>ชื่อ - นามสกุล</TableCell>
                                      <TableCell>ค่าแรง</TableCell>
                                      <TableCell>จำนวนเงิน OT</TableCell>
                                      <TableCell>รวมค่าแรง + OT</TableCell>
                                      <TableCell>เบิก</TableCell>
                                      <TableCell>คงเหลือ</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item.wageDetails.length > 0 &&
                                      item.wageDetails
                                        .sort((a: any, b: any) => {
                                          return dayjs(a.createAt).diff(
                                            b.createAt
                                          );
                                        })
                                        .map(
                                          (
                                            detail: any,
                                            indexDetail: number
                                          ) => {
                                            return (
                                              <React.Fragment
                                                key={`item-wageDetails-dialog-${indexDetail.toString()}-${
                                                  detail?.id
                                                }`}
                                              >
                                                {detail?.worker && (
                                                  <TableRow
                                                    key={`item-wageDetails-dialog-${indexDetail.toString()}`}
                                                  >
                                                    <TableCell
                                                      sx={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {indexDetail + 1}.{" "}
                                                      {
                                                        detail?.worker
                                                          ?.firstName
                                                      }{" "}
                                                      {detail?.worker?.lastName}
                                                    </TableCell>
                                                    <TableCell>
                                                      {" "}
                                                      {parseFloat(
                                                        detail?.totalLaborCost ??
                                                          0
                                                      ).toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {" "}
                                                      {parseFloat(
                                                        detail?.totalNet ?? 0
                                                      ).toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {" "}
                                                      {parseFloat(
                                                        detail?.totalLaborAndCostOt ??
                                                          0
                                                      ).toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {" "}
                                                      {parseFloat(
                                                        detail?.withdraw ?? 0
                                                      ).toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {" "}
                                                      {parseFloat(
                                                        detail?.totalNet ?? 0
                                                      ).toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
            <CardContent></CardContent>
          </Card>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => props.onClose()}>
          ตกลง
        </Button>
      </DialogActions>
      <Box sx={{ display: "none" }}>
        <PrintHouseProducts
          ref={printProductsRef}
          title={props.title}
          summary={props.total}
          data={props?.data?.detail || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintHouseProductGroups
          ref={printProductGroupsRef}
          title={props.title}
          summary={props.total}
          data={props?.productGroup?.expense || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileHouseProducts
          ref={exportProductsRef}
          title={props.title}
          summary={props.total}
          data={props?.data?.detail || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileHouseProductGroups
          ref={exportProductGroupsRef}
          title={props.title}
          summary={props.total}
          data={props?.productGroup?.expense || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintHouseProductCategories
          ref={printProductCategoriesRef}
          title={props?.productCategory?.project?.name || ""}
          subTitle={props.title}
          summary={props.total}
          data={props?.productCategory?.categories || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileHouseProductCategories
          ref={exportProductCategoriesRef}
          title={props?.productCategory?.project?.name || ""}
          subTitle={props.title}
          summary={props.total}
          data={props?.productCategory?.categories || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintWorkers
          ref={printWorkersRef}
          title={props.title}
          data={props.worker?.wages || []}
          summary={props.worker?.total?.totalWages}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportWorkers
          ref={exportWorkersRef}
          title={props.title}
          data={props.worker?.wages || []}
          summary={props.worker?.total?.totalWages}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintExpensesHouse
          ref={printExpensesHouseRef}
          title={props.title}
          data={props.productCategory}
          companyInfo={props?.companyInfo}
          totalMaterial={props.totalMaterial}
          totalWage={props.totalWage}
          totalExpense={props.totalMaterialAndWage}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesHouse
          ref={exportExpensesHouseRef}
          title={props.title}
          data={props.productCategory}
          companyInfo={props?.companyInfo}
          totalMaterial={props.totalMaterial}
          totalWage={props.totalWage}
          totalExpense={props.totalMaterialAndWage}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintExpensesHouseDetail
          ref={printExpensesHouseDetailRef}
          title={props.title}
          data={props.productCategory}
          companyInfo={props?.companyInfo}
          totalMaterial={props.totalMaterial}
          totalWage={props.totalWage}
          totalExpense={props.totalMaterialAndWage}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesHouseDetail
          ref={exportExpensesHouseDetailRef}
          title={props.title}
          data={props.productCategory}
          companyInfo={props?.companyInfo}
          totalMaterial={props.totalMaterial}
          totalWage={props.totalWage}
          totalExpense={props.totalMaterialAndWage}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintExpensesHouseCommonFees
          ref={printCommonRef}
          title={props?.commonFee?.commonFee?.name || ""}
          data={props?.commonFee?.commonFee?.commonFeeCategoriesResult}
          summary={parseFloat(
            props?.commonFee?.totalCommonFee ?? 0
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          subTitle={props?.title || ""}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileExpensesHouseCommonFees
          ref={exportCommonRef}
          title={props?.commonFee?.commonFee?.name || ""}
          data={props?.commonFee?.commonFee?.commonFeeCategoriesResult}
          summary={parseFloat(
            props?.commonFee?.totalCommonFee ?? 0
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          subTitle={props?.title || ""}
        />
      </Box>
      <DialogProductDetail
        open={productDetails.open}
        title={productDetails.title}
        total={productDetails.total}
        data={productDetails.data}
        house={productDetails.house}
        onClose={() => setProductDetails({ open: false })}
      />
    </Dialog>
  );
};

export default DialogHouseProduct;
