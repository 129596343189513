import React, { ReactNode, useEffect, useState } from 'react';
import { Toolbar, Typography, Drawer, List, ListItemText, IconButton, Menu, MenuItem, ListItemButton, styled, Box, CssBaseline, Divider, ListItemIcon, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import authStore from "../stores/AuthStore";
import { observer } from "mobx-react-lite";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ESTATE_LOGO from '../res/images/ESTATE_LOGO.png';
import checkPermission from '../utils';
import menusStore from '../stores/MenusStore';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
interface LayoutProps {
    children: ReactNode;
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop: any) => prop !== "open" })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const Layout: React.FC<LayoutProps> = observer(({ children }) => {
    const [appVersion, setAppVersion] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [isExpand, setIsExpand] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rowId, setRowId] = useState('');
    const [roles, setRoles] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        authStore.logout();
        navigate('/login');
    };

    const profileMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (list: any) => {
        if (!list.parent?.id && !list.parent) {
            if (list.id !== rowId) {
                setRowId(list.id);
                setIsExpand(true);
            } else {
                setIsExpand(prevState => !prevState);
            }
        }
    };

    const findUserAndSortMenus = (roles: any) => {
        const parents = roles.filter((role: any) => role.menu.level === 0).sort((a: any, b: any) => a.menu.order - b.menu.order);
        const childs = roles.filter((role: any) => role.menu.level === 1);

        const sortedMenus = parents.map((parent: any) => {
            const relatedChilds = childs.filter((child: any) => child.menu.parent.id === parent.menu.id);
            const sortedChilds = relatedChilds.sort((a: any, b: any) => a.menu.order - b.menu.order);
            return { ...parent, menu: { ...parent.menu, child: sortedChilds } };
        });
        return sortedMenus;
    };
    let menus: any;

    if (roles) {
        menus = findUserAndSortMenus(roles);

    }


    useEffect(() => {
        setAppVersion(process.env.REACT_APP_VERSION || '');
        let user: any = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            checkUserAccess(user);
        }
        if (user && user?.isSuperAdmin) {
            const storedMenus: any = localStorage.getItem('menus');
            const parsedMenus = JSON.parse(storedMenus);
            setRoles(parsedMenus);
            if (!parsedMenus) {
                menusStore.getAll().then((menu: any) => {
                    const _roles = menu.map((item: any) => {
                        return {
                            id: item.id,
                            menu: item,
                            read: true,
                            create: true,
                            delete: true,
                            edit: true,
                            user: authStore.user
                        };
                    });

                    setRoles(_roles);
                    const menusString = JSON.stringify(_roles);
                    localStorage.setItem('menus', menusString);
                });
            }


        } else {
            setRoles(user?.roles);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkUserAccess = (user: any) => {
        if (
            user &&
            !user?.isSuperAdmin &&
            (user?.roles?.length === 0 || !user?.roles)
        ) {
            handleLogout();
        }
        if (!user) {
            handleLogout();
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar open={isDrawerOpen} position="fixed" elevation={1} sx={{ bgcolor: "white" }}>
                <Toolbar sx={{ backgroundColor: process.env.REACT_APP_ENV !== 'production' ? 'greenyellow' : 'white' }}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" color={'black'} style={{ flexGrow: 1 }}>
                        {process.env.REACT_APP_ENV !== 'production' ? process.env?.REACT_APP_ENV?.toUpperCase() || '' : ''}
                    </Typography>
                    <IconButton onClick={handleProfileMenuOpen} sx={{ color: 'black' }}>
                        <AccountCircleIcon />
                        {authStore.isAuthenticated && (
                            <Typography style={{ marginLeft: '8px' }}>{(authStore.user?.name || authStore.user?.firstName)
                                ? (authStore.user?.name || authStore.user?.firstName)
                                : authStore.user?.username}</Typography>
                        )}
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={profileMenuOpen}
                        onClose={handleProfileMenuClose}
                    >
                        <MenuItem onClick={handleLogout}>ออกจากระบบ</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                },
            }}
                variant="persistent"
                anchor="left"
                open={isDrawerOpen}>
                <DrawerHeader sx={{ justifyContent: 'center', backgroundColor: process.env.REACT_APP_ENV !== 'production' ? 'greenyellow' : 'white' }}>
                    <Box component={'img'} src={ESTATE_LOGO} sx={{ width: '80%', height: '80%' }} />
                </DrawerHeader>
                <Divider />
                <List>
                    {menus &&
                        menus.map((role: any, index: number) => {

                            if (role.menu.level === 0) {
                                return (
                                    <React.Fragment key={index}>
                                        <ListItemButton key={index} onClick={() => {
                                            navigate(role.menu.path);
                                            handleMenuClick(role.menu);
                                        }}
                                        >
                                            {checkPermission(roles, role.menu.name) && (
                                                <>
                                                    <ListItemIcon>
                                                        {(() => {
                                                            switch (role.menu.name) {
                                                                case "โครงการ":
                                                                    return <HomeOutlinedIcon sx={{ color: 'black' }} />;
                                                                case "ภาพรวม":
                                                                    return <DashboardOutlinedIcon sx={{ color: 'black' }} />;
                                                                case "บันทึกค่าใช้จ่าย":
                                                                    return <ReceiptLongOutlinedIcon sx={{ color: 'black' }} />;
                                                                case "ใบสั่งซื้อ":
                                                                    return <DescriptionOutlinedIcon sx={{ color: 'black' }} />;
                                                                case "คลังวัสดุ":
                                                                    return <WarehouseOutlinedIcon sx={{ color: 'black' }} />;
                                                                case "ขาย/จอง":
                                                                    return <NoteAltOutlinedIcon sx={{ color: 'black' }} />;
                                                                case "เอกสาร":
                                                                    return <FolderOpenIcon sx={{ color: 'black' }} />;
                                                                case "การจัดการ":
                                                                    return <SettingsOutlinedIcon sx={{ color: 'black' }} />;
                                                                default:
                                                                    return null;
                                                            }
                                                        })()}
                                                    </ListItemIcon>
                                                    <ListItemText primary={role.menu.name} />
                                                    {role.menu.child.length > 0 ? (
                                                        isExpand === true && rowId === role.menu.id ? <ExpandLess /> : <ExpandMore />
                                                    ) : null
                                                    }
                                                </>
                                            )}
                                        </ListItemButton>
                                        {role.menu.child && role.menu.child.length > 0 && (
                                            <Collapse in={isExpand === true && rowId === role.menu.id} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {isExpand === true && rowId === role.menu.id && role.menu.child.map((childRole: any, childIndex: number) => {
                                                        return (<ListItemButton selected={childRole.menu.path === location.pathname} key={childIndex} sx={{ pl: 4 }} onClick={() => { navigate(childRole.menu.path); handleMenuClick(childRole.menu); }}
                                                        >
                                                            <ListItemIcon><ArrowRightIcon fontSize="small" sx={{ color: 'black' }} /></ListItemIcon>
                                                            <ListItemText primary={childRole.menu.name} />
                                                        </ListItemButton>);
                                                    })}

                                                </List>
                                            </Collapse>
                                        )}
                                    </React.Fragment>
                                );
                            }
                            return null;
                        })}
                </List>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="caption" color={'black'} textAlign={'center'}>
                    v {appVersion}
                </Typography>
            </Drawer>
            <Main open={isDrawerOpen}>
                <DrawerHeader />
                <div style={{ padding: '16px' }}>{children}</div>
            </Main>
        </Box >
    );
});

export default Layout;
