import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/th";
import { ThaiBaht } from "thai-baht-text-ts";
interface PrintExpensesProjectProps {
  title?: string;
  date?: string;
  data: any;
  companyInfo?: any;
  totalMaterial?: any;
  totalWage?: any;
  totalExpense?: any;
}
const PrintExpensesProject = React.forwardRef(
  (props: PrintExpensesProjectProps, ref) => {
    dayjs.extend(buddhistEra);
    dayjs.extend(updateLocale);
    dayjs.updateLocale("en", {
      months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      weekdays: [
        "อาทิตย์",
        "จันทร์",
        "อังคาร",
        "พุธ",
        "พฤหัสบดี",
        "ศุกร์",
        "เสาร์",
      ],
    });
    return (
      <Box
        ref={ref}
        sx={{
          margin: 0,
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "center",
          paddingBottom: "40px",
          "@page": {
            size: "A4 portrait", // Default page size is portrait
          },
          overflow: "hidden",
          fontSize: 8,
          display: "block",
          pageBreakBefore: "auto", // Allow page break before this element
          pageBreakAfter: "auto", // Allow page break before this element
          "@media print": {
            "@page": {
              size: "A4 portrait", // Subsequent pages are landscape
              margin: "40px",
            },
          },
        }}
      >
        <Grid
          container
          spacing={2}
          className="summary"
          sx={{
            "@media print": {
              "@page": {
                size: "A4 portrait", // Subsequent pages are portrait
                margin: "40px",
              },
            },
          }}
        >
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead sx={{ pageBreakBefore: "avoid" }}>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        fontWeight: "bold",
                        borderBottom: 1,
                        lineHeight: 0.5,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography fontWeight={"bold"}>
                        สรุปรายการประมาณราคา
                      </Typography>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                      <Typography fontWeight={"bold"}>
                        วันที่ {dayjs(new Date()).format("DD")}{" "}
                        {dayjs(new Date()).format("MMMM")} พ.ศ.{" "}
                        {dayjs(new Date()).format("BBBB")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                      }}
                    >
                      ลำดับ
                    </TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                      }}
                    >
                      รายการ
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                      }}
                    >
                      ราคาค่าวัสดุ
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                      }}
                    >
                      ราคาค่าแรง
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    >
                      หมายเหตุ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data &&
                    props.data?.length > 0 &&
                    props.data?.map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width={300}
                          sx={{
                            textAlign: "left",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                          }}
                        >
                          หมวด{row?.productCategoryName || ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                          }}
                        >
                          {parseFloat(row?.total).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                          }}
                        >
                          {parseFloat(row?.totalWage ?? 0)?.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                            borderRight: 1,
                          }}
                        ></TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      รวม
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {props?.totalMaterial}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {props?.totalWage}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      รวมราคาค่าวัสดุ+ค่าแรง
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {props?.totalExpense?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      รวมราคาทั้งสิ้น
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {props.totalExpense?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      รวมจำนวนเงินทั้งหมด
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {props.totalExpense?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        textAlign: "center",
                        fontWeight: "bold",
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    >
                      {ThaiBaht(props?.totalExpense || 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    >
                      หมายเหตุ
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default PrintExpensesProject;
