import React from 'react';
import { Box, Typography } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';

interface WorkInProgressProps {
    titile?: string;
}

const WorkInProgress: React.FC<WorkInProgressProps> = ({ titile }) => {
    return (
        <Box sx={{ textAlign: 'center', padding: '20px' }}>
            <EngineeringIcon fontSize="large" />
            <Typography variant="h4">{titile}</Typography>
            <Typography variant="h4">Work in progress</Typography>
            <Typography variant="body1">อยู่ระหว่างพัฒนา</Typography>
        </Box>
    );
};

export default WorkInProgress;
