import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class VendorsStore {
  vendors: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/vendors");
      this.vendors = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting vendors:", error);
      return {
        error: true,
        message: "An error occurred while getting vendors",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/vendors/${id}`);
      this.vendors = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting vendor:", error);
      return {
        error: true,
        message: "An error occurred while getting vendor",
      };
    }
  }

  async getByName(name: string) {
    try {
      const response = await apiService.get(`/vendors/name/${name}`);
      this.vendors = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting vendor:", error);
      return {
        error: true,
        message: "An error occurred while getting vendor",
      };
    }
  }

  async getByNameLike(name: string) {
    try {
      const response = await apiService.get(`/vendors/name/like/${name}`);
      this.vendors = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting vendor:", error);
      return {
        error: true,
        message: "An error occurred while getting vendor",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/vendors/criteria", criteria);
      this.vendors = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting vendors:", error);
      return {
        error: true,
        message: "An error occurred while getting vendors",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/vendors", data);
      this.vendors = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating vendor:", error);
      return {
        error: true,
        message: "An error occurred while creating vendor",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/vendors/${id}`, data);
      this.vendors = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating vendor:", error);
      return {
        error: true,
        message: "An error occurred while updating vendor",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/vendors/${id}`);
      this.vendors = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting vendor:", error);
      return {
        error: true,
        message: "An error occurred while deleting vendor",
      };
    }
  }
}
const vendorsStore = new VendorsStore();
export default vendorsStore;
