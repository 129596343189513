import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Paper, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import authStore from "../../stores/AuthStore";
import housesStore from "../../stores/HousesStore";
import projectsStore from "../../stores/ProjectsStore";
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleBackdrop from "../../components/SimpleBackdrop";
import DialogConfirm from "../../components/DialogConfirm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import landStore from "../../stores/LandStore";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import bookingStore from "../../stores/BookingStore";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import imagesStore from "../../stores/ImagesStore";
import ChipCustom from "../../components/ChipCustom";

const HouseCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [project, setProject] = useState('');
  const [houeseName, setHouseName] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState<any[]>([]);
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: '',
    message: '',
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete'
  });
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/houses');
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [land, setLand] = useState('');
  const [landDescription, setLandDescription] = useState<any>({});
  const [landData, setLandData] = useState<any[]>([]);
  const [expenseData, setExpenseData] = useState<any[]>([]);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [workerData, setWorkerData] = useState<any[]>([]);
  const [workerTotal, setWorkerTotal] = useState(0);
  const [bookingData, setBookingData] = useState<any[]>([]);
  const [sellPrice, setSellPrice] = useState('');
  const [remark, setRemark] = useState('');
  const [appraisalPrice, setAppraisalPrice] = useState('');
  const [sellData, setSellData] = useState<any[]>([]);
  const [gifts, setGifts] = useState<any[]>([]);
  const [giftsTotal, setGiftsTotal] = useState(0);
  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const handleKeyDown = (event: any, index: number) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      projectsStore.getAll(),
      landStore.getAll()
    ]).then((res) => {
      setProjectData(res[0]);
      setLandData(res[1]);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
    if (id) {
      projectsStore.getExpenseAllByHouseId(id).then((res: any) => {
        if (res.error) {
          enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
          setIsLoading(false);
        }
        setExpenseData(res.productCategory?.categories);
        setExpenseTotal(res.productCategory?.total);
        setProject(res.land?.land?.project?.id);
        setHouseName(res.land?.land?.house?.name);
        setDescription(res.land?.land?.house?.description);
        setLand(res.land?.land?.deedNo);
        setLandDescription(res.land?.land);
        setSellPrice(res.land?.land?.house?.sellPrice);
        setRemark(res.land?.land?.house?.remark);
        setImages(res.land?.land?.house?.images);
        setAppraisalPrice(res.land?.land?.house?.appraisalPrice);
        setWorkerData(res.worker?.wages);
        setWorkerTotal(parseFloat(res.worker?.total?.totalWages || '0'));
      });
      bookingStore.getByHouseId(id).then((res) => {
        setBookingData(res);
        const _sell = res?.filter((item: any) => item.sell)?.map((item: any) => ({ ...item.sell, customer: item?.customer }));
        setSellData(_sell);
        const _gifts = res?.filter((item: any) => item.sell)?.flatMap((item: any) => item.sell.gifts);
        setGifts(_gifts);
        const _total = _gifts?.reduce((total: number, item: any) => {
          return total + (parseFloat(item?.price || 0) * parseFloat(item?.quantity || 0));
        }, 0);
        setGiftsTotal(_total);
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeProject = (event: SelectChangeEvent) => {
    setProject(event.target.value as string);
  };

  const handleSave = () => {
    if (!id) {
      setIsLoading(true);
      housesStore.create({
        project: project || undefined,
        name: houeseName,
        description: description,
        sellPrice: sellPrice ? parseFloat(sellPrice) : 0,
        appraisalPrice: appraisalPrice ? parseFloat(appraisalPrice) : 0,
        remark: remark,

        createBy: authStore.user?.id,
        createAt: new Date()
      }).then((res) => {
        handleFileUpload(res.id);
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      });
    }
  };

  const handleEdit = () => {
    if (id) {
      setIsLoading(true);
      if (landDescription && landDescription?.id) {
        try {
          landStore.update(landDescription?.id, {
            project: project || undefined,
            house: id,
            updateBy: authStore.user?.id,
            updateAt: new Date()
          });
        } catch (err) {
          console.error(err);
        }
      }
      housesStore.update(id, {
        project: project || undefined,
        name: houeseName,
        description: description,
        sellPrice: sellPrice ? parseFloat(sellPrice) : 0,
        appraisalPrice: appraisalPrice ? parseFloat(appraisalPrice) : 0,
        remark: remark,
        updateBy: authStore.user?.id,
        updateAt: new Date()
      }).then((res) => {
        handleEditFileUpload(id);
        if (landDescription && landDescription.id) {
          handleUpdateLand();
        }
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('แก้ไขข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        enqueueSnackbar('แก้ไขข้อมูลสำเร็จ', { variant: 'success' });
      });
    }
  };

  const handleDelete = () => {
    if (id) {
      setIsLoading(true);
      housesStore.update(id, {
        isActive: false,
        updateBy: authStore.user?.id,
        updateAt: new Date()
      }).then((res) => {
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('ลบข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
        navigate('/houses');
      });
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    }
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleFileUpload = (refId: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "house");
        formdata.append("refId", refId);
        formdata.append('file', file);
        formdata.append('createBy', authStore.user?.id);
        formdata.append("createAt", new Date(Date.now() + index * 1000).toISOString());

        await imagesStore.upload(formdata)
          .catch(err => console.error("err", err));
      });
    }
  };

  const handleEditFileUpload = async (id: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "booking");
        formdata.append("refId", id);
        formdata.append('file', file);
        formdata.append('updateBy', authStore.user?.id);
        formdata.append("updateAt", new Date(Date.now() + index * 1000).toISOString());
        await imagesStore.upload(formdata)
          .catch(err => console.error("err", err));
      });
    }
    if (deleteImages.length > 0) {
      await deleteImages.forEach(async (item: any) => {
        await imagesStore.update(item.id, {
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          isDeleted: true
        }).catch(err => console.error("err deleteImages", err));
      });
    }
  };

  const handleUpdateLand = () => {
    try {
      landStore.update(landDescription?.id, {
        project: project || undefined,
        house: id,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box component={'div'}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton aria-label="delete" onClick={() => navigate('/houses')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>โครงการ</Typography>
            <Link component="button" onClick={() => navigate('/houses')}>บ้าน</Link>
            <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={'flex'} justifyContent={'end'}>
          {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
              open: true,
              title: 'ลบข้อมูล',
              message: 'คุณต้องการลบบ้านนี้ใช่หรือไม่',
              color: 'error',
              type: 'delete'
            })}>ลบข้อมูล</Button>
          }
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3} >
                  <FormControl fullWidth>
                    <InputLabel id="project-select-label">โครงการ</InputLabel>
                    <Select
                      labelId="project-select-label"
                      id="project-select"
                      value={project}
                      label="โครงการ"
                      onChange={handleChangeProject}
                    >
                      {projectData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} >
                  <TextField id="outlined-basic" label="ชื่อบ้าน" variant="outlined" fullWidth
                    onChange={(event) => setHouseName(event.target.value)} value={houeseName}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 0)}
                  />
                </Grid>
                <Grid item xs={5} >
                  <TextField id="outlined-basic" label="รายละเอียด" variant="outlined" fullWidth multiline
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 1)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography mt={1} mb={1}>ที่ดิน</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={land}
                        disablePortal
                        options={landData}
                        sx={{ width: '100%' }}
                        renderOption={(props, option: any) =>
                          <li {...props}>{option.deedNo}</li>
                        }
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option || '';
                          }
                          if (option.inputValue) {
                            return option.inputValue || '';
                          }
                          return option.deedNo || '';
                        }}
                        renderInput={(params) => <TextField {...params} label="โฉนดที่ดิน" />}
                        onChange={(e, value) => {
                          setLand(value?.deedNo);
                          setLandDescription(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} flexDirection={'column'} justifyContent={'center'}>
                      <Typography><b>เนื้อที่: </b>{landDescription?.rai ? parseFloat(landDescription?.rai ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"} ไร่ {landDescription?.ngan ? parseFloat(landDescription?.ngan).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"} งาน {landDescription?.squareWah ? parseFloat(landDescription?.squareWah ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"} ตร.วา</Typography>
                      <Typography><b>ที่ตั้ง: </b>{landDescription?.address} หมู่ที่ {landDescription?.village} ตำบล{landDescription?.landSubDistrict?.nameTh} อำเภอ{landDescription?.landDistrict?.nameTh} จังหวัด{landDescription?.landProvince?.nameTh} รหัสไปรษณีย์ {landDescription?.zipCode}
                      </Typography>
                      <Typography><b>มูลค่าที่ดิน:</b> {parseFloat(landDescription?.landPrice || '0').toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}>
                      <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'}>ค่าใช้จ่าย</Typography>
                        <Typography mr={10}>{parseFloat(expenseTotal.toString()).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container justifyContent={'end'}>
                            <Grid item xs={12} display={'flex'} gap={1.5}>
                              <Typography fontWeight={'bold'}>รายละเอียด</Typography>
                              <Typography>ทั้งหมด {expenseData?.length.toLocaleString() || '0'} รายการ</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          {expenseData && expenseData.map((item: any, index: number) => {
                            return (
                              <Accordion style={{ width: '100%' }} key={index.toString()}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header">
                                  <Grid container display={'flex'} justifyContent={'space-between'}>
                                    <Typography fontWeight={'bold'}>{index + 1}.  {item?.productCategoryName || 'ยังไม่ระบุ'}</Typography>
                                    <Typography mr={10}>{parseFloat(item?.total ?? 0).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}</Typography>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }}>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>รายการ</TableCell>
                                          <TableCell>จำนวน</TableCell>
                                          <TableCell>ราคาต่อหน่วย</TableCell>
                                          <TableCell>มูลค่ารวม</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {
                                          item.groups.length > 0 && item.groups.map((group: any, indexGroup: number) => {
                                            return (
                                              <Fragment key={`g-${indexGroup.toString()}`}>
                                                {group?.productGroupName && <TableRow key={`p-${indexGroup.toString()}`}>
                                                  <TableCell sx={{ fontWeight: 'bold' }} colSpan={3}>{index + 1}.{indexGroup + 1} {group?.productGroupName}</TableCell>
                                                  <TableCell> {parseFloat(group?.total ?? 0).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                  })}</TableCell>
                                                </TableRow>
                                                }
                                                {
                                                  group.products.length > 0 && group.products.map((product: any, indexProduct: number) => {
                                                    return (
                                                      <TableRow key={`p-${indexProduct.toString()}`}>
                                                        <TableCell>- {product.product}</TableCell>
                                                        <TableCell>{parseFloat(product?.quantity ?? 0).toLocaleString(undefined, {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2
                                                        })}</TableCell>
                                                        <TableCell> {parseFloat(product?.price ?? 0).toLocaleString(undefined, {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2
                                                        })}</TableCell>
                                                        <TableCell> {parseFloat(product?.total ?? 0).toLocaleString(undefined, {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2
                                                        })}</TableCell>
                                                      </TableRow>
                                                    );
                                                  })

                                                }

                                              </Fragment>
                                            );
                                          })
                                        }
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}>
                      <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'}>ค่าแรง</Typography>
                        <Typography mr={10}>{parseFloat(workerTotal.toString()).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container justifyContent={'end'}>
                            <Grid item xs={12} display={'flex'} gap={1.5}>
                              <Typography fontWeight={'bold'}>รายละเอียด</Typography>
                              <Typography>ทั้งหมด {workerData?.length.toLocaleString()} รายการ</Typography>
                              <Typography>ค่าแรงทั้งหมด {parseFloat(workerTotal.toString()).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          {workerData && workerData.map((item: any, index: number) => {
                            return (
                              <Accordion style={{ width: '100%' }} key={index.toString()}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header">
                                  <Grid container display={'flex'} justifyContent={'space-between'}>
                                    <Typography fontWeight={'bold'}>{`${index + 1}. ค่าแรงงวดวันที่ ${dayjs(item?.periodDateFrom).format('DD/MM/YYYY')} ถึง ${dayjs(item?.periodDateTo).format('DD/MM/YYYY')}`}</Typography>
                                    <Typography mr={10}>{parseFloat(item?.total ?? 0).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}</Typography>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }}>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>ชื่อ - นามสกุล</TableCell>
                                          <TableCell>รายละเอียดการทำงาน</TableCell>
                                          <TableCell>ค่าแรง</TableCell>
                                          <TableCell>จำนวนเงิน OT</TableCell>
                                          <TableCell>รวมค่าแรง + OT</TableCell>
                                          <TableCell>เบิก</TableCell>
                                          <TableCell>คงเหลือ</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {
                                          item.wageDetails.length > 0 &&
                                          item.wageDetails.sort((a: any, b: any) => { return dayjs(a.createAt).diff(b.createAt); })
                                            .map((detail: any, indexDetail: number) => {
                                              return (
                                                <Fragment key={`g-${indexDetail.toString()}`}>
                                                  {
                                                    detail?.worker &&
                                                    <TableRow key={`p-${indexDetail.toString()}`}>
                                                      <TableCell sx={{ fontWeight: 'bold' }} >{indexDetail + 1}. {detail?.worker?.firstName} {detail?.worker?.lastName}</TableCell>
                                                      <TableCell>{detail?.note || ''}</TableCell>
                                                      <TableCell> {parseFloat(detail?.totalLaborCost ?? 0).toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}</TableCell>
                                                      <TableCell> {parseFloat(detail?.totalNet ?? 0).toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}</TableCell>
                                                      <TableCell> {parseFloat(detail?.totalLaborAndCostOt ?? 0).toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}</TableCell>
                                                      <TableCell> {parseFloat(detail?.withdraw ?? 0).toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}</TableCell>
                                                      <TableCell> {parseFloat(detail?.totalNet ?? 0).toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}</TableCell>
                                                    </TableRow>
                                                  }
                                                </Fragment>
                                              );
                                            })
                                        }
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}>
                      <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'}>ข้อมูลการจอง</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>วันที่</TableCell>
                              <TableCell>เลขที่จอง</TableCell>
                              <TableCell>ชื่อลูกค้า</TableCell>
                              <TableCell>เบอร์โทร</TableCell>
                              <TableCell>สถานะ</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              bookingData?.length > 0 &&
                              bookingData.sort((a: any, b: any) => { return dayjs(a.docDate).diff(b.docDate); })
                                .map((booking: any) => {
                                  return (
                                    <Fragment key={`g-${booking.id}`}>
                                      <TableRow key={booking.id}>
                                        <TableCell>{dayjs(booking?.docDate).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell>{booking?.bookingNo}</TableCell>
                                        <TableCell>{booking?.customer?.firstname} {booking?.customer?.lastname}</TableCell>
                                        <TableCell>{booking?.customer?.tel}</TableCell>
                                        <TableCell>
                                          {booking?.status?.name && <ChipCustom lable={booking?.status?.name} />}
                                        </TableCell>
                                        <TableCell>
                                          <IconButton aria-label="edit" onClick={() => navigate(`/booking-edit/${booking.id}`)}>
                                            <KeyboardArrowRightIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    </Fragment>
                                  );
                                })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}>
                      <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'}>ข้อมูลการขาย</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>วันที่</TableCell>
                              <TableCell width={400} >ชื่อลูกค้า</TableCell>
                              <TableCell>เบอร์โทร</TableCell>
                              <TableCell width={300} ></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              sellData?.length > 0 &&
                              sellData.sort((a: any, b: any) => { return dayjs(a.docDate).diff(b.docDate); })
                                .map((sell: any) => {
                                  return (
                                    <Fragment key={`g-${sell.id}`}>
                                      <TableRow key={sell.id}>
                                        <TableCell>{dayjs(sell?.docDate).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell >{sell?.customer?.firstname} {sell?.customer?.lastname}</TableCell>
                                        <TableCell>{sell?.customer?.tel}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell >
                                          <IconButton aria-label="edit" onClick={() => navigate(`/sell-edit/${sell.id}`)}>
                                            <KeyboardArrowRightIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    </Fragment>
                                  );
                                })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}>
                      <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'}>ของแถม</Typography>
                        <Typography mr={10}>{parseFloat(giftsTotal.toString()).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>วันที่</TableCell>
                              <TableCell width={400} >รายการ</TableCell>
                              <TableCell>จำนวน</TableCell>
                              <TableCell>มูลค่า</TableCell>
                              <TableCell>รวม</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              gifts?.length > 0 &&
                              gifts.sort((a: any, b: any) => { return dayjs(a.createAt).diff(b.createAt); })
                                .map((gift: any) => {
                                  return (
                                    <Fragment key={`g-${gift.id}`}>
                                      <TableRow key={gift.id}>
                                        <TableCell>{dayjs(gift?.createAt).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell >{gift?.name}</TableCell>
                                        <TableCell >{parseFloat(gift?.price || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                        <TableCell >{parseFloat(gift?.quantity || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                        <TableCell >{(parseFloat(gift?.price || 0) * parseFloat(gift?.quantity || 0)).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}</TableCell>
                                      </TableRow>
                                    </Fragment>
                                  );
                                })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <TextField label="ราคาตั้งขาย" variant="outlined" fullWidth
                        value={sellPrice ? parseFloat(sellPrice) : 0}
                        type={'number'}
                        onChange={(event) => setSellPrice(event.target.value)}
                        inputRef={addRef}
                        onKeyDown={(e) => handleKeyDown(e, 2)} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField label="ราคาประเมินที่ดิน" variant="outlined" fullWidth
                        value={appraisalPrice ? parseFloat(appraisalPrice) : 0}
                        type={'number'}
                        onChange={(event) => setAppraisalPrice(event.target.value)}
                        inputRef={addRef}
                        onKeyDown={(e) => handleKeyDown(e, 3)} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField label="หมายเหตุ" variant="outlined" fullWidth multiline
                        value={remark}
                        inputRef={addRef}
                        onChange={(event) => setRemark(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={8} >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>เอกสาร</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                        เลือกไฟล์
                        <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                          onChange={handleFileChange} />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedFiles.length > 0 && (
                        <Box component={'div'} mt={1}>
                          {selectedFiles.map((file: any, index: number) => (
                            <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small" color="error"
                                    onClick={() => handleFileRemove(index)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {images && (
                        <Box component={'div'} mt={1}>
                          {images.map((file: any, index: number) => (
                            <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small" color="error"
                                    onClick={() => handleFileRemoveImageList(file)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={`${apiUrl}/uploads/${file.name}`}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>ค่าใช้จ่าย</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>{parseFloat((expenseTotal).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>ค่าแรง</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>{parseFloat((workerTotal).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>มูลค่าที่ดิน</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>{parseFloat(landDescription?.landPrice || '0').toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={'bold'}>ต้นทุนรวม</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>{parseFloat((expenseTotal + workerTotal + parseFloat(landDescription?.landPrice || '0')).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={'bold'}>ราคาขาย</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>{parseFloat(sellPrice ? sellPrice : '0').toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={'bold'}>กำไร</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>{parseFloat((parseFloat(sellPrice ? sellPrice : '0') - (expenseTotal + workerTotal)).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Stack spacing={2} direction="row">
                        <Button variant="outlined" fullWidth onClick={() => navigate('/houses')}>ยกเลิก</Button>
                        {!id && <Button variant="contained" fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'บันทึกเอกสาร',
                            message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'create'
                          });
                        }}>บันทึก</Button>}
                        {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'แก้ไขเอกสาร',
                            message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'edit'
                          });
                        }}>แก้ไข</Button>}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'delete') {
            handleDelete();
          } else if (openConfirm.type === 'create') {
            handleSave();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          }
        }} />
    </Box>
  );
};

export default HouseCreate;
