import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Card, CardContent, Divider, FormControl, Grid, IconButton, MenuItem, Pagination, Select, SelectChangeEvent, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import authStore from "../../stores/AuthStore";
import CachedIcon from '@mui/icons-material/Cached';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import VillaIcon from '@mui/icons-material/Villa';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import PaidIcon from '@mui/icons-material/Paid';
import { BarChart } from '@mui/x-charts/BarChart';
import { useNavigate } from "react-router-dom";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
import sellStore from "../../stores/SellStore";
import bookingStore from "../../stores/BookingStore";
import cashflowStore from "../../stores/CashflowStore";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Dashboard: React.FC = observer(() => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isHouseLoading, setIsHouseLoading] = useState(false);
  const [date, setDate] = useState(dayjs().format('DD/MM/YYYY'));
  const [project, setProject] = useState('');
  const [projectData, setProjectData] = useState<any[]>([]);
  const [housesData, setHousesData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [male, setMale] = useState([0]);
  const [female, setFemale] = useState([0]);
  const xLabels = [
    '20-24',
    '25-29',
    '30-34',
    '35-39',
    '40-44',
    '45-49',
    '50 up'
  ];
  const [sellTotal, setSellTotal] = useState(0);
  const [bookingTotal, setBookingTotal] = useState(0);
  const [cancelTotal, setCancelTotal] = useState(0);
  const [cashflowTotal, setCashflowTotal] = useState<any[]>([]);

  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem('date-dashboard');
  });

  const handleChangeDate = (event: SelectChangeEvent) => {
    setDate(event.target.value);
    handleRefresh(event.target.value);
    localStorage.setItem('date-dashboard', event.target.value);
  };

  const handleChangeProject = (event: SelectChangeEvent) => {
    setProject(event.target.value);
    handleGetHouseData(undefined, event.target.value);
  };

  const handleRefresh = (dateselect?: string, projectId?: string) => {
    setIsLoading(true);
    setHousesData([]);
    setTotal(0);
    setPage(0);
    const dateArray = dateselect ? dateselect.split('-') : date.split('-');
    if (dateArray.length > 1) {
      dateArray[0] = dayjs(dateArray[0], 'DD/MM/YYYY').format('YYYY-MM-DD');
      dateArray[1] = dayjs(dateArray[1], 'DD/MM/YYYY').format('YYYY-MM-DD');
    } else {
      dateArray[0] = dayjs(dateArray[0], 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    setTimeout(() => {
      Promise.all([
        housesStore.dashboard({
          page: 0,
          limit: 5,
          projectId: projectId ? projectId : project
        }),
        sellStore.dashboard(dateArray),
        bookingStore.dashboard(dateArray),
        bookingStore.dashboardCanceled(dateArray),
        bookingStore.dashboardCustomer(dateArray),
        cashflowStore.dashboard(dateArray),
      ]).then(([houses, sell, booking, bookingCaceled, customer, cashflow]) => {
        setSellTotal(sell.sell || 0);
        setBookingTotal(booking.booking || 0);
        setCancelTotal(bookingCaceled.booking || 0);
        setMale(customer.filter((item: any) => item.gender === 'ชาย').sort((a: any, b: any) => a.ageRange.localeCompare(b.ageRange)).map((item: any) => item.booking));
        setFemale(customer.filter((item: any) => item.gender === 'หญิง').sort((a: any, b: any) => a.ageRange.localeCompare(b.ageRange)).map((item: any) => item.booking));
        setCashflowTotal(cashflow);
        setHousesData(houses.data);
        setTotal(Math.ceil(houses.total / 5));
        setIsLoading(false);
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    }, 1000);
  };

  const handleGetHouseData = (pageSelect?: string, projectSelect?: string) => {
    setIsHouseLoading(true);
    setHousesData([]);
    setTotal(0);
    setPage(0);
    const _page = parseInt(pageSelect || '0');
    setTimeout(() => {
      housesStore.dashboard({
        page: _page > 0 ? _page - 1 : _page,
        limit: 5,
        projectId: projectSelect
      }).then((res) => {
        setHousesData(res.data);
        setTotal(Math.ceil(res.total / 5));
        setIsHouseLoading(false);
      }).catch((err) => {
        console.error(err);
        setIsHouseLoading(false);
      });
    }, 1000);
  };

  useEffect(() => {
    const _dateDashboard = localStorage.getItem('date-dashboard');
    const dateDashboard = _dateDashboard ? _dateDashboard : dayjs().format('DD/MM/YYYY');
    setDate(dateDashboard);
    setIsLoading(true);
    setProject('all');
    setTimeout(() => {
      Promise.all([
        projectsStore.getAll(),
      ]).then(([projects]) => {
        setProjectData(projects);
        setProject(projects[0].id);
        handleRefresh(dateDashboard, projects[0].id);
        setIsLoading(false);
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box component={'div'}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h5">ภาพรวม</Typography>
              <Typography>ยินดีต้อนรับเข้าสู่ระบบ {(authStore.user?.firstName || authStore.user?.name) ? (authStore.user?.firstName || authStore.user?.name) : authStore.user?.username}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container display={'flex'} alignItems={'center'}>
                <Grid item xs={8} display={'flex'} justifyContent={'end'} pr={3}>
                  <IconButton onClick={() => handleRefresh()}>
                    <CachedIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth sx={{ bgcolor: 'white' }}>
                    <Select
                      value={date.toString()}
                      onChange={handleChangeDate}
                      size="small"
                    >
                      <MenuItem value={dayjs().format('DD/MM/YYYY')}>วันนี้</MenuItem>
                      <MenuItem value={dayjs().subtract(1, 'day').format('DD/MM/YYYY')}>เมื่อวาน</MenuItem>
                      <MenuItem value={dayjs().subtract(7, 'day').format('DD/MM/YYYY') + '-' + dayjs().format('DD/MM/YYYY')}>7 วันที่ผ่านมา</MenuItem>
                      <MenuItem value={dayjs().subtract(30, 'day').format('DD/MM/YYYY') + '-' + dayjs().format('DD/MM/YYYY')}>30 วันที่ผ่านมา</MenuItem>
                      <MenuItem value={dayjs().startOf('month').format('DD/MM/YYYY') + '-' + dayjs().format('DD/MM/YYYY')}>เดือนนี้</MenuItem>
                      <MenuItem value={dayjs().subtract(1, 'month').startOf('month').format('DD/MM/YYYY') + '-' + dayjs().subtract(1, 'month').endOf('month').format('DD/MM/YYYY')}>เดือนที่ผ่านมา</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Card>
                <CardContent sx={{ height: 100 }}>
                  <Grid container display={'flex'} alignItems={'center'}>
                    <Grid item xs={9}>
                      <Typography fontWeight={'bold'}>ยอดขาย</Typography>
                      {!isLoading && <Typography variant="h5" noWrap>
                        {parseFloat(sellTotal.toString()).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </Typography>}
                      {isLoading && <Skeleton animation="wave" variant="text" sx={{ fontSize: '1.333rem' }} />}
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => navigate('/sells')}>
                        <VillaIcon sx={{ fontSize: '2em', color: 'darkgreen' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent sx={{ height: 100 }}>
                  <Grid container display={'flex'} alignItems={'center'}>
                    <Grid item xs={9}>
                      <Typography fontWeight={'bold'}>ยอดจำนวนจอง</Typography>
                      {!isLoading && <Typography variant="h5" noWrap>
                        {bookingTotal.toLocaleString()}
                      </Typography>}
                      {isLoading && <Skeleton animation="wave" variant="text" sx={{ fontSize: '1.333rem' }} />}
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => navigate('/bookings')}>
                        <BookmarkAddedIcon sx={{ fontSize: '2em', color: 'darkblue' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent sx={{ height: 100 }}>
                  <Grid container display={'flex'} alignItems={'center'}>
                    <Grid item xs={9}>
                      <Typography fontWeight={'bold'}>ยอดจำนวนยกเลิกจอง</Typography>
                      {!isLoading && <Typography variant="h5" noWrap>
                        {cancelTotal.toLocaleString()}
                      </Typography>}
                      {isLoading && <Skeleton animation="wave" variant="text" sx={{ fontSize: '1.333rem' }} />}
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => navigate('/bookings')}>
                        <BookmarkRemoveIcon sx={{ fontSize: '2em', color: 'darkred' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent sx={{ height: 100 }}>
                  <Grid container display={'flex'} alignItems={'center'}>
                    <Grid item xs={9}>
                      <Typography><b>กระแสเงินสด</b> (<b style={{ color: 'darkred' }}>จ่าย</b>/รับ)</Typography>
                      {!isLoading &&
                        <>
                          <Typography fontWeight={'bold'} color={'darkred'} noWrap>
                            {parseFloat(cashflowTotal.find((item: any) => item.docType === 'paid')?.total || '0').toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </Typography>
                          <Typography noWrap>
                            {parseFloat(cashflowTotal.find((item: any) => item.docType === 'received')?.total || '0').toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </Typography>
                        </>
                      }
                      {isLoading &&
                        <>
                          <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                          <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                        </>
                      }
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => navigate('/cashflow')}>
                        <PaidIcon sx={{ fontSize: '2em', color: 'darkorange' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Card sx={{ maxHeight: 450, height: 450 }}>
                <CardContent>
                  <Typography fontWeight={'bold'}>กลุ่มลูกค้า <small>(ข้อมูลจากการจอง)</small></Typography>
                  {!isLoading && <BarChart
                    width={600}
                    height={400}
                    series={[
                      { data: male, label: 'ชาย', id: 'male' },
                      { data: female, label: 'หญิง', id: 'female' },
                    ]}
                    xAxis={[{ data: xLabels, scaleType: 'band' }]}
                    yAxis={[{ tickMinStep: 1 }]}
                  />}
                  {isLoading && <Skeleton animation="wave" variant="rectangular" sx={{ height: 370, mt: 3.2 }} />}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={5}>
              <Card sx={{ maxHeight: 450, height: 450 }}>
                <CardContent>
                  <Grid container display={'flex'} alignItems={'center'}>
                    <Grid item xs={3}>
                      <Typography fontWeight={'bold'}>ต้นทุนบ้าน</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl fullWidth size="small">
                        <Select
                          value={project}
                          onChange={handleChangeProject}
                        >
                          {projectData && projectData?.length > 0 && projectData?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem>))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {(!isLoading && !isHouseLoading) && <>
                        <TableContainer>
                          <Table size="small" sx={{ mt: 5 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>บ้าน</TableCell>
                                <TableCell>ต้นทุน</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {housesData?.length > 0 && housesData?.map((item: any) => (
                                <TableRow key={item.id} >
                                  <TableCell >{item.name}</TableCell>
                                  <TableCell>{parseFloat(item.cost || '0').toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                  <TableCell align="right">
                                    <IconButton size="small" onClick={() => navigate(`/house-edit/${item.id}`)}>
                                      <KeyboardArrowRightIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                              {housesData?.length === 0 && <TableRow>
                                <TableCell colSpan={3} align="center">ไม่พบข้อมูล</TableCell>
                              </TableRow>}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {housesData?.length > 0 && <Box mt={3} display={'flex'} justifyContent={'center'}>
                          <Pagination
                            count={total}
                            page={page}
                            onChange={(e, newPage) => {
                              setPage(newPage);
                              handleGetHouseData(newPage.toString(), project);
                            }}
                          />
                        </Box>}
                      </>}
                      {(isLoading || isHouseLoading) && <Skeleton animation="wave" variant="rectangular" sx={{ height: 370, mt: 1.5 }} />}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
});

export default Dashboard;
