import { Box, Breadcrumbs, Button, Card, CardContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import userGroupsStore from "../../stores/UserGroupsStore";
import usersStore from "../../stores/UserStore";
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import dayjs from "dayjs";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { enqueueSnackbar } from "notistack";
import authStore from "../../stores/AuthStore";
import ReactToPrint from "react-to-print";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportFileUsers from "../../components/Report/Excel/ExportFileUser";
import PrintUsers from "../../components/Report/Pdf/PrintUsers";

const Users: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userGroupLists, setUserGroupLists] = useState<any[]>([]);
    const [userGroups, setUserGroups] = useState('all');
    const [txtSearch, setTxtSearch] = useState('');
    const [rows, setRows] = useState<any[]>([]);
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/users');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [sortType, setSortType] = useState({ name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" });
    const sortOption = [
        { name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" },
        { name: "วันที่บันทึก: เก่าสุด", variable: "createAtASC", sortBy: "createAt", sortType: "ASC" },
        { name: "วันที่แก้ไข: ล่าสุด", variable: "updateAtDESC", sortBy: "updateAt", sortType: "DESC" },
        { name: "วันที่แก้ไข: เก่าสุด", variable: "updateAtASC", sortBy: "updateAt", sortType: "ASC" },
    ];
    const [sortValue, setSortValue] = useState("createAtDESC");

    // Remove local storage
    window.addEventListener("beforeunload", function (event) {
        localStorage.removeItem('sortType');
        localStorage.removeItem('limit');
        localStorage.removeItem('page');
    });

    useEffect(() => {
        setIsLoading(true);
        userGroupsStore.getAll().then((result) => {
            if ((result && result.length > 0) || result !== undefined) {
                const _limit = localStorage.getItem('limit');
                const _page = localStorage.getItem('page');
                setUserGroupLists(result);
                setLimit(_limit ? parseInt(_limit) : limit);
                setPage(_page ? parseInt(_page) : page);
                const _sortType: any = localStorage.getItem('sortType');
                const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
                setSortType(_sortTypeConvert || sortType);
                setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
                if (!_sortType) {
                    localStorage.setItem('sortType', JSON.stringify(sortType));
                }
                if (!_limit) {
                    localStorage.setItem('limit', '10');
                }
                if (!_page) {
                    localStorage.setItem('page', '0');
                }
                handleSearch(undefined, undefined, _sortTypeConvert);
            } else {
                enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
                setIsLoading(false);
            }
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (pageNumber?: string, limitNumber?: string, _sortType?: string, isRefresh = false) => {
        setIsLoading(true);
        const _page = parseInt(pageNumber || '0');
        const _limit = parseInt(limitNumber || '0');
        usersStore.getByCriteria({
            userGroup: userGroups === 'all' ? undefined : userGroups,
            txtSearch: txtSearch || undefined,
            page: _page,
            limit: _limit,
            sortType: _sortType ? _sortType : sortType,
        }).then((result) => {
            setRows(result.data);
            setTotal(result.total);
            if ((result?.total && result.total <= 10) || isRefresh === true) {
                setPage(0);
                setLimit(10);
            };
            const _sortType = localStorage.getItem('sortType');
            const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
            setSortType(_sortTypeConvert || sortType);
            setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
            if (!_sortType) {
                localStorage.setItem('sortType', JSON.stringify(sortType));
            }
            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    };

    const printRef = useRef(null);
    const exportRef = useRef(null);

    const handleChangeSort = (e: any) => {
        const findSortType: any = sortOption.find((item: any) => item.variable === e.target.value);
        localStorage.setItem('sortType', JSON.stringify(findSortType));
        setSortType(findSortType);
        setSortValue(e.target.value);
    };
    return (
        <Box component={'div'}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography>การจัดการ</Typography>
                <Typography variant="h6" color="text.primary">ผู้ใช้งาน</Typography>
            </Breadcrumbs>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} alignItems={'end'}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="userGroup-select-label">ตำแหน่ง</InputLabel>
                                        <Select
                                            labelId="userGroup-select-label"
                                            id="userGroup-select"
                                            value={userGroups}
                                            label="ตำแหน่ง"
                                            onChange={(event) => setUserGroups(event.target.value)}>
                                            <MenuItem value='all'>ทั้งหมด</MenuItem>
                                            {userGroupLists.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="outlined-basic" label="ชื่อ-นามสกุล/ชื่อผู้ใช้งาน" variant="outlined" fullWidth onChange={(event) => setTxtSearch(event.target.value)} value={txtSearch} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">เรียงตาม
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={sortValue}
                                            label="sortType"
                                            onChange={handleChangeSort}
                                        >
                                            {sortOption.map((option: any, index: number) => (
                                                <MenuItem key={`sort-option-${index}`} value={option.variable}>{option.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} textAlign={'end'} pb={0.5}>
                                    <Button variant="contained" onClick={() => handleSearch(undefined, undefined, undefined, true)} startIcon={<SearchIcon />}>ค้นหา</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container >
                                        <Grid item xs={9}>
                                            <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <DownloadTableExcel
                                                filename={`products-${dayjs().format('DD-MM-YYYY')}`}
                                                sheet={`products-${dayjs().format('DD-MM-YYYY')}`}
                                                currentTableRef={exportRef.current}
                                            >
                                                <IconButton aria-label="download"  >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </DownloadTableExcel>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <ReactToPrint
                                                trigger={() => {
                                                    return (
                                                        <IconButton aria-label="print">
                                                            <PrintIcon />
                                                        </IconButton>
                                                    );
                                                }}
                                                content={() => {
                                                    return printRef.current;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/users-create')}>สร้าง</Button>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">ชื่อ-นามสกุล</TableCell>
                                                    <TableCell align="center" >ชื่อผู้ใช้งาน</TableCell>
                                                    <TableCell align="center">ตำแหน่ง</TableCell>
                                                    <TableCell width={100} align="center">วันที่บันทึก</TableCell>
                                                    <TableCell width={100} align="center">วันที่แก้ไข</TableCell>
                                                    <TableCell ></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.length > 0 && rows.map((row) => (
                                                    <TableRow key={row?.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">{row.firstName + ' ' + row.lastName}</TableCell>
                                                        <TableCell width={300}>{row.username || ''}</TableCell>
                                                        <TableCell width={200} align="center">{row.userGroup?.name || ''}</TableCell>
                                                        <TableCell>{<b>{row.createBy?.name}</b>} {row.createAt ? dayjs(row.createAt).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                                                        <TableCell>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton aria-label="edit" onClick={() => navigate(`/users-edit/${row.id}`)}>
                                                                <KeyboardArrowRightIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>

                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={3}>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            count={total}
                                            onPageChange={(e, newPage) => {
                                                setPage(newPage);
                                                handleSearch(newPage.toString());
                                            }}
                                            page={page}
                                            rowsPerPage={limit ? limit : 10}
                                            onRowsPerPageChange={(e: any) => {
                                                setRows([]);
                                                setLimit(e.target.value);
                                                setPage(0);
                                                handleSearch('0', e.target.value.toString());
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <Box sx={{ display: "none" }}>
                <PrintUsers ref={printRef} title={'รายการผู้ใช้งาน'} rows={rows} />
            </Box>
            <Box sx={{ display: "none" }}>
                <ExportFileUsers ref={exportRef} title={'รายการผู้ใช้งาน'} rows={rows} />
            </Box>
        </Box >
    );
};

export default Users;
