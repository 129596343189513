import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

interface ExportFileBillProps {
    title?: string;
    date?: string;
    data: any[];
}

const ExportFileBill = React.forwardRef((props: ExportFileBillProps, ref) => {
    const [grandTotal, setGrandTotal] = useState(0);
    const calculateGrandTotal = () => {
        return props.data.reduce((accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue.grandTotal);
        }, 0);
    };
    useEffect(() => {
        const calculatedTotal = calculateGrandTotal();
        setGrandTotal(calculatedTotal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);
    return (
        <Box ref={ref} sx={{ textAlign: 'center', padding: '20px', '&@page': { size: 'A4' }, }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                                        <Typography fontWeight={'bold'}>{props.title} <br />{props.date}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width={70}>วันที่เอกสาร</TableCell>
                                    <TableCell width={200}>เลขเอกสาร</TableCell>
                                    <TableCell width={300}>ชื่อผู้ขาย</TableCell>
                                    <TableCell width={200}>ยอดรวมสุทธิ</TableCell>
                                    <TableCell width={100}>ประเภท</TableCell>
                                    <TableCell width={70}>บันทึก</TableCell>
                                    <TableCell width={70}>แก้ไข</TableCell>
                                    <TableCell width={70}>ตรวจ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props?.data?.length > 0 && props?.data?.map((row: any) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" width={70}>
                                            {dayjs(row.docDate).format('DD/MM/YYYY')}
                                        </TableCell>
                                        <TableCell width={200} scope="row">{row.docNo}</TableCell>
                                        <TableCell width={350} scope="row">{row.vendor?.name}</TableCell>
                                        <TableCell width={200} scope="row" align="right">{parseFloat(row.grandTotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                        <TableCell width={100}>{
                                            row.docType === 'tax'
                                                ? "ใบกำกับภาษี"
                                                : row.docType === 'receipt'
                                                    ? "ใบเสร็จทั่วไป"
                                                    : "บิลเงินสด/ใบส่งของ"
                                        }</TableCell>
                                        <TableCell width={70}>{<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                                        <TableCell width={70}>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                                        <TableCell width={70}>{<b>{row.checkedBy?.name}</b>} {row.checkedAt ? dayjs(row.checkedAt).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={8} sx={{ textAlign: 'right' }}>
                                        <Typography><b>จำนวนเงินรวมทั้งสิ้น</b> {parseFloat(grandTotal.toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <b> บาท</b> </Typography>
                                    </TableCell>
                                </TableRow>
                                {props.data.length === 0 && (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
});

export default ExportFileBill;