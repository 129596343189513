import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

interface PrintExpensesHouseCommonFeesProps {
  title?: string;
  subTitle?: string;
  summary?: string;
  data: any[];
}

const PrintExpensesHouseCommonFees = React.forwardRef(
  (props: PrintExpensesHouseCommonFeesProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          margin: 0,
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          "&@page": {
            size: "A4 portrait",
          },
          overflow: "hidden",
          fontSize: 8,
          display: "block",
          pageBreakBefore: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650, overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                      <Typography fontWeight={"bold"}>
                        {props.subTitle}
                      </Typography>
                      <Typography fontWeight={"bold"}>
                        มูลค่ารวม: {props.summary} บาท
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={10}>ลำดับ</TableCell>
                    <TableCell width={300}>รายการ</TableCell>
                    <TableCell width={100} sx={{ textAlign: "center" }}>
                      จำนวน
                    </TableCell>
                    <TableCell width={100} sx={{ textAlign: "center" }}>
                      ราคาต่อหน่วย
                    </TableCell>
                    <TableCell width={100} sx={{ textAlign: "center" }}>
                      ราคา
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.length > 0 &&
                    props?.data?.map((row: any, index: number) => {
                      return (
                        <React.Fragment
                          key={`item-wageDetails-house-detail-${index.toString()}- ${
                            row?.id
                          }-print`}
                        >
                          <TableRow
                            key={`item-wageDetails-${index.toString()}-print-${
                              row?.id
                            }`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={{ textAlign: "center" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              {row?.productCategoryName || "ยังไม่ระบุ"}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              sx={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {parseFloat(row?.total ?? 0).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                          </TableRow>
                          {row?.group?.length > 0 &&
                            row?.group?.map((group: any, gIndex: number) => {
                              return (
                                <React.Fragment
                                  key={`common-fee-groups-house-product-categories-${gIndex.toString()}- ${
                                    row?.id
                                  }-print`}
                                >
                                  {group && (
                                    <TableRow
                                      key={`g-${gIndex.toString()}`}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell></TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        {index + 1}.{gIndex + 1}{" "}
                                        {group?.name || "ยังไม่ระบุ"}
                                      </TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "right",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {parseFloat(
                                          group?.total ?? 0
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  {group?.detail?.length > 0 &&
                                    group?.detail?.map(
                                      (product: any, pIndex: number) => {
                                        return (
                                          <TableRow
                                            key={`common-fee-house-product-${pIndex.toString()}`}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell></TableCell>
                                            <TableCell>
                                              - {product?.product?.name || ""}
                                            </TableCell>
                                            <TableCell
                                              sx={{ textAlign: "center" }}
                                            >
                                              {product?.quantity
                                                ? parseFloat(
                                                    product?.quantity
                                                  ).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })
                                                : ""}
                                            </TableCell>
                                            <TableCell
                                              sx={{ textAlign: "center" }}
                                            >
                                              {product?.price
                                                ? (product?.price).toLocaleString(
                                                    undefined,
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )
                                                : ""}
                                            </TableCell>
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              {product?.total
                                                ? parseFloat(
                                                    product?.total
                                                  ).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })
                                                : ""}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                </React.Fragment>
                              );
                            })}
                          <TableRow
                            key={`item-wageDetails-total-${index.toString()}-${
                              row?.id
                            }-print`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              colSpan={4}
                              sx={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              รวม
                            </TableCell>
                            <TableCell
                              sx={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {props?.summary || "0.00"}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  {props.data && props.data.length === 0 && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell colSpan={10} align="center">
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default PrintExpensesHouseCommonFees;
