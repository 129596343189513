import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class RolesStore {
    roles: any = null;
    construnctor() {
        makeAutoObservable(this);
    }

    async create(data: any) {
        try {
            const response = await apiService.post("/roles", data);
            this.roles = response;
            return response;
        } catch (error) {
            console.error("An error occurred while creating user:", error);
            return {
                error: true,
                message: "An error occurred while creating user",
            };
        }

    }

    async update(id: string, data: any) {
        try {
            const response = await apiService.put(`/roles/${id}`, data);
            this.roles = response;
            return response;
        } catch (error) {
            console.error("An error occurred while creating user:", error);
            return {
                error: true,
                message: "An error occurred while creating user",
            };
        }

    }


    async delete(user: string) {
        try {
            const response = await apiService.delete(`/roles/user?userId=${user}`);
            this.roles = response;
            return response;
        } catch (error) {
            console.error("An error occurred while deleteing roles:", error);
            return {
                error: true,
                message: "An error occurred while deleteing roles",
            };
        }

    }
}

const rolesStore = new RolesStore();
export default rolesStore;