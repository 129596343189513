import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class CustomerStore {
  customer: any[] = [];
  construnctor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/customers");
      this.customer = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting customer:", error);
      return {
        error: true,
        message: "An error occurred while getting customer",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/customers/${id}`);
      this.customer = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting customer:", error);
      return {
        error: true,
        message: "An error occurred while getting customer",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/customers", data);
      this.customer = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating customer:", error);
      return {
        error: true,
        message: "An error occurred while creating customer",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/customers/${id}`, data);
      this.customer = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating customer:", error);
      return {
        error: true,
        message: "An error occurred while updating customer",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/customers/criteria", criteria);
      this.customer = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting customer:", error);
      return {
        error: true,
        message: "An error occurred while getting customer",
      };
    }
  }
}
const customerStore = new CustomerStore();
export default customerStore;