import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class StatusStore {
  status: any = {};

  constructor() {
    makeAutoObservable(this);
  }
  async getAll() {
    try {
      const response = await apiService.get(`/status`);
      this.status = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting status", error);
      return {
        error: true,
        message: "An error occurred while getting status",
      };
    }
  }
  async get(id: string) {
    try {
      const response = await apiService.get(`/status/${id}`);
      this.status = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting status", error);
      return {
        error: true,
        message: "An error occurred while getting status",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/status/criteria", criteria);
      this.status = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting status", error);
      return {
        error: true,
        message: "An error occurred while getting status",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/status", data);
      this.status = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating status", error);
      return {
        error: true,
        message: "An error occurred while creating status",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/status/${id}`, data);
      this.status = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating status", error);
      return {
        error: true,
        message: "An error occurred while updating status",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/status/${id}`);
      this.status = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting status", error);
      return {
        error: true,
        message: "An error occurred while deleting status",
      };
    }
  }
}
const statusStore = new StatusStore();
export default statusStore;
