import { Autocomplete, Box, Breadcrumbs, Button, Card, CardContent, FormControl, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from "dayjs";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactToPrint from "react-to-print";
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import warehousesStore from "../../stores/WarehousesStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import PrintWarehouse from "../../components/Report/Pdf/PrintWarehouse";
import ExportFileWarehouses from "../../components/Report/Excel/ExportFileWarehouses";
import vendorsStore from "../../stores/VendorsStore";

const Warehouses: React.FC = () => {
  const [rows, setRows] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const [productName, setProductName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [vendorData, setVendorData] = useState<any[]>([]);
  const [vendor, setVendor] = useState('');
  const handleSearch = (pageNumber?: string, limitNumber?: string, vendorValue?: '', productNameValue?: '') => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    const vendorId: any = vendorValue || vendor;
    warehousesStore.getBycriteria({
      name: productNameValue || productName,
      page: _page,
      limit: _limit,
      vendor: vendorId?.id || undefined
    }).then((result) => {
      setRows(result.data.sort((a: any, b: any) => a.productCategory?.name.localeCompare(b.productCategory?.name || '')).sort((a: any, b: any) => a.productGroup?.name.localeCompare(b.productGroup?.name || '')));
      setTotal(result.total);
      setPage(0);
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });;
  };

  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem('vendor');
    localStorage.removeItem('productName');
  });

  useEffect(() => {
    setIsLoading(true);
    const _vandor: any = localStorage.getItem('vendor');
    const vandorId = JSON.parse(_vandor);
    const _productName: any = localStorage.getItem('productName');
    setVendor(vandorId || undefined);
    setProductName(_productName);

    vendorsStore.getAll().then((result) => {
      setVendorData(result);
    });
    handleSearch(undefined, undefined, vandorId, _productName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const printRef = useRef(null);
  const exportRef = useRef(null);

  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>คลังวัสดุ</Typography>
        <Typography variant="h6" color="text.primary">รายการวัสดุ</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                <Grid item xs={5} >
                  <FormControl fullWidth>
                    <Autocomplete
                      id="vendor-select"
                      options={vendorData}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.name;
                      }}
                      value={vendor !== '' ? vendor : ''}
                      onChange={(event, newValue) => {
                        setVendor(newValue);
                        localStorage.setItem('vendor', JSON.stringify(newValue));
                      }}
                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                      renderInput={(params) => (
                        <TextField {...params} label="ผู้ขาย" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5} >
                  <TextField id="outlined-basic" label="ชื่อสินค้า" variant="outlined" fullWidth
                    onChange={(event) => {
                      setProductName(event.target.value);
                      localStorage.setItem('productName', event.target.value);
                    }} value={productName}
                  />
                </Grid>
                <Grid item xs={2} textAlign={'end'} pb={0.5} >
                  <Button variant="contained"
                    onClick={() => handleSearch()}
                    startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent={'end'}>
                    <Grid item xs={10}>
                      <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`warehouses-${dayjs().format('DD-MM-YYYY')}`}
                        sheet={`warehouses-${dayjs().format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <IconButton aria-label="print">
                              <PrintIcon />
                            </IconButton>
                          );
                        }}
                        content={() => {
                          return printRef.current;
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>ชื่อสินค้า</TableCell>
                          <TableCell>สี</TableCell>
                          <TableCell>ขนาด</TableCell>
                          <TableCell>จำนวน</TableCell>
                          <TableCell>หน่วย</TableCell>
                          <TableCell>มูลค่ารวม</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows && rows?.length > 0 && rows?.map((row, index) => (
                          <TableRow
                            key={row.id + index.toString()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row"  >{row?.name || ''}
                            </TableCell>
                            <TableCell component="th" scope="row"  >{row?.color || ''}
                            </TableCell>
                            <TableCell component="th" scope="row"  >{row?.size || ''}
                            </TableCell>
                            <TableCell>{parseFloat(row?.quantity || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                            <TableCell>{row?.unit || ''}</TableCell>
                            <TableCell>{parseFloat(row?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                            <TableCell align="right">
                              <IconButton aria-label="detail" onClick={() => navigate(`/warehouse-detail/${row.id}`)}>
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        {rows && rows?.length === 0 && (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell colSpan={7} align="center">ไม่พบข้อมูล</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch('0', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintWarehouse ref={printRef} title={'รายการวัสดุ'} data={rows} />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileWarehouses ref={exportRef} title={'รายการวัสดุ'} data={rows} />
      </Box>
    </Box>
  );
};

export default Warehouses;
