import { Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, Grid, IconButton, Link, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import authStore from "../../stores/AuthStore";
import productsStore from "../../stores/ProductsStore";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogConfirm from "../../components/DialogConfirm";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';




const ProductCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [productName, setProductName] = useState('');
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [unit, setUnit] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  /* NOTE - Keep it in consideration for future use
  const [productCategory, setProductCategory] = useState('');
  const [productGroup, setProductGroup] = useState('');
  const [productCategoryData, setProductCategoryData] = useState<any[]>([]);
  const [productGroupData, setProductGroupData] = useState<any[]>([]);*/
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: '',
    message: '',
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete'
  });
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/products');

  /* NOTE - Keep it in consideration for future use
  const handleChangeProductCategory = (event: SelectChangeEvent) => {
    setProductCategory(event.target.value as string);
  };
  const handleChangeProductGroup = (event: SelectChangeEvent) => {
    setProductGroup(event.target.value as string);
  };*/

  const handleSave = () => {
    if (!id) {
      setIsLoading(true);
      productsStore.create({
        //NOTE - Keep it in consideration for future use
        // productCategory: productCategory ? productCategory : null,
        // productGroup: productGroup ? productGroup : null,
        name: productName,
        size: size,
        color: color,
        unit: unit,
        description: description,
        createBy: authStore.user?.id,
        createAt: new Date()
      }).then((res: any) => {
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
        navigate(`/product-edit/${res.id}`);
      });
    }
  };
  const handleEdit = () => {
    if (id) {
      setIsLoading(true);
      productsStore.update(id, {
        //NOTE - Keep it in consideration for future use
        // productCategory: productCategory ? productCategory : null,
        // productGroup: productGroup ? productGroup : null,
        name: productName,
        size: size,
        color: color,
        unit: unit,
        description: description,
        updateBy: authStore.user?.id,
        updateAt: new Date()
      }).then((res) => {
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      });
    }
  };
  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const handleKeyDown = (event: any, index: number) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  useEffect(() => {
    /* NOTE - Keep it in consideration for future use
    productCategoriesStore.getAll().then((pcResult) => {
      setProductCategoryData(pcResult);
      productGroupsStore.getAll().then((pgResult) => {
        setProductGroupData(pgResult);
        setIsLoading(false);
      });
    });*/

    if (id) {
      setIsLoading(true);
      productsStore.get(id).then((res) => {
        if (res.error) {
          enqueueSnackbar('ไม่พบสินค้า', { variant: 'error' });
          setIsLoading(false);
        }
        /* NOTE - Keep it in consideration for future use
         setProductCategory(res.productCategory?.id);
         setProductGroup(res.productGroup?.id);*/
        setProductName(res.name);
        setSize(res.size);
        setColor(res.color);
        setUnit(res.unit);
        setDescription(res.description);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = () => {
    if (id) {
      setIsLoading(true);
      productsStore.update(id, {
        isActive: false,
        updateBy: authStore.user?.id,
        updateAt: new Date()
      }).then((res) => {
        setIsLoading(false);
        if (res.error) {
          enqueueSnackbar('ลบสินค้าไม่สำเร็จ', { variant: 'error' });
          return;
        }
        enqueueSnackbar('ลบสินค้าสำเร็จ', { variant: 'success' });
        navigate('/products');
      });
    }
  };

  return (
    <Box component={'div'}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton aria-label="delete" onClick={() => navigate('/products')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>การจัดการ</Typography>
            <Link component="button" onClick={() => navigate('/products')}>สินค้า</Link>
            <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={'flex'} justifyContent={'end'}>
          {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
              open: true,
              title: 'ลบข้อมูล',
              message: 'คุณต้องการลบสินค้านี้ใช่หรือไม่',
              color: 'error',
              type: 'delete'
            })}>ลบข้อมูล</Button>
          }
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {/*NOTE - Keep it in consideration for future use
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="productCategory-select-label">ประเภทสินค้า</InputLabel>
                                        <Select
                                            labelId="productCategory-select-label"
                                            id="productCategory-select"
                                            value={productCategory}
                                            label="ประเภทสินค้า"
                                            onChange={handleChangeProductCategory} >
                                            {productCategoryData.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} >
                                    <FormControl fullWidth>
                                        <InputLabel id="productGroup-select-label">หมวดหมู่สินค้า</InputLabel>
                                        <Select
                                            labelId="productGroup-select-label"
                                            id="productGroup-select"
                                            value={productGroup}
                                            label="หมวดหมู่สินค้า"
                                            onChange={handleChangeProductGroup} >
                                            {productGroupData.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>*/}
                <Grid item xs={12} >
                  <TextField id="outlined-basic" label="ชื่อสินค้า" variant="outlined" fullWidth
                    onChange={(event) => setProductName(event.target.value)} value={productName}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 0)} />
                </Grid>
                <Grid item xs={3} >
                  <TextField id="outlined-basic" label="ขนาด" variant="outlined" fullWidth
                    value={size}
                    onChange={(event) => setSize(event.target.value)}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 1)} />
                </Grid>
                <Grid item xs={3} >
                  <TextField id="outlined-basic" label="สี" variant="outlined" fullWidth
                    value={color}
                    onChange={(event) => setColor(event.target.value)}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 2)} />
                </Grid>
                <Grid item xs={3} >
                  <TextField id="outlined-basic" label="หน่วย" variant="outlined" fullWidth
                    value={unit}
                    onChange={(event) => setUnit(event.target.value)}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 3)} />
                </Grid>
                <Grid item xs={3} >
                  <TextField id="outlined-basic" label="รายละเอียด" variant="outlined" fullWidth multiline
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 4)} />
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12} >
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={4} >
                      <Stack spacing={2} direction="row">
                        <Button variant="outlined" fullWidth onClick={() => navigate('/products')}>ยกเลิก</Button>
                        {!id && <Button variant="contained" fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'บันทึกเอกสาร',
                            message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'create'
                          });
                        }}>บันทึก</Button>}
                        {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'แก้ไขเอกสาร',
                            message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'edit'
                          });
                        }}>แก้ไข</Button>}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'delete') {
            handleDelete();
          } else if (openConfirm.type === 'create') {
            handleSave();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          }
        }} />
    </Box>
  );
};

export default ProductCreate;
