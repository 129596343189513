import { Button, Card, CardContent, Divider, Grid, Stack, TextField, ButtonProps, Dialog, Autocomplete, FormControl, Typography, Box, IconButton } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import authStore from "../stores/AuthStore";
import workersStore from "../stores/WorkersStore";
import DialogConfirm from "./DialogConfirm";
import masterDataStore from "../stores/MasterDataStore";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisuallyHiddenInput from "./VisuallyHiddenInput";
import imagesStore from "../stores/ImagesStore";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
interface DialogCreatWorkerProps {
    open: boolean;
    name: string;
    onClose: () => void;
    onSave: (success: boolean) => void;
}
const DialogCreateWorker: React.FC<DialogCreatWorkerProps> = (props: DialogCreatWorkerProps) => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [tel, setTel] = useState('');
    const [note, setNote] = useState('');
    const [address, setAddress] = useState('');
    const [alley, setAlley] = useState('');
    const [street, setStreet] = useState('');
    const [moo, setMoo] = useState('');
    const [province, setProvince] = useState('');
    const [district, setDistrict] = useState('');
    const [subDistrict, setSubDistrict] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [subDistrictId, setSubDistrictId] = useState('');
    const [workerZipcode, setWorkerZipcode] = useState('');
    const [provinceData, setProvinceData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
    const [districtOptions, setDistrictOptions] = useState<any>([]);
    const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);
    const [banks, setBanks] = useState<any[]>([]);
    const [bank, setBank] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [openConfirm, setOpenConfirm] = useState({
        open: false,
        title: '',
        message: '',
        color: 'primary' as ButtonProps['color'],
        type: 'create' as 'create' | 'edit' | 'delete'
    });
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const handleSave = () => {
        workersStore.create({
            firstName: props.name ? props.name : firstName,
            lastName: lastName,
            tel: tel,
            note: note,
            address: address,
            alley: alley,
            street: street,
            moo: moo,
            district: districtId !== '' ? districtId : null,
            province: provinceId !== '' ? provinceId : null,
            subDistrict: subDistrictId !== '' ? subDistrictId : null,
            zipCode: workerZipcode,
            bank: bank !== '' ? bank : null,
            bankAccount: bankAccount,
            createBy: authStore.user?.id,
            createAt: new Date()
        }).then((res) => {
            if (res.error) {
                enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                return;
            }
            handleFileUpload(res?.id);
            enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
            props.onSave(true);

        });
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const newSelectedFiles = Array.from(files);
            setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
        }
    };

    const handleFileRemove = (index: number) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    const handleFileUpload = (refId: string) => {
        if (selectedFiles.length > 0) {
            selectedFiles.forEach(async (file: any, index: number) => {
                const formdata = new FormData();
                formdata.append("refType", "worker");
                formdata.append("refId", refId);
                formdata.append('file', file);
                formdata.append('createBy', authStore.user?.id);
                formdata.append("createAt", new Date(Date.now() + index * 1000).toISOString());

                await imagesStore.upload(formdata)
                    .catch(err => console.error("err", err));
            });
        }
    };

    useEffect(() => {
        setFirstName(props.name);
        Promise.all([
            masterDataStore.getAllProvince(),
            masterDataStore.getAllAmphur(),
            masterDataStore.getAllTambon(),
            workersStore.getBanksAll()
        ]).then((res: any) => {
            setProvinceData(res[0]);
            setDistrictData(res[1]);
            setSubDistrictData(res[2]);
            setBanks(res[3]);
        }).catch((err) => {
            console.error(err);
            enqueueSnackbar('เกิดข้อผิดพลาด', { variant: 'error' });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.name]);


    return (
        <Dialog open={props.open} fullWidth maxWidth={'md'}>

            <Grid container spacing={2} mt={1}>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4.5} >
                                    <TextField id="outlined-basic" label="ชื่อ" variant="outlined" fullWidth
                                        onChange={(event) => { setFirstName(event.target.value); }} value={firstName}
                                    />
                                </Grid>
                                <Grid item xs={4.5} >
                                    <TextField id="outlined-basic" label="นามสกุล" variant="outlined" fullWidth
                                        onChange={(event) => setLastName(event.target.value)} value={lastName}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="outlined-basic" label="เบอร์โทรศัพท์" variant="outlined" fullWidth
                                        onChange={(event) => setTel(event.target.value)} value={tel}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='ที่อยู่' variant='outlined' fullWidth value={address} onChange={(e) => setAddress(e.target.value)} />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='หมู่' variant='outlined' fullWidth multiline value={moo} onChange={(e) => setMoo(e.target.value)} />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='ตรอก/ซอย' variant='outlined' fullWidth value={alley} onChange={(e) => setAlley(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='ถนน' variant='outlined' fullWidth value={street} onChange={(e) => setStreet(e.target.value)} />
                                </Grid>
                                <Grid item xs={3} >
                                    <Autocomplete
                                        value={province}
                                        id='province'
                                        onChange={(e: any, value: any) => {
                                            if (value?.id) {
                                                setProvinceId(value?.id);
                                                setProvince(value?.nameTh);
                                                const _districtOptions = districtData.filter((district: any) => district?.province?.id === value?.id
                                                );
                                                setDistrictOptions(_districtOptions);
                                            }
                                        }}
                                        disablePortal
                                        options={provinceData}
                                        sx={{ width: '100%' }}
                                        renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                                        getOptionLabel={(option: any) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.nameTh;
                                        }}
                                        renderInput={(params: any) => <TextField {...params} label='จังหวัด' />}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <Autocomplete
                                        value={district}
                                        id='district'
                                        onChange={(e: any, value: any) => {
                                            setDistrictId(value);
                                            setDistrict(value?.nameTh);
                                            if (value?.id) {
                                                const _subDistrictOptions = subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                                                setSubDistrictOptions(_subDistrictOptions);
                                            }
                                        }}
                                        disablePortal
                                        options={districtOptions}
                                        sx={{ width: '100%' }}
                                        renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                                        getOptionLabel={(option: any) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.nameTh;
                                        }}
                                        renderInput={(params) => <TextField {...params} label='อำเภอ' />}
                                        disabled={(districtOptions.length > 0 || district !== '') ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <Autocomplete
                                        value={subDistrict ?? ''}
                                        id='district'
                                        onChange={(e: any, value: any) => {
                                            if (value?.id) {
                                                setSubDistrictId(value?.id);
                                                setSubDistrict(value?.nameTh);
                                            }
                                            if (value?.zipCode) { setWorkerZipcode(value?.zipCode); }
                                        }}
                                        disablePortal
                                        options={subDistrictOptions}
                                        sx={{ width: '100%' }}
                                        renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                                        getOptionLabel={(option: any) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.nameTh;
                                        }}
                                        renderInput={(params) => <TextField {...params} label='ตำบล' />}
                                        disabled={(subDistrictOptions.length > 0 || subDistrict !== '') ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField label='รหัสไปรษณีย์' variant='outlined' fullWidth value={workerZipcode} onChange={(e) => setWorkerZipcode(e.target.value)} />
                                </Grid>
                                <Grid item xs={5} >
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="bank-select"
                                            options={banks} //เก็บข้อมูลธนาคารทั้งหมด
                                            getOptionLabel={(option: any) => {
                                                if (option.nameTh) { return option.nameTh; }
                                                else { return ''; }
                                            }} //เลือกวิธีดึงข้อมูลโดยดึงข้อมูลของ
                                            value={!bank ? '' : bank} //หาค่าที่ให้ตรงกับ id
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setBank(newValue);
                                                } else {
                                                    setBank('all');
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="ธนาคาร" />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={7} >
                                    <TextField id="outlined-basic" label="เลขที่บัญชี" variant="outlined" fullWidth
                                        onChange={(event) => setBankAccount(event.target.value)} value={bankAccount}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField id="outlined-basic" label="หมายเหตุ" variant="outlined" fullWidth multiline
                                        value={note}
                                        onChange={(event) => setNote(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>
                                <Grid item xs={7} >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography>บัตรประจำตัวประชาชน</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                                                เลือกไฟล์
                                                <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                                                    onChange={handleFileChange}
                                                />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {selectedFiles.length > 0 && (
                                                <Box component={'div'} mt={1}>
                                                    {selectedFiles.map((file: any, index: number) => (
                                                        <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                                                            <Grid container >
                                                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                                    <IconButton size="small" color="error"
                                                                        onClick={() => handleFileRemove(index)}>
                                                                        <RemoveCircleIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={11.5}>
                                                                    <img
                                                                        src={URL.createObjectURL(file)}
                                                                        alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                                                        width="500"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Box>
                                            )}
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={4} >
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={4} >
                                            <Stack spacing={2} direction="row">
                                                <Button variant="outlined" fullWidth onClick={() => props.onClose()}>ยกเลิก</Button>
                                                <Button variant="contained" fullWidth onClick={() => setOpenConfirm({
                                                    open: true,
                                                    title: id ? 'แก้ไขข้อมูล' : 'บันทึกข้อมูล',
                                                    message: id ? 'คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่' : 'คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่',
                                                    color: 'primary',
                                                    type: 'create'
                                                })}>{id ? 'แก้ไข' : 'บันทึก'}</Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === 'create') {
                        handleSave();
                    }
                }} />
        </Dialog>
    );
};

export default DialogCreateWorker;