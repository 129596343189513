import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class HousesStore {
  houses: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/houses");
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting houses:", error);
      return {
        error: true,
        message: "An error occurred while getting houses",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/houses/${id}`);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting house:", error);
      return {
        error: true,
        message: "An error occurred while getting house",
      };
    }
  }

  async getByName(name: string) {
    try {
      const response = await apiService.get(`/houses/name/${name}`);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting house:", error);
      return {
        error: true,
        message: "An error occurred while getting house",
      };
    }
  }

  async getByNameLike(name: string) {
    try {
      const response = await apiService.get(`/houses/name/like/${name}`);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting house:", error);
      return {
        error: true,
        message: "An error occurred while getting house",
      };
    }
  }


  async getByProjectId(id: string) {
    try {
      const response = await apiService.get(`/houses/project/${id}`);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting house:", error);
      return {
        error: true,
        message: "An error occurred while getting house",
      };
    }
  }
  async getCostDashboardByProjectId(id: string) {
    try {
      const response = await apiService.get(`/houses/dashboard/${id}`);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting cost dashboard:", error);
      return {
        error: true,
        message: "An error occurred while cost dashboard",
      };
    }
  }

  async getByProjectIdAndNameLike(id: string, name: string) {
    try {
      const response = await apiService.get(
        `/houses/projectname/${id}/${name}`
      );
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting house:", error);
      return {
        error: true,
        message: "An error occurred while getting house",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/houses/criteria", criteria);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting houses:", error);
      return {
        error: true,
        message: "An error occurred while getting houses",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/houses", data);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating house:", error);
      return {
        error: true,
        message: "An error occurred while creating house",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/houses/${id}`, data);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating house:", error);
      return {
        error: true,
        message: "An error occurred while updating house",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/houses/${id}`);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting house:", error);
      return {
        error: true,
        message: "An error occurred while deleting house",
      };
    }
  }

  async dashboard(data: any) {
    try {
      const response = await apiService.post("/houses/dashboard", data);
      this.houses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting houses:", error);
      return {
        error: true,
        message: "An error occurred while getting houses",
      };
    }
  }
}
const housesStore = new HousesStore();
export default housesStore;
