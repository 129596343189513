/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Grid,
  Link,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
  Autocomplete,
  Divider,
  TableRow,
  TableCell,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DialogConfirm from "../../components/DialogConfirm";
import { useState, ChangeEvent, useEffect, useRef } from "react";
import "dayjs/locale/th";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
import { v4 as uuid4 } from "uuid";
import workersStore from "../../stores/WorkersStore";
import DialogCreateWorker from "../../components/DialogCreateWorker";
import { enqueueSnackbar } from "notistack";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import wagesStore from "../../stores/WagesStore";
import authStore from "../../stores/AuthStore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import imagesStore from "../../stores/ImagesStore";
import productCategoriesStore from "../../stores/ProductCategoriesStore";
import productGroupsStore from "../../stores/ProductGroupsStore";

interface WorkerOptionType {
  inputValue?: string;
  name: string;
}
const filterWorker = createFilterOptions<WorkerOptionType>();
const WageCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [housesList, setHousesList] = useState<any[]>([]);
  const [workersList, setWorkersList] = useState<any[]>([]);
  const [projectValue, setProjectValue] = useState("");
  const [houseValue, setHouseValue] = useState("");
  const [total, setTotal] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [withdraw, setWithdraw] = useState(0);
  const [workerValue, setWorkerValue] = useState<WorkerOptionType[]>([]);
  const [wageTypes, setWageType] = useState("wageWorker");
  const [items, setItems] = useState<any[]>(id ? [] : [{ id: uuid4() }]);
  const [itemsOld, setItemsOld] = useState<any[]>([]);
  const [house, setHouse] = useState<any[]>([]);
  const [openAddWorkerDialog, setOpenAddWorkerDialog] = useState(false);
  const [dialogCreateWorkerValue, setDialogCreateWorkerValue] = useState("");
  const [project, setProject] = useState<any[]>([]);
  const [periodDateFrom, setPeriodDateFrom] = useState<Dayjs | null>(
    dayjs().startOf("month")
  );
  const [periodDateTo, setPeriodDateTo] = useState<Dayjs | null>(
    dayjs().startOf("month").add(14, "day")
  );
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
    setWageType((event.target as HTMLInputElement).value);
  };
  const permission: any = authStore.user?.roles.find(
    (role: any) => role.menu.path === "/wages"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState("");
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [itemLength, setItemLength] = useState(0);
  const [categoriesList, setCategoriesList] = useState<any[]>([]);
  const [groupsList, setGroupsList] = useState<any[]>([]);
  const [rangeDate, setRangeDate] = useState("range");
  const [week, setWeek] = useState(1);
  const [pickMonth, setPickMonth] = useState<any>(dayjs().month());
  const [pickYear, setPickYear] = useState<any>(dayjs().year());
  const [pickMonthYear, setPickMonthYear] = useState<Dayjs | null>(dayjs());
  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
    setItemLength(inputRefs.current.length - 1);
  };

  const handleKeyDown = (event: any, index: number, i = 0) => {
    const currentInput = event.target;
    if (event.key === "Enter") {
      const currentIndex = Array.from(inputRefs.current).indexOf(currentInput);
      if (!id) {
        if (i === -1) {
          inputRefs.current[13].focus();
        } else if (index === 12 && i === 0) {
          if (inputRefs.current[14]) {
            inputRefs.current[14].focus();
          } else {
            const nextIndex = currentIndex + 1;
            inputRefs.current[nextIndex].focus();
          }
        } else if (currentIndex === itemLength) {
          inputRefs.current[13].focus();
        } else {
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputRefs.current.length) {
            inputRefs.current[nextIndex].focus();
            event.preventDefault();
          }
        }
      } else {
        if (i === -1) {
          inputRefs.current[2].focus();
        } else if (index === 1 && i === 0) {
          inputRefs.current[3].focus();
        } else {
          if (currentIndex === itemLength) {
            inputRefs.current[2].focus();
          } else {
            const nextIndex = currentIndex + 1;
            inputRefs.current[nextIndex].focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      wagesStore.get(id).then((res: any) => {
        const wageDetails = Array.isArray(res.wageDetails)
          ? res.wageDetails.sort((a: any, b: any) => {
              return dayjs(a.createAt).isBefore(dayjs(b.createAt)) ? -1 : 1;
            })
          : [];
        setRangeDate("custom");
        setItems(wageDetails || []);
        setItemsOld(wageDetails || []);
        setProject(res.project?.name || "");
        setProjectValue(res.project?.id || "");
        setNote(res.note || "");
        setImages(res.images);
        setHouse(res.house?.name || "");
        setHouseValue(res.house?.id || "");
        setPeriodDateFrom(dayjs(res.periodDateFrom));
        setPeriodDateTo(dayjs(res.periodDateTo));
        setTotal(parseFloat(res.total) || 0);
        setWithdraw(parseFloat(res.withdraw) || 0);
        setNetTotal(parseFloat(res.netTotal) || 0);
        const day = dayjs(res.periodDateFrom).date();
        if (day === 1) {
          setWeek(1);
        } else {
          setWeek(2);
        }
        setIsLoading(false);
      });
    }
    Promise.all([
      projectsStore.getAll(),
      housesStore.getAll(),
      workersStore.getAll(),
      productCategoriesStore.getAll(),
      productGroupsStore.getAll(),
    ]).then((res) => {
      setProjectsList(res[0]);
      setHousesList(res[1]);
      setWorkersList(res[2]);
      setCategoriesList(res[3]);
      setGroupsList(res[4]);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (!id) {
        const dataDetail = items.map((item: any, index: number) => {
          return {
            ...item,
            createBy: authStore.user?.id,
            createAt: new Date(Date.now() + index * 1000),
          };
        });
        await wagesStore
          .create({
            periodDateFrom: periodDateFrom,
            periodDateTo: periodDateTo,
            total: total,
            withdraw: withdraw,
            netTotal: netTotal,
            note: note,
            createBy: authStore.user?.id,
            createAt: new Date(),
            detail: dataDetail,
          })
          .then((res: any) => {
            if (res.error) {
              setIsLoading(false);
              throw res.error;
            }
            handleFileUpload(res.id);
            navigate(`/wage-edit/${res.id}`);
          });
        setIsLoading(false);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = async () => {
    try {
      if (id) {
        const newItems = items.filter(
          (item) => !itemsOld.some((itemOld) => itemOld.id === item.id)
        );
        const updateItems = itemsOld.filter((itemOld: any) => {
          return items.find((item: any) => itemOld.id === item.id);
        });
        const deleteItems = itemsOld.filter((itemOld: any) => {
          return !items.find((item: any) => itemOld.id === item.id);
        });

        handleEditFileUpload(id);

        await wagesStore
          .update(id, {
            periodDateFrom: periodDateFrom,
            periodDateTo: periodDateTo,
            project: projectValue || null,
            house: houseValue || null,
            total: total,
            withdraw: withdraw,
            netTotal: netTotal,
            note: note,
            updateBy: authStore.user?.id,
            updateAt: new Date(),
            detail: updateItems,
          })
          .then((res: any) => {
            if (res.error) {
              setIsLoading(false);
              throw res.error;
            }
          });

        const wageDetail = newItems.map((item: any, index: number) => {
          return {
            ...item,
            wage: id,
            createBy: authStore.user?.id,
            createAt: new Date(Date.now() + index * 1000),
            updateBy: authStore.user?.id,
            updateAt: new Date(Date.now() + index * 1000),
          };
        });

        if (wageDetail && wageDetail?.length > 0) {
          await wagesStore.createDetail(wageDetail).then((res: any) => {
            if (res.error) {
              setIsLoading(false);
              throw res.error;
            }
          });
        }

        if (deleteItems.length > 0) {
          await deleteItems.forEach((item: any) => {
            wagesStore.deleteDetail(item.id).then((res: any) => {
              if (res.error) {
                setIsLoading(false);
                throw res.error;
              }
            });
          });
        }
        setIsLoading(false);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };
  const handleDelete = async () => {
    setIsLoading(true);
    if (id) {
      try {
        const itemDetail = await items.map((item: any, index: number) => {
          return {
            ...item,
            isDeleted: true,
            updateBy: authStore.user?.id,
            updateAt: new Date(),
          };
        });
        await wagesStore.update(id, {
          isDeleted: true,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          detail: itemDetail,
        });
        enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
        setIsLoading(false);
        navigate("/wages");
      } catch (err) {
        setIsLoading(false);
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
        console.error(err);
      }
    }
  };
  const handleAddItem = () => {
    setItems([...items, { id: uuid4() }]);
  };
  const handleRemoveItem = (id: string) => {
    setItems(items.filter((item: any) => item.id !== id));
  };

  const calculate = (index: number, key: string, value: any) => {
    const newItems = [...items];

    newItems[index][key] = parseFloat(value);
    setItems(newItems);
    if (key === "workDay" || key === "laborCost") {
      const sumLabor =
        parseFloat(value || 0) *
        parseFloat(
          key === "workDay"
            ? newItems[index].laborCost
            : newItems[index].workDay
        );
      newItems[index].totalLaborCost = sumLabor;
      const labour = newItems[index].laborCost || 0;
      const otRate = newItems[index].otPerHour || 0;
      const hour = newItems[index].hourOt || 0;
      const sumPerhour = (labour / 8) * otRate;
      const sum = sumPerhour * hour;
      newItems[index].costOt = sum;
    }
    if (key === "hourOt" || key === "otPerHour") {
      const labour = newItems[index].laborCost || 0;
      const otRate = newItems[index].otPerHour || 0;
      const hour = newItems[index].hourOt || 0;
      const sumPerhour = (labour / 8) * otRate;
      const sum = sumPerhour * hour;
      newItems[index].costOt = sum;
    }
    const sumLaborAndOt =
      key !== "totalLaborAndCostOt"
        ? parseFloat(newItems[index].totalLaborCost || 0) +
          parseFloat(newItems[index].costOt || 0)
        : parseFloat(value || 0);
    newItems[index].totalLaborAndCostOt = sumLaborAndOt;
    const sumNet =
      key !== "totalNet"
        ? parseFloat(newItems[index].totalLaborAndCostOt || 0) -
          parseFloat(newItems[index].withdraw || 0)
        : parseFloat(value || 0);
    newItems[index].totalNet = sumNet;
    calculateSummary();
  };
  const calculateSummary = () => {
    const totalLaborCost = items.reduce(
      (a: any, b: any) => a + parseFloat(b?.totalLaborAndCostOt || 0),
      0
    );
    setTotal(parseFloat(totalLaborCost));
    const withdraw = items.reduce(
      (a: any, b: any) => a + parseFloat(b?.withdraw || 0),
      0
    );
    setWithdraw(withdraw);
    const totalNet = items.reduce(
      (a: any, b: any) => a + parseFloat(b?.totalNet || 0),
      0
    );
    setNetTotal(parseFloat(totalNet));
  };
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    }
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleFileUpload = (refId: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "bill");
        formdata.append("refId", refId);
        formdata.append("file", file);
        formdata.append("createBy", authStore.user?.id);
        formdata.append(
          "createAt",
          new Date(Date.now() + index * 1000).toISOString()
        );

        await imagesStore
          .upload(formdata)
          .catch((err) => console.error("err", err));
      });
    }
  };

  const handleEditFileUpload = async (id: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "bill");
        formdata.append("refId", id);
        formdata.append("file", file);
        formdata.append("updateBy", authStore.user?.id);
        formdata.append(
          "updateAt",
          new Date(Date.now() + index * 1000).toISOString()
        );
        await imagesStore
          .upload(formdata)
          .catch((err) => console.error("err", err));
      });
    }
    if (deleteImages.length > 0) {
      await deleteImages.forEach(async (item: any) => {
        await imagesStore
          .update(item.id, {
            updateBy: authStore.user?.id,
            updateAt: new Date(),
            isDeleted: true,
          })
          .catch((err) => console.error("err deleteImages", err));
      });
    }
  };

  const handleChangeRangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    setRangeDate(e.target.value);
  };

  const handleChangeWeek = (e: ChangeEvent<HTMLInputElement>) => {
    setWeek(Number(e.target.value));
    if (Number(e.target.value) === 1) {
      setPeriodDateFrom(
        dayjs().set("month", pickMonth).set("year", pickYear).startOf("month")
      );
      setPeriodDateTo(
        dayjs()
          .set("month", pickMonth)
          .set("year", pickYear)
          .startOf("month")
          .add(14, "day")
      );
    } else {
      setPeriodDateFrom(
        dayjs()
          .set("month", pickMonth)
          .set("year", pickYear)
          .startOf("month")
          .add(15, "day")
      );
      setPeriodDateTo(
        dayjs()
          .set("month", pickMonth)
          .set("year", pickYear)
          .endOf("month")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
    }
  };

  const handleChangeMonthYear = (value?: any) => {
    const _month = value.$M;
    const _year = value.$y;
    // Set pickMonth and pickYear states
    setPickMonth(_month);
    setPickYear(_year);
    if (week === 1) {
      setPeriodDateFrom(
        dayjs().startOf("month").set("month", _month).set("year", _year)
      );
      setPeriodDateTo(
        dayjs()
          .startOf("month")
          .add(14, "day")
          .set("month", _month)
          .set("year", _year)
      );
    } else {
      setPeriodDateFrom(
        dayjs()
          .startOf("month")
          .add(15, "day")
          .set("month", _month)
          .set("year", _year)
      );
      setPeriodDateTo(
        dayjs()
          .endOf("month")
          .set("month", _month)
          .set("year", _year)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
    }
  };

  useEffect(() => {
    calculateSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton aria-label="delete" onClick={() => navigate("/wages")}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>บันทึกค่าใช้จ่าย</Typography>
            <Link component="button" onClick={() => navigate("/wages")}>
              ค่าแรง
            </Link>
            <Typography variant="h6" color="text.primary">
              {id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {((permission && permission.delete) ||
            authStore.user?.isSuperAdmin) &&
            id && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() =>
                  setOpenConfirm({
                    open: true,
                    title: "ลบเอกสาร",
                    message: "คุณต้องการลบเอกสารนี้ใช่หรือไม่",
                    color: "error",
                    type: "delete",
                  })
                }
              >
                ลบเอกสาร
              </Button>
            )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl>
                    <FormLabel
                      id="row-radio-buttons-group-label"
                      sx={{ color: "black" }}
                    >
                      ประเภทค่าแรง
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={wageTypes}
                      onChange={handleChangeDateType}
                    >
                      <FormControlLabel
                        value="wageWorker"
                        control={<Radio />}
                        label="ค่าแรงคนงาน"
                      />
                      {/* TODO:Please completly this feature*/}
                      {/* <FormControlLabel value="wageContractor" control={<Radio />} label="ค่าแรงผู้รับเหมา" hidden /> */}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel
                      id="row-radio-buttons-group-label"
                      sx={{ color: "black" }}
                    >
                      งวดประจำวันที่
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={rangeDate}
                      onChange={handleChangeRangeDate}
                    >
                      <FormControlLabel
                        value="range"
                        control={<Radio />}
                        label="วันที่แบบงวด"
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label="วันที่ปรับแต่ง"
                        hidden
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {rangeDate === "range" && (
                  <>
                    <Grid item xs={1.5} display={"flex"} alignItems={"end"}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          งวดที่
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={week}
                          label="งวด"
                          onChange={(e: any) => handleChangeWeek(e)}
                        >
                          <MenuItem value={1}>Week 1</MenuItem>
                          <MenuItem value={2}>Week 2</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"th"}
                      >
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            views={["month", "year"]}
                            label="เดือนและปี"
                            value={pickMonthYear}
                            onChange={(newValue: any) =>
                              handleChangeMonthYear(newValue)
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </>
                )}
                {rangeDate === "custom" && (
                  <Grid item xs={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"th"}
                    >
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          label="ตั้งแต่วันที่"
                          value={periodDateFrom}
                          onChange={(newValue) => setPeriodDateFrom(newValue)}
                        />
                        <DatePicker
                          label="ถึงวันที่"
                          value={periodDateTo}
                          onChange={(newValue) => setPeriodDateTo(newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography>รายการชื่อคนงาน</Typography>
                </Grid>
                {items.map((item: any, index: number) => {
                  return (
                    <Grid item xs={12} key={item.id} mb={1}>
                      <Grid
                        container
                        spacing={0.5}
                        justifyContent={"end"}
                        alignItems={"center"}
                        display={"flex"}
                      >
                        <Grid
                          item
                          xs={0.7}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Grid
                            item
                            xs={7}
                            justifyContent={"center"}
                            alignItems={"center"}
                            display={"flex"}
                          >
                            <IconButton
                              aria-label={`delete-${item.id}`}
                              size="small"
                              color="error"
                              onClick={() => handleRemoveItem(items[index].id)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            justifyContent={"center"}
                            alignItems={"center"}
                            display={"flex"}
                          >
                            <Typography>{index + 1}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={11.3}>
                          <Grid
                            container
                            spacing={0.5}
                            justifyContent={"end"}
                            alignItems={"center"}
                          >
                            <Grid item xs={3.6}>
                              <Autocomplete
                                fullWidth
                                size="small"
                                disablePortal
                                value={item.project}
                                options={projectsList}
                                sx={{ width: "100%" }}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.name}</li>
                                )}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option || "";
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue || "";
                                  }
                                  return option.name || "";
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="โครงการ"
                                    inputRef={addRef}
                                    onKeyDown={(e) => {
                                      handleKeyDown(e, 0);
                                    }}
                                  />
                                )}
                                onChange={(e, value) => {
                                  if (value?.id) {
                                    housesStore
                                      .getByProjectId(value?.id)
                                      .then((res) => {
                                        setHousesList(res);
                                      });
                                  } else {
                                    if (housesList.length === 0) {
                                      housesStore.getAll().then((res) => {
                                        setHousesList(res);
                                      });
                                    }
                                  }
                                  const newItems = [...items];
                                  newItems[index].project = value;
                                  setItems(newItems);
                                }}
                              />
                            </Grid>
                            <Grid item xs={4.2}>
                              <Autocomplete
                                fullWidth
                                size="small"
                                value={item.house}
                                disablePortal
                                options={housesList}
                                sx={{ width: "100%" }}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.name}</li>
                                )}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option || "";
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue || "";
                                  }
                                  return option.name || "";
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="บ้าน"
                                    inputRef={addRef}
                                    onKeyDown={(e) => handleKeyDown(e, 1)}
                                  />
                                )}
                                onChange={(e, value) => {
                                  const newItems = [...items];
                                  newItems[index].house = value;
                                  setItems(newItems);
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.1}>
                              <Autocomplete
                                fullWidth
                                size="small"
                                value={item.productCategory}
                                disablePortal
                                options={categoriesList}
                                sx={{ width: "100%" }}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    {option.isCommonFee ? (
                                      <b>[ส่วนกลาง]</b>
                                    ) : null}
                                    &nbsp;{option.name}
                                  </li>
                                )}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option || "";
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue || "";
                                  }
                                  return option.name || "";
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="ประเภท"
                                    inputRef={addRef}
                                    onKeyDown={(e) => handleKeyDown(e, 1)}
                                  />
                                )}
                                onChange={(e, value) => {
                                  const newItems = [...items];
                                  newItems[index].productCategory = value;
                                  setItems(newItems);
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.1}>
                              <Autocomplete
                                fullWidth
                                size="small"
                                value={item.productGroup}
                                disablePortal
                                options={groupsList}
                                sx={{ width: "100%" }}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    {option.isCommonFee ? (
                                      <b>[ส่วนกลาง]</b>
                                    ) : null}
                                    &nbsp;{option.name}
                                  </li>
                                )}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option || "";
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue || "";
                                  }
                                  return option.name || "";
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="หมวดหมู่"
                                    inputRef={addRef}
                                    onKeyDown={(e) => handleKeyDown(e, 1)}
                                  />
                                )}
                                onChange={(e, value) => {
                                  const newItems = [...items];
                                  newItems[index].productGroup = value;
                                  setItems(newItems);
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={3.6}>
                              <Autocomplete
                                value={
                                  workerValue[index]?.name ||
                                  item.worker?.name ||
                                  item?.name ||
                                  `${
                                    (item.worker?.firstName || "") +
                                    " " +
                                    (item.worker?.lastName || "")
                                  }`
                                }
                                disablePortal
                                sx={{ width: "100%" }}
                                onChange={async (event, newValue) => {
                                  if (typeof newValue === "string") {
                                    setTimeout(() => {
                                      setOpenAddWorkerDialog(true);
                                      setDialogCreateWorkerValue(newValue);
                                    });
                                  } else if (newValue && newValue.inputValue) {
                                    setOpenAddWorkerDialog(true);
                                    setDialogCreateWorkerValue(
                                      newValue.inputValue
                                    );
                                  } else {
                                    setWorkerValue({
                                      ...workerValue,
                                      [index]: newValue,
                                    });
                                    const newItems = [...items];
                                    newItems[index].worker = newValue?.id;
                                    newItems[index].name = newValue?.name;
                                    newItems[index].laborCost = 0;
                                    if (newValue?.id) {
                                      const _laborCost: any =
                                        await wagesStore.getLastestLaborCost(
                                          newValue?.id
                                        );
                                      if (_laborCost) {
                                        newItems[index].laborCost =
                                          _laborCost.laborCost;
                                      }
                                    }

                                    setItems(newItems);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filterWorker(
                                    options,
                                    params
                                  );
                                  if (params.inputValue !== "") {
                                    filtered.push({
                                      inputValue: params.inputValue,
                                      name: `สร้าง "${params.inputValue}"`,
                                    });
                                  }
                                  return filtered;
                                }}
                                id={`item-${index}-worker-autocomplete`}
                                options={workersList}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  return option.name;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                renderOption={(props, option) => (
                                  <li {...props}>{option.name}</li>
                                )}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="ชื่อคนงาน"
                                    variant="outlined"
                                    inputRef={addRef}
                                    onKeyDown={(e) => {
                                      handleKeyDown(e, 2 + index, index);
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.4}>
                              <TextField
                                label="จำนวนวัน"
                                inputRef={addRef}
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 3 + index, index);
                                }}
                                id={`item-${index}-workDay`}
                                type="number"
                                variant="outlined"
                                value={
                                  item.workDay ? parseFloat(item.workDay) : ""
                                }
                                onChange={(e) => {
                                  calculate(index, "workDay", e.target.value);
                                }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={1.4}>
                              <TextField
                                label="ค่าแรง"
                                fullWidth
                                inputRef={addRef}
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 4 + index, index);
                                }}
                                id={`item-${index}-laborCost`}
                                variant="outlined"
                                type="number"
                                value={
                                  item.laborCost
                                    ? parseFloat(item.laborCost)
                                    : ""
                                }
                                onChange={(e) => {
                                  calculate(index, "laborCost", e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1.4}>
                              <TextField
                                inputRef={addRef}
                                label="รวมค่าแรง"
                                fullWidth
                                variant="outlined"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 5 + index, index);
                                }}
                                id={`item-${index}-totalLaborCost`}
                                value={
                                  item.totalLaborCost
                                    ? parseFloat(item.totalLaborCost)
                                    : ""
                                }
                                onChange={(e) => {
                                  calculate(
                                    index,
                                    "totalLaborCost",
                                    e.target.value
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={1.4}>
                              <TextField
                                inputRef={addRef}
                                label="ชั่วโมง OT"
                                fullWidth
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 6 + index, index);
                                }}
                                id={`item-${index}-hourOt`}
                                variant="outlined"
                                type="number"
                                value={
                                  item.hourOt ? parseFloat(item.hourOt) : ""
                                }
                                onChange={(e) => {
                                  calculate(index, "hourOt", e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1.4}>
                              <TextField
                                inputRef={addRef}
                                label="ค่า OT (เท่า)"
                                fullWidth
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 7 + index, index);
                                }}
                                id={`item-${index}-otPerHour`}
                                variant="outlined"
                                type="number"
                                value={
                                  item.otPerHour
                                    ? parseFloat(item.otPerHour)
                                    : ""
                                }
                                onChange={(e) => {
                                  calculate(index, "otPerHour", e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1.4}>
                              <TextField
                                inputRef={addRef}
                                label="จำนวนเงิน OT"
                                fullWidth
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 8 + index, index);
                                }}
                                id={`item-${index}-costOt`}
                                variant="outlined"
                                type="number"
                                value={
                                  item.costOt ? parseFloat(item.costOt) : ""
                                }
                                onChange={(e) => {
                                  calculate(index, "costOt", e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1.4} mt={0.7}>
                              <TextField
                                inputRef={addRef}
                                label="รวมค่าแรง + OT"
                                fullWidth
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 9 + index, index);
                                }}
                                id={`item-${index}-totalLaborAndCostOt`}
                                variant="outlined"
                                type="number"
                                value={
                                  item.totalLaborAndCostOt
                                    ? parseFloat(item.totalLaborAndCostOt)
                                    : ""
                                }
                                onChange={(e) => {
                                  calculate(
                                    index,
                                    "totalLaborAndCostOt",
                                    e.target.value
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={1.4} mt={0.7}>
                              <TextField
                                inputRef={addRef}
                                label="เบิก"
                                type="number"
                                fullWidth
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 10 + index, index);
                                }}
                                id={`item-${index}-withdraw`}
                                variant="outlined"
                                value={
                                  item.withdraw ? parseFloat(item.withdraw) : ""
                                }
                                onChange={(e) => {
                                  calculate(index, "withdraw", e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1.4} mt={0.7}>
                              <TextField
                                inputRef={addRef}
                                label="คงเหลือ"
                                fullWidth
                                inputProps={{
                                  min: 0,
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 11 + index, index);
                                }}
                                id={`item-${index}-total`}
                                type="number"
                                variant="outlined"
                                value={
                                  item.totalNet ? parseFloat(item.totalNet) : ""
                                }
                                onChange={(e) => {
                                  calculate(index, "totalNet", e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={4.2} mt={0.7}>
                              <TextField
                                fullWidth
                                label="รายละเอียดการทำงาน"
                                id={`item-${index}-note`}
                                variant="outlined"
                                value={item.note ? item.note : ""}
                                onChange={(e) => {
                                  const newItems = [...items];
                                  newItems[index].note = e.target.value;
                                  setItems(newItems);
                                }}
                                inputRef={addRef}
                                onKeyDown={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                  handleKeyDown(e, 12 + index, index);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                {items.length === 0 && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell colSpan={10} align="center">
                      ไม่พบข้อมูล
                    </TableCell>
                  </TableRow>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onClick={handleAddItem}
                    startIcon={<AddCircleIcon />}
                  >
                    เพิ่มรายการ
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>เอกสาร</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<UploadFileIcon />}
                      >
                        เลือกไฟล์
                        <VisuallyHiddenInput
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          onChange={handleFileChange}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        รองรับไฟล์นามสกุล .jpg, .jpeg, .png
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedFiles.length > 0 && (
                        <Box component={"div"} mt={1}>
                          {selectedFiles.map((file: any, index: number) => (
                            <Grid
                              item
                              xs={12}
                              key={`sf-${index.toString()}`}
                              mt={1}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={0.5}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  display={"flex"}
                                >
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => handleFileRemove(index)}
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {images && (
                        <Box component={"div"} mt={1}>
                          {images.map((file: any, index: number) => (
                            <Grid
                              item
                              xs={12}
                              key={`im-${index.toString()}`}
                              mt={1}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={0.5}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  display={"flex"}
                                >
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleFileRemoveImageList(file)
                                    }
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={`${apiUrl}/uploads/${file.name}`}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>รวมเป็นเงิน</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                      <Typography>
                        {parseFloat(total.toString()).toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>เบิก</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                      <Typography>
                        {parseFloat(withdraw.toString()).toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>คงเหลือ</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                      <Typography>
                        {parseFloat(netTotal.toString()).toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="หมายเหตุ"
                        value={note}
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                        multiline
                        maxRows={4}
                        fullWidth
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          handleKeyDown(e, itemLength, -1);
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => navigate("/wages")}
                      >
                        ยกเลิก
                      </Button>
                      {!id && (
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            setOpenConfirm({
                              open: true,
                              title: "บันทึกข้อมูล",
                              message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
                              color: "primary",
                              type: "create",
                            });
                          }}
                        >
                          บันทึก
                        </Button>
                      )}
                      {((permission && permission.edit) ||
                        authStore.user?.isSuperAdmin) &&
                        id && (
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              setOpenConfirm({
                                open: true,
                                title: "แก้ไขข้อมูล",
                                message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
                                color: "primary",
                                type: "edit",
                              });
                            }}
                          >
                            แก้ไข
                          </Button>
                        )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogCreateWorker
        name={dialogCreateWorkerValue}
        open={openAddWorkerDialog}
        onClose={() => {
          setOpenAddWorkerDialog(false);
        }}
        onSave={(success) => {
          if (success) {
            workersStore.getAll().then((res) => {
              setWorkersList(res);
            });
          }
          setOpenAddWorkerDialog(false);
        }}
      />
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};
export default WageCreate;
