import { Box, Breadcrumbs, Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import productGroupsStore from "../../stores/ProductGroupsStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import ReactToPrint from "react-to-print";
import PrintSettingProductGroups from "../../components/Report/Pdf/PrintSettingProductGroups";
import { DownloadTableExcel } from "react-export-table-to-excel";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExportFileSettingProductGroups from "../../components/Report/Excel/ExportFileSettingProductGroups";
import authStore from "../../stores/AuthStore";

const ProductGroups: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [productGroupName, setProductGroupName] = useState('');
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/product-groups');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState<any[]>([]);
    const printRef = useRef(null);
    const exportRef = useRef(null);
    const [isCommonFee, setIsCommonFee] = useState(false);

    const handleSearch = (pageNumber?: string, limitNumber?: string) => {
        setIsLoading(true);
        const _page = parseInt(pageNumber || '0');
        const _limit = parseInt(limitNumber || '0');
        productGroupsStore.getByCriteria({
            name: productGroupName ? productGroupName : "",
            page: _page,
            limit: _limit,
            isCommonFee: isCommonFee
        }).then((result) => {
            setRows(result.data);
            setTotal(result.total);

            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    };

    const handleIsCommonFee = () => {
        setIsCommonFee(!isCommonFee);
    };

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box component={'div'}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography>การจัดการ</Typography>
                <Typography variant="h6" color="text.primary">หมวดหมู่สินค้า</Typography>
            </Breadcrumbs>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} alignItems={'end'}>
                                <Grid item xs={9.5} >
                                    <TextField id="outlined-basic" label="ชื่อหมวดหมู่สินค้า" variant="outlined" fullWidth
                                        onChange={(event) => setProductGroupName(event.target.value)} value={productGroupName}
                                    />
                                </Grid>
                                <Grid item xs={1.5}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox onChange={handleIsCommonFee} />} label="ส่วนกลาง" />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={1} textAlign={'end'} pb={0.5} >
                                    <Button variant="contained" onClick={() => handleSearch()} startIcon={<SearchIcon />}>ค้นหา</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent={'end'}>
                                        <Grid item xs={9}>
                                            <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <DownloadTableExcel
                                                filename={`product-groups-${dayjs().format('DD-MM-YYYY')}`}
                                                sheet={`product-groups-${dayjs().format('DD-MM-YYYY')}`}
                                                currentTableRef={exportRef.current}
                                            >
                                                <IconButton aria-label="download"  >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </DownloadTableExcel>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <ReactToPrint
                                                trigger={() => {
                                                    return (
                                                        <IconButton aria-label="print">
                                                            <PrintIcon />
                                                        </IconButton>
                                                    );
                                                }}
                                                content={() => {
                                                    return printRef.current;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/product-group-create')}>สร้าง</Button>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ชื่อหมวดหมู่สินค้า</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows && rows?.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.name}</TableCell>
                                                        <TableCell>{row.isCommonFee ? 'ส่วนกลาง' : ''}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton aria-label="edit" onClick={() => navigate(`/product-group-edit/${row.id}`)}>
                                                                <KeyboardArrowRightIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={3}>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            count={total}
                                            onPageChange={(e, newPage) => {
                                                setPage(newPage);
                                                handleSearch(newPage.toString());
                                            }}
                                            page={page}
                                            rowsPerPage={limit ? limit : 10}
                                            onRowsPerPageChange={(e: any) => {
                                                setRows([]);
                                                setLimit(e.target.value);
                                                setPage(0);
                                                handleSearch('0', e.target.value.toString());
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <Box sx={{ display: 'none' }}>
                <PrintSettingProductGroups ref={printRef} title={'รายการหมวดหมู่สินค้า'} data={rows} />
            </Box>
            <Box sx={{ display: 'none' }}>
                <ExportFileSettingProductGroups ref={exportRef} title={'รายการหมวดหมู่สินค้า'} data={rows} />
            </Box>
        </Box>
    );
};

export default ProductGroups;
