import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class ImagesStore {

  images: any[] = [];

  constructor() {
    makeAutoObservable(this);

  }

  async create(data: any) {
    try {
      const response = await apiService.post("/images/upload", data);
      this.images = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating expense:", error);
      return {
        error: true,
        message: "An error occurred while creating expense",
      };
    }
  }

  async upload(data: any) {
    try {

      const response = await apiService.postFormData("/images/upload", data);
      this.images = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating expense:", error);
      return {
        error: true,
        message: "An error occurred while creating expense",
      };
    }
  }


  async get(id: string) {
    try {
      const response = await apiService.get(`/images/upload/${id}`);
      this.images = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting vendor:", error);
      return {
        error: true,
        message: "An error occurred while getting vendor",
      };
    }
  }
  async getAll() {
    try {
      const response = await apiService.get("/images/upload");
      this.images = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting expenses:", error);
      return {
        error: true,
        message: "An error occurred while getting expenses",
      };
    }
  }
  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/images/upload/${id}`, data);
      this.images = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating expense:", error);
      return {
        error: true,
        message: "An error occurred while updating expense",
      };
    }
  }
  async delete(id: string) {
    try {
      const response = await apiService.delete(`/images/upload/${id}`);
      this.images = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting expense:", error);
      return {
        error: true,
        message: "An error occurred while deleting expense",
      };
    }
  }

}
const imagesStore = new ImagesStore();
export default imagesStore;