import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

interface PrintCashflowProps {
    title?: string;
    date?: string;
    data: any[];
}

const PrintCashflow = React.forwardRef((props: PrintCashflowProps, ref) => {
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalIncomes, setTotalIncomes] = useState(0);
    const calculateGrandTotal = () => {
        const expense = props.data.reduce((acc: any, item: any) => {
            if (item.docType === 'paid') {
                const total = parseFloat(item.total) || 0;
                return acc + total;
            }
            return acc;
        }, 0);
        const income = props.data.reduce((acc: any, item: any) => {
            if (item.docType === 'received') {
                const total = parseFloat(item.total) || 0;
                return acc + total;
            }
            return acc;
        }, 0);
        return { expense, income };
    };
    useEffect(() => {
        const calculatedTotal = calculateGrandTotal();
        setTotalIncomes(calculatedTotal.income);
        setTotalExpenses(calculatedTotal.expense);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);
    return (
        <Box ref={ref} sx={{
            margin: 0,
            width: "100%",
            borderCollapse: "collapse",
            textAlign: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
            '&@page': {
                size: 'A4 portrait',
            },
            overflow: 'hidden',
            fontSize: 8,
            display: 'block',
            pageBreakBefore: 'auto', // Allow page break before this element    
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={8} sx={{ textAlign: 'center', }}>
                                        <Typography fontWeight={'bold'}>{props.title}</Typography>
                                        <Typography>{props.date}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width={20}>วันที่เอกสาร</TableCell>
                                    <TableCell width={500}>รายละเอียด</TableCell>
                                    <TableCell width={100}>รายรับ</TableCell>
                                    <TableCell width={100}>รายจ่าย</TableCell>
                                    <TableCell width={30}>บันทึก</TableCell>
                                    <TableCell width={30}>แก้ไข</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props?.data?.length > 0 && props?.data?.map((row: any) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell component="th" width={100}>
                                            {dayjs(row.docDate).format('DD/MM/YYYY')}
                                        </TableCell>
                                        <TableCell width={500} style={{
                                            wordBreak: 'break-all',
                                            flexWrap: 'wrap',
                                            maxWidth: 500,
                                        }}>{row?.cashflowDetail?.sort((a: any, b: any) => dayjs(a.createAt).diff(b.createAt)).map((cashflowDetail: any, index: number) => {
                                            return <span key={cashflowDetail.id}>{`${cashflowDetail.description || ''}${index < row?.cashflowDetail.length - 1 ? ', ' : ''} `}</span>;

                                        })}</TableCell>
                                        <TableCell align="right">{row.docType === 'received' ? parseFloat(row.total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ''}
                                        </TableCell>
                                        <TableCell align="right">{row.docType === 'paid' ? parseFloat(row.total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ''}
                                        </TableCell>
                                        <TableCell width={30}><span style={{ fontSize: 8 }}><Box>{<b>{row.createBy?.name}</b>}</Box> {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')} </span></TableCell>
                                        <TableCell width={30}><span style={{ fontSize: 8 }}>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}</span> </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2} align="left" sx={{ fontWeight: 'bold' }}>รวมทั้งหมด</TableCell>
                                    <TableCell>{totalIncomes.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                    <TableCell>{totalExpenses.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                    <TableCell colSpan={2}></TableCell>
                                </TableRow>
                                {props.data.length === 0 && (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box >
    );
});

export default PrintCashflow;