import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class ProductsStore {
  products: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/products");
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting products:", error);
      return {
        error: true,
        message: "An error occurred while getting products",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/products/${id}`);
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting product:", error);
      return {
        error: true,
        message: "An error occurred while getting product",
      };
    }
  }

  async getByName(name: string) {
    try {
      const response = await apiService.get(`/products/name/${name}`);
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting product:", error);
      return {
        error: true,
        message: "An error occurred while getting product",
      };
    }
  }

  async getByNameLike(name: string) {
    try {
      const response = await apiService.get(`/products/name/like/${name}`);
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting product:", error);
      return {
        error: true,
        message: "An error occurred while getting product",
      };
    }
  }

  async getByProductGroupId(groupId: string) {
    try {
      const response = await apiService.get(`/products/group/${groupId}`);
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting product:", error);
      return {
        error: true,
        message: "An error occurred while getting product",
      };
    }
  }

  async getByProductGroupIdAndNameLike(id: string, name: string) {
    try {
      const response = await apiService.get(
        `/products/groupname/${id}/${name}`
      );
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting product:", error);
      return {
        error: true,
        message: "An error occurred while getting product",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/products", data);
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating product:", error);
      return {
        error: true,
        message: "An error occurred while creating product",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/products/${id}`, data);
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating product:", error);
      return {
        error: true,
        message: "An error occurred while updating product",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/products/${id}`);
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting product:", error);
      return {
        error: true,
        message: "An error occurred while deleting product",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post(`/products/criteria`, criteria);
      this.products = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting products:", error);
      return {
        error: true,
        message: "An error occurred while getting products",
      };
    }
  }
}
const productsStore = new ProductsStore();
export default productsStore;
