import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { } from "react";

interface PrintReceiptListProps {
  title?: string;
  date?: string;
  data: any[];
  grandTotal?: number;
}
const PrintReceiptLists = React.forwardRef((props: PrintReceiptListProps, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        margin: 0,
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        '@page': {
          size: "A4 portrait", // Default page size is portrait
        },
        overflow: "hidden",
        fontSize: 8,
        display: "block",
        pageBreakBefore: "auto", // Allow page break before this element 
        pageBreakAfter: "auto", // Allow page break before this element 
        '@media print': {
          '@page': {
            size: "A4 portrait", // Subsequent pages are landscape
            marginLeft: "60px",
            marginRight: "60px",
          },
          footer: {
            display: 'none'
          }
          , header: {
            display: 'none'
          }
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer >
            <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={9} sx={{ textAlign: 'center', }}>
                    <Typography fontWeight={'bold'}>{props.title}</Typography>
                    <Typography>{props.date}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>วันที่เอกสาร</TableCell>
                  <TableCell>รายชื่อลูกค้า</TableCell>
                  <TableCell>เบอร์โทรศัพท์</TableCell>
                  <TableCell>จำนวนเงิน</TableCell>
                  <TableCell>ประเภทการจ่าย</TableCell>
                  <TableCell width={100}>บันทึก</TableCell>
                  <TableCell width={100}>แก้ไข</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 && props?.data?.map((row: any, index: number) => (
                  <TableRow key={row.id + index}>
                    <TableCell>{dayjs(row.docDate).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>{row?.customer?.firstname ?? ''} {row?.customer?.lastname ?? ''}</TableCell>
                    <TableCell>{row?.customer?.tel ?? ''}</TableCell>
                    <TableCell align="right">{parseFloat(row?.total || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    <TableCell align="center">
                      {row.payment === 'cash' ? "เงินสด" : row.payment === 'transfer' ? "โอน" : "ว่าง"}</TableCell>
                    <TableCell>{<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                  </TableRow>

                ))}
                {props.data?.length === 0 && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={9} align="center">ไม่พบข้อมูล</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center', fontWeight: 'bold' }}>รวม </TableCell>
                  <TableCell sx={{ textAlign: 'right', }}>{parseFloat((props?.grandTotal || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

export default PrintReceiptLists;





