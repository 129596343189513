import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  createFilterOptions,
  tooltipClasses,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/th";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuid4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import ItemsForHouse from "../../components/ItemsForHouse";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import vendorsStore from "../../stores/VendorsStore";
import productsStore from "../../stores/ProductsStore";
import authStore from "../../stores/AuthStore";
import expensesStore from "../../stores/ExpensesStore";
import DialogCreateVendor from "../../components/DialogCreateVendor";
import { enqueueSnackbar } from "notistack";
import DialogCreateProduct from "../../components/DialogCreateProduct";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DialogConfirm from "../../components/DialogConfirm";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import imagesStore from "../../stores/ImagesStore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styled from "@emotion/styled";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import productCategoriesStore from "../../stores/ProductCategoriesStore";
import productGroupsStore from "../../stores/ProductGroupsStore";

interface VendorOptionType {
  inputValue?: string;
  name: string;
}
interface ProductOptionType {
  inputValue?: string;
  name: string;
  size?: string;
  color?: string;
}
const filterVendor = createFilterOptions<VendorOptionType>();
const filterProduct = createFilterOptions<ProductOptionType>();

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 300,
  },
});

const BillCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [docType, setDocType] = useState("tax");
  const handleChangeDocType = (event: ChangeEvent<HTMLInputElement>) => {
    setDocType((event.target as HTMLInputElement).value);
  };
  const [docDate, setDocDate] = useState<Dayjs | null>(dayjs());
  const [docNo, setDocNo] = useState("");
  const [itemDetailsOldTry, setItemDetailsOldTry] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([{ id: uuid4() }]);
  const [itemsOld, setItemsOld] = useState<any[]>([]);
  const [itemDetails, setItemDetails] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [itemDetailsOld, setItemDetailsOld] = useState<any[]>([]);
  const [itemDetailsDelete, setItemDetailsDelete] = useState<any[]>([]);
  const [vendors, setVendors] = useState<any>();
  const permission: any = authStore.user?.roles.find(
    (role: any) => role.menu.path === "/bills"
  );
  const handleAddItem = () => {
    setItems([...items, { id: uuid4(), isNew: true }]);
  };
  const handleRemoveItem = (id: string) => {
    setItems(items.filter((item: any) => item.id !== id));
    calculatorSummary();
  };
  const [itemsForHouse, setItemsForHouse] = useState<any>(null);
  const [itemId, setItemId] = useState<any>(null);
  const [itemsForHouseQty, setItemsForHouseQty] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [vat, setVat] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [vatType, setVatType] = useState("include-vat");
  const handleChangeVatType = (event: ChangeEvent<HTMLInputElement>) => {
    setVatType((event.target as HTMLInputElement).value);
    let grandTotal = 0;
    if ((event.target as HTMLInputElement).value === "exclude-vat") {
      grandTotal =
        parseFloat(totalAfterDiscount.toString()) + parseFloat(vat.toString());
    } else {
      grandTotal = totalAfterDiscount;
    }
    setGrandTotal(grandTotal);
  };

  const [vendorsList, setVendorsList] = useState<any[]>([]);
  const [vendorValue, setVendorValue] = useState<VendorOptionType | null>(null);
  const [openAddVendorDialog, setOpenAddVendorDialog] = useState(false);
  const [dialogCreateVendorValue, setDialogCreateVendorValue] = useState("");

  const [productList, setProductList] = useState<any[]>([]);
  const [productValue, setProductValue] = useState<ProductOptionType[]>([]);
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
  const [dialogCreateProductValue, setDialogCreateProductValue] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageList, setImageList] = useState<any[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [itemDetailEdit, setItemDetailEdit] = useState<any[]>([]);
  const [itemDetailEditOld, setItemDetailEditOld] = useState<any[]>([]);
  const [productCategoryData, setProductCategoryData] = useState<any[]>([]);
  const [productGroupData, setProductGroupData] = useState<any[]>([]);
  const [note, setNote] = useState("");
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [itemLength, setItemLength] = useState(0);

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
    setItemLength(inputRefs.current.length - 1);
  };
  console.log("itemsOld", itemsOld);
  const handleKeyDown = (event: any, index: number, i = 0) => {
    const currentInput = event.target;
    if (event.key === "Enter") {
      const currentIndex = Array.from(inputRefs.current).indexOf(currentInput);
      if (i === -1) {
        inputRefs.current[10].focus();
      } else if (index === 8 && i === 0) {
        if (inputRefs.current[11]) {
          inputRefs.current[11].focus();
        } else {
          const nextIndex = currentIndex + 1;
          inputRefs.current[nextIndex].focus();
        }
      } else if (currentIndex === itemLength) {
        inputRefs.current[9].focus();
      } else {
        const nextIndex = currentIndex + 1;
        if (nextIndex < inputRefs.current.length) {
          inputRefs.current[nextIndex].focus();
          event.preventDefault();
        }
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!id) {
      vendorsStore
        .getAll()
        .then((res) => {
          setVendorsList(res);
          productCategoriesStore
            .getAll()
            .then((pcResult) => {
              setProductCategoryData(pcResult);
              productGroupsStore
                .getAll()
                .then((pgResult) => {
                  setProductGroupData(pgResult);
                  productsStore
                    .getAll()
                    .then((res) => {
                      setProductList(
                        res.sort((a: any, b: any) =>
                          a.name.localeCompare(b.name)
                        )
                      );
                      setIsLoading(false);
                    })
                    .catch((err) => {
                      console.error(err);
                      setIsLoading(false);
                    });
                })
                .catch((err) => {
                  console.error(err);
                  setIsLoading(false);
                });
            })
            .catch((err) => {
              console.error(err);
              setIsLoading(false);
            });
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    } else {
      handleFetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleFetch = (id: string) => {
    vendorsStore
      .getAll()
      .then((res) => {
        setVendorsList(res);
        productCategoriesStore
          .getAll()
          .then((pcResult) => {
            setProductCategoryData(pcResult);
            productGroupsStore
              .getAll()
              .then((pgResult) => {
                setProductGroupData(pgResult);
                productsStore
                  .getAll()
                  .then((res) => {
                    setProductList(
                      res.sort((a: any, b: any) => a.name.localeCompare(b.name))
                    );
                    expensesStore
                      .get(id)
                      .then((res) => {
                        setDocType(res.docType);
                        setDocNo(res.docNo);
                        setDocDate(dayjs(res.docDate));
                        setVendors({
                          ...vendors,
                          id: res.vendor?.id,
                          name: res.vendor?.name,
                          address: res.vendor?.address,
                          tel: res.vendor?.tel,
                        });
                        setVendorValue({ name: res.vendor?.name });
                        setTotal(res.total);
                        setDiscount(res.discount);
                        setTotalAfterDiscount(res.total - res.discount);
                        setVat(res.vat);
                        setGrandTotal(res.grandTotal);
                        setVatType(res.vatType);
                        setImageList(res.images);
                        setImages(res.images);
                        setNote(res.note || "");
                        // sort expenseDetails createAt first to last use dayjs
                        const expenseDetails = res.expenseDetails.sort(
                          (a: any, b: any) => {
                            return dayjs(a.createAt).isBefore(dayjs(b.createAt))
                              ? -1
                              : 1;
                          }
                        );
                        setItems(expenseDetails);
                        setItemsOld(res.expenseDetails);
                        if (res.expenseDetails.length > 0) {
                          res.expenseDetails.forEach((item: any) => {
                            if (item.items.length > 0) {
                              setItemDetails([...itemDetails, item.items]);
                              setItemDetailsOld([...itemDetails, item.items]);
                            }
                          });

                          const _itemDetailEdit = res.expenseDetails.reduce(
                            (acc: any[], dataItem: any) => {
                              const _data = dataItem.items.map((item: any) => {
                                return {
                                  ...item,
                                  expenseDetail: dataItem.id,
                                  itemId: dataItem.id,
                                  expenseDetailId: dataItem.id,
                                };
                              });
                              return [...acc, ..._data];
                            },
                            []
                          );
                          setItemDetailEdit(_itemDetailEdit);
                          setItemDetailEditOld(_itemDetailEdit);
                        }
                        setIsLoading(false);
                      })
                      .catch((err) => {
                        console.error(err);
                        setIsLoading(false);
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                    setIsLoading(false);
                  });
              })
              .catch((err) => {
                console.error(err);
                setIsLoading(false);
              });
          })
          .catch((err) => {
            console.error(err);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };
  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (!docNo) {
        enqueueSnackbar("กรุณากรอกเลขที่ใบเสร็จ/ใบกำกับภาษี", {
          variant: "warning",
        });
        setIsLoading(false);
        return;
      }
      if (!vendors) {
        enqueueSnackbar("กรุณาเลือกผู้ขาย", { variant: "warning" });
        setIsLoading(false);
        return;
      }
      if (!items[0].name) {
        enqueueSnackbar("กรุณาเลือกรายการสินค้า/บริการ", {
          variant: "warning",
        });
        setIsLoading(false);
        return;
      }
      if (!total) {
        enqueueSnackbar("กรุณากรอกจำนวนเงิน", { variant: "warning" });
        setIsLoading(false);
        return;
      }
      if (!id) {
        const dataDetail = items.map((detail: any, index: number) => {
          const _itemDetailEdit = itemDetailEdit.filter(
            (itemEdit: any) => itemEdit.itemId === detail.id
          );
          return {
            ...detail,
            createBy: authStore.user?.id,
            createAt: new Date(Date.now() + index * 1000),
            item:
              _itemDetailEdit.length > 0
                ? _itemDetailEdit.map((item: any, index: number) => {
                    const _projectId = item.project?.id;
                    const _houseId = item.house?.id;
                    const _quantity = item?.qty;
                    delete item.project;
                    delete item.house;
                    delete item.qty;
                    delete item.id;
                    return {
                      ...item,
                      project: _projectId,
                      house: _houseId,
                      quantity: _quantity,
                      createdAt: new Date(Date.now() + index * 1000),
                      createBy: authStore.user?.id,
                    };
                  })
                : [],
          };
        });

        await expensesStore
          .create({
            docType: docType,
            docNo: docNo,
            docDate: docDate?.format("YYYY-MM-DD"),
            vendor: vendors?.id,
            total: total,
            discount: discount,
            vat: vat,
            vatType: vatType,
            grandTotal: grandTotal,
            createBy: authStore.user?.id,
            createAt: new Date(),
            detail: dataDetail,
            note: note,
          })
          .then((res: any) => {
            if (res.error) {
              enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
              setIsLoading(false);
            }
            handleFileUpload(res);
            navigate(`/bill-edit/${res}`);
          });
        setIsLoading(false);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    if (id) {
      try {
        await expensesStore.update(id, {
          isDeleted: true,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        });
        await imagesStore.update(id, {
          isDeleted: true,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        });
        enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
        setIsLoading(false);
        navigate("/bills");
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }
  };

  // NOTE: Should improve performance (Use it for now)
  const handleEdit = async () => {
    setIsLoading(true);
    try {
      if (!docNo) {
        enqueueSnackbar("กรุณากรอกเลขที่ใบเสร็จ/ใบกำกับภาษี", {
          variant: "warning",
        });
        setIsLoading(false);
        return;
      }
      if (!vendors) {
        enqueueSnackbar("กรุณาเลือกผู้ขาย", { variant: "warning" });
        setIsLoading(false);
        return;
      }
      if (!items[0].product) {
        enqueueSnackbar("กรุณาเลือกรายการสินค้า/บริการ", {
          variant: "warning",
        });
        setIsLoading(false);
        return;
      }
      if (!items[0].total) {
        enqueueSnackbar("กรุณากรอกจำนวนเงิน", { variant: "warning" });
        setIsLoading(false);
        return;
      }
      if (id) {
        const itemsUpdate = items.map((newItem) => {
          const oldItem = itemsOld.find((oldItem) => oldItem.id === newItem.id);
          return {
            ...newItem,
            old: oldItem ? oldItem.old : undefined,
          };
        });

        const itemsCreate = items.filter((item: any) => {
          return !itemsOld.find((itemOld: any) => itemOld.id === item.id);
        });

        const itemsDetele = itemsOld.filter((itemOld: any) => {
          return !items.find((item: any) => itemOld.id === item.id);
        });

        const itemDetailsCreate = itemDetailEdit.filter((item: any) => {
          return !itemDetailEditOld.find(
            (itemDetailsOld: any) => itemDetailsOld.id === item.id
          );
        });

        const itemDetailsUpdate = itemDetailEdit.filter((item: any) => {
          return itemDetailEditOld.find(
            (itemOld: any) => itemOld.id === item.id
          );
        });

        handleEditFileUpload(id);

        expensesStore
          .update(id, {
            docType: docType,
            docNo: docNo,
            docDate: docDate?.format("YYYY-MM-DD"),
            vendor: vendors?.id,
            total: total,
            discount: discount,
            vat: vat,
            vatType: vatType,
            grandTotal: grandTotal,
            updateBy: authStore.user?.id,
            updateAt: new Date(),
            note: note,
          })
          .then(async (head) => {
            await itemsUpdate.forEach((item: any) => {
              expensesStore
                .updateDetail(item.id, {
                  expense: {
                    id: id,
                    docNo: docNo,
                    docDate: docDate?.format("YYYY-MM-DD"),
                  },
                  productCategory: item?.productCategory,
                  productGroup: item?.productGroup,
                  product: item?.product?.id,
                  quantity: item?.quantity,
                  unit: item?.unit,
                  price: item?.price,
                  total: item?.total,
                  updateBy: authStore.user?.id,
                  updateAt: new Date(),
                  old: item.old,
                  item: [...itemDetailsUpdate, ...itemDetailsCreate],
                })
                .then(async (detail: any) => {
                  await itemDetailsUpdate.forEach(
                    (item: any, index: number) => {
                      expensesStore.updateItem(item.id, {
                        expenseDetail: item.itemId,
                        product: item?.product?.id,
                        project: item.project?.id,
                        house: item.house?.id,
                        quantity: item?.qty,
                        createdAt: new Date(Date.now() + index * 1000),
                        createBy: authStore.user?.id,
                        updateAt: new Date(Date.now() + index * 1000),
                        updateBy: authStore.user?.id,
                      });
                    }
                  );
                });
            });

            if (itemsCreate.length > 0) {
              await itemsCreate.forEach(async (item: any, index: number) => {
                const _newItemInOldDetail = itemDetailsCreate.filter(
                  (itemDetail) => itemDetail.isNew === false
                );
                const _newItemInNewDetail = itemDetailsCreate.filter(
                  (itemDetail) => itemDetail.isNew === true
                );
                if (_newItemInNewDetail) {
                  await expensesStore
                    .createDetail({
                      expense: {
                        id: id,
                        docNo: docNo,
                        docDate: docDate?.format("YYYY-MM-DD"),
                      },
                      productCategory: item?.productCategory,
                      productGroup: item?.productGroup,
                      product:
                        typeof item?.product === "object"
                          ? item?.product?.id
                          : item?.product,
                      quantity: item?.quantity,
                      unit: item?.unit,
                      price: item?.price,
                      total: item?.total,
                      createBy: authStore.user?.id,
                      createAt: new Date(Date.now() + index * 1000),
                      item: [..._newItemInOldDetail, ..._newItemInNewDetail],
                      itemId: item?.id,
                    })
                    .then(async (detail) => {
                      if (_newItemInNewDetail.length > 0) {
                        const newNewItem = _newItemInNewDetail.filter(
                          (itemDetail) =>
                            itemDetail.isNew === true &&
                            itemDetail.product.id === detail.product
                        );
                        newNewItem.forEach(async (item: any, index: number) => {
                          const product =
                            typeof item?.product === "object"
                              ? item?.product?.id
                              : item?.product;
                          const project = item.project?.id;
                          delete item?.product;
                          delete item?.project;
                          await expensesStore.createItem({
                            expenseDetail: detail?.id,
                            product: product,
                            project: project,
                            house: item.house?.id,
                            quantity: item?.qty,
                            createdAt: new Date(Date.now() + index * 1000),
                            createBy: authStore.user?.id,
                            updateAt: new Date(Date.now() + index * 1000),
                            updateBy: authStore.user?.id,
                          });
                        });
                      }
                    })
                    .catch((err) => {
                      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
                        variant: "error",
                      });
                      console.error(err);
                      setIsLoading(false);
                    });
                }

                if (_newItemInOldDetail) {
                  const _item = _newItemInOldDetail.map(
                    (item: any, index: number) => {
                      const project = item.project?.id;
                      const house = item?.house?.id;
                      const product =
                        typeof item?.product === "object"
                          ? item?.product?.id
                          : item?.product;
                      const quantity = item?.qty;
                      const _expenseDetail = item?.itemId;
                      delete item.project;
                      delete item.house;
                      delete item.qty;
                      delete item.expenseDetail;
                      return {
                        ...item,
                        project: project,
                        house: house,
                        quantity: quantity,
                        product: product,
                        expenseDetail: _expenseDetail,
                        createdAt: new Date(Date.now() + index * 1000),
                        createBy: authStore.user?.id,
                        updateAt: new Date(Date.now() + index * 1000),
                        updateBy: authStore.user?.id,
                      };
                    }
                  );

                  expensesStore.createItem(_item).catch((err) => {
                    console.error(err);
                    enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
                      variant: "error",
                    });
                    console.error(err);

                    setIsLoading(false);
                  });
                }
              });
            }

            const newItemInOldDetail = itemDetailsCreate.filter(
              (itemDetail) => itemDetail.isNew === false
            );
            if (
              itemDetailsCreate.find((item) => item.isNew === false) &&
              itemsCreate.length === 0
            ) {
              const _item = newItemInOldDetail.map(
                (item: any, index: number) => {
                  const project = item.project?.id;
                  const house = item?.house?.id;
                  const product =
                    typeof item?.product === "object"
                      ? item?.product?.id
                      : item?.product;
                  const quantity = item?.qty;
                  const _expenseDetail = item?.itemId;
                  delete item.project;
                  delete item.house;
                  delete item.qty;
                  delete item.expenseDetail;

                  return {
                    ...item,
                    project: project,
                    house: house,
                    quantity: quantity,
                    product: product,
                    expenseDetail: _expenseDetail,
                    expenseDetailId: item.expenseDetailId,
                    createdAt: new Date(Date.now() + index * 1000),
                    createBy: authStore.user?.id,
                    updateAt: new Date(Date.now() + index * 1000),
                    updateBy: authStore.user?.id,
                  };
                }
              );
              expensesStore.createItem(_item).catch((err) => {
                console.error(err);
                enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
                console.error(err);
                setIsLoading(false);
              });
            }
            if (itemDetailsDelete.length > 0) {
              await itemDetailsDelete.forEach((item: any) => {
                expensesStore
                  .deleteItemDetail(item.id, authStore.user?.id)
                  .catch((err) => {
                    enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
                      variant: "error",
                    });
                    console.error(err);
                    expensesStore.delete(head.id);
                    setIsLoading(false);
                  });
              });
            }
            if (itemsDetele.length > 0) {
              await itemsDetele.forEach((item: any) => {
                expensesStore
                  .deleteDetail(item.id, authStore.user?.id)
                  .catch((err) => {
                    enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
                      variant: "error",
                    });
                    console.error(err);
                    expensesStore.delete(head.id);
                    setIsLoading(false);
                  });
              });
            }
            enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
            setIsLoading(false);
          })
          .catch((err) => {
            enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
            setIsLoading(false);
            console.error(err);
          });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete" | "confirm-duplicate",
  });

  const [itemsSelected, setItemsSelected] = useState<any[]>([]);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    }
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleFileUpload = (refId: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "bill");
        formdata.append("refId", refId);
        formdata.append("file", file);
        formdata.append("createBy", authStore.user?.id);
        formdata.append(
          "createAt",
          new Date(Date.now() + index * 1000).toISOString()
        );

        await imagesStore
          .upload(formdata)
          .catch((err) => console.error("err", err));
      });
    }
  };

  const handleEditFileUpload = async (id: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "bill");
        formdata.append("refId", id);
        formdata.append("file", file);
        formdata.append("updateBy", authStore.user?.id);
        formdata.append(
          "updateAt",
          new Date(Date.now() + index * 1000).toISOString()
        );
        await imagesStore
          .upload(formdata)
          .catch((err) => console.error("err", err));
      });
    }
    if (deleteImages.length > 0) {
      await deleteImages.forEach(async (item: any) => {
        await imagesStore
          .update(item.id, {
            updateBy: authStore.user?.id,
            updateAt: new Date(),
            isDeleted: true,
          })
          .catch((err) => console.error("err deleteImages", err));
      });
    }
  };

  const calculate = (index: number, key: string, value: any) => {
    const newItems = [...items];
    newItems[index][key] = parseFloat(value);
    setItems(newItems);
    if (key === "quantity" || key === "price") {
      const newTotal =
        parseFloat(value) *
        parseFloat(
          key === "quantity" ? newItems[index].price : newItems[index].quantity
        );
      newItems[index].total = newTotal;
    }
    if (key === "total") {
      newItems[index].total = parseFloat(value);
    }
    calculatorSummary();
  };

  const calculatorSummary = () => {
    const total = items.reduce(
      (a: any, b: any) => a + parseFloat(b?.total || 0),
      0
    );
    setTotal(total);

    const afterDiscount = parseFloat(total) - discount;
    setTotalAfterDiscount(afterDiscount);

    const totalAfterDiscount = parseFloat(total) - discount;
    setTotalAfterDiscount(totalAfterDiscount);

    const vat = afterDiscount * 0.07;
    setVat(vat);
    let grandTotal = 0;
    if (vatType === "exclude-vat") {
      grandTotal = afterDiscount + vat;
    } else {
      grandTotal = afterDiscount;
    }
    setGrandTotal(grandTotal);
  };

  useEffect(() => {
    calculatorSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, items]);

  const checkIfAlready = async () => {
    setOpenConfirm({ ...openConfirm, open: false });
    setIsLoading(true);

    const isExist = await expensesStore.checkExpenseAlreadyExist({
      vendorId: vendors?.id,
      docNo: docNo,
    });

    if (isExist) {
      setOpenConfirm({
        open: true,
        title: "เลขที่เอกสารซ้ำ",
        message: "คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่",
        color: "warning",
        type: "confirm-duplicate",
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      handleSave();
    }
  };

  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton aria-label="delete" onClick={() => navigate("/bills")}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>บันทึกค่าใช้จ่าย</Typography>
            <Link component="button" onClick={() => navigate("/bills")}>
              ใบเสร็จ/ใบกำกับภาษี
            </Link>
            <Typography variant="h6" color="text.primary">
              {id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {((permission && permission.delete) ||
            authStore.user?.isSuperAdmin) &&
            id && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() =>
                  setOpenConfirm({
                    open: true,
                    title: "ลบเอกสาร",
                    message: "คุณต้องการลบเอกสารนี้ใช่หรือไม่",
                    color: "error",
                    type: "delete",
                  })
                }
              >
                ลบเอกสาร
              </Button>
            )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl>
                    <FormLabel
                      id="row-radio-buttons-group-label"
                      sx={{ color: "black" }}
                    >
                      ประเภทเอกสาร
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={docType}
                      onChange={handleChangeDocType}
                    >
                      <FormControlLabel
                        value="receipt"
                        control={<Radio />}
                        label="ใบเสร็จทั่วไป"
                      />
                      <FormControlLabel
                        value="bill"
                        control={<Radio />}
                        label="บิลเงินสด/ใบส่งของ"
                      />
                      <FormControlLabel
                        value="tax"
                        control={<Radio />}
                        label="ใบกำกับภาษี"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="docNo"
                    label="เลขที่ใบเสร็จ/ใบกำกับภาษี"
                    variant="outlined"
                    fullWidth
                    value={docNo}
                    onChange={(event) => {
                      const newValue = event.target.value.replace(
                        /[^A-Za-z0-9ก-ฮ]/g,
                        ""
                      );
                      setDocNo(newValue);
                    }}
                    inputRef={addRef}
                    onKeyDown={(e) => {
                      handleKeyDown(e, 0);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"th"}
                  >
                    <DatePicker
                      label="วันที่เอกสาร"
                      value={docDate}
                      onChange={(newValue) => setDocDate(newValue)}
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={vendorValue}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setTimeout(() => {
                          setOpenAddVendorDialog(true);
                          setDialogCreateVendorValue(newValue);
                        });
                      } else if (newValue && newValue.inputValue) {
                        setOpenAddVendorDialog(true);
                        setDialogCreateVendorValue(newValue.inputValue);
                      } else {
                        setVendorValue(newValue);
                        setVendors({
                          ...vendors,
                          id: newValue?.id,
                          name: newValue?.name,
                          address: newValue?.address,
                          tel: newValue?.tel,
                        });
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filterVendor(options, params);

                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `สร้าง "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    id="vendor-autocomplete"
                    options={vendorsList}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ผู้ขาย"
                        variant="outlined"
                        inputRef={addRef}
                        onKeyDown={(e) => handleKeyDown(e, 1)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="vendor-address"
                    label="ที่อยู่"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={vendors?.address || ""}
                    inputProps={{ readOnly: true }}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 2)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="vendor-tel"
                    label="เบอร์โทร"
                    variant="outlined"
                    fullWidth
                    value={vendors?.tel || ""}
                    inputProps={{ readOnly: true }}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 3)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>รายการสินค้า/บริการ</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={0.5}
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                    ></Grid>
                    <Grid
                      item
                      xs={0.5}
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                    >
                      <Typography>ลำดับ</Typography>
                    </Grid>
                    <Grid item xs={4.5}>
                      <Typography>รายการ</Typography>
                    </Grid>
                    <Grid item xs={1.25}>
                      <Typography>จำนวน</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>หน่วย</Typography>
                    </Grid>
                    <Grid item xs={1.25}>
                      <Typography>ราคาต่อหน่วย</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>จำนวนเงิน</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
                {items.map((item: any, index: number) => {
                  return (
                    <Grid item xs={12} key={`i-${index.toString()}`}>
                      <Grid container spacing={2} alignItems={"end"}>
                        <Grid
                          item
                          xs={0.5}
                          mb={1.5}
                          justifyContent={"center"}
                          alignItems={"center"}
                          display={"flex"}
                        >
                          <IconButton
                            aria-label={`delete-${item.id}`}
                            size="small"
                            color="error"
                            onClick={() => handleRemoveItem(items[index].id)}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          xs={0.5}
                          mb={2}
                          justifyContent={"center"}
                          alignItems={"center"}
                          display={"flex"}
                        >
                          <Typography>{index + 1}</Typography>
                        </Grid>
                        <Grid item xs={4.5}>
                          <Grid container spacing={0.5}>
                            <Grid item xs={6}>
                              <Autocomplete
                                autoHighlight
                                fullWidth
                                size="small"
                                id="productCategory-select"
                                value={
                                  item.productCategory?.name
                                    ? item.productCategory?.name +
                                      (item.productCategory?.isCommonFee
                                        ? " [ส่วนกลาง]"
                                        : "")
                                    : ""
                                }
                                options={productCategoryData}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option || "";
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue || "";
                                  }
                                  return option.name || "";
                                }}
                                PaperComponent={({ children }) => (
                                  <Paper style={{ width: "300px" }}>
                                    {children}
                                  </Paper>
                                )}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={option?.id}
                                  >
                                    {option.isCommonFee ? (
                                      <b>[ส่วนกลาง]</b>
                                    ) : null}
                                    &nbsp;{option.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="ประเภทสินค้า"
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    const newItems = [...items];
                                    newItems[index].productCategory = newValue;
                                    setItems(newItems);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                autoHighlight
                                fullWidth
                                size="small"
                                id="productGroup-select"
                                value={
                                  item.productGroup?.name
                                    ? item.productGroup?.name +
                                      (item.productGroup?.isCommonFee
                                        ? " [ส่วนกลาง]"
                                        : "")
                                    : ""
                                }
                                options={productGroupData}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option || "";
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue || "";
                                  }
                                  return option.name || "";
                                }}
                                PaperComponent={({ children }) => (
                                  <Paper style={{ width: "300px" }}>
                                    {children}
                                  </Paper>
                                )}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={option.id}
                                  >
                                    {option.isCommonFee ? (
                                      <b>[ส่วนกลาง]</b>
                                    ) : null}
                                    &nbsp;{option.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="หมวดหมู่สินค้า"
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                                onChange={(e, newValue) => {
                                  const newItems = [...items];
                                  newItems[index].productGroup = newValue;
                                  setItems(newItems);
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}></Grid>
                          </Grid>
                          <Autocomplete
                            value={
                              productValue[index]?.name || item.product?.name
                                ? (productValue[index]?.name ||
                                    item.product?.name ||
                                    "") +
                                  " " +
                                  (productValue[index]?.color ||
                                  item?.product?.color ||
                                  productValue[index]?.size ||
                                  item?.product?.size
                                    ? "("
                                    : " ") +
                                  (productValue[index]?.color ||
                                    item?.product?.color ||
                                    "") +
                                  " " +
                                  (productValue[index]?.size ||
                                    item?.product?.size ||
                                    "") +
                                  (productValue[index]?.color ||
                                  item?.product?.color ||
                                  productValue[index]?.size ||
                                  item?.product?.size
                                    ? ")"
                                    : " ")
                                : ""
                            }
                            onChange={(event, newValue) => {
                              if (typeof newValue === "string") {
                                setTimeout(() => {
                                  setOpenAddProductDialog(true);
                                  setDialogCreateProductValue(newValue);
                                });
                              } else if (newValue && newValue.inputValue) {
                                setOpenAddProductDialog(true);
                                setDialogCreateProductValue(
                                  newValue.inputValue
                                );
                              } else {
                                setProductValue({
                                  ...productValue,
                                  [index]: newValue,
                                });
                                const newItems = [...items];
                                newItems[index].product = newValue?.id;
                                newItems[index].name = newValue?.name;
                                newItems[index].unit = newValue?.unit;
                                setItems(newItems);
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filterProduct(options, params);
                              const uniqueOptions = filtered
                                .reduce((unique, option) => {
                                  const optionLabel =
                                    typeof option === "string"
                                      ? option
                                      : option.name;
                                  return unique.has(optionLabel)
                                    ? unique
                                    : unique.set(optionLabel, option);
                                }, new Map())
                                .values();

                              const result = [...uniqueOptions];

                              if (params.inputValue !== "") {
                                result.push({
                                  inputValue: params.inputValue,
                                  name: `สร้าง "${params.inputValue}"`,
                                });
                              }

                              return result;
                            }}
                            id={`item-${index}-product-autocomplete`}
                            options={productList}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              return option.name;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                              <li {...props}>
                                {option.name}{" "}
                                {option.color || option.size
                                  ? `(${option.color || ""} ${
                                      option.size || ""
                                    })`
                                  : ""}
                              </li>
                            )}
                            sx={{ width: "100%" }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                inputRef={addRef}
                                onKeyDown={(e) =>
                                  handleKeyDown(e, 4 + index, index)
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1.25}>
                          <TextField
                            id={`item-${index}-qty`}
                            variant="outlined"
                            fullWidth
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            inputRef={addRef}
                            onKeyDown={(e) => {
                              handleKeyDown(e, 5 + index, index);
                              if (e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                            value={
                              item?.quantity ? parseFloat(item?.quantity) : ""
                            }
                            onChange={(e) => {
                              calculate(index, "quantity", e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            id={`item-${index}-unit`}
                            variant="outlined"
                            fullWidth
                            value={item.unit || ""}
                            inputProps={{
                              min: 0,
                            }}
                            inputRef={addRef}
                            onKeyDown={(e) => {
                              handleKeyDown(e, 6 + index, index);
                              if (e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].unit = e.target.value;
                              setItems(newItems);
                            }}
                          />
                        </Grid>
                        <Grid item xs={1.25}>
                          <TextField
                            id={`item-${index}-price`}
                            variant="outlined"
                            fullWidth
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            inputRef={addRef}
                            onKeyDown={(e) => {
                              handleKeyDown(e, 7 + index, index);
                              if (e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                            value={item.price ? parseFloat(item.price) : ""}
                            onChange={(e) => {
                              calculate(index, "price", e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id={`item-${index}-total`}
                            variant="outlined"
                            fullWidth
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            inputRef={addRef}
                            onKeyDown={(e) => {
                              handleKeyDown(e, 8 + index, index);
                              if (e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                            value={item.total ? parseFloat(item.total) : ""}
                            onChange={(e) => {
                              calculate(index, "total", e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          p={0}
                          mb={2}
                          xs={1}
                          justifyContent={"center"}
                          alignItems={"center"}
                          display={"flex"}
                        >
                          <CustomWidthTooltip
                            title={
                              <Grid container>
                                {itemDetailEdit.find(
                                  (detail: any) => detail.itemId
                                ) && (
                                  <>
                                    {itemDetailEdit
                                      .filter(
                                        (detail: any) =>
                                          detail.itemId === item.id
                                      )
                                      .map((item: any, ideIndex: number) => {
                                        return (
                                          <>
                                            {item && (
                                              <Fragment
                                                key={`ide-${ideIndex.toString()}`}
                                              >
                                                <Grid
                                                  item
                                                  xs={
                                                    item?.house?.name ? 5 : 10
                                                  }
                                                >
                                                  {item?.project?.name || ""}
                                                </Grid>
                                                {item?.house?.name && (
                                                  <Grid item xs={5}>
                                                    {item?.house?.name || ""}
                                                  </Grid>
                                                )}
                                                <Grid item xs={2}>
                                                  (
                                                  {parseFloat(
                                                    item?.quantity || 0
                                                  ).toLocaleString(undefined, {
                                                    maximumFractionDigits: 10,
                                                    minimumFractionDigits: 4,
                                                  })}
                                                  )
                                                </Grid>
                                              </Fragment>
                                            )}
                                          </>
                                        );
                                      })}
                                  </>
                                )}
                                {(!itemDetailEdit.find(
                                  (detail: any) =>
                                    detail?.product?.id || detail?.product
                                ) ||
                                  !itemDetailEdit.find(
                                    (detail: any) =>
                                      (detail?.product?.id ===
                                        item?.product?.id ||
                                        detail?.product === item?.product ||
                                        detail?.product?.id === item?.product ||
                                        detail?.product?.id ===
                                          item?.product?.id) &&
                                      detail.itemId === item.id
                                  )) && (
                                  <Grid item xs={12} textAlign={"center"}>
                                    ยังไม่ระบุช้อมูล
                                  </Grid>
                                )}
                              </Grid>
                            }
                          >
                            <Button
                              variant="text"
                              sx={{ width: "100%", padding: 0 }}
                              onClick={() => {
                                setItemsForHouse({
                                  ...item,
                                  expenseDetail: item.product,
                                  expenseDetailId: item?.id,
                                });
                                setItemsForHouseQty(parseFloat(item?.quantity));
                                setItemsSelected(
                                  itemDetailEdit.filter(
                                    (itemEdit: any) =>
                                      itemEdit?.itemId === item?.id
                                  )
                                );
                                setItemId(item.id);
                                setItemDetailsOldTry(itemDetailEditOld);
                              }}
                              endIcon={
                                !itemDetailEdit.find(
                                  (detail: any) =>
                                    (detail?.product?.id ===
                                      item?.product?.id ||
                                      detail?.product === item?.product ||
                                      detail?.product?.id === item?.product ||
                                      detail?.product?.id ===
                                        item?.product?.id) &&
                                    detail.itemId === item.id
                                ) &&
                                itemDetailEdit.filter(
                                  (detail: any) =>
                                    (detail?.product?.id ===
                                      item?.product?.id ||
                                      detail?.product === item?.product ||
                                      detail?.product?.id === item?.product ||
                                      detail?.product?.id ===
                                        item?.product?.id) &&
                                    detail.itemId === item.id
                                ) ? undefined : parseFloat(item?.quantity) -
                                    parseFloat(
                                      itemDetailEdit
                                        .filter(
                                          (detail: any) =>
                                            (detail?.product?.id ===
                                              item?.product?.id ||
                                              detail?.product ===
                                                item?.product ||
                                              detail?.product?.id ===
                                                item?.product ||
                                              detail?.product?.id ===
                                                item?.product?.id) &&
                                            detail.itemId === item.id
                                        )
                                        .reduce((a: any, b: any) => {
                                          const quantity = parseFloat(
                                            b.quantity
                                          );
                                          return (
                                            parseFloat(
                                              a !== undefined ? a : a.quantity
                                            ) + quantity
                                          );
                                        }, 0)
                                    ) >
                                  0 ? (
                                  <ErrorOutlineIcon color="warning" />
                                ) : (
                                  <CheckCircleOutlineIcon color="success" />
                                )
                              }
                            >
                              {" "}
                              เลือกบ้าน
                            </Button>
                          </CustomWidthTooltip>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginTop: 2 }} />
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onClick={handleAddItem}
                    startIcon={<AddCircleIcon />}
                  >
                    เพิ่มรายการ
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>เอกสาร</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<UploadFileIcon />}
                      >
                        เลือกไฟล์
                        <VisuallyHiddenInput
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          onChange={handleFileChange}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        รองรับไฟล์นามสกุล .jpg, .jpeg, .png
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedFiles.length > 0 && (
                        <Box component={"div"} mt={1}>
                          {selectedFiles.map((file: any, index: number) => (
                            <Grid
                              item
                              xs={12}
                              key={`sf-${index.toString()}`}
                              mt={1}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={0.5}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  display={"flex"}
                                >
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => handleFileRemove(index)}
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {images && (
                        <Box component={"div"} mt={1}>
                          {images.map((file: any, index: number) => (
                            <Grid
                              item
                              xs={12}
                              key={`im-${index.toString()}`}
                              mt={1}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={0.5}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  display={"flex"}
                                >
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleFileRemoveImageList(file)
                                    }
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={`${apiUrl}/uploads/${file.name}`}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>รวมเป็นเงิน</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                      <Typography>
                        {parseFloat(total.toString()).toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} display={"flex"} alignItems={"center"}>
                      <Typography mr={1}>ส่วนลด</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                      <TextField
                        id="discount"
                        variant="outlined"
                        size="small"
                        sx={{ textAlign: "end" }}
                        type="number"
                        onChange={(e) => {
                          setDiscount(
                            e.target.value ? parseFloat(e.target.value) : 0
                          );
                        }}
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          handleKeyDown(e, itemLength);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>ราคาหลังหักส่วนลด</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                      <Typography>
                        {parseFloat(
                          totalAfterDiscount.toString()
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl>
                        <FormLabel
                          id="row-radio-buttons-group-label"
                          sx={{ color: "black" }}
                        >
                          ภาษีมูลค่าเพิ่ม 7%
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={vatType}
                          onChange={handleChangeVatType}
                        >
                          <FormControlLabel
                            value="non-vat"
                            control={<Radio />}
                            label="ไม่รวมภาษี"
                          />
                          <FormControlLabel
                            value="include-vat"
                            control={<Radio />}
                            label="ภาษีรวมใน"
                          />
                          <FormControlLabel
                            value="exclude-vat"
                            control={<Radio />}
                            label="ภาษีแยกนอก"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                      <Typography>
                        {parseFloat(vat.toString()).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="หมายเหตุ"
                        value={note}
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                        multiline
                        maxRows={4}
                        fullWidth
                        inputProps={{
                          min: 0,
                        }}
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          handleKeyDown(e, itemLength, -1);
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                      <Typography>
                        {parseFloat(grandTotal.toString()).toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => navigate("/bills")}
                        >
                          ยกเลิก
                        </Button>
                        {!id && (
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              setOpenConfirm({
                                open: true,
                                title: "บันทึกเอกสาร",
                                message: "คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่",
                                color: "primary",
                                type: "create",
                              });
                            }}
                          >
                            บันทึก
                          </Button>
                        )}
                        {((permission && permission.edit) ||
                          authStore.user?.isSuperAdmin) &&
                          id && (
                            <Button
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                setOpenConfirm({
                                  open: true,
                                  title: "แก้ไขเอกสาร",
                                  message: "คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "edit",
                                });
                              }}
                            >
                              แก้ไข
                            </Button>
                          )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ItemsForHouse
        open={itemsForHouse ? true : false}
        item={itemsForHouse || {}}
        qty={itemsForHouseQty}
        items={itemsSelected}
        itemId={itemId}
        onClose={() => {
          setItemsForHouse(null);
          setItemsSelected([]);
        }}
        onSelected={(item: any) => {
          setItemDetails([...itemDetails, item]);
          if (item.length > itemDetailEdit.length) {
            const _itemEdit = itemDetailEdit.filter(
              (itemEdit: any) => itemEdit.itemId !== item[0].itemId
            );
            setItemDetailEdit([..._itemEdit, ...item]);
          } else {
            if (!id) {
              const _itemEdit = itemDetailEdit.filter(
                (itemEdit: any) => itemEdit.itemId !== item[0].itemId
              );
              setItemDetailEdit([..._itemEdit, ...item]);
            } else if (id) {
              // remove item that was sent to item for house
              const _itemEdit = itemDetailEdit.filter(
                (itemEdit: any) => itemEdit.itemId !== item[0].itemId
              );
              setItemDetailEdit(() => [..._itemEdit, ...item]);
              // find delete item for house
              const _itemDetailEdit = [..._itemEdit, ...item];
              const _itemsDetailsDetele = itemDetailsOldTry.filter(
                (itemOld: any) => {
                  return !_itemDetailEdit.find(
                    (item: any) => itemOld.id === item.id
                  );
                }
              );
              setItemDetailsDelete(_itemsDetailsDetele);
            }
          }
          setItemsForHouse(null);
          setItemsSelected([]);
          setItemId(null);
        }}
      />
      <SimpleBackdrop open={isLoading} />
      <DialogCreateVendor
        name={dialogCreateVendorValue}
        open={openAddVendorDialog}
        onClose={() => setOpenAddVendorDialog(false)}
        onSave={(success) => {
          if (success) {
            vendorsStore.getAll().then((res) => {
              setVendorsList(res);
            });
          }
          setOpenAddVendorDialog(false);
        }}
      />
      <DialogCreateProduct
        name={dialogCreateProductValue}
        open={openAddProductDialog}
        onClose={() => {
          setOpenAddProductDialog(false);
        }}
        onSave={(success) => {
          if (success) {
            productsStore.getAll().then((res) => {
              setProductList(
                res.sort((a: any, b: any) => a.name.localeCompare(b.name))
              );
            });
          }
          setOpenAddProductDialog(false);
        }}
      />
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            checkIfAlready();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          } else if (openConfirm.type === "confirm-duplicate") {
            handleSave();
          }
        }}
      />
    </Box>
  );
};

export default BillCreate;
