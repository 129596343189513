import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuid4 } from "uuid";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import projectsStore from "../stores/ProjectsStore";
import housesStore from "../stores/HousesStore";
import { enqueueSnackbar } from "notistack";

interface ItemsForHouseProps {
  open: boolean;
  item: any;
  items?: any[];
  qty: number;
  itemId?: any;
  onClose: () => void;
  onSelected: (item: any) => void;
}

const ItemsForHouse: React.FC<ItemsForHouseProps> = (
  props: ItemsForHouseProps
) => {
  const [items, setItems] = useState<any[]>([
    {
      id: uuid4(),
      product: props.item?.id,
      expenseDetail: props.item?.expenseDetail,
      isNew: props.item?.isNew || false,
      itemId: props?.itemId,
    },
  ]);
  const [project, setProject] = useState<any[]>([]);
  const [house, setHouse] = useState<any[]>([]);
  const [remaining, setRemaining] = useState(
    parseFloat(
      props.qty.toLocaleString(undefined, {
        maximumFractionDigits: 10,
        minimumFractionDigits: 4,
      })
    ) || parseFloat(props.item.quantity)
  );
  const handleAddItem = () => {
    setItems([
      ...items,
      {
        id: uuid4(),
        product: props.item?.product?.id,
        expenseDetail: props.item?.expenseDetail,
        isNew: true,
        itemId: props?.itemId,
      },
    ]);
  };
  const handleRemoveItem = (id: string, index: number) => {
    const updatedItems = items.filter((item: any) => item.id !== id);
    setItems(updatedItems);
    setRemaining(() => {
      return (
        parseFloat(props.qty.toString()) -
        updatedItems.reduce((sum, item) => sum + parseFloat(item.quantity), 0)
      );
    });
  };

  useEffect(() => {
    if (props.item.length > 0) {
      setRemaining(() => {
        return (
          parseFloat(props.qty.toString()) -
          items.reduce((sum, item) => sum + parseFloat(item.quantity), 0)
        );
      });
    } else {
      setRemaining(0 || props.qty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.qty, props.open]);

  useEffect(() => {
    projectsStore.getAll().then((res) => {
      setProject(res);
    });

    housesStore.getAll().then((res) => {
      setHouse(res);
    });
  }, []);

  useEffect(() => {
    if (props.items && props.items.length > 0) {
      setItems(
        !items[0].expenseDetail
          ? props.items
          : props.items.map((item: any) => {
              return {
                ...item,
                expenseDetail: props.item?.expenseDetail,
                isNew: props.item?.isNew || false,
                isDelete: props.item?.isDelete || false,
                itemId: props?.itemId,
              };
            })
      );
      setRemaining(
        parseFloat(props.qty.toString()) -
          props.items.reduce((sum, item) => sum + parseFloat(item.quantity), 0)
      );
    }

    return () => {
      setItems(
        [
          {
            id: uuid4(),
            product: props.item?.product?.id || props.item?.product,
            expenseDetail: props.item?.expenseDetail,
            isNew: props.item?.isNew || false,
            isDelete: props.item?.isDelete || false,
            itemId: props?.itemId,
          },
        ] || []
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items, props.open]);

  return (
    <Dialog open={props.open} fullWidth maxWidth={"lg"}>
      <DialogContent>
        <Typography variant="h4">
          {props.item?.name || props.item?.product?.name || ""}
        </Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={0.5}
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
              ></Grid>
              <Grid
                item
                xs={1}
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
              >
                <Typography>ลำดับ</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>จำนวน</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>โครงการ</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>บ้าน</Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography>คงเหลือ</Typography>
              </Grid>
            </Grid>
          </Grid>
          {items.map((item, index) => (
            <Grid item xs={12} key={item.id}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={0.5}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <IconButton
                    aria-label="delete"
                    size="small"
                    color="error"
                    onClick={() => {
                      handleRemoveItem(item.id, index);
                    }}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Typography>{index + 1}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id={`item-${index}-qty`}
                    type="number"
                    value={parseFloat(item?.quantity)}
                    inputProps={{
                      max:
                        (props?.qty ? parseFloat(props?.qty.toString()) : 0) ||
                        (props.item.quantity ? props.item.quantity : 0),
                      min: 0,
                    }}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        parseFloat(e.target.value) > parseFloat(e.target.max)
                      ) {
                        e.target.value = e.target.max;
                      }
                    }}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[index].qty = parseFloat(e.target.value);
                      newItems[index].quantity = parseFloat(e.target.value);
                      newItems[index].product = {
                        id: props.item?.product?.id || props.item?.product,
                      };
                      newItems[index].expenseDetail = props.item?.expenseDetail;
                      newItems[index].isNew = props.item?.isNew || false;
                      newItems[index].itemId = props?.itemId;
                      setItems(newItems);
                      setRemaining(
                        props.qty -
                          items.reduce(
                            (sum, item) =>
                              parseFloat(sum || 0) +
                              parseFloat(
                                (item.qty ? item.qty : item.quantity) || 0
                              ),
                            0
                          )
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    value={item?.project || null}
                    disablePortal
                    options={project}
                    sx={{ width: "100%" }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, value) => {
                      const newItems = [...items];
                      newItems[index].project = value;

                      setItems(newItems);
                      if (value?.id) {
                        housesStore.getByProjectId(value?.id).then((res) => {
                          setHouse(res);
                        });
                      } else {
                        housesStore.getAll().then((res) => {
                          setHouse(res);
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={item?.house || null}
                    disablePortal
                    options={house}
                    sx={{ width: "100%" }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, value) => {
                      const newItems = [...items];
                      newItems[index].house = value;
                      setItems(newItems);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Typography>
                    {isNaN(parseFloat(remaining.toString()))
                      ? 0.0
                      : parseFloat(remaining.toString()).toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                        )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              sx={{ width: "100%" }}
              onClick={handleAddItem}
              startIcon={<AddCircleIcon />}
            >
              เพิ่มรายการ
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose()}>
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (!items.find((item) => item.quantity || item.quantity === 0)) {
              enqueueSnackbar("กรุณากรอกจำนวน", { variant: "warning" });
            } else {
              if (remaining >= 0) {
                setItems([
                  {
                    id: uuid4(),
                    product: props.item?.product,
                    itemId: props?.itemId,
                  },
                ]);
                props.onSelected(items);
              } else {
                enqueueSnackbar("จำนวนสินค้าไม่ถูกต้อง", {
                  variant: "error",
                });
              }
            }
          }}
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemsForHouse;
