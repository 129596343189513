import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,

} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { ThaiBaht } from 'thai-baht-text-ts';
interface PrintOnePOProps {
  title?: string;
  date?: string;
  companyInfo?: any;
  data: any;
  details?: any[];
}


const PrintOnePO = React.forwardRef((props: PrintOnePOProps, ref) => {

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
        "&@page": {
          size: "A4 portrait",
        },
        overflow: "hidden",
        display: "block",
        pageBreakBefore: "auto", // Allow page break before this element
      }}
      mt={5}
      mb={5}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} minWidth={650} overflow={'hidden'}>
          <Grid item xs={4} sx={{ textAlign: "start" }}>
            <Typography className="po-title" >{props?.companyInfo?.name}</Typography>
            <Typography className="po-body" >สาขา{props?.companyInfo?.branch}</Typography>
            <Typography className="po-body">{props?.companyInfo?.address} หมู่ {props?.companyInfo?.moo || '-'} ตรอก/ซอย {props?.companyInfo?.alley || '-'} ถนน {props?.companyInfo?.street || '-'} ต.{props?.companyInfo?.subDistrict?.nameTh || '-'} อ.{props?.companyInfo?.district?.nameTh || '-'} จ.{props?.companyInfo?.province?.nameTh || '-'} {props?.companyInfo?.subDistrict?.zipCode || '-'}</Typography>
            <Typography className="po-body">{props?.companyInfo?.taxId}</Typography>
            <Typography className="po-body">{props?.companyInfo?.email}</Typography>
          </Grid>
          <Grid container>
            <Grid item xs={9} display={'flex'} justifyContent={'center'} alignItems={'center'} >
              <Typography sx={{ paddingLeft: '200px' }} className="po-title">{props.title}</Typography>
            </Grid>
            <Grid item xs={3} >
              <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                <Grid item xs={3} border={1} p={1} >
                  <Typography className="po-body" fontWeight={'bold'} >เลขที่</Typography>
                </Grid>
                <Grid item xs={9} p={1} borderTop={1} borderRight={1} borderBottom={1}>
                  <Typography className="po-body"  >{props.data?.docNo}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                <Grid item xs={3} borderLeft={1} borderRight={1} borderBottom={1} p={1}>
                  <Typography className="po-body" fontWeight={'bold'} >วันที่</Typography>
                </Grid>
                <Grid item xs={9} p={1} borderRight={1} borderBottom={1}>
                  <Typography className="po-body"  >{dayjs(props.data.docDate).format('DD/MM/YYYY')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={6.9} sx={{ border: 1 }} >
              <Grid container spacing={1} textAlign={'left'} p={1} >
                <Grid item xs={3}>
                  <Typography className="po-body" fontWeight={'bold'}>ชื่อบริษัท</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="po-body" >{props.data.vendor ? props.data.vendor.name : ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="po-body" fontWeight={'bold'}>สาขา</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="po-body" >{props.data.vendor ? props.data.vendor.branch : ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="po-body" fontWeight={'bold'}>เลขประจำตัวผู้เสียภาษี</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="po-body" >{props.data.vendor && props.data.vendor.taxId ? props.data.vendor.taxId : ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="po-body" fontWeight={'bold'} >ที่อยู่</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="po-body" >{props.data.vendor && props.data.vendor.address ? props.data.vendor.address : ''}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="po-body" fontWeight={'bold'}>เบอร์โทรศัพท์</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="po-body">{props.data.vendor && props.data.vendor.tel ? props.data.vendor.tel : ''}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="po-body" fontWeight={'bold'}>อีเมล์</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className="po-body">{props.data.vendor && props.data.vendor.email ? props.data.vendor.email : ''}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={0.1}></Grid>
            <Grid item xs={5} sx={{ border: 1 }}>
              <Grid container spacing={1} textAlign={'left'} p={1}>
                <Grid item xs={3}>
                  <Typography className="po-body" fontWeight={'bold'}>เลขที่อ้างอิง</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="po-body">{props.data.refNo ? props.data.refNo : ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="po-body" fontWeight={'bold'}>วันที่กำหนดส่ง</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="po-body">{dayjs(props.data.deliveryDate).format('DD/MM/YYYY')}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="po-body" fontWeight={'bold'} >ที่อยู่จัดส่ง</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="po-body">{props.data.shipTo}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Table sx={{ border: 1 }}>
              <TableHead>
                <TableRow sx={{ padding: 0 }}>
                  <TableCell align="center" width={20} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>ลำดับ</Typography>
                  </TableCell>
                  <TableCell align="center" width={270} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>รายการ</Typography>
                  </TableCell>
                  <TableCell align="center" width={20} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>จำนวน</Typography>
                  </TableCell>
                  <TableCell align="center" width={20} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>หน่วย</Typography>
                  </TableCell>
                  <TableCell align="center" width={20} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>ราคา/หน่วย</Typography>
                  </TableCell>
                  <TableCell align="center" width={20} sx={{ border: 1 }}>
                    <Typography className="po-body" fontWeight={'bold'}>จำนวนเงิน</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.details
                  ?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="po-body" align="center" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 1, paddingBottom: 1 }}>
                        {index + 1}
                      </TableCell>
                      <TableCell className="po-body" align="left" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                        {item?.product?.name ?? ''}
                      </TableCell>
                      <TableCell className="po-body" align="right" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                        {parseFloat((item.quantity ?? 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </TableCell>
                      <TableCell className="po-body" align="right" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                        {(item.unit ?? '')}
                      </TableCell>
                      <TableCell className="po-body" align="right" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                        {parseFloat((item.price ?? 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </TableCell>
                      <TableCell className="po-body" align="right" sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>

                        {parseFloat(((item.quantity ?? 0) * (item.price ?? 0)).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}

                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Grid container mt={1} >
              <Grid item xs={8} border={1} sx={{ textAlign: 'left', padding: 1 }}>
                <Grid item xs={12} >
                  <Typography className="po-body" sx={{ fontWeight: 'bold' }}>หมายเหตุ</Typography>
                </Grid>
                <Grid item xs={12} >
                  <Typography className="po-body" >{props.data.note ? props.data.note : ''}</Typography>
                </Grid>
              </Grid>

              <Grid item xs={4} borderTop={1} borderRight={1} borderBottom={1} >
                <Grid container>
                  <Grid item xs={7.5} borderBottom={1} borderRight={1} textAlign={'left'} >
                    <Typography className="po-body" sx={{ fontWeight: 'bold', padding: 1 }}>รวมเงิน</Typography>
                  </Grid>
                  <Grid item xs={4.5} borderBottom={1} textAlign={'right'}>
                    <Typography className="po-body" sx={{ padding: 1 }}>{parseFloat((props?.data?.total || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                  </Grid>
                  <Grid item xs={7.5} borderBottom={1} borderRight={1} textAlign={'left'}>
                    <Typography className="po-body" sx={{ fontWeight: 'bold', padding: 1 }}>ส่วนลด</Typography>
                  </Grid>
                  <Grid item xs={4.5} borderBottom={1} textAlign={'right'}>
                    <Typography className="po-body" sx={{ padding: 1 }}>{parseFloat((props?.data?.discount || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                  </Grid>
                  <Grid item xs={7.5} borderBottom={1} borderRight={1} textAlign={'left'}>
                    <Typography className="po-body" sx={{ fontWeight: 'bold', padding: 1 }}>ราคาหลังหักส่วนลด</Typography>
                  </Grid>
                  <Grid item xs={4.5} borderBottom={1} textAlign={'right'}>
                    <Typography className="po-body" sx={{ padding: 1 }}>{parseFloat((parseFloat(props?.data?.total) - parseFloat(props?.data?.discount) || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                  </Grid>
                  <Grid item xs={7.5} borderRight={1} textAlign={'left'}>
                    <Typography className="po-body" sx={{ fontWeight: 'bold', padding: 1 }}>ภาษีมูลค่าเพิ่ม  7 %</Typography>
                  </Grid>
                  <Grid item xs={4.5} textAlign={'right'}>
                    <Typography className="po-body" sx={{ padding: 1 }}>{parseFloat((props?.data?.vat || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={8} borderLeft={1} borderRight={1} borderBottom={1} sx={{ textAlign: 'center', padding: 1 }}>
              <Grid item xs={12} >
                <Typography className="po-body" sx={{ fontWeight: 'bold' }}> {ThaiBaht(props?.data?.grandTotal ?? 0)}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={7.48} borderBottom={1} borderRight={1} textAlign={'left'} >
                  <Typography className="po-body" sx={{ fontWeight: 'bold', padding: 1 }}>จำนวนเงินทั้งสิ้น</Typography>
                </Grid>
                <Grid item xs={4.52} borderBottom={1} borderRight={1} textAlign={'right'}>
                  <Typography className="po-body" sx={{ padding: 1 }}>{parseFloat((props?.data?.grandTotal || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={1} >
            <Grid item xs={6} border={1} sx={{ textAlign: 'left', padding: 1 }}>
              <Grid item xs={12} >
                <Typography className="po-body" sx={{ fontWeight: 'bold' }}>เงื่อนไขอื่นๆ</Typography>
              </Grid>
              <Grid item xs={12} justifyContent='flex-start'>
                <Typography className="po-body" >{props.data.condition}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={2} borderTop={1} borderRight={1} borderBottom={1} paddingBottom={8} >
              <Typography className="po-body" sx={{ fontWeight: 'bold', padding: 1 }}>ผู้จัดทำ</Typography>
            </Grid>
            <Grid item xs={2} borderTop={1} borderRight={1} borderBottom={1} >
              <Typography className="po-body" sx={{ fontWeight: 'bold', padding: 1 }}>ผู้ตรวจสอบ</Typography>
            </Grid>
            <Grid item xs={2} borderTop={1} borderRight={1} borderBottom={1}>
              <Typography className="po-body" sx={{ fontWeight: 'bold', padding: 1 }}>ผู้มีอำนาจลงนาม</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default PrintOnePO;
