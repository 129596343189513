import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class ProductGroupsStore {
  productGroups: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/product-groups");
      this.productGroups = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productGroups:", error);
      return {
        error: true,
        message: "An error occurred while getting productGroups",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/product-groups/${id}`);
      this.productGroups = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productGroup:", error);
      return {
        error: true,
        message: "An error occurred while getting productGroup",
      };
    }
  }

  async getByName(name: string) {
    try {
      const response = await apiService.get(`/product-groups/name/${name}`);
      this.productGroups = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productGroup:", error);
      return {
        error: true,
        message: "An error occurred while getting productGroup",
      };
    }
  }

  async getByNameLike(name: string) {
    try {
      const response = await apiService.get(
        `/product-groups/name/like/${name}`
      );
      this.productGroups = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productGroup:", error);
      return {
        error: true,
        message: "An error occurred while getting productGroup",
      };
    }
  }

  async getByCriteria(object: any) {
    try {
      const response = await apiService.post(
        `/product-groups/criteria`, object
      );
      this.productGroups = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productGroup:", error);
      return {
        error: true,
        message: "An error occurred while getting productGroup",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/product-groups", data);
      this.productGroups = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating productGroup:", error);
      return {
        error: true,
        message: "An error occurred while creating productGroup",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/product-groups/${id}`, data);
      this.productGroups = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating productGroup:", error);
      return {
        error: true,
        message: "An error occurred while updating productGroup",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/product-groups/${id}`);
      this.productGroups = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting productGroup:", error);
      return {
        error: true,
        message: "An error occurred while deleting productGroup",
      };
    }
  }
}
const productGroupsStore = new ProductGroupsStore();
export default productGroupsStore;
