import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import authStore from "../stores/AuthStore";
import { enqueueSnackbar } from "notistack";
import productsStore from "../stores/ProductsStore";

interface DialogCreateProductProps {
  open: boolean;
  name: string;
  onClose: () => void;
  onSave: (success: boolean) => void;
}

const DialogCreateProduct: React.FC<DialogCreateProductProps> = (props: DialogCreateProductProps) => {
  /* NOTE - Keep it in consideration for future use
  const [productGroup, setProductGroup] = useState('');
  const [productCategory, setProductCategory] = useState('');*/

  const [productName, setProductName] = useState(props.name);
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [unit, setUnit] = useState('');
  const [description, setDescription] = useState('');

  /* NOTE - Keep it in consideration for future use
  const [productGroupData, setProductGroupData] = useState<any[]>([]);
  const [productCategoryData, setProductCategoryData] = useState<any[]>([]);

  const handleChangeProductGroup = (event: SelectChangeEvent) => {
    setProductGroup(event.target.value as string);
  };

  const handleChangeProductCategory = (event: SelectChangeEvent) => {
    setProductCategory(event.target.value as string);
  };*/

  const handleSave = () => {
    productsStore.create({
      //NOTE - Keep it in consideration for future use
      // productCategory: productCategory ? productCategory : null,
      // productGroup: productGroup ? productGroup : null,
      name: productName,
      size: size,
      color: color,
      unit: unit,
      description: description,
      createBy: authStore.user?.id,
      createAt: new Date(),
    }).then((res) => {
      if (res.error) {
        enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
        return;
      }
      enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      props.onSave(true);
    });
  };

  useEffect(() => {
    setProductName(props.name);
    /* NOTE - Keep it in consideration for future use
    productCategoriesStore.getAll().then((pcResult) => {
      setProductCategoryData(pcResult);
      productGroupsStore.getAll().then((res) => {
        setProductGroupData(res);
      });
    });*/
  }, [props.name]);

  return (
    <Dialog open={props.open} fullWidth maxWidth={'md'}>
      <DialogContent >
        <Typography variant="h6">สร้างสินค้า</Typography>
        <Grid container spacing={2} mt={1}>
          {/* NOTE - Keep it in consideration for future use 
           <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="productCategory-select-label">ประเภทสินค้า</InputLabel>
              <Select
                labelId="productCategory-select-label"
                id="productCategory-select"
                value={productCategory}
                label="ประเภทสินค้า"
                onChange={handleChangeProductCategory} >
                {productCategoryData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} >
            <FormControl fullWidth>
              <InputLabel id="productGroup-select-label">หมวดหมู่สินค้า</InputLabel>
              <Select
                labelId="productGroup-select-label"
                id="productGroup-select"
                value={productGroup}
                label="หมวดหมู่สินค้า"
                onChange={handleChangeProductGroup}
              >
                {productGroupData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={12} >
            <TextField id="outlined-basic" label="ชื่อสินค้า" variant="outlined" fullWidth
              onChange={(event) => setProductName(event.target.value)} value={productName}
            />
          </Grid>
          <Grid item xs={3} >
            <TextField id="outlined-basic" label="ขนาด" variant="outlined" fullWidth
              value={size}
              onChange={(event) => setSize(event.target.value)}
            />
          </Grid>
          <Grid item xs={3} >
            <TextField id="outlined-basic" label="สี" variant="outlined" fullWidth
              value={color}
              onChange={(event) => setColor(event.target.value)}
            />
          </Grid>
          <Grid item xs={3} >
            <TextField id="outlined-basic" label="หน่วย" variant="outlined" fullWidth
              value={unit}
              onChange={(event) => setUnit(event.target.value)}
            />
          </Grid>
          <Grid item xs={3} >
            <TextField id="outlined-basic" label="รายละเอียด" variant="outlined" fullWidth multiline
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose()}>ยกเลิก</Button>
        <Button variant="contained" onClick={() => handleSave()}>บันทึก</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateProduct;
