import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { v4 as uuid4 } from 'uuid';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisuallyHiddenInput from './VisuallyHiddenInput';

interface LandOfHouseDialogProps {
  open: boolean;
  item: any;
  house: any[];
  project: any[];
  subDistrict: any[];
  district: any[];
  province: any[];
  projectId?: '';
  landOption?: any[];
  onClose: () => void;
  onSelected: (item: any) => void;
}

const LandOfHouseDialog: React.FC<LandOfHouseDialogProps> = (props: LandOfHouseDialogProps) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [deedNo, setDeedNo] = useState('');
  const [volumn, setVolumn] = useState('');
  const [landDistrict, setLandDistrict] = useState('');
  const [landProvince, setLandProvince] = useState('');
  const [page, setPage] = useState('');
  const [mapsheet, setMapsheet] = useState('');
  const [parcelNo, setParcelNo] = useState('');
  const [dealingFileNo, setDealingFileNo] = useState('');
  const [landSubDistrict, setLandSubDistrict] = useState('');
  const [rai, setRai] = useState<number | ''>('');
  const [ngan, setNgan] = useState<number | ''>('');
  const [squareWah, setSquareWah] = useState<number | ''>('');
  const [landPrice, setLandPrice] = useState<number | ''>('');
  const [registrationFee, setRegistrationFee] = useState<number | ''>('');
  const [transferer, setTransferer] = useState('');
  const [transferee, setTransferee] = useState('');
  const [subDistrict, setSubDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [address, setAddress] = useState('');
  const [village, setVillage] = useState('');
  const [nationality, setNationality] = useState('');
  const [provinceValue, setProvinceValue] = useState('');
  const [districtValue, setDistrictValue] = useState('');
  const [subDistrictValue, setSubDistrictValue] = useState('');
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);
  const [zipCode, setZipCode] = useState('');
  const [landSubDistrictOptions, setLandSubDistrictOptions] = useState<any>([]);
  const [landProvinceOptions, setLandProvinceOptions] = useState<any[]>([]);
  const [landDistrictOptions, setLandDistrictOptions] = useState<any>([]);
  const [landDistrictValue, setLandDistrictValue] = useState('');
  const [landProvinceValue, setLandProvinceValue] = useState('');
  const [landSubDistrictValue, setLandSubDistrictValue] = useState('');
  const [mortgageFee, setMortgageFee] = useState<number | ''>('');
  const [itemId, setItemId] = useState('');
  const [deedDate, setDeedDate] = useState<Dayjs | null>(dayjs());
  const [project, setProject] = useState<any[]>([]);
  const [house, setHouse] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  const [taxDate, setTaxDate] = useState<Dayjs | null>(dayjs());
  const [requestFee, setRequestFee] = useState<number | ''>('');
  const [lgoFee, setLgoFee] = useState<number | ''>('');
  const [totalFee, setTotalFee] = useState<number | ''>('');
  const [personalTaxIncome, setPersonalTaxIncome] = useState<number | ''>('');
  const [corporateIncomeTax, setCorporateIncomeTax] = useState<number | ''>('');
  const [stampDuty, setStampDuty] = useState<number | ''>('');
  const [specificBusinessTax, setSpecificBusinessTax] = useState<number | ''>('');
  const [localIncome, setLocalIncome] = useState<number | ''>('');
  const [totalTax, setTotalTax] = useState<number | ''>('');
  const [grandTotalTax, setGrandTotalTax] = useState<number | ''>('');
  const [remarkText, setRemarkText] = useState('');
  const [landTaxId, setLandTaxId] = useState('');
  const [isCreate, setIsCreate] = useState(true);
  const [landOption, setLandOption] = useState<any[]>([]);
  const [oldLand, setOldLand] = useState<any>([]);

  const handleFileChange = (e?: ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;
    if (files && files.length > 0) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    } else {
      setSelectedFiles([]);
    }
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleReset = () => {
    setProject([]);
    setHouse([]);
    setDeedNo('');
    setVolumn('');
    setLandDistrict('');
    setLandDistrictValue('');
    setLandProvince('');
    setLandProvinceValue('');
    setPage('');
    setMapsheet('');
    setParcelNo('');
    setDealingFileNo('');
    setLandSubDistrict('');
    setLandSubDistrictValue('');
    setRai('');
    setNgan('');
    setSquareWah('');
    setLandPrice('');
    setRegistrationFee('');
    setTransferer('');
    setTransferee('');
    setSubDistrict('');
    setSubDistrictValue('');
    setProvince('');
    setProvinceValue('');
    setDistrict('');
    setDistrictValue('');
    setAddress('');
    setVillage('');
    setNationality('');
    setImages([]);
    handleFileChange();
    setZipCode('');
    setMortgageFee('');
    setItemId('');
    setTaxDate(dayjs());
    setRequestFee('');
    setLgoFee('');
    setTotalFee('');
    setPersonalTaxIncome('');
    setCorporateIncomeTax('');
    setStampDuty('');
    setSpecificBusinessTax('');
    setLocalIncome('');
    setGrandTotalTax('');
    setTotalTax('');
    setRemarkText('');
    setLandTaxId('');
    setOldLand([]);
    setIsCreate(true);
  };

  const handleChangData = (e: ChangeEvent<HTMLInputElement>, type: string) => {
    const value: any = e.target?.value;
    const date: any = e;
    switch (type) {
      case 'taxDate': setTaxDate(date);
        break;
      case 'requestFee': setRequestFee(value);
        break;
      case 'personalTaxIncome': setPersonalTaxIncome(value);
        break;
      case 'lgoFee': setLgoFee(value);
        break;
      case 'totalFee': setPersonalTaxIncome(value);
        break;
      case 'corporateIncomeTax': setCorporateIncomeTax(value);
        break;
      case 'stampDuty': setStampDuty(value);
        break;
      case 'specificBusinessTax': setSpecificBusinessTax(value);
        break;
      case 'localIncome': setLocalIncome(value);
        break;
      case 'grandTotalTax': setGrandTotalTax(value);
        break;
      case 'totalTax': setTotalTax(value);
        break;
      case 'remarkText': setRemarkText(value);
        break;

    }
  };

  useMemo(() => {
    const result = parseFloat(requestFee.toString() || '0') + parseFloat(lgoFee.toString() || '0') + parseFloat(personalTaxIncome.toString() || '0') + parseFloat(corporateIncomeTax.toString() || '0') + parseFloat(stampDuty.toString() || '0') + parseFloat(specificBusinessTax.toString() || '0') + parseFloat(localIncome.toString() || '0');
    setGrandTotalTax(result);
    return result;
  }, [requestFee, lgoFee, personalTaxIncome, corporateIncomeTax, stampDuty, specificBusinessTax, localIncome]);

  useMemo(() => {
    const result = parseFloat(requestFee.toString() || '0') + parseFloat(lgoFee.toString() || '0');
    setTotalFee(result);
  }, [requestFee, lgoFee]);

  useMemo(() => {
    const result = parseFloat(personalTaxIncome.toString() || '0') + parseFloat(corporateIncomeTax.toString() || '0') + parseFloat(stampDuty.toString() || '0') + parseFloat(specificBusinessTax.toString() || '0') + parseFloat(localIncome.toString() || '0');
    setTotalTax(result);
  }, [personalTaxIncome, corporateIncomeTax, stampDuty, specificBusinessTax, localIncome]);

  const handleChangeIsCreate = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === 'true') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  };

  const handleOnSelect = () => {
    let data: any = {};
    if (isCreate) {
      data = {
        id: itemId || undefined,
        project: project || undefined,
        house: house || undefined,
        deedNo: deedNo,
        volumn: volumn,
        landDistrict: landDistrict || undefined,
        landProvince: landProvince || undefined,
        page: page,
        mapsheet: mapsheet,
        parcelNo: parcelNo,
        dealingFileNo: dealingFileNo,
        landSubDistrict: landSubDistrict || undefined,
        rai: rai || undefined,
        ngan: ngan || undefined,
        squareWah: squareWah || undefined,
        landPrice: landPrice || undefined,
        registrationFee: registrationFee || undefined,
        transferer: transferer,
        transferee: transferee,
        subDistrict: subDistrict || undefined,
        province: province || undefined,
        district: district || undefined,
        zipCode: zipCode,
        address: address,
        village: village,
        nationality: nationality,
        mortgageFee: mortgageFee || undefined,
        images: [...selectedFiles || [], ...images || []],
        deleteImages: deleteImages,
        landTax: {
          id: landTaxId || undefined,
          land: itemId || undefined,
          taxDate: taxDate?.format('YYYY-MM-DD'),
          requestFee: requestFee || undefined,
          lgoFee: lgoFee || undefined,
          totalFee: totalFee || undefined,
          personalTaxIncome: personalTaxIncome || undefined,
          corporateIncomeTax: corporateIncomeTax || undefined,
          stampDuty: stampDuty || undefined,
          specificBusinessTax: specificBusinessTax || undefined,
          localIncome: localIncome || undefined,
          totalTax: totalTax || undefined,
          grandTotalTax: grandTotalTax || undefined,
          remarkText: remarkText || undefined
        },
      };
    } else {
      data = {
        ...oldLand,
        id: itemId || undefined,
        oldLand: oldLand || undefined,
      };
    }
    props.onSelected(data);
    handleReset();
  };
  const handleChangeLand = (event: SelectChangeEvent, value: any) => {
    setDeedNo(value);
    setOldLand(value);
  };

  useEffect(() => {
    setLandOption(props?.landOption || []);
    if (props?.item && props?.item !== null && !props?.item?.oldLand) {
      setProject(props?.item?.project);
      setHouse(props?.item?.house);
      setDeedNo(props?.item?.deedNo);
      setVolumn(props?.item?.volumn);
      setLandDistrict(props?.item?.landDistrict);
      setLandDistrictValue(props?.item?.landDistrict);
      setLandProvince(props?.item?.landProvince);
      setLandProvinceValue(props?.item?.landProvince);
      setPage(props?.item?.page);
      setMapsheet(props?.item?.mapsheet);
      setParcelNo(props?.item?.parcelNo);
      setDealingFileNo(props?.item?.dealingFileNo);
      setLandSubDistrict(props?.item?.landSubDistrict);
      setLandSubDistrictValue(props?.item?.landSubDistrict);
      setRai(props?.item?.rai);
      setNgan(props?.item?.ngan);
      setSquareWah(props?.item?.squareWah);
      setLandPrice(props?.item?.landPrice);
      setRegistrationFee(props?.item?.registrationFee);
      setTransferer(props?.item?.transferer);
      setTransferee(props?.item?.transferee);
      setSubDistrict(props?.item?.subDistrict);
      setSubDistrictValue(props?.item?.subDistrict);
      setProvince(props?.item?.province);
      setProvinceValue(props?.item?.province);
      setDistrict(props?.item?.district);
      setDistrictValue(props?.item?.district);
      setAddress(props?.item?.address);
      setVillage(props?.item?.village);
      setNationality(props?.item?.nationality);
      if (props?.item?.images) { setImages(props?.item?.images); }
      setZipCode(props?.item?.zipCode);
      setMortgageFee(props?.item?.mortgageFee);
      setItemId(props?.item?.id || uuid4());
      setTaxDate(dayjs(!props?.item?.landTax?.taxDate ? new Date() : dayjs(props?.item?.landTax?.taxDate)));
      setRequestFee(props?.item?.landTax?.requestFee || '');
      setLgoFee(props?.item?.landTax?.lgoFee || '');
      setTotalFee(props?.item?.landTax?.totalFee || '');
      setPersonalTaxIncome(props?.item?.landTax?.personalTaxIncome || '');
      setCorporateIncomeTax(props?.item?.landTax?.corporateIncomeTax || '');
      setStampDuty(props?.item?.landTax?.stampDuty || '');
      setSpecificBusinessTax(props?.item?.landTax?.specificBusinessTax || '');
      setLocalIncome(props?.item?.landTax?.localIncome || '');
      setGrandTotalTax(props?.item?.landTax?.grandTotalTax || '');
      setTotalTax(props?.item?.landTax?.totalTax || '');
      setRemarkText(props?.item?.landTax?.remarkText || '');
      setLandTaxId(props?.item?.landTax?.id || undefined);

    } else if (props?.item && props?.item !== null && props?.item?.oldLand) {
      setItemId(props?.item?.id);
      setDeedNo(props?.item?.oldLand);
      setOldLand(props?.item?.oldLand);
      setIsCreate(false);
    } else {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.item]);

  return (
    <Dialog open={props.open} fullWidth maxWidth={'lg'}>
      <DialogContent >
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontWeight={'bold'}>ข้อมูลโฉนดที่ดิน</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={isCreate}
                    onChange={handleChangeIsCreate}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="สร้างโฉนดใหม่" />
                    <FormControlLabel value={false} control={<Radio />} label="เลือกโฉนดที่มีอยู่แล้ว" />

                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography mt={1} mb={1}>โฉนดที่ดิน</Typography>
                <Grid container spacing={2}>
                  {isCreate &&
                    <>
                      <Grid item xs={3}>
                        <TextField label="เลขที่"
                          id={`deedNo-${props?.item?.id}`}
                          type="text" variant="outlined"
                          value={deedNo}
                          onChange={(e) => setDeedNo(e.target.value)}
                          fullWidth />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="เล่มที่"
                          id={`volumn-${props?.item?.id}`}
                          type="text" variant="outlined"
                          value={volumn}
                          onChange={(e) => setVolumn(e.target.value)
                          }
                          fullWidth />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="หน้า"
                          id={`page-${props?.item?.id}`}
                          type="text" variant="outlined"
                          value={page}
                          onChange={(e) => setPage(e.target.value)}
                          fullWidth />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={landDistrictValue}
                          onChange={(e: any, value: any) => {
                            if (value?.id && value?.id !== -1 && value?.nameTh && isCreate) {
                              setLandDistrict(value?.id);
                              setLandDistrictValue(value?.nameTh);
                            }
                          }}
                          disablePortal
                          options={landDistrictOptions}
                          sx={{ width: '100%' }}
                          renderOption={(props, option: any) => <li {...props}>{option?.nameTh}</li>}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="อำเภอ"
                              helperText='กรุณากรอกอย่างน้อย 3 ตัวอักษร'
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue && inputValue.length >= 3) {
                                  // Filter options based on user input
                                  const filteredOptions = props?.district.filter((district: any) =>
                                    district.nameTh.includes(inputValue)
                                  );
                                  const filteredProvinceOptions = filteredOptions.map((district: any) => district.province);
                                  setLandDistrictOptions(filteredOptions);
                                  setLandProvinceOptions(filteredProvinceOptions);
                                } else {
                                  setLandDistrictOptions([{ id: -1, nameTh: 'กรุณากรอกอำเภออย่างน้อย 3 ตัวอักษร' }]);
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={landProvinceValue}
                          onChange={(e: any, value) => {
                            if (value?.id) {
                              setLandProvince(value?.id);
                              setLandProvinceValue(value?.nameTh);
                            }
                          }}

                          options={landProvinceOptions}
                          sx={{ width: '100%' }}
                          renderOption={(props, option) => <li {...props}>{option.nameTh}</li>}
                          getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh;
                          }}
                          renderInput={(params) => <TextField {...params} label="จังหวัด" />}
                          disabled={landProvinceOptions.length > 0 ? false : true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography mb={1}>ตำแหน่งที่ดิน</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <TextField label="ระวาง"
                              id={`mapsheet-${props?.item?.id}`}
                              type="text" variant="outlined"
                              value={mapsheet}
                              onChange={(e) => setMapsheet(e.target.value)}
                              fullWidth />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField label="เลขที่ดิน"
                              id={`parcelNo-${props?.item?.id}`}
                              type="text" variant="outlined"
                              value={parcelNo}
                              onChange={(e) => setParcelNo(e.target.value)}
                              fullWidth />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField label="หน้าสำรวจ"
                              id={`dealingFileNo-${props?.item?.id}`}
                              type="text" variant="outlined"
                              value={dealingFileNo}
                              onChange={(e) => setDealingFileNo(e.target.value)}
                              fullWidth />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              value={landSubDistrictValue}
                              onChange={(e: any, value: any) => {
                                if (value?.id) {
                                  setLandSubDistrict(value?.id);
                                  setLandSubDistrictValue(value?.nameTh);
                                }
                              }}
                              disablePortal
                              options={landSubDistrictOptions}
                              sx={{ width: '100%' }}
                              renderOption={(props, option: any) => <li {...props}>{option.nameTh}</li>}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                return option.nameTh;
                              }}
                              renderInput={(params) => <TextField {...params} label="ตำบล"
                                helperText='กรุณากรอกอย่างน้อย 3 ตัวอักษร'
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (inputValue && inputValue.length >= 3) {
                                    const filteredOptions = (landSubDistrictOptions.length > 0) ? landSubDistrictOptions.filter((subDistrict: any) => subDistrict.nameTh.includes(inputValue)) : props?.subDistrict.filter((subDistrict: any) => subDistrict.nameTh.includes(inputValue));
                                    setLandSubDistrictOptions(filteredOptions);
                                  } else {
                                    setLandSubDistrictOptions([{ id: -1, nameTh: 'กรุณากรอกตำบลอย่างน้อย 3 ตัวอักษร' }]);
                                  }
                                }} />}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography mb={1}>เนื้อที่ดิน</Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <TextField label="ไร่"
                                  id={`rai-${props?.item?.id}`}
                                  type="number" variant="outlined"
                                  value={rai ? parseFloat(rai.toString()) : ''}
                                  onChange={(e) => setRai(parseFloat(e.target.value))}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField label="งาน"
                                  id={`ngan-${props?.item?.id}`}
                                  type="number" variant="outlined"
                                  value={ngan ? parseFloat(ngan.toString()) : ''}
                                  onChange={(e) => setNgan(parseFloat(e.target.value))}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField label="ตร.วา"
                                  type="number"
                                  id={`squareWah-${props?.item?.id}`}
                                  variant="outlined"
                                  value={squareWah ? parseFloat(squareWah.toString()) : ''}
                                  onChange={(e) => setSquareWah(parseFloat(e.target.value))}
                                  fullWidth />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography mt={1} mb={1}>ข้อมูลการจดทะเบียนสิทธิ</Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                  <DemoContainer components={['DatePicker', 'DatePicker']} >
                                    <DatePicker
                                      label="วันที่ออกโฉนด"
                                      value={deedDate}
                                      onChange={(newValue) => setDeedDate(newValue)} sx={{ width: '100%' }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={4.5}>
                                <DemoContainer components={['Autocomplete', 'Autocomplete']}>
                                  <Autocomplete
                                    disablePortal
                                    value={project}
                                    options={props?.project || []}
                                    sx={{ width: '100%' }}
                                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                    getOptionLabel={(option) => {
                                      if (typeof option === 'string') {
                                        return option || '';
                                      }
                                      if (option.inputValue) {
                                        return option.inputValue || '';
                                      }
                                      return option.name || '';
                                    }}
                                    renderInput={(params) => <TextField {...params} label="โครงการ" />}
                                    onChange={(e, value) => {
                                      setProject(value);
                                    }}
                                  />
                                </DemoContainer>

                              </Grid>
                              <Grid item xs={4.5}>
                                <DemoContainer components={['Autocomplete', 'Autocomplete']}>
                                  <Autocomplete
                                    value={house}
                                    disablePortal
                                    options={props?.house || []}
                                    sx={{ width: '100%' }}
                                    renderOption={(props, option) =>

                                      <li {...props}>{option.name}</li>
                                    }
                                    getOptionLabel={(option) => {
                                      if (typeof option === 'string') {
                                        return option || '';
                                      }
                                      if (option.inputValue) {
                                        return option.inputValue || '';
                                      }
                                      return option.name || '';
                                    }}
                                    renderInput={(params) => <TextField {...params} label="บ้าน"
                                    />}
                                    onChange={(e, value) => {
                                      setHouse(value);
                                    }}
                                  />
                                </DemoContainer>
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="ผู้ให้สัญญา"
                                  id={`transferer-${props?.item?.id}`}
                                  type="text" variant="outlined"
                                  value={transferer}
                                  onChange={(e) => setTransferer(e.target.value)}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="สัญชาติ"
                                  id={`nationality-${props?.item?.id}`}
                                  type="text" variant="outlined"
                                  value={nationality}
                                  onChange={(e) => setNationality(e.target.value)}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="บ้านเลขที่"
                                  id={`address-${props?.item?.id}`}
                                  type="text" variant="outlined"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="หมู่ที่"
                                  id={`village-${props?.item?.id}`}
                                  type="text" variant="outlined"
                                  value={village}
                                  onChange={(e) => setVillage(e.target.value)}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3}>
                                <Autocomplete
                                  value={provinceValue}
                                  id="province"
                                  onChange={(e: any, value: any) => {
                                    if (value?.id) {
                                      setProvince(value?.id);
                                      setProvinceValue(value?.nameTh);
                                      const _districtOptions = props?.district.filter((district: any) => district?.province?.id === value?.id
                                      );
                                      setDistrictOptions(_districtOptions);
                                    }
                                  }}
                                  disablePortal
                                  options={props?.province}
                                  sx={{ width: '100%' }}
                                  renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                                  getOptionLabel={(option: any) => {
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    return option.nameTh;
                                  }}
                                  renderInput={(params) => <TextField {...params} label="จังหวัด" />}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Autocomplete
                                  value={districtValue}
                                  id="district"
                                  onChange={(e: any, value: any) => {
                                    setDistrict(value);
                                    setDistrictValue(value?.nameTh);
                                    if (value?.id) {
                                      const _subDistrictOptions = props?.subDistrict.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                                      setSubDistrictOptions(_subDistrictOptions);
                                    }
                                  }}
                                  disablePortal
                                  options={districtOptions}
                                  sx={{ width: '100%' }}
                                  renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                                  getOptionLabel={(option: any) => {
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    return option.nameTh;
                                  }}
                                  renderInput={(params) => <TextField {...params} label="อำเภอ" />}
                                  disabled={districtOptions.length > 0 ? false : true}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Autocomplete
                                  value={subDistrictValue ?? ''}
                                  id="subDistric"
                                  onChange={(e: any, value: any) => {
                                    if (value?.id) {
                                      setSubDistrict(value?.id);
                                      setSubDistrictValue(value?.nameTh);
                                    }
                                    if (value?.zipCode) { setZipCode(value?.zipCode); }
                                  }}
                                  disablePortal
                                  options={subDistrictOptions}
                                  sx={{ width: '100%' }}
                                  renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                                  getOptionLabel={(option: any) => {
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    return option.nameTh;
                                  }}
                                  renderInput={(params) => <TextField {...params} label="ตำบล" />}
                                  disabled={subDistrictOptions.length > 0 ? false : true}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="รหัสไปรษณีย์"
                                  id={`district-${props?.item?.id}`}
                                  type="text" variant="outlined"
                                  value={zipCode}
                                  onChange={(e) => setZipCode(e.target.value)}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="ผู้รับสัญญา"
                                  id={`transferee-${props?.item?.id}`}
                                  type="text" variant="outlined"
                                  value={transferee}
                                  onChange={(e) => setTransferee(e.target.value)}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="มูลค่าที่ดิน"
                                  id={`landPrice-${props?.item?.id}`}
                                  type="number" variant="outlined"
                                  value={landPrice ? parseFloat(landPrice.toString()) : ''}
                                  onChange={(e) => setLandPrice(parseFloat(e.target.value))}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="ค่าโอนที่ดิน"
                                  id={`registrationFee-${props?.item?.id}`}
                                  type="number" variant="outlined"
                                  value={registrationFee ? parseFloat(registrationFee.toString()) : ''}
                                  onChange={(e) => setRegistrationFee(parseFloat(e.target.value))}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField label="ค่าจดจำนอง"
                                  id={`registrationFee-${props?.item?.id}`}
                                  type="number" variant="outlined"
                                  value={mortgageFee ? parseFloat(mortgageFee.toString()) : ''}
                                  onChange={(e) => setMortgageFee(parseFloat(e.target.value))}
                                  fullWidth />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography mt={1} mb={1}>ข้อมูลภาษีและค่าธรรมเนียมที่ดิน</Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                  <DemoContainer components={['DatePicker', 'DatePicker']} >
                                    <DatePicker
                                      label='วันที่จ่ายภาษี'
                                      value={taxDate}
                                      onChange={(newValue: any) => handleChangData(newValue, 'taxDate')}
                                      sx={{ width: '100%' }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='ค่าคำขอ'
                                  id={`requestFee-${props?.item?.id}`}
                                  type='number' variant='outlined'
                                  value={requestFee ? parseFloat(requestFee.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'requestFee')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='ค่าธรรมเนียม อปท.'
                                  id={`lgoFee-${props?.item?.id}`}
                                  type='number' variant='outlined'
                                  value={lgoFee ? parseFloat(lgoFee.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'lgoFee')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='รวมค่าธรรมเนียม'
                                  type='number'
                                  id={`totalFee-${props?.item?.id}`}
                                  variant='outlined'
                                  value={totalFee ? parseFloat(totalFee.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'totalFee')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='ภาษีเงินได้บุคคลธรรมดา'
                                  type='number'
                                  id={`personalTaxIncome-${props?.item?.id}`}
                                  variant='outlined'
                                  value={personalTaxIncome ? parseFloat(personalTaxIncome.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'personalTaxIncome')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='ภาษีเงินได้นิติบุคคล'
                                  type='number'
                                  id={`corporateIncomeTax-${props?.item?.id}`}
                                  variant='outlined'
                                  value={corporateIncomeTax ? parseFloat(corporateIncomeTax.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'corporateIncomeTax')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='ค่าอากรแสตมป์'
                                  type='number'
                                  id={`stampDuty-${props?.item?.id}`}
                                  variant='outlined'
                                  value={stampDuty ? parseFloat(stampDuty.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'stampDuty')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='ภาษีธุรกิจเฉพาะ'
                                  type='number'
                                  id={`specificBusinessTax-${props?.item?.id}`}
                                  variant='outlined'
                                  value={specificBusinessTax ? parseFloat(specificBusinessTax.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'specificBusinessTax')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='รายได้ส่วนท้องถิ่น'
                                  type='number'
                                  id={`localIncome-${props?.item?.id}`}
                                  variant='outlined'
                                  value={localIncome ? parseFloat(localIncome.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'localIncome')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={3} mt={1}>
                                <TextField label='รวมทั้งสิ้นค่าภาษีอากร'
                                  type='number'
                                  id={`totalTax-${props?.item?.id}`}
                                  variant='outlined'
                                  value={totalTax ? parseFloat(totalTax.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'totalTax')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={6} mt={1}>
                                <TextField label='รวมทั้งสิ้นค่าธรรมเนียมและค่าภาษีอากร'
                                  type='number'
                                  id={`grandTotalTax-${props?.item?.id}`}
                                  variant='outlined'
                                  value={grandTotalTax ? parseFloat(grandTotalTax.toString()) : ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'grandTotalTax')}
                                  fullWidth />
                              </Grid>
                              <Grid item xs={12} mt={1}>
                                <TextField label='หมายเหตุ'
                                  type='text'
                                  id={`remarkText-${props?.item?.id}`}
                                  variant='outlined'
                                  value={remarkText ?? ''}
                                  onChange={(newValue: any) => handleChangData(newValue, 'remarkText')}
                                  fullWidth />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}><Divider /></Grid>
                          <Grid item xs={8} >
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography>เอกสาร</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                                  เลือกไฟล์
                                  <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                                    onChange={handleFileChange} />
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                {selectedFiles.length > 0 && (
                                  <Box component={'div'} mt={1}>
                                    {selectedFiles.map((file: any, index: number) => (
                                      <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                                        <Grid container >
                                          <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                            <IconButton size="small" color="error"
                                              onClick={() => handleFileRemove(index)}
                                            >
                                              <RemoveCircleIcon />
                                            </IconButton>
                                          </Grid>
                                          <Grid item xs={11.5}>
                                            <img
                                              src={URL.createObjectURL(file)}
                                              alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                              width="500"
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Box>
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                {images && images.length > 0 && (
                                  <Box component={'div'} mt={1}>
                                    {images.map((file: any, index: number) => (
                                      <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                                        <Grid container >
                                          <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                            <IconButton size="small" color="error"
                                              onClick={() => handleFileRemoveImageList(file)}>
                                              <RemoveCircleIcon />
                                            </IconButton>
                                          </Grid>
                                          <Grid item xs={11.5}>
                                            <img
                                              src={file.id ? `${apiUrl}/uploads/${file.name}` : URL.createObjectURL(file)}
                                              alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                              width="500"
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  }
                  {!isCreate &&
                    <>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <Autocomplete
                            id="landOption"
                            options={landOption} //ข้อมูลธนาคารทั้งหมด
                            getOptionLabel={(option) => option.deedNo} //เลือกวิธีดึงข้อมูลโดยดึงข้อมูลของ
                            value={deedNo} //หาค่าที่ให้ตรงกับ id
                            onChange={(e: any, value: any) => { handleChangeLand(e, value); }}
                            renderInput={(params) => (
                              <TextField {...params} label="เลขที่" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>เล่มที่:</b>  {oldLand?.volumn || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>หน้า:</b>  {oldLand?.page || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>อำเภอ:</b> {oldLand?.landDistrict?.nameTh || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ระวาง:</b>  {oldLand?.mapsheet || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>เลขที่ดิน:</b>  {oldLand?.dealingFileNo || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ตำบล:</b> {oldLand?.landSubDistrict?.nameTh || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}><Divider /></Grid>
                      <Grid item xs={12}>
                        <Typography mb={1}>เนื้อที่ดิน</Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ไร่:</b> {oldLand?.rai ? parseFloat(oldLand?.rai) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>งาน:</b>  {oldLand?.ngan ? parseFloat(oldLand?.ngan) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ตร.วา:</b>  {oldLand?.squareWah ? parseFloat(oldLand?.squareWah) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}><Divider /></Grid>
                      <Grid item xs={12}>
                        <Typography mb={1}>ข้อมูลการจดทะเบียนสิทธิ</Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>วันที่ออกโฉนด:</b> {oldLand?.deedDate ? dayjs(oldLand?.deedDate).format('DD/MM/YYYY') : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={4.5} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>โครงการ:</b>  {oldLand?.project?.name || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={4.5} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>บ้าน:</b> {oldLand?.house?.name || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ผู้ให้สัญญา:</b>  {oldLand?.transferer || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={4.5} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>สัญชาติ:</b>  {oldLand?.nationality || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={4.5} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ที่อยู่:</b>  {oldLand?.address || '-'} หมู่ {oldLand?.village || '-'} ต.{oldLand?.subDistric?.nameTh || '-'} อ.{oldLand?.district?.nameTh || '-'} จ.{oldLand?.province?.nameTh || '-'} {oldLand?.zipCode || '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ผู้รับสัญญา:</b>  {oldLand?.transferee || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>มูลค่าที่ดิน:</b> {oldLand?.landPrice ? parseFloat(oldLand?.landPrice) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ค่าโอนที่ดิน:</b>  {oldLand?.registrationFee ? parseFloat(oldLand?.registrationFee) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ค่าจดจำนอง:</b>  {oldLand?.mortgageFee ? parseFloat(oldLand?.mortgageFee) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}><Divider /></Grid>
                      <Grid item xs={12}>
                        <Typography mb={1}>ข้อมูลภาษีและค่าธรรมเนียมที่ดิน</Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>วันที่จ่ายภาษี:</b> {oldLand?.landTax?.taxDate ? dayjs(oldLand?.landTax?.taxDate).format('DD/MM/YYYY') : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ค่าคำขอ:</b> {oldLand?.landTax?.requestFee ? parseFloat(oldLand?.landTax?.requestFee) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ค่าธรรมเนียม อปท. :</b>  {oldLand?.landTax?.lgoFee ? parseFloat(oldLand?.landTax?.lgoFee) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>รวมทั้งสิ้นค่าธรรมเนียม:</b>  {oldLand?.landTax?.totalFee ? parseFloat(oldLand?.landTax?.totalFee) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ค่าคำขอ:</b> {oldLand?.landTax?.requestFee ? parseFloat(oldLand?.landTax?.requestFee) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ค่าธรรมเนียม อปท. :</b>  {oldLand?.landTax?.lgoFee ? parseFloat(oldLand?.landTax?.lgoFee) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>รวมค่าธรรมเนียม:</b>  {oldLand?.landTax?.totalFee ? parseFloat(oldLand?.landTax?.totalFee) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ภาษีเงินได้บุคคลธรรมดา:</b> {oldLand?.landTax?.personalTaxIncome ? parseFloat(oldLand?.landTax?.personalTaxIncome) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ภาษีเงินได้นิติบุคคล:</b>  {oldLand?.landTax?.corporateIncomeTax ? parseFloat(oldLand?.landTax?.corporateIncomeTax) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ค่าอากรแสตมป์:</b>  {oldLand?.landTax?.stampDuty ? parseFloat(oldLand?.landTax?.stampDuty) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>ภาษีธุรกิจเฉพาะ:</b> {oldLand?.landTax?.specificBusinessTax ? parseFloat(oldLand?.landTax?.specificBusinessTax) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>รายได้ส่วนท้องถิ่น:</b>  {oldLand?.landTax?.localIncome ? parseFloat(oldLand?.landTax?.localIncome) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>รวมทั้งสิ้นค่าภาษีอากร:</b>  {oldLand?.landTax?.totalTax ? parseFloat(oldLand?.landTax?.totalTax) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>รวมทั้งสิ้นค่าธรรมเนียมและค่าภาษีอากร:</b> {oldLand?.landTax?.grandTotalTax ? parseFloat(oldLand?.landTax?.grandTotalTax) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} display={'flex'} alignItems={'center'}>
                        <Typography>
                          <b>หมายเหตุ:</b>  {oldLand?.landTax?.remarkText || ''}
                        </Typography>
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => {
          props.onClose();
          handleReset();
        }}>ยกเลิก</Button>
        <Button variant="contained" onClick={() => {
          handleOnSelect();
        }}>ยืนยัน</Button>
      </DialogActions>
    </Dialog >
  );
};

export default LandOfHouseDialog;