import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React from "react";

interface ExportFileContractsProps {
  title?: string;
  date?: string;
  data: any[];
}

const ExportFileContracts = React.forwardRef((props: ExportFileContractsProps, ref) => {
  return (
    <Box ref={ref} sx={{ textAlign: 'center', padding: '20px', '&@page': { size: 'A4' }, }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer >
            <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={10} sx={{ textAlign: 'center', }}>
                    <Typography fontWeight={'bold'}>{props.title}</Typography>
                    <Typography>{props.date}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>วันที่เอกสาร</TableCell>
                  <TableCell>ชื่อโครงการ</TableCell>
                  <TableCell>บ้าน</TableCell>
                  <TableCell>ชื่อผู้รับเหมา</TableCell>
                  <TableCell>ชื่อสัญญา</TableCell>
                  <TableCell>มูลค่า</TableCell>
                  <TableCell width={100}>บันทึก</TableCell>
                  <TableCell width={100}>แก้ไข</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 && props?.data?.map((row: any) => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell>{dayjs(row.contractDate).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>{row?.project?.name}</TableCell>
                    <TableCell>{row?.house?.name}</TableCell>
                    <TableCell>{row?.worker?.firstName ?? ''} {row?.worker?.lastName ?? ''}</TableCell>
                    <TableCell>{row?.title}</TableCell>
                    <TableCell>{row?.total?.toLocaleString()} บาท</TableCell>
                    <TableCell >{<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                  </TableRow>
                ))}

                {props.data?.length === 0 && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ExportFileContracts; 