import { Autocomplete, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
interface DraftContractProps {
  title: string;
  content?: any;
  type?: string;
  image?: any;
  onSelected: (title: any, content: any, isSaveTemplate?: any) => void;
  contractTypeData?: any;
  isSaveTemplate?: boolean;
}

const DraftContract: React.FC<DraftContractProps> = (props: DraftContractProps) => {
  const [content, setContent] = useState<string>('');
  const [title, setTitle] = useState('');
  const [isSaveTemplate, setIsSaveTemplate] = useState(false);
  const joeditor = useRef<any>(null);

  const handleOnSelect = () => {
    setIsSaveTemplate(!isSaveTemplate);
    props.onSelected(title, content, !isSaveTemplate);
  };

  useEffect(() => {
    setTitle(props?.title);
    setContent(props?.content);
  }, [props?.title, props?.content]);


  const handleSelectTemplate = (value: any) => {
    setContent(value.content);
    props.onSelected(title, value.content, isSaveTemplate);
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: any = e;
    setContent(value);
    props.onSelected(title, value, isSaveTemplate);
  };

  const editorConfig: any = useMemo(() => ({
    readonly: false,
    autofocus: false,
    tabIndex: 1,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: 'insert_clear_html',
    placeholder: '',
    beautyHTML: true,
    toolbarButtonSize: "large",
    toolbarAdaptive: false,
    buttons: 'bold,strikethrough,underline,italic,hr,|,ul,ol,align,|,indent,outdent,|,font,fontsize,brush,paragraph,lineHeight,|,undo,redo,|,fullsize,',
    style: {
      padding: '60px',
      margin: '10px',
      width: '210mm',
      border: '1px solid black',
    },
  }), []);

  return (
    <Grid container   >
      <Grid item xs={12} >
        <Grid container    >
          <Grid item xs={12} mb={2}>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="บันทึกเป็นแบบฟอร์มสัญญา" value={isSaveTemplate} onChange={handleOnSelect} />
            </FormGroup>
          </Grid>
          <Grid item xs={8} mb={2} >
            <TextField label='ชื่อสัญญา' value={title} onChange={(e) => {
              setTitle(e.target.value);
              props.onSelected(e.target.value, content, isSaveTemplate);
            }} fullWidth />
          </Grid>
          <Grid item xs={4} mb={2} paddingLeft={2}>
            <Autocomplete
              id='contractType'
              onChange={(e: any, value: any) => {
                handleSelectTemplate(value);
              }}
              disablePortal
              options={props.contractTypeData}
              sx={{ width: '100%' }}
              renderOption={(props: any, option: any) => <li {...props}>{option?.name}</li>}
              getOptionLabel={(option: any) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name;
              }}
              renderInput={(params) => <TextField {...params} label='แบบฟอร์มสัญญา' />}
            />
          </Grid>
          <Card style={{ width: '100%' }}  >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}  >
                  <JoditEditor
                    ref={joeditor}
                    value={content}
                    config={editorConfig}
                    onChange={(e: any) => { handleChangeText(e); }} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DraftContract;