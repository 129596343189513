import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import vendorsStore from "../stores/VendorsStore";
import authStore from "../stores/AuthStore";
import { enqueueSnackbar } from "notistack";

interface DialogCreateVendorProps {
    open: boolean;
    name: string;
    onClose: () => void;
    onSave: (success: boolean) => void;
}

const DialogCreateVendor: React.FC<DialogCreateVendorProps> = (props: DialogCreateVendorProps) => {

    const [vendorName, setVendorName] = useState(props.name);
    const [vendorAddress, setVendorAddress] = useState('');
    const [vendorTel, setVendorTel] = useState('');
    const handleSave = () => {
        vendorsStore.create({
            name: vendorName || props.name,
            address: vendorAddress,
            tel: vendorTel,
            createBy: authStore.user?.id,
            createAt: new Date(),
        }).then((res) => {
            if (res.error) {
                enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                return;
            }
            enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
            props.onSave(true);
        });
    };

    useEffect(() => {
        setVendorName(props.name);
    }, [props.name]);

    return (
        <Dialog open={props.open} fullWidth maxWidth={'md'}>
            <DialogContent >
                <Typography variant="h6">สร้างผู้ขาย</Typography>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} >
                        <Grid container spacing={2}>
                            <Grid item xs={4} >
                                <TextField id="outlined-basic" label="ชื่อผู้ขาย" variant="outlined" fullWidth
                                    onChange={(event) => setVendorName(event.target.value)} value={vendorName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField id="outlined-basic" label="ที่อยู่" variant="outlined" fullWidth
                                    onChange={(event) => setVendorAddress(event.target.value)} value={vendorAddress}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField id="outlined-basic" label="เบอร์โทร" variant="outlined" fullWidth
                                    onChange={(event) => setVendorTel(event.target.value)} value={vendorTel}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => props.onClose()}>ยกเลิก</Button>
                <Button variant="contained" onClick={() => handleSave()}>บันทึก</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCreateVendor;
