import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

interface DialogDisplayImageProps {
    open: boolean;
    images: any[];
    docNo?: string;
    onClose: () => void;
}

const DialogDisplayImage: React.FC<DialogDisplayImageProps> = (props: DialogDisplayImageProps) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    return (
        <Dialog open={props.open} fullWidth maxWidth={'md'} >
            <DialogContent >
                <Typography variant="h6">เลขเอกสาร: {props.docNo}</Typography>
                {props.images.map((file: any, index) => (
                    <Grid item xs={12} key={index} mt={1} display="flex" justifyContent="center" alignItems="center">
                        <img
                            src={`${apiUrl}/uploads/${file.name}`}
                            alt={`ตัวอย่างไฟล์ ${index + 1}`}
                            width="600"
                        />
                    </Grid>
                ))}
            </DialogContent>
            <DialogActions>
                <Button variant="text"
                    onClick={() => props.onClose()}
                >ปิด</Button>
            </DialogActions>
        </Dialog >

    );
};

export default DialogDisplayImage;