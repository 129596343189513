import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import expensesStore from "../stores/ExpensesStore";
import { enqueueSnackbar } from "notistack";
import dayjs from 'dayjs';
import 'dayjs/locale/th';

interface DialogForCheckQcProps {
  open: boolean;
  id: string;
  onClose: () => void;
  onSubmit: (item: any) => void;
}
const DialogForCheckQc: React.FC<DialogForCheckQcProps> = (props: DialogForCheckQcProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [docType, setDocType] = useState('');
  const [head, setHead] = useState<any>(null);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [images, setImages] = useState<any[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setChecked(false);
    if (props.open) {
      expensesStore.get(props.id).then((result: any) => {
        if (result.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          setIsLoading(false);
          return;
        }
        setHead(result);
        setImages(result.images);
        setRows(result.expenseDetails.sort((a: any, b: any) => a.createAt.localeCompare(b.createAt)));
        setDocType(result.docType === 'tax' ? "ใบกำกับภาษี" : result.docType === 'receipt' ? "ใบเสร็จทั่วไป" : "บิลเงินสด/ใบส่งของ");
        setTotalAfterDiscount(parseFloat(result?.total ?? 0) - parseFloat(result?.discount ?? 0));
        setChecked(result.isChecked);
        setIsLoading(false);
      }).catch((error) => {
        enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
        console.error(error);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <Dialog open={props.open} fullWidth maxWidth={'md'}>
      {isLoading && <DialogContent >
        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
          <CircularProgress />
        </Box>
      </DialogContent>}
      {!isLoading && <DialogContent>
        <Grid container spacing={2}  >
          <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
            <Box component={'div'} display={'flex'}>
              <Typography>
                {<b>{docType}</b>} {<b>เลขที่</b>}  {head?.docNo ?? ''} {<b>วันที่</b>} {head?.docDate ? dayjs(head?.docDate).format('DD/MM/YYYY') : ''}
              </Typography></Box>
            <Box component={'div'} display={'flex'}>
              <Typography>{<b>บันทึกโดย</b>} {(head?.createBy?.fristName) ? head?.createBy?.fristName : head?.createBy?.name ?? ''} {<b>วันที่บันทึก</b>} {head?.createAt ? dayjs(head?.createAt).format('DD/MM/YYYY HH:mm') : ''}</Typography>
            </Box>

          </Grid>
          <Grid item xs={12}>
            <Typography>{<b>ผู้ขาย</b>} {head?.vendor?.name ?? ''} </Typography>
            <Typography>{<b>ที่อยู่</b>} {head?.vendor?.address ?? ''}</Typography>
            <Typography>{<b>เบอร์โทร</b>} {head?.vendor?.tel ?? ''}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>ลำดับ</TableCell>
                    <TableCell>
                      <Grid container display={'flex'}>
                        <Grid item xs={8} textAlign={'center'}>รายการ</Grid>
                        <Grid item xs={4} textAlign={'center'}>จำนวน</Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>หน่วย</TableCell>
                    <TableCell>ราคาต่อหน่วย</TableCell>
                    <TableCell>จำนวนเงิน</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length > 0 && rows.map((row, index: number) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell >
                        <Grid container display={'flex'}>
                          <Grid item xs={8} style={{ textDecoration: !row?.product?.isActive ? 'line-through' : 'none' }}>
                            <Typography fontSize={12} fontWeight={'bold'} color={'gray'}>{`${row?.productCategory?.name ? row?.productCategory?.name : 'ยังไม่ระบุ'} | ${row?.productGroup?.name ? row?.productGroup?.name : 'ยังไม่ระบุ'}`}
                            </Typography>
                            {row?.product?.isActive ? (<b>{row?.product?.name ?? ''}</b>) :
                              <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                                {
                                  <b>{row?.product?.name ?? ''}</b>
                                }
                              </Tooltip>}
                            {(row?.product?.color || row?.product?.size) && (row?.product?.isActive ?
                              <Box component={'div'}>({row?.product?.color || ''} {row?.product?.size || ''})</Box>
                              :
                              <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                                {
                                  <Box component={'div'}>({row?.product?.color || ''} {row?.product?.size || ''})</Box>
                                }
                              </Tooltip>)
                            }
                          </Grid>
                          <Grid item xs={4} textAlign={'center'} >
                            {parseFloat(row?.quantity ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                          </Grid>
                        </Grid>
                        {row.items?.length > 0 && row.items.map((item: any, index: number) => (
                          <Grid container key={index} display={'flex'}>
                            <Grid item xs={8} >
                              {'-'}
                              <Box component={'span'} style={{ textDecoration: !item?.project?.isActive ? 'line-through' : 'none' }}>
                                {
                                  item?.project?.isActive ?
                                    (<Box component={'span'}>
                                      {item?.project?.name ?? ''}
                                    </Box>) :
                                    (<Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                                      <Box component={'span'}>
                                        {item?.project?.name ?? ''}
                                      </Box>
                                    </Tooltip>)
                                }
                              </Box>
                              {item?.house?.name ? ' | ' : ''}
                              <Box component={'span'} style={{ textDecoration: !item?.house?.isActive ? 'line-through' : 'none' }}>
                                {
                                  item?.house?.isActive ?
                                    (<Box component={'span'}>
                                      {item?.house?.name ?? ''}
                                    </Box>) :
                                    (<Tooltip title="ข้อมูลนี้ถูกลบแล้ว" >
                                      <Box component={'span'}>
                                        {item?.house?.name ?? ''}
                                      </Box>
                                    </Tooltip>)
                                }
                              </Box>
                            </Grid>
                            <Grid item xs={4} textAlign={'center'}>{parseFloat(item?.quantity ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Grid>
                          </Grid>
                        ))}
                      </TableCell>
                      <TableCell>{row?.unit ?? ''}
                      </TableCell>
                      <TableCell>{parseFloat(row?.price ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </TableCell>
                      <TableCell>{parseFloat(row?.total ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={8} mt={1} >
            <Grid item xs={12}>
              <Typography>เอกสาร</Typography>
            </Grid>
            {images && (
              <Box component={'div'} mt={1}>
                {images.map((file: any, index) => (
                  <Grid item xs={12} key={index} mt={1}>
                    <Grid container >
                      <Grid item xs={11.5}>
                        <img
                          src={`${apiUrl}/uploads/${file.name}`}
                          alt={`ตัวอย่างไฟล์ ${index + 1}`}
                          width="500"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            )}
          </Grid>
          <Grid item xs={4} mt={1} >
            <Grid container spacing={2}>
              <Grid item xs={8} textAlign={'end'}>
                <Typography>รวมเป็นเงิน</Typography>
              </Grid>
              <Grid item xs={4} textAlign={'end'}>
                <Typography>{parseFloat(head?.total ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
              </Grid>
              <Grid item xs={8} textAlign={'end'}>
                <Typography>ส่วนลด</Typography>
              </Grid>
              <Grid item xs={4} textAlign={'end'}>
                <Typography>{parseFloat(head?.discount ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
              </Grid>
              <Grid item xs={8} textAlign={'end'}>
                <Typography>ราคาหลังหักส่วนลด</Typography>
              </Grid>
              <Grid item xs={4} textAlign={'end'}>
                <Typography>{totalAfterDiscount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
              </Grid>
              <Grid item xs={8} textAlign={'end'}>
                <Typography>ภาษีมูลค่าเพิ่ม 7%</Typography>
              </Grid>
              <Grid item xs={4} textAlign={'end'}>
                <Typography>{parseFloat(head?.vat ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
              </Grid>
              <Grid item xs={8} textAlign={'end'}>
                <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
              </Grid>
              <Grid item xs={4} textAlign={'end'}>
                <Typography>{parseFloat(head?.grandTotal ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent >}
      <DialogActions>
        <Grid container>
          <Grid item xs={6}>
            <FormControlLabel control={<Checkbox checked={checked}
              onChange={handleCheckboxChange} />} label="ยืนการตรวจสอบ" />
          </Grid>
          <Grid item xs={6} display={'flex'} justifyContent={'end'}>
            <Button variant="text"
              onClick={() => props.onClose()}
            >ยกเลิก</Button>
            <Button variant="contained"
              onClick={() => props.onSubmit({ checked: checked, expenseId: props.id })}
            >ยืนยัน</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog >);
};
export default DialogForCheckQc;