import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class MenusStore {
    menus: any[] = [];
    construnctor() {
        makeAutoObservable(this);
    }
    async getAll() {
        try {
            const response = await apiService.get("/menus");
            this.menus = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting menu:", error);
            return {
                error: true,
                message: "An error occurred while getting menu",
            };
        }
    }
}
const menusStore = new MenusStore();
export default menusStore;