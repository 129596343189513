import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";

interface PrintWarehouseDetailProps {
    title?: string;
    summary?: string;
    data: any[];
}

const PrintWarehouseDetail = React.forwardRef((props: PrintWarehouseDetailProps, ref) => {

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);
    return (
        <Box ref={ref} sx={{
            margin: 0,
            width: "100%",
            borderCollapse: "collapse",
            textAlign: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
            '&@page': {
                size: 'A4 portrait',
            },
            overflow: 'hidden',
            fontSize: 8,
            display: 'block',
            pageBreakBefore: 'auto',
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={6} sx={{ textAlign: 'center', }}>
                                        <Typography fontWeight={'bold'}>{props.title}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width={10}>ลำดับ</TableCell>
                                    <TableCell width={300}>รายละเอียดวัสดุ</TableCell>
                                    <TableCell width={100} sx={{ textAlign: 'center' }}>จำนวน</TableCell>
                                    <TableCell width={100} sx={{ textAlign: 'center' }}>ราคาต่อหน่วย</TableCell>
                                    <TableCell width={100} sx={{ textAlign: 'center' }}>ราคา</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props?.data?.length > 0 && props?.data?.map((row: any, index: number) => {
                                        return (
                                            <>
                                                <TableRow
                                                    key={`print-warehouse-detail-${index.toString()}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{row?.name || 'ยังไม่ระบุ'}</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                                {
                                                    row?.bills?.length > 0 && row?.bills?.map((bill: any, bIndex: number) => {
                                                        return (
                                                            <>
                                                                <TableRow
                                                                    key={`p-${bIndex.toString()}`}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell></TableCell>
                                                                    <TableCell>- {bill?.docNo}</TableCell>

                                                                    <TableCell sx={{ textAlign: 'center' }}>{parseFloat(bill?.quantity ?? 0).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })}</TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}> {parseFloat(bill?.price ?? 0).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })}</TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}> {parseFloat(bill?.total ?? 0).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        );
                                                    })
                                                }

                                            </>
                                        );
                                    })
                                }
                                {props.data && props.data.length === 0 && (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={6} align="center">ไม่พบข้อมูล</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box >
    );
});

export default PrintWarehouseDetail;