import { Autocomplete, Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import SimpleBackdrop from '../../components/SimpleBackdrop';
import { Fragment, useEffect, useRef, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate, useParams } from 'react-router-dom';
import authStore from '../../stores/AuthStore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import VisuallyHiddenInput from '../../components/VisuallyHiddenInput';
import bookingStore from '../../stores/BookingStore';
import imagesStore from '../../stores/ImagesStore';
import DialogConfirm from '../../components/DialogConfirm';
import sellStore from '../../stores/SellStore';
import { v4 as uuid4 } from 'uuid';
import projectsStore from "../../stores/ProjectsStore";
import masterDataStore from "../../stores/MasterDataStore";
import { enqueueSnackbar } from "notistack";

const SellCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/bookings');
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: '',
    message: '',
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete' | 'confirm-duplicate'
  });
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [docDate, setDocDate] = useState<Dayjs | null>(dayjs());
  const [paymentType, setPaymentType] = useState('all');
  const [bank, setBank] = useState('');
  const [bankData, setBankData] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [bookingData, setBookingData] = useState<any[]>([]);
  const [booking, setBooking] = useState('');
  const [customer, setCustomer] = useState('');
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [house, setHouse] = useState('');
  const [project, setProject] = useState('');
  const [land, setLand] = useState<any>([]);
  const [contractNo, setContractNo] = useState('');
  const [interest, setInterest] = useState('');
  const [sellingPrice, setSellingPrice] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  const [installments, setInstallments] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [increaseLoanAmout, setIncreaseLoanAmout] = useState('');
  const [gifts, setGifts] = useState<any[]>([]);
  const [giftOld, setGiftOld] = useState<any[]>([]);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [workerTotal, setWorkerTotal] = useState(0);
  const [bankRemark, setBankRemark] = useState('');
  const [chequeDocDate, setChequeDocDate] = useState<Dayjs | null>(dayjs());
  const [referenceNo, setReferenceNo] = useState('');
  const [chequeValue, setChequeValue] = useState(0);
  const [chequeBank, setChequeBank] = useState('');
  const [chequeId, setChequeId] = useState('');
  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const handleKeyDown = (event: any, index: number, i = 0) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (i === -1) {
        inputRefs.current[23].focus();
      }
      else if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaymentChange = (event: SelectChangeEvent) => {
    setPaymentType((event.target as HTMLInputElement).value);
  };

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    if (files) {
      const newFiles = Array.from(files);
      setSelectedFiles(newFiles);
    }
  };

  const handleFileRemove = (index: number) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleFileUpload = (refId: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append('refType', 'sell');
        formdata.append('refId', refId);
        formdata.append('file', file);
        formdata.append('createBy', authStore.user?.id);
        formdata.append('createAt', new Date(Date.now() + index * 1000).toISOString());

        await imagesStore.upload(formdata)
          .catch(err => console.error('err', err));
      });
    }
  };

  const handleEditFileUpload = async (id: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append('refType', 'sell');
        formdata.append('refId', id);
        formdata.append('file', file);
        formdata.append('updateBy', authStore.user?.id);
        formdata.append('updateAt', new Date(Date.now() + index * 1000).toISOString());
        await imagesStore.upload(formdata)
          .catch(err => console.error('err', err));
      });
    }
    if (deleteImages.length > 0) {
      await deleteImages.forEach(async (item: any) => {
        await imagesStore.update(item.id, {
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          isDeleted: true
        }).catch(err => console.error('err deleteImages', err));
      });
    }
  };
  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (booking === '') {
        enqueueSnackbar('กรุณาเลือกเลขที่จอง', { variant: 'warning' });
      }
      sellStore.create({
        docDate: docDate?.format('YYYY-MM-DD'),
        booking: booking,
        paymentType: paymentType === 'all' ? null : paymentType,
        sellingPrice: sellingPrice,
        downPayment: downPayment,
        bank: bank !== '' ? bank : null,
        contractNo: contractNo,
        interest: interest === '' ? 0 : interest,
        installments: installments === '' ? 0 : installments,
        loanAmount: loanAmount === '' ? 0 : loanAmount,
        increaseLoanAmout: increaseLoanAmout === '' ? 0 : increaseLoanAmout,
        gifts: gifts,
        cheque: {
          docDate: chequeDocDate?.format('YYYY-MM-DD'),
          referenceNo: referenceNo,
          bank: chequeBank || undefined,
          value: chequeValue,
          createBy: authStore.user?.id,
          createAt: new Date(),
        },
        bankRemark: bankRemark,
        createBy: authStore.user?.id,
        createAt: new Date(),
      }).then(async (res) => {
        handleFileUpload(res.id);
        if (res.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          setIsLoading(false);
          return;
        } else {
          setIsLoading(false);
          enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
          navigate(`/sell-edit/${res.id}`);
        }
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
      const _booking: any = booking;
      bookingStore.update(_booking.id, {
        status: 'sold',
        house: _booking?.house?.id,
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const handleEdit = () => {
    try {
      if (id) {
        sellStore.updateCheque(chequeId, {
          sell: id,
          id: chequeId,
          docDate: chequeDocDate?.format('YYYY-MM-DD'),
          referenceNo: referenceNo,
          bank: chequeBank || undefined,
          value: chequeValue,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        });
        sellStore.update(id, {
          docDate: docDate?.format('YYYY-MM-DD'),
          booking: booking,
          paymentType: paymentType === 'all' ? null : paymentType,
          sellingPrice: sellingPrice,
          downPayment: downPayment,
          bank: bank !== '' ? bank : null,
          contractNo: contractNo,
          interest: interest === '' ? 0 : interest,
          installments: installments === '' ? 0 : installments,
          loanAmount: loanAmount === '' ? 0 : loanAmount,
          increaseLoanAmout: increaseLoanAmout === '' ? 0 : increaseLoanAmout,
          gifts: [],
          bankRemark: bankRemark,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        }).then(async (res: any) => {
          handleEditFileUpload(id);
          if (res.error) {
            enqueueSnackbar(res.error.message, { variant: 'error' });
            setIsLoading(false);
            return;
          } else {
            giftOld.forEach(async (item: any) => {
              sellStore.deleteGift(item.id);
            });
            gifts.forEach(async (item: any) => {
              sellStore.createGift({
                name: item.name,
                quantity: item.quantity,
                price: item.price,
                sell: id,
                createBy: authStore.user?.id,
                createAt: new Date(),
              });
            });
            setIsLoading(false);
            enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
          }
        }).catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = () => {
    try {
      if (id) {
        setIsLoading(true);
        sellStore.update(id, {
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          isDeleted: true
        }).then(async (res) => {
          if (res.error) {
            enqueueSnackbar(res.error.message, { variant: 'error' });
            setIsLoading(false);
            return;
          } else {
            setIsLoading(false);
            enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
            navigate('/sells');
          }
        }).catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddGift = () => {
    setGifts([...gifts, { id: uuid4(), name: '', quantity: 0, price: 0, createBy: authStore.user?.id, createAt: new Date() }]);
  };

  const handleRemoveGift = (id: string) => {
    if (id) {
      const updatedGifts = gifts.filter((gift) => gift.id !== id);
      setGifts(updatedGifts);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      bookingStore.getByStatusForSell(),
      masterDataStore.getBanksAll(),
    ]).then((res: any) => {
      const customerData = res[0].map((item: any) => { return { ...item.customer, booking: item }; });
      setBookingData(res[0].filter((booking: any) => booking?.status && (booking?.status?.code === "loaned" || booking?.status?.code === "cash")));
      setCustomerData(customerData);
      setBankData(res[1]);
      setIsLoading(false);
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
    if (id) {
      setIsLoading(true);
      sellStore.get(id).then((res) => {
        if (res.error) {
          enqueueSnackbar(res.error.message, { variant: 'error' });
          setIsLoading(false);
          return;
        } else {
          setDocDate(dayjs(res.docDate));
          setBooking(res.booking);
          setCustomer(res.booking?.customer);
          setProject(res.booking?.project?.name);
          setHouse(res.booking?.house?.name);
          setLand(res.booking?.house?.land);
          setPaymentType(res.paymentType);
          setSellingPrice(res.sellingPrice);
          setDownPayment(res.downPayment);
          setBank(res.bank);
          setContractNo(res.contractNo);
          setInterest(res.interest);
          setInstallments(res.installments);
          setLoanAmount(res.loanAmount);
          setIncreaseLoanAmout(res.increaseLoanAmout);
          setGiftOld(res.gifts);
          setGifts(res.gifts);
          setImages(res.images);
          setChequeDocDate(dayjs(res.cheque?.docDate));
          setReferenceNo(res.cheque?.referenceNo);
          setChequeBank(res.cheque?.bank);
          setChequeValue(res.cheque?.value);
          setChequeId(res.cheque?.id);
          setIsLoading(false);
        }
      }).catch((err: any) => {
        console.error(err);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box component={'div'}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton onClick={() => navigate('/sells')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
          <Breadcrumbs separator='›' aria-label='breadcrumb'>
            <Typography>ขาย/จอง</Typography>
            <Link component='button' onClick={() => navigate('/sells')}>ขาย</Link>
            <Typography variant='h6' color='text.primary'>{id ? 'แก้ไข' : 'สร้าง'}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={'flex'} justifyContent={'end'}>
          {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
            <Button variant='contained' color='error' startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
              open: true,
              title: 'ลบเอกสาร',
              message: 'คุณต้องการลบเอกสารนี้ใช่หรือไม่',
              color: 'error',
              type: 'delete'
            })}>ลบเอกสาร</Button>
          }
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                    <DatePicker
                      label='วันที่เอกสาร'
                      value={docDate}
                      onChange={(newValue) => setDocDate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    id="booking"
                    value={booking}
                    onChange={async (e: any, value: any) => {
                      if (value) {
                        setBooking(value);
                        setCustomer(value?.customer);
                        setProject(value?.project?.name ?? '');
                        setHouse(value?.house?.name ?? '');
                        setLand(value?.house?.land ?? '');
                        setSellingPrice(value?.sellingPrice ? parseFloat(value?.sellingPrice) : 0);
                        if (value?.bookingBank) {
                          setPaymentType('bank');
                          setBank(value?.bookingBank?.bank);
                          setInterest(value?.bookingBank?.interest);
                          setLoanAmount(value?.bookingBank?.loanAmount);
                        }
                        await projectsStore.getExpenseWithWorkerByHouseId(value?.house?.id).then((res) => {
                          setWorkerTotal(parseFloat(res?.total?.totalWages || '0'));
                        });
                        await projectsStore.getExpenseWithProductCategoryByHouseId(value?.house?.id).then((res) => {
                          setExpenseTotal(res.categories.reduce((sum: any, item: any) => {
                            return sum + parseFloat(item.total);
                          }, 0));
                        });

                      } else {
                        setBooking('all');
                      }
                    }}
                    disabled={id ? true : false}
                    options={bookingData}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.bookingNo}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.bookingNo;
                    }}
                    renderInput={(params) => <TextField {...params} label='เลขที่จอง' inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 0);
                      }} />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    value={customer}
                    id='customer'
                    disabled={id ? true : false}
                    onChange={(e: any, value: any) => {
                      setCustomer(value);
                      setBooking(value?.booking);
                    }}
                    disablePortal
                    options={customerData}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => (
                      <li {...props}>{`${option?.firstname} ${option?.lastname || ''}`}</li>
                    )}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return `${option.firstname} ${option.lastname}`;
                    }}
                    renderInput={(params) => <TextField {...params} label='ลูกค้า' inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 1);
                      }} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลบ้าน</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>โครงการ:</b> {project ?? '-'}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>บ้าน:</b> {house ?? '-'}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>เลขที่โฉนด:</b> {land?.deedNo ?? '-'}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>ที่อยู่:</b> {land?.address ?? '-'} หมู่ที่ {land?.village ?? '-'} ตำบล{land?.landSubDistrict?.nameTh ?? '-'} อำเภอ{land?.landDistrict?.nameTh ?? '-'} จังหวัด{land?.landProvince?.nameTh ?? '-'} รหัสไปรษณีย์{land?.landProvince?.zipCode ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>เนื้อที่:</b> {land?.rai ? parseFloat(land?.rai ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} ไร่ {land?.ngan ? parseFloat(land?.ngan).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} งาน {land?.squareWah ? parseFloat(land?.squareWah ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} ตร.วา</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลซื้อขาย</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>ประเภท</InputLabel>
                    <Select
                      value={paymentType}
                      label='ประเภท'
                      onChange={handlePaymentChange}
                      inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 2);
                      }}
                    >
                      <MenuItem value={'all'}>กรุณาเลือกประเภทการซื้อขาย</MenuItem>
                      <MenuItem value={'cash'}>เงินสด</MenuItem>
                      <MenuItem value={'bank'}>กู้ธนาคาร</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField label='ราคาขาย' variant='outlined'
                    value={sellingPrice ? parseFloat(sellingPrice.toString()) : 0}
                    type={'number'}
                    onChange={(e) => setSellingPrice(parseFloat(e.target.value || '0'))}
                    fullWidth inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 3);
                    }} />
                </Grid>
                <Grid item xs={4}>
                  <TextField label='เงินดาวน์' variant='outlined' fullWidth
                    value={downPayment ? parseFloat(downPayment.toString()) : 0}
                    type={'number'}
                    onChange={(e) => setDownPayment(parseFloat(e.target.value || '0'))}
                    inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 4);
                    }} />
                </Grid>
                {paymentType === 'bank' &&
                  <>
                    <Grid item xs={12}>
                      <Typography>ข้อมูลธนาคาร</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <Autocomplete
                          options={bankData}
                          getOptionLabel={(option) => option.nameTh}
                          value={bank ? bank : null}
                          onChange={(e: any, value: any) => {
                            setBank(value);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label='ธนาคาร' inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 5)} />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField label='เลขที่สัญญา'
                        value={contractNo} onChange={(e) => setContractNo(e.target.value)}
                        variant='outlined' fullWidth inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 6);
                        }} />
                    </Grid>
                    <Grid item xs={2.5}>
                      <TextField label='ดอกเบี้ย' variant='outlined' type='number'
                        value={interest ? parseFloat(interest) : ''} onChange={(e) => setInterest(e.target.value)}
                        fullWidth inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 7);
                        }} />
                    </Grid>
                    <Grid item xs={2.5}>
                      <TextField label='จำนวนงวด' variant='outlined' type='number'
                        value={installments ? parseFloat(installments) : ''} onChange={(e) => setInstallments(e.target.value)}
                        fullWidth inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 8);
                        }} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField label='ยอดกู้' variant='outlined' type='number'
                        value={loanAmount ? parseFloat(loanAmount) : ''} onChange={(e) => setLoanAmount(e.target.value)}
                        fullWidth inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 9);
                        }} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField label='ยอดกู้เพิ่มเติม' type='number'
                        value={increaseLoanAmout ? parseFloat(increaseLoanAmout) : ''} onChange={(e) => setIncreaseLoanAmout(e.target.value)}
                        variant='outlined' fullWidth inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 10);
                        }} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label='หมายเหตุ'
                        value={bankRemark || ''} onChange={(e) => setBankRemark(e.target.value)}
                        variant='outlined' fullWidth inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 10);
                        }} />
                    </Grid>
                  </>}
                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                  <Typography>ข้อมูลเช็ค</Typography>
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                    <DatePicker
                      label='วันที่ทำรายการ'
                      value={chequeDocDate}
                      onChange={(newValue) => setChequeDocDate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <TextField label='เลขที่อ้างอิง' variant='outlined' value={referenceNo} onChange={(e) => setReferenceNo(e.target.value)} fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={bankData}
                      getOptionLabel={(option) => option.nameTh}
                      value={chequeBank ? chequeBank : null}
                      onChange={(e: any, value: any) => {
                        setChequeBank(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label='ธนาคาร' inputRef={addRef} onKeyDown={(e) => handleKeyDown(e, 5)} />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField label='มูลค่า' variant='outlined' fullWidth type='number'
                    value={chequeValue ? parseFloat((chequeValue || 0).toString()) : ''} onChange={(e: any) => {
                      setChequeValue(e.target.value);
                    }}
                    inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      // handleKeyDown(e, 12, index + 1);
                    }} />
                </Grid>


                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                  <Typography>ของแถม</Typography>
                  <IconButton onClick={handleAddGift}>
                    <AddCircleIcon color='primary' />
                  </IconButton>
                </Grid>
                {gifts && gifts.length > 0 && gifts.map((item: any, index: number) => (
                  <Fragment key={item?.id}>
                    <Grid item xs={0.5} mb={1.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                      <IconButton aria-label={`delete-${item.id}`} size="small" color="error" onClick={() => handleRemoveGift(item.id)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField label='รายการ' variant='outlined' fullWidth
                        value={item.name} onChange={(e) => {
                          const updatedGifts = [...gifts];
                          updatedGifts[index].name = e.target.value;
                          setGifts(updatedGifts);
                        }}
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 11, index + 1);
                        }} />
                    </Grid>
                    <Grid item xs={2.5}>
                      <TextField label='จำนวน' variant='outlined' fullWidth type='number'
                        value={item.quantity ? parseFloat(item.quantity) : ''} onChange={(e) => {
                          const updatedGifts = [...gifts];
                          updatedGifts[index].quantity = e.target.value;
                          setGifts(updatedGifts);
                        }}
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 12, index + 1);
                        }} />
                    </Grid>
                    <Grid item xs={2.5}>
                      <TextField label='มูลค่า' variant='outlined' fullWidth type='number'
                        value={item.price ? parseFloat(item.price) : ''} onChange={(e) => {
                          const updatedGifts = [...gifts];
                          updatedGifts[index].price = e.target.value;
                          setGifts(updatedGifts);
                        }}
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 13, index + 1);
                        }} />
                    </Grid>
                    <Grid item xs={2.5}>
                      <TextField label='รวม' variant='outlined' fullWidth type='number'
                        value={(parseFloat(item.quantity) * parseFloat(item.price))}
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 14, index + 1);
                        }} />
                    </Grid>
                  </Fragment>
                ))}
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>เอกสาร</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button component='label' variant='outlined' startIcon={<UploadFileIcon />}>
                        เลือกไฟล์
                        <VisuallyHiddenInput type='file' accept='.jpg, .jpeg, .png'
                          onChange={handleFileChange} />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='caption'>รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedFiles.length > 0 && (
                        <Box component={'div'} mt={1}>
                          {selectedFiles.map((file: any, index: number) => (
                            <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size='small' color='error'
                                    onClick={() => handleFileRemove(index)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width='500'
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {images && (
                        <Box component={'div'} mt={1}>
                          {images.map((file: any, index: number) => (
                            <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size='small' color='error'
                                    onClick={() => handleFileRemoveImageList(file)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={`${apiUrl}/uploads/${file.name}`}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width='500'
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>รวมของแถม</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>
                        {gifts.reduce((sum, item) => sum + (item.quantity * item.price), 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={'bold'}>ต้นทุนรวม</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>
                        {(expenseTotal + workerTotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={'bold'}>ราคาขาย</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>
                        {parseFloat(sellingPrice.toString() || '0').toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={'bold'}>กำไร</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                      <Typography>
                        {(sellingPrice - (expenseTotal + workerTotal + gifts.reduce((sum, item) => sum + (item.quantity * item.price), 0))).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Stack spacing={2} direction='row'>
                        <Button variant='outlined' fullWidth onClick={() => navigate('/sells')}>ยกเลิก</Button>
                        {!id && <Button variant='contained' fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'บันทึกเอกสาร',
                            message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'create'
                          });
                        }}>บันทึก</Button>}
                        {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant='contained' fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'แก้ไขเอกสาร',
                            message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'edit'
                          });
                        }}>แก้ไข</Button>}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'delete') {
            handleDelete();
          } else if (openConfirm.type === 'create') {
            handleSave();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          }
        }} />
    </Box>
  );
};
export default SellCreate;