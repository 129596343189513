import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { ThaiBaht } from "thai-baht-text-ts";

interface ExportFileExpensesHouseProps {
  title?: string;
  date?: string;
  data: any;
  companyInfo?: any;
  totalMaterial?: any;
  totalWage?: any;
  totalExpense?: any;
}

const ExportFileExpensesHouse = React.forwardRef(
  (props: ExportFileExpensesHouseProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{ textAlign: "center", padding: "20px", "&@page": { size: "A4" } }}
      >
        <Grid container spacing={2} className="summary">
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        fontWeight: "bold",
                        borderBottom: 1,
                        lineHeight: 0.5,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography fontWeight={"bold"}>
                        สรุปรายการประมาณราคา
                      </Typography>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                      <Typography fontWeight={"bold"}>
                        วันที่ {dayjs(props.date).format("DD")}{" "}
                        {dayjs(
                          props?.data?.docDate !== "Invalid Date"
                            ? props?.data?.docDate
                            : new Date()
                        ).format("MMMM")}{" "}
                        พ.ศ.{" "}
                        {dayjs(
                          props?.data?.docDate !== "Invalid Date"
                            ? props?.data?.docDate
                            : new Date()
                        ).format("BBBB")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ borderColor: "text.primary" }}>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                      }}
                    >
                      ลำดับ
                    </TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                      }}
                    >
                      รายการ
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                      }}
                    >
                      ราคาค่าวัสดุ
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                      }}
                    >
                      ราคาค่าแรง
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    >
                      หมายเหตุ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data?.categories &&
                    props.data?.categories?.length > 0 &&
                    props.data?.categories?.map((row: any, index: number) => (
                      <TableRow
                        key={`export-expenses-house-${index.toString()}-${
                          row?.id
                        }-table-row`}
                      >
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width={300}
                          sx={{
                            textAlign: "left",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                          }}
                        >
                          หมวด{row?.productCategoryName || ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                          }}
                        >
                          {parseFloat(row?.total || "0").toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                          }}
                        >
                          {row?.totalWage}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                            borderColor: "text.primary",
                            lineHeight: 0.2,
                            borderLeft: 1,
                            borderRight: 1,
                          }}
                        ></TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      รวม
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {props?.totalMaterial}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {props?.totalWage}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      รวมราคาค่าวัสดุ+ค่าแรง
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {parseFloat(props.totalExpense).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      รวมราคาทั้งสิ้น
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {parseFloat(props.totalExpense).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderRight: 1,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      width={300}
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      รวมจำนวนเงินทั้งหมด
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        fontWeight: "bold",
                        borderLeft: 1,
                      }}
                    >
                      {parseFloat(props?.totalExpense || 0).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderColor: "text.primary",
                        lineHeight: 0.2,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        textAlign: "center",
                        fontWeight: "bold",
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    >
                      {ThaiBaht(props?.totalExpense || 0) || "ศูนย์บาทถ้วน"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        borderColor: "text.primary",
                        lineHeight: 0.5,
                        borderLeft: 1,
                        borderRight: 1,
                      }}
                    >
                      หมายเหตุ
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default ExportFileExpensesHouse;
