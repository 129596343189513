import { Box, Breadcrumbs, Typography, Link, Grid, Button, ButtonProps, Card, CardContent, Divider, FormControl, InputLabel, Select, MenuItem, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Stack } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import 'dayjs/locale/th';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogConfirm from "../../components/DialogConfirm";
import userGroupsStore from "../../stores/UserGroupsStore";
import menusStore from "../../stores/MenusStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import usersStore from "../../stores/UserStore";
import authStore from "../../stores/AuthStore";
import { enqueueSnackbar } from "notistack";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import rolesStore from "../../stores/RoleStore";

const UsersCreate: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [userGroupLists, setUserGroupLists] = useState<any[]>([]);
    const [menus, setMenus] = useState<any>([]);
    const [userGroups, setUserGroups] = useState('all');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isCkeckAll, setIsCheckAll] = useState(false);
    const [openConfirm, setOpenConfirm] = useState({
        open: false,
        title: '',
        message: '',
        color: 'primary' as ButtonProps['color'],
        type: 'create' as 'create' | 'edit' | 'delete'
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/users');

    useEffect(() => {
        setIsLoading(true);
        userGroupsStore.getAllWithRoles().then((result) => {
            setUserGroupLists(result);
            menusStore.getAll().then((resultMenu) => {
                const sortedMenu = sortMenu(resultMenu);
                setMenus(sortedMenu);

                if (id) {
                    usersStore.getOne(id).then((result) => {
                        if (result) {
                            setFirstName(result.firstName);
                            setLastName(result.lastName);
                            setUserGroups(result.userGroup?.id || 'all');
                            setUsername(result.username);
                            const _roles = result.roles;
                            const _menu = resultMenu.map((menu: any) => {
                                menu.isSelected = _roles?.find((role: any) => role.menu?.id === menu?.id)?.read ? true : false;
                                menu.read = _roles?.find((role: any) => role.menu?.id === menu?.id)?.read ? _roles?.find((role: any) => role.menu.id === menu?.id).read : false;
                                menu.edit = _roles?.find((role: any) => role.menu?.id === menu?.id)?.edit ? _roles?.find((role: any) => role.menu.id === menu?.id).edit : false;
                                menu.create = _roles?.find((role: any) => role.menu?.id === menu?.id)?.create ? _roles?.find((role: any) => role.menu.id === menu?.id).create : false;
                                menu.delete = _roles?.find((role: any) => role.menu?.id === menu?.id)?.delete ? _roles?.find((role: any) => role.menu.id === menu?.id).delete : false;
                                return menu;
                            });
                            const sortedMenu = sortMenu(_menu);
                            setMenus(sortedMenu);
                            setIsLoading(false);
                        } else {
                            enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
                            setIsLoading(false);
                        }
                    }).catch(() => {
                        setIsLoading(false);
                    });
                } else {
                    setIsLoading(false);
                }
            }).catch(() => {
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortMenu = (menu: any) => {
        if (menu && menu?.length > 0) {
            const parents = menu.filter((role: any) => role.level === 0).sort((a: any, b: any) => a.order - b.order);
            const childs = menu.filter((role: any) => role.level === 1);
            const sortedMenus: any[] = [];
            parents.forEach((parent: any) => {
                sortedMenus.push(parent);
                const relatedChilds = childs.filter((child: any) => child.parent.id === parent.id);
                const sortedChilds = relatedChilds.sort((a: any, b: any) => a.order - b.order);
                sortedMenus.push(...sortedChilds);
            });
            return sortedMenus;
        }
    };

    const handleEdit = async () => {
        setIsLoading(true);
        const error = validation();
        if (id && !error) {
            const _menus = menus.map((item: any) => {
                const _item = { ...item };
                delete _item.id;
                delete _item.createAt;
                delete _item.createBy;
                delete _item.parent;
                delete _item.name;
                delete _item.level;
                delete _item.isSelected;
                delete _item.path;
                delete _item.order;
                return { ..._item, menu: item.id, updateBy: authStore.user?.id, updateAt: new Date(), user: id, createBy: authStore.user?.id, createAt: new Date() };
            });

            await rolesStore.delete(id);

            const _readMenu = _menus.filter((menu: any) => menu.read);

            await rolesStore.create(_readMenu).catch((err) => {
                enqueueSnackbar('แก้ไขข้อมูลไม่สำเร็จ', { variant: 'error' });
                setIsLoading(false);
                console.error(err);
            });

            await usersStore.update(id, {
                firstName: firstName,
                lastName: lastName,
                username: username,
                password: password,
                userGroup: userGroups,
                updateBy: authStore.user?.id,
                updateAt: new Date(),
            }).catch((err) => {
                enqueueSnackbar('แก้ไขข้อมูลไม่สำเร็จ', { variant: 'error' });
                setIsLoading(false);
                console.error(err);
            });

            enqueueSnackbar('แก้ไขข้อมูลสำเร็จ', { variant: 'success' });
            setIsLoading(false);
            navigate('/users');
        }
    };

    const handleSave = async () => {
        setIsLoading(true);
        const error = validation();
        if (!error) {
            await usersStore.create({
                firstName: firstName,
                lastName: lastName,
                username: username,
                password: password,
                userGroup: userGroups,
                createBy: authStore.user?.id,
                createAt: new Date(),
            }).then(async (res) => {
                if (!res.error) {
                    const _menus = menus.map((item: any) => {
                        const _item = { ...item };
                        delete _item.id;
                        delete _item.createAt;
                        delete _item.createBy;
                        delete _item.parent;
                        delete _item.name;
                        delete _item.level;
                        delete _item.isSelected;
                        delete _item.path;
                        delete _item.order;
                        return { ..._item, menu: item.id, updateBy: authStore.user?.id, updateAt: new Date(), user: res.id, createBy: authStore.user?.id, createAt: new Date() };
                    });

                    const _readMenu = _menus.filter((menu: any) => menu.read);
                    await rolesStore.create(_readMenu).catch((err) => {
                        enqueueSnackbar('แก้ไขข้อมูลไม่สำเร็จ', { variant: 'error' });
                        setIsLoading(false);
                        console.error(err);
                    });

                    enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
                    setIsLoading(false);
                    navigate(`/users-edit/${res.id}`);
                } else {
                    enqueueSnackbar('username นี้ถูกใช้แล้ว', { variant: 'warning' });
                    setIsLoading(false);
                }
            }).catch((err) => {
                enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                console.error(err);
                setIsLoading(false);
            });

        }
    };

    const handleChangeUserGroup = (id: string) => {
        setUserGroups(id);
        const _groupRole = userGroupLists.find(group => group.id === id)?.roles;
        menus.map((menu: any) => {
            menu.isSelected = _groupRole?.find((role: any) => role.menu.id === menu.id) ? true : false;
            menu.read = _groupRole?.find((role: any) => role.menu.id === menu.id)?.read ? _groupRole?.find((role: any) => role.menu.id === menu.id).read : false;
            menu.edit = _groupRole?.find((role: any) => role.menu.id === menu.id)?.edit ? _groupRole?.find((role: any) => role.menu.id === menu.id).edit : false;
            menu.create = _groupRole?.find((role: any) => role.menu.id === menu.id)?.create ? _groupRole?.find((role: any) => role.menu.id === menu.id).create : false;
            menu.delete = _groupRole?.find((role: any) => role.menu.id === menu.id)?.delete ? _groupRole?.find((role: any) => role.menu.id === menu.id).delete : false;
            return menu;
        });
    };

    const handleChangeCheckall = () => {
        menus.map((menu: any) => {
            if (!isCkeckAll) {
                menu.isSelected = true;
                menu.isSelected = true;
                menu.read = true;
                menu.edit = true;
                menu.create = true;
                menu.delete = true;
            } else if (isCkeckAll) {
                menu.isSelected = false;
                menu.isSelected = false;
                menu.read = false;
                menu.edit = false;
                menu.create = false;
                menu.delete = false;
            };
            return menu;
        });
    };

    const handleDelete = async () => {
        setIsLoading(true);
        if (id) {
            await usersStore.update(id, {
                isActive: false,
                updateBy: authStore.user?.id,
                updateAt: new Date(),
            }).catch((err) => {
                enqueueSnackbar('ลบข้อมูลไม่สำเร็จ', { variant: 'error' });
                setIsLoading(false);
                console.error(err);
            });
            enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
            setIsLoading(false);
            navigate('/users');
        }
    };

    const validation = () => {
        if (!firstName) {
            enqueueSnackbar('กรุณากรอกชื่อ', { variant: 'warning' });
            setIsLoading(false);
            return 'error';
        }
        if (!lastName) {
            enqueueSnackbar('กรุณากรอกนามสกุล', { variant: 'warning' });
            setIsLoading(false);
            return 'error';
        }
        if (!userGroups || userGroups === 'all') {
            enqueueSnackbar('กรุณาเลือกตำแหน่ง', { variant: 'warning' });
            setIsLoading(false);
            return 'error';
        }
        if (!menus.some((role: any) => {
            return role.read || role.edit || role.create || role.delete;
        })) {
            enqueueSnackbar('กรุณาเลือกสิทธิ์การใช้งาน', { variant: 'warning' });
            setIsLoading(false);
            return 'error';
        }
        //TODO:validate format
        // if (password && password !== "") {
        //     const letterRegex = /[a-zA-Z@\-_]/;
        //     const numberRegex = /\d{6}/;
        // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;
        //     const isValidFormat = passwordRegex.test(password);
        //     if (!isValidFormat) {
        //         enqueueSnackbar('ต้องมีทั้งหมดอย่างน้อย 6 ตัวอักษรหรือตัวเลขโดยประกอบตัวอักษรหรือตัวเลขอย่างน้อย 1 ตัว', { variant: 'warning' });
        //         setIsLoading(false);
        //         return 'error';
        //     }
        //     if (!confirmPassword) {
        //         enqueueSnackbar('กรุณากรอกยืนยันรหัสผ่าน', { variant: 'warning' });
        //         setIsLoading(false);
        //         return 'error';
        //     }
        //     if (password !== confirmPassword) {
        //         enqueueSnackbar('กรุณาตรวจสอบรหัสผ่านและยืนยันรหัสผ่าน', { variant: 'warning' });
        //         setIsLoading(false);
        //         return 'error';
        //     }
        // }
    };
    const markChildMenusAsRead = (menus: any[], menu: any) => {
        const childMenus = menus.filter((m: any) => m.parent?.id === menu.id);
        if (menu.isSelected === false) {
            for (const childMenu of childMenus) {
                childMenu.read = false;
                childMenu.edit = false;
                childMenu.create = false;
                childMenu.delete = false;
            }
        }
        if (menu.isSelected === true) {
            for (const childMenu of childMenus) {
                childMenu.isSelected = true;
                childMenu.read = true;
                childMenu.edit = true;
                childMenu.create = true;
                childMenu.delete = true;
            }
        }
    };
    const handleClickShowPassword = (show: string) => {
        show === 'password' ? setShowPassword((show) => !show) : setShowConfirmPassword((show) => !show);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <Box component={'div'}>
            <Grid container>
                <Grid item xs={0.5}>
                    <IconButton aria-label="delete" onClick={() => navigate('/users')}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Typography>การจัดการ</Typography>
                        <Link component="button" onClick={() => navigate('/users')}>ผู้ใช้งาน</Link>
                        <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={3} display={'flex'} justifyContent={'end'}>
                    {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
                        <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
                            open: true,
                            title: 'ลบข้อมูล',
                            message: 'คุณต้องการลบประเภทสินค้านี้ใช่หรือไม่',
                            color: 'error',
                            type: 'delete'
                        })}>ลบข้อมูล</Button>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField id="firstName" label="ชื่อ" variant="outlined" fullWidth value={firstName}
                                        onChange={(event: any) => setFirstName(event.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="lastName" label="นามสกุล" variant="outlined" fullWidth value={lastName}
                                        onChange={(event: any) => setLastName(event.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="userGroup-select-label">ตำแหน่ง</InputLabel>
                                        <Select
                                            labelId="userGroup-select-label"
                                            id="userGroup-select"
                                            value={userGroups}
                                            label="ตำแหน่ง"
                                            onChange={(e: any) => handleChangeUserGroup(e.target.value)}>
                                            <MenuItem value='all'>กรุณาเลือกตำแหน่ง</MenuItem>
                                            {userGroupLists.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="username" label="ชื่อผู้ใช้งาน" variant="outlined" fullWidth value={username}
                                        onChange={(event: any) => setUsername(event.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword('password')}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            fullWidth
                                            value={password}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                                            label="รหัสผ่าน"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-confirm-password">ยืนยันรหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-confirm-password"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword('confirm')}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            fullWidth
                                            value={confirmPassword}
                                            onChange={(event: any) => setConfirmPassword(event.target.value)}
                                            label="ยืนยันรหัสผ่าน"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>
                                <Grid item xs={12}>
                                    <Typography>สิทธิ์การใช้งาน</Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <TableContainer sx={{ height: 600, overflow: 'auto' }} >
                                        <Table sx={{ minWidth: 650 }} stickyHeader >
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell width={100}><Checkbox value={isCkeckAll} checked={isCkeckAll} onChange={() => {
                                                        setIsCheckAll(!isCkeckAll);
                                                        handleChangeCheckall();
                                                    }} /></TableCell>
                                                    <TableCell width={400} align="left" >เมนู</TableCell>
                                                    <TableCell>ดูข้อมูล</TableCell>
                                                    <TableCell>สร้าง</TableCell>
                                                    <TableCell>แก้ไข</TableCell>
                                                    <TableCell>ลบ</TableCell>
                                                    <TableCell width={200} align="center"></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {menus && menus?.length > 0 &&
                                                    menus.map((menu: any, index: number) => {
                                                        return (
                                                            <TableRow key={menu.id}>
                                                                <TableCell width={100}><Checkbox checked={menu.read ? menu.isSelected : false}
                                                                    value={menu?.isSelected || false}
                                                                    onChange={() => {
                                                                        const newMenus = [...menus];
                                                                        newMenus[index].isSelected = !newMenus[index].isSelected;
                                                                        if (menu.isSelected === false) {
                                                                            newMenus[index].read = !newMenus[index].read;
                                                                            newMenus[index].edit = !newMenus[index].edit;
                                                                            newMenus[index].create = !newMenus[index].create;
                                                                            newMenus[index].delete = !newMenus[index].delete;
                                                                            if (menu.level === 0) {
                                                                                markChildMenusAsRead(newMenus, menu);
                                                                            }
                                                                        } else if (menu.isSelected === true) {
                                                                            newMenus[index].read = true;
                                                                            newMenus[index].edit = true;
                                                                            newMenus[index].create = true;
                                                                            newMenus[index].delete = true;
                                                                            if (menu.level === 0) {
                                                                                markChildMenusAsRead(newMenus, menu);
                                                                            }
                                                                        }
                                                                        setMenus(newMenus);
                                                                    }}
                                                                /></TableCell>
                                                                <TableCell width={400} align="left" sx={{ fontWeight: !menu.parent ? 'bold' : '', pl: menu.parent ? 5 : undefined }}>
                                                                    {(menu.level === 0) ? menu.name : ('- ' + menu.name)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Checkbox checked={menu.read || false}
                                                                        value={menu?.read || false}
                                                                        onChange={() => {
                                                                            const newMenus = [...menus];
                                                                            newMenus[index].read = !newMenus[index].read;
                                                                            setMenus(newMenus);
                                                                            if (menu.read === false) {
                                                                                newMenus[index].isSelected = false;
                                                                                newMenus[index].edit = false;
                                                                                newMenus[index].create = false;
                                                                                newMenus[index].delete = false;
                                                                                if (menu.level === 0) {
                                                                                    markChildMenusAsRead(newMenus, menu);
                                                                                }
                                                                            } else if (menu.read === true) {
                                                                                newMenus[index].isSelected = true;
                                                                                if (menu.level === 0) {
                                                                                    markChildMenusAsRead(newMenus, menu);
                                                                                }
                                                                            }
                                                                        }}
                                                                    /></TableCell>
                                                                <TableCell><Checkbox checked={menu.create || false}
                                                                    value={menu?.create || false}
                                                                    onChange={() => {
                                                                        const newMenus = [...menus];
                                                                        newMenus[index].create = !newMenus[index].create;
                                                                        setMenus(newMenus);
                                                                        if (menu.create === true) {
                                                                            newMenus[index].read = true;
                                                                            newMenus[index].isSelected = true;
                                                                        }
                                                                    }} /></TableCell>
                                                                <TableCell><Checkbox checked={menu.edit || false}
                                                                    value={menu?.edit || false}
                                                                    onChange={() => {
                                                                        const newMenus = [...menus];
                                                                        newMenus[index].edit = !newMenus[index].edit;
                                                                        setMenus(newMenus);
                                                                        if (menu.edit === true) {
                                                                            newMenus[index].read = true;
                                                                            newMenus[index].isSelected = true;
                                                                        }
                                                                    }} /></TableCell>
                                                                <TableCell><Checkbox checked={menu.delete || false}
                                                                    value={menu?.delete || false}
                                                                    onChange={() => {
                                                                        const newMenus = [...menus];
                                                                        newMenus[index].delete = !newMenus[index].delete;
                                                                        setMenus(newMenus);
                                                                        if (menu.delete === true) {
                                                                            newMenus[index].read = true;
                                                                            newMenus[index].isSelected = true;
                                                                        }
                                                                    }} /></TableCell>
                                                                <TableCell width={200} align="center"></TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid container style={{ width: '100%' }} mt={3} justifyContent={'end'}>
                                    <Grid item xs={4} >
                                        <Stack spacing={2} direction="row" >
                                            <Button variant="outlined" fullWidth onClick={() => navigate('/users')}>ยกเลิก</Button>
                                            {!id && <Button variant="contained" fullWidth onClick={() => {
                                                setOpenConfirm({
                                                    open: true,
                                                    title: 'บันทึกเอกสาร',
                                                    message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                                                    color: 'primary',
                                                    type: 'create'
                                                });
                                            }}>บันทึก</Button>}
                                            {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                                                setOpenConfirm({
                                                    open: true,
                                                    title: 'แก้ไขเอกสาร',
                                                    message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                                                    color: 'primary',
                                                    type: 'edit'
                                                });
                                            }}>แก้ไข</Button>}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === 'delete') {
                        handleDelete();
                    } else if (openConfirm.type === 'create') {
                        handleSave();
                    } else if (openConfirm.type === 'edit') {
                        handleEdit();
                    }
                }} />
        </Box >
    );
};

export default UsersCreate;