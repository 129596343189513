import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Button, Card, CardContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Link, ButtonProps, Autocomplete, Stack, Divider } from "@mui/material";
import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import customerStore from "../../stores/CustomerStore";
import statusStore from "../../stores/StatusStore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from "react-router-dom";
import authStore from "../../stores/AuthStore";
import ChipCustom from "../../components/ChipCustom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DialogConfirm from "../../components/DialogConfirm";
import { enqueueSnackbar } from "notistack";
import masterDataStore from "../../stores/MasterDataStore";
import DeleteIcon from '@mui/icons-material/Delete';
import imagesStore from "../../stores/ImagesStore";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import UploadFileIcon from '@mui/icons-material/UploadFile';
const CustomerCreate: React.FC = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [tel, setTel] = useState("");
  const [address, setAddress] = useState("");
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [gender, setGender] = useState("0");
  const [idCard, setIdCard] = useState("");
  const [moo, setMoo] = useState("");
  const [alley, setAlley] = useState("");
  const [street, setStreet] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [provinceValue, setProvinceValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [subDistrictValue, setSubDistrictValue] = useState("");
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);
  const [provinceData, setProvinceData] = useState<any>([]);
  const [districtData, setDistrictData] = useState<any>([]);
  const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
  const [zipCode, setZipCode] = useState("");
  const [occupation, setOccupation] = useState("");
  const [income, setIncome] = useState("");
  const [otherIncome, setOtherIncome] = useState("");
  const [source, setSource] = useState("");
  const [birthdate, setBirthdate] = useState<Dayjs | null>(dayjs());
  const [bookingData, setBookingData] = useState<any[]>([]);
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/bookings');
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete' | 'confirm-duplicate'
  });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleEdit = () => {
    if (id) {
      try {
        setIsLoading(true);
        Promise.all([

          masterDataStore.getAllProvince(),
          masterDataStore.getAllAmphur(),
          masterDataStore.getAllTambon(),
          statusStore.getAll(),
          masterDataStore.getBanksAll(),

        ]).then((res) => {

          setProvinceData(res[0]);
          setDistrictData(res[1]);
          setSubDistrictData(res[2]);
          setIsLoading(false);
        }).catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
        customerStore.update(id, {
          firstname: firstname,
          lastname: lastname,
          idCard: idCard,
          address: address,
          alley: alley,
          street: street,
          subDistrict: subDistrict !== "" ? subDistrict : null,
          province: province !== "" ? province : null,
          district: district !== "" ? district : null,
          zipCode: zipCode,
          tel: tel,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          occupation: occupation,
          income: income ? parseFloat(income) : 0,
          otherIncome: otherIncome ? parseFloat(otherIncome) : 0,
          source: source,
          moo: moo,
          gender: gender,
          birthdate: birthdate?.format('YYYY-MM-DD'),
        });
        handleEditFileUpload(id);
        enqueueSnackbar('บันทึกข้อมูลเรียบร้อย', { variant: 'success' });
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(true);
        enqueueSnackbar('เกิดข้อผิดพลาด', { variant: 'error' });
      }
    }
  };

  const handleDelete = () => {
    try {
      setIsLoading(true);
      if (id) {
        customerStore.update(id, {
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          isDeleted: true
        });
        enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
        setIsLoading(false);
        navigate('/customers');
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      enqueueSnackbar('เกิดข้อผิดพลาด', { variant: 'error' });
    }
  };

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };
  const handleKeyDown = (event: any, index: number, i = 0) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (i === -1) {
        inputRefs.current[24].focus();
      }
      else if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    }
  };

  const handleEditFileUpload = async (id: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "customer");
        formdata.append("refId", id);
        formdata.append('file', file);
        formdata.append('updateBy', authStore.user?.id);
        formdata.append("updateAt", new Date(Date.now() + index * 1000).toISOString());
        await imagesStore.upload(formdata)
          .catch(err => console.error("err", err));
      });
    }
    if (deleteImages.length > 0) {
      await deleteImages.forEach(async (item: any) => {
        await imagesStore.update(item.id, {
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          isDeleted: true
        }).catch(err => console.error("err deleteImages", err));
      });
    }
  };
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      masterDataStore.getAllProvince(),
      masterDataStore.getAllAmphur(),
      masterDataStore.getAllTambon(),

    ]).then((res) => {
      setSubDistrictData(res[2]);
      setDistrictData(res[1]);
      setProvinceData(res[0]);
      setIsLoading(false);
    });
    if (id) {
      customerStore.get(id).then((res) => {
        setFirstname(res.firstname);
        setLastname(res.lastname);
        setTel(res.tel);
        setAddress(res.address);
        setGender(res.gender);
        setIdCard(res.idCard);
        setMoo(res.moo);
        setAlley(res.alley);
        setStreet(res.street);
        setSubDistrict(res.subDistrict);
        setProvince(res.province);
        setZipCode(res.zipCode);
        setOccupation(res.occupation);
        setIncome(res.income);
        setOtherIncome(res.otherIncome);
        setSource(res.source);
        setBirthdate(dayjs(res.birthdate));
        setProvinceValue(res.province);
        setDistrictValue(res.district);
        setSubDistrictValue(res.subDistrict?.nameTh);
        setDistrict(res.district);
        setBookingData(res?.booking);
        setImages(res.images);
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box component={'div'}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton onClick={() => navigate('/customers')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ขาย/จอง</Typography>
            <Link component="button" onClick={() => navigate('/customers')}>รายชื่อลูกค้า</Link>
            <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={'flex'} justifyContent={'end'}>
          {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
              open: true,
              title: 'ลบเอกสาร',
              message: 'คุณต้องการลบเอกสารนี้ใช่หรือไม่',
              color: 'error',
              type: 'delete'
            })}>ลบเอกสาร</Button>
          }
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>ข้อมูลลูกค้า</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ชื่อ" variant="outlined" fullWidth value={firstname} onChange={(e) => setFirstname(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 0);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="นามสกุล" variant="outlined" fullWidth value={lastname} onChange={(e) => setLastname(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 1);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="เลขที่บัตรประชาชน" variant="outlined" fullWidth value={idCard} onChange={(e) => setIdCard(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 2);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel>เพศ</InputLabel>
                    <Select
                      value={gender}
                      label="เพศ"
                      onChange={(e) => { setGender(e.target.value); }}
                    >
                      <MenuItem value={'0'}>กรุณาเลือกเพศ</MenuItem>
                      <MenuItem value={'1'}>หญิง</MenuItem>
                      <MenuItem value={'2'}>ชาย</MenuItem>
                      <MenuItem value={'3'}>อื่นๆ</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} >
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                    <DatePicker
                      label="วันที่เกิด"
                      value={birthdate}
                      onChange={(newValue) => setBirthdate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ที่อยู่ เลขที่" variant="outlined" fullWidth value={address} onChange={(e) => setAddress(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 3);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="หมู่" variant="outlined" fullWidth multiline value={moo} onChange={(e) => setMoo(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 4);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ตรอก/ซอย" variant="outlined" fullWidth value={alley} onChange={(e) => setAlley(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 5);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="ถนน" variant="outlined" fullWidth value={street} onChange={(e) => setStreet(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 6);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={provinceValue}
                    id="province"
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setProvince(value?.id);
                        setProvinceValue(value?.nameTh);
                        const _districtOptions = districtData.filter((district: any) => district?.province?.id === value?.id
                        );
                        setDistrictOptions(_districtOptions);
                      }
                    }}
                    disablePortal
                    options={provinceData}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="จังหวัด" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 7);
                      }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={districtValue}
                    id="district"
                    onChange={(e: any, value: any) => {
                      setDistrict(value);
                      setDistrictValue(value?.nameTh);
                      if (value?.id) {
                        const _subDistrictOptions = subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                        setSubDistrictOptions(_subDistrictOptions);
                      }
                    }}
                    disablePortal
                    options={districtOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="อำเภอ" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 8);
                      }} />}
                    disabled={(districtOptions.length > 0 || districtValue !== "") ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={subDistrictValue ?? ""}
                    id="district"
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setSubDistrict(value?.id);
                        setSubDistrictValue(value?.nameTh);
                      }
                      if (value?.zipCode) { setZipCode(value?.zipCode); }
                    }}
                    disablePortal
                    options={subDistrictOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label="ตำบล" inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 9);
                      }} />}
                    disabled={(subDistrictOptions.length > 0 || subDistrictValue !== "") ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รหัสไปรษณีย์" variant="outlined" fullWidth value={zipCode} onChange={(e) => setZipCode(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 10);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="เบอร์โทร" variant="outlined" fullWidth value={tel} onChange={(e) => setTel(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 11);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="อาชีพ" variant="outlined" fullWidth value={occupation} onChange={(e) => setOccupation(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 12);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รายได้" variant="outlined" fullWidth value={income} onChange={(e) => setIncome(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 13);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="รายได้อื่นๆ" variant="outlined" fullWidth value={otherIncome} onChange={(e) => setOtherIncome(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 14);
                    }} />
                </Grid>
                <Grid item xs={9}>
                  <TextField label="แหล่งที่มา" variant="outlined" fullWidth multiline value={source} onChange={(e) => setSource(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 15);
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}>
                      <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'}>ข้อมูลการขาย/จอง</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>วันที่</TableCell>
                              <TableCell>เลขที่จอง</TableCell>
                              <TableCell>โครงการ</TableCell>
                              <TableCell>บ้าน</TableCell>
                              <TableCell>สถานะ</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              bookingData?.length > 0 &&
                              bookingData.sort((a: any, b: any) => { return dayjs(a.docDate).diff(b.docDate); })
                                .map((booking: any) => (
                                  <Fragment key={`g-${booking.id}`}>
                                    <TableRow key={booking.id}>
                                      <TableCell>{dayjs(booking?.docDate).format('DD/MM/YYYY')}</TableCell>
                                      <TableCell>{booking?.bookingNo}</TableCell>
                                      <TableCell>{booking?.project?.name}</TableCell>
                                      <TableCell>{booking?.house?.name}</TableCell>
                                      <TableCell>
                                        {booking?.status?.name && <ChipCustom lable={booking?.status?.name} />}
                                      </TableCell>
                                      <TableCell>
                                        <Button variant="outlined" size="small" onClick={() => navigate(`/booking-edit/${booking.id}`)}>
                                          ข้อมูลการจอง
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  </Fragment>
                                ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={8} >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>เอกสาร</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                        เลือกไฟล์
                        <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                          onChange={handleFileChange} />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedFiles.length > 0 && (
                        <Box component={'div'} mt={1}>
                          {selectedFiles.map((file: any, index: number) => (
                            <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small" color="error"
                                    onClick={() => handleFileRemove(index)}
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {images && (
                        <Box component={'div'} mt={1}>
                          {images.map((file: any, index: number) => (
                            <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                              <Grid container >
                                <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <IconButton size="small" color="error"
                                    onClick={() => handleFileRemoveImageList(file)}>
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={11.5}>
                                  <img
                                    src={`${apiUrl}/uploads/${file.name}`}
                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                    width="500"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2} >
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <Button variant="outlined" fullWidth onClick={() => navigate('/customers')}>ยกเลิก</Button>
                        {!id && <Button variant="contained" fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'บันทึกเอกสาร',
                            message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'create'
                          });
                        }}>บันทึก</Button>}
                        {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'แก้ไขเอกสาร',
                            message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'edit'
                          });
                        }}>แก้ไข</Button>}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>


      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'delete') {
            handleDelete();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          }
        }} />
      {/* <Box sx={{ display: "none" }}>
        <PrintContract ref={printRef} title={'สัญญาจอง'} data={data} />
      </Box> */}
    </Box>
  );
};

export default CustomerCreate;