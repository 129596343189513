import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import authStore from "./stores/AuthStore";

import Layout from './components/Layout';
import Login from "./features/Login/Login";
import Dashboard from "./features/Dashboard/Dashboard";
import Projects from "./features/Projects/Projects";
import Users from "./features/Settings/Users";
import Lands from "./features/Projects/Lands";
import Houses from "./features/Projects/Houses";

import Bills from "./features/Expenses/Bills";
import Purchases from "./features/Purchases/Purchases";
import Warehouses from "./features/Warehouses/Warehouses";
import Sells from "./features/SellBookings/Sells";
import Bookings from "./features/SellBookings/Bookings";
import UserGroups from "./features/Settings/UserGroups";
import Customers from "./features/SellBookings/Customers";
import BillCreate from "./features/Expenses/BillCreate";
import Products from "./features/Settings/Products";
import ProductCreate from "./features/Settings/ProductCreate";
import ProductGroups from "./features/Settings/ProductGroups";
import ProductGroupCreate from "./features/Settings/ProductGroupCreate";
import Vendors from "./features/Settings/Vendors";
import VendorCreate from "./features/Settings/VendorCreate";
import SettingHouses from "./features/Settings/SettingHouses";
import SettingHoueseCreate from "./features/Settings/SettingHoueseCreate";
import SettingProjects from "./features/Settings/SettingProjects";
import SettingProjectCreate from "./features/Settings/SettingProjectCreate";
import WageCreate from "./features/Expenses/WageCreate";
import ProjectDetail from "./features/Projects/ProjectDetail";
import SettingWorkers from './features/Settings/SettingWorkers';
import SettingWorkerCreate from './features/Settings/SettingWorkerCreate';
import Wages from './features/Expenses/Wages';
import UsersCreate from './features/Settings/UsersCreate';
import ProductCategories from "./features/Settings/ProductCategories";
import ProductCategoryCreate from "./features/Settings/ProductCategoryCreate";
import WarehouseDetail from './features/Warehouses/WarehouseDetail';
import HouseCreate from "./features/Projects/HouesCreate";
import LandCreate from "./features/Projects/LandCreate";
import BookingCreate from "./features/SellBookings/BookingCreate";
import Company from './features/Settings/Company';
import PurchaseCreate from './features/Purchases/PurchaseCreate';
import SellCreate from "./features/SellBookings/SellCreate";
import CustomerCreate from './features/SellBookings/CustomerCreate';
import Cashflow from './features/Expenses/Cashflow';
import CashflowCreate from './features/Expenses/CashflowCreate';
import Contracts from './features/Document/Contracts';
import ContractCreate from './features/Document/ContractCreate';
import Welcome from './features/Welcome/Welcome';
import Receipts from './features/Document/Receipts';
import ReceiptCreate from './features/Document/ReceiptCreate';
import InventoryCreate from './features/Warehouses/InventoryCreate';
import Inventories from './features/Warehouses/Inventories';

const App: React.FC = observer(() => {
  const user = localStorage.getItem('user');
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      authStore.setUser(JSON.parse(user));
    }
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Layout><Dashboard /></Layout> : <Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
        <Route path="/welcome" element={<Layout><Welcome /></Layout>} />

        {/* project menu group */}
        <Route path="/projects-list" element={<Layout><Projects /></Layout>} />
        <Route path="/project-detail/:id" element={<Layout><ProjectDetail /></Layout>} />
        <Route path="/lands" element={<Layout><Lands /></Layout>} />
        <Route path="/land-create" element={<Layout><LandCreate /></Layout>} />
        <Route path="/land-edit/:id" element={<Layout><LandCreate /></Layout>} />
        <Route path="/houses" element={<Layout><Houses /></Layout>} />
        <Route path="/house-create" element={<Layout><HouseCreate /></Layout>} />
        <Route path="/house-edit/:id" element={<Layout><HouseCreate /></Layout>} />
        {/* expenses menu group */}
        <Route path="/bills" element={<Layout><Bills /></Layout>} />
        <Route path="/bill-create" element={<Layout><BillCreate /></Layout>} />
        <Route path="/bill-edit/:id" element={<Layout><BillCreate /></Layout>} />
        <Route path="/wages" element={<Layout><Wages /></Layout>} />
        <Route path="/wage-create" element={<Layout><WageCreate /></Layout>} />
        <Route path="/wage-edit/:id" element={<Layout><WageCreate /></Layout>} />
        <Route path="/cashflow" element={<Layout><Cashflow /></Layout>} />
        <Route path="/cashflow-create" element={<Layout><CashflowCreate /></Layout>} />
        <Route path="/cashflow-edit/:id" element={<Layout><CashflowCreate /></Layout>} />

        {/* purchases menu group */}
        <Route path="/purchases" element={<Layout><Purchases /></Layout>} />
        <Route path="/purchase-create" element={<Layout><PurchaseCreate /></Layout>} />
        <Route path="/purchase-edit/:id" element={<Layout><PurchaseCreate /></Layout>} />

        {/* warehouses menu group */}
        <Route path="/warehouses" element={<Layout><Warehouses /></Layout>} />
        <Route path="/warehouse-detail/:id" element={<Layout><WarehouseDetail /></Layout>} />;
        <Route path="/inventories" element={<Layout><Inventories /></Layout>} />
        <Route path="/inventory-return-create" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-return-edit/:id" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-requisition-create" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-requisition-edit/:id" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-add-create" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-add-edit/:id" element={<Layout><InventoryCreate /></Layout>} />
        {/* <Route path="/inventory-create/:return" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-edit/:id/:return" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-create/:requisition" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-edit/:id/:requisition" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-create/:add" element={<Layout><InventoryCreate /></Layout>} />
        <Route path="/inventory-edit/:id/:add" element={<Layout><InventoryCreate /></Layout>} /> */}

        {/* sell bookings menu group */}
        <Route path="/sells" element={<Layout><Sells /></Layout>} />
        <Route path="/sell-create" element={<Layout><SellCreate /></Layout>} />
        <Route path="/sell-edit/:id" element={<Layout><SellCreate /></Layout>} />
        <Route path="/bookings" element={<Layout><Bookings /></Layout>} />
        <Route path="/booking-create" element={<Layout><BookingCreate /></Layout>} />
        <Route path="/booking-edit/:id" element={<Layout><BookingCreate /></Layout>} />
        <Route path="/customers" element={<Layout><Customers /></Layout>} />
        <Route path="/customer-edit/:id" element={<Layout><CustomerCreate /></Layout>} />

        {/* documentes menu group */}
        <Route path="/contracts" element={<Layout><Contracts /></Layout>} />
        <Route path="/contract-create" element={<Layout><ContractCreate /></Layout>} />
        <Route path="/contract-edit/:id" element={<Layout><ContractCreate /></Layout>} />
        <Route path="/receipts" element={<Layout><Receipts /></Layout>} />
        <Route path="/receipt-create" element={<Layout><ReceiptCreate /></Layout>} />
        <Route path="/receipt-edit/:id" element={<Layout><ReceiptCreate /></Layout>} />

        {/* settings menu group */}
        <Route path="/users" element={<Layout><Users /></Layout>} />
        <Route path="/users-create" element={<Layout><UsersCreate /></Layout>} />
        <Route path="/users-edit/:id" element={<Layout><UsersCreate /></Layout>} />
        <Route path="/user-groups" element={<Layout><UserGroups /></Layout>} />
        <Route path="/products" element={<Layout><Products /></Layout>} />
        <Route path="/product-create" element={<Layout><ProductCreate /></Layout>} />
        <Route path="/product-edit/:id" element={<Layout><ProductCreate /></Layout>} />
        <Route path="/product-groups" element={<Layout><ProductGroups /></Layout>} />
        <Route path="/product-group-create" element={<Layout><ProductGroupCreate /></Layout>} />
        <Route path="/product-group-edit/:id" element={<Layout><ProductGroupCreate /></Layout>} />
        <Route path="/product-categories" element={<Layout><ProductCategories /></Layout>} />
        <Route path="/product-category-create" element={<Layout><ProductCategoryCreate /></Layout>} />
        <Route path="/product-category-edit/:id" element={<Layout><ProductCategoryCreate /></Layout>} />
        <Route path="/vendors" element={<Layout><Vendors /></Layout>} />
        <Route path="/vendor-create" element={<Layout><VendorCreate /></Layout>} />
        <Route path="/vendor-edit/:id" element={<Layout><VendorCreate /></Layout>} />
        <Route path="/setting-houses" element={<Layout><SettingHouses /></Layout>} />
        <Route path="/setting-house-create" element={<Layout><SettingHoueseCreate /></Layout>} />
        <Route path="/setting-house-edit/:id" element={<Layout><SettingHoueseCreate /></Layout>} />
        <Route path="/setting-projects" element={<Layout><SettingProjects /></Layout>} />
        <Route path="/setting-project-create" element={<Layout><SettingProjectCreate /></Layout>} />
        <Route path="/setting-project-edit/:id" element={<Layout><SettingProjectCreate /></Layout>} />
        <Route path="/setting-workers" element={<Layout><SettingWorkers /></Layout>} />
        <Route path="/setting-worker-create" element={<Layout><SettingWorkerCreate /></Layout>} />
        <Route path="/setting-worker-edit/:id" element={<Layout><SettingWorkerCreate /></Layout>} />
        <Route path="/company" element={<Layout><Company /></Layout>} />

        <Route path="*" element={<Navigate to="/welcome" />} />
      </Routes>
    </Router>
  );
});

export default App;
