import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactToPrint from "react-to-print";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import warehousesStore from "../../stores/WarehousesStore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import PrintWarehouseDetail from "../../components/Report/Pdf/PrintWarehouseDetail";
import ExportFileWarehouseDetail from "../../components/Report/Excel/ExportFileWarehouseDetail";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import inventoryStore from "../../stores/InventoryStore";
import PrintInventoryHistory from "../../components/Report/Pdf/PrintInventoryHistory";
import ExportFileInventoryHistory from "../../components/Report/Excel/ExportFileInventoryHistory";

const WarehouseDetail: React.FC = () => {
  const navigate = useNavigate();
  const printRef = useRef(null);
  const { id } = useParams();
  const exportRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [value, setValue] = useState("home");
  const [inventories, setInventories] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const printInventoryRef = useRef(null);
  const exportInventoryRef = useRef(null);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSearch = (pageNumber?: string, limitNumber?: string) => {
    setIsLoading(true);
    setInventories([]);
    const _page = parseInt(pageNumber || "0");
    const _limit = parseInt(limitNumber || "0");
    inventoryStore
      .getDetailByProductId({
        product: id,
        page: _page,
        limit: _limit,
      })
      .then((result) => {
        setInventories(result.data);
        setTotal(result.total);
        if (result?.total && result.total <= 10) {
          setPage(0);
          setLimit(10);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      warehousesStore.getDetailById(id).then((result) => {
        setData(result);
        handleSearch();
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/warehouses")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>คลังวัสดุ</Typography>
            <Link component="button" onClick={() => navigate("/warehouses")}>
              รายการวัสดุ
            </Link>
            <Typography variant="h6" color="text.primary">
              รายละเอียดวัสดุ
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table></Table>
              <Grid container>
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    style={{
                      textDecoration: !data?.isActive ? "line-through" : "none",
                    }}
                  >
                    {data?.isActive ? (
                      data?.name || ""
                    ) : (
                      <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                        {data?.name || ""}
                      </Tooltip>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    จำนวนคงเหลือ{" "}
                    {parseFloat(data?.total || 0).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                    {data?.unit || ""}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  {data?.projects.length > 0 ? (
                    data?.projects.map((project: any) => (
                      <Accordion
                        style={{ width: "100%" }}
                        key={project?.id?.toString()}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Grid
                            container
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Typography fontWeight={"bold"}>
                              {project?.name || "ยังไม่ระบุ"}
                            </Typography>
                            <Typography mr={10}>
                              จำนวน{" "}
                              {parseFloat(
                                project?.quantity ?? 0
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              {data?.unit || ""}
                            </Typography>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }}>
                              <TableBody>
                                {project.houses.map((house: any) => (
                                  <TableRow>
                                    <TableCell sx={{ width: "80%" }}>
                                      {house.name || "ยังไม่ระบุ"}
                                    </TableCell>
                                    <TableCell>
                                      จำนวน{" "}
                                      {parseFloat(
                                        house?.quantity ?? 0
                                      ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}{" "}
                                      {data?.unit || ""}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : (
                    <Typography fontWeight={"bold"}>ไม่มีข้อมูล</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange}>
                  <Tab label="คลังวัสดุ" value="home" />
                  <Tab label="เทียบราคา" value="warehouse" />
                </TabList>
              </Box>
              <TabPanel value="home">
                <Grid item xs={12}>
                  <Grid container justifyContent={"end"}>
                    <Grid item xs={10}>
                      <Typography>
                        ทั้งหมด {inventories?.length.toLocaleString()} รายการ
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`inventory-history-${dayjs().format(
                          "DD-MM-YYYY"
                        )}`}
                        sheet={`inventory-history-${dayjs().format(
                          "DD-MM-YYYY"
                        )}`}
                        currentTableRef={exportInventoryRef.current}
                      >
                        <IconButton aria-label="download">
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <IconButton aria-label="print">
                              <PrintIcon />
                            </IconButton>
                          );
                        }}
                        content={() => {
                          return printInventoryRef.current;
                        }}
                      />
                    </Grid>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell width={100} align="center">
                              วันที่เอกสาร
                            </TableCell>
                            <TableCell align="center">เลขเอกสาร</TableCell>
                            <TableCell align="center">รายการ</TableCell>
                            <TableCell align="center">ประเภท</TableCell>
                            <TableCell align="center">จำนวนเข้า</TableCell>
                            <TableCell align="center">จำนวนออก</TableCell>
                            <TableCell align="center">คงเหลือ</TableCell>
                            <TableCell width={100} align="center">
                              ผู้ทำรายการ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inventories &&
                            inventories.length > 0 &&
                            inventories?.map((row: any, index: number) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    backgroundColor:
                                      row?.inventory?.type === "add" ||
                                      row?.inventory?.type === "return"
                                        ? "#90F5B0"
                                        : "#F5B0B0",
                                  }}
                                >
                                  <TableCell>
                                    {dayjs(row?.inventory?.docDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell>{row?.inventory?.docNo}</TableCell>
                                  <TableCell>
                                    {row?.product?.name}
                                    <Box>
                                      <b>โครงการ: </b> {row?.project?.name}{" "}
                                      <b>บ้าน: </b> {row?.house?.name}
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.inventory?.type === "add"
                                      ? "เพิ่ม"
                                      : row?.inventory?.type === "return"
                                      ? "คืน"
                                      : row?.inventory?.type === "buy"
                                      ? "ซื้อ"
                                      : "เบิก"}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row?.inventory?.type === "add" ||
                                    row?.inventory?.type === "return" ||
                                    row?.inventory?.type === "buy"
                                      ? parseFloat(
                                          row?.quantity || 0
                                        ).toLocaleString(undefined, {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row?.inventory?.type === "requisition"
                                      ? parseFloat(
                                          row?.quantity || 0
                                        ).toLocaleString(undefined, {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </TableCell>
                                  <TableCell align="right">
                                    {parseFloat(
                                      row?.remain || 0
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    })}
                                  </TableCell>
                                  <TableCell>
                                    {row?.inventory?.worker?.firstName}{" "}
                                    {row?.inventory?.worker?.lastName}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box mt={3}>
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[
                          10,
                          25,
                          50,
                          100,
                          { label: "All", value: -1 },
                        ]}
                        count={total}
                        onPageChange={(e, newPage) => {
                          setPage(newPage);
                          handleSearch(newPage.toString());
                        }}
                        page={page}
                        rowsPerPage={limit ? limit : 10}
                        onRowsPerPageChange={(e: any) => {
                          setInventories([]);
                          setLimit(e.target.value);
                          setPage(0);
                          handleSearch("0", e.target.value.toString());
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="warehouse">
                <Grid item xs={12}>
                  <Grid container justifyContent={"end"}>
                    <Grid item xs={10}>
                      <Typography>
                        ทั้งหมด {data?.vendors.length.toLocaleString()} รายการ
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`warehouse-detail-${dayjs().format(
                          "DD-MM-YYYY"
                        )}`}
                        sheet={`warehouse-detail-${dayjs().format(
                          "DD-MM-YYYY"
                        )}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download">
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <IconButton aria-label="print">
                              <PrintIcon />
                            </IconButton>
                          );
                        }}
                        content={() => {
                          return printRef.current;
                        }}
                      />
                    </Grid>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ width: "400px" }}
                              align="center"
                            >
                              รายการ
                            </TableCell>
                            <TableCell align="center">รายละเอียด</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.vendors.map((vendor: any) => (
                            <TableRow key={vendor.id}>
                              <TableCell>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} fontWeight="bold">
                                    {vendor.name}
                                  </Grid>
                                  <Grid item xs={12}>
                                    {vendor.address}
                                  </Grid>
                                  <Grid item xs={12}>
                                    {vendor.tel}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Table>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>เลขที่เอกสาร</TableCell>
                                      <TableCell>จำนวน</TableCell>
                                      <TableCell>ราคาต่อหน่วย</TableCell>
                                      <TableCell>มูลค่ารวม</TableCell>
                                    </TableRow>
                                    {vendor?.bills.map((bill: any) => (
                                      <TableRow key={bill.id}>
                                        <TableCell>
                                          {bill?.docNo || ""}
                                        </TableCell>
                                        <TableCell>
                                          {parseFloat(
                                            bill?.quantity || 0
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          })}
                                        </TableCell>
                                        <TableCell>
                                          {parseFloat(
                                            bill?.price || 0
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          })}
                                        </TableCell>
                                        <TableCell>
                                          {parseFloat(
                                            bill?.total || 0
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          })}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintWarehouseDetail
          ref={printRef}
          title={data?.name}
          data={data?.vendors || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileWarehouseDetail
          ref={exportRef}
          title={data?.name}
          data={data?.vendors || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <PrintInventoryHistory
          ref={printInventoryRef}
          title={data?.name}
          inventories={inventories || []}
        />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileInventoryHistory
          ref={exportInventoryRef}
          title={data?.name}
          inventories={inventories || []}
        />
      </Box>
    </Box>
  );
};

export default WarehouseDetail;
