import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import authStore from "../../stores/AuthStore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuid4 } from "uuid";
import dayjs, { Dayjs } from "dayjs";
import productsStore from "../../stores/ProductsStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import DialogCreateProduct from "../../components/DialogCreateProduct";
import DialogConfirm from "../../components/DialogConfirm";
import { enqueueSnackbar } from "notistack";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
import workersStore from "../../stores/WorkersStore";
import DialogCreateWorker from "../../components/DialogCreateWorker";
import inventoryStore from "../../stores/InventoryStore";
import PrintInventory from "../../components/Report/Pdf/PrintInventory";
import CancelIcon from "@mui/icons-material/Cancel";

interface ProductOptionType {
  inputValue?: string;
  name: string;
  size?: string;
  color?: string;
}
interface WorkerOptionType {
  inputValue?: string;
  name: string;
}

const filterProduct = createFilterOptions<ProductOptionType>();
const filterWorker = createFilterOptions<WorkerOptionType>();
const InventoryCreate: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const permission: any = authStore.user?.roles.find(
    (role: any) => role.menu.path === "/purchases"
  );
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as
      | "create"
      | "edit"
      | "delete"
      | "confirm-duplicate"
      | "cancel",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [docDate, setDocDate] = useState<Dayjs | null>(dayjs());
  const [refNo, setRefNo] = useState("");
  const [productList, setProductList] = useState<any[]>([]);
  const [productValue, setProductValue] = useState<ProductOptionType[]>([]);
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
  const [dialogCreateProductValue, setDialogCreateProductValue] = useState("");
  const [remark, setRemark] = useState("");
  const [docNo, setDocNo] = useState("");
  const [itemsOld, setItemsOld] = useState<any[]>([]);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [itemLength, setItemLength] = useState(0);
  const [items, setItems] = useState<any[]>([{ id: uuid4() }]);
  const navigate = useNavigate();
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [housesList, setHousesList] = useState<any[]>([]);
  const [workersList, setWorkersList] = useState<any[]>([]);
  const [workerValue, setWorkerValue] = useState<WorkerOptionType[]>([]);
  const [workerId, setWorkerId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [type, setType] = useState("");
  const [refNoList, setRefNoList] = useState<any[]>([]);
  const [openAddWorkerDialog, setOpenAddWorkerDialog] = useState(false);
  const [dialogCreateWorkerValue, setDialogCreateWorkerValue] = useState("");
  const [refNoValue, setRefNoValue] = useState("");
  const [isCancelled, setIsCancelled] = useState(false);

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        id: uuid4(),
        isNew: true,
      },
    ]);
  };
  const handleRemoveItem = (id: string) => {
    setItems(items.filter((item: any) => item.id !== id));
  };

  const handleCancel = async () => {
    if (id) {
      try {
        await inventoryStore.update(id, {
          isCancelled: true,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          detail: itemsOld,
          type: type,
          remark: remark,
          worker: workerId || undefined,
        });
        enqueueSnackbar("ยกเลิกข้อมูลสำเร็จ", { variant: "success" });
        setIsLoading(false);
        navigate("/inventories");
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (!items[0].product) {
        enqueueSnackbar("กรุณาเลือกรายการวัสดุ", { variant: "warning" });
        setIsLoading(false);
        return;
      }

      const details = await items.map((item: any) => {
        const _total =
          type === "add" || type === "return"
            ? parseFloat(item.product?.totalQuantity || 0) +
              parseFloat(item.quantity || 0)
            : parseFloat(item.product?.totalQuantity || 0) -
              parseFloat(item.quantity || 0);
        return {
          id: item.id,
          project: item.project?.id || undefined,
          house: item.house?.id || undefined,
          product: item.product || undefined,
          total: _total,
          quantity: item.quantity,
          unit: item.unit,
          remark: item.remark,
          createBy: authStore.user?.id,
          createAt: new Date(),
        };
      });
      await inventoryStore
        .create({
          docDate: docDate,
          refNo: refNo || undefined,
          remark: remark,
          type: type,
          worker: workerId || undefined,
          createBy: authStore.user?.id,
          createAt: new Date(),
          detail: details,
        })
        .then((res) => {
          navigate(`/inventory-${type}-edit/${res.id}`);
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });

      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      setIsLoading(false);
      console.error(error);
    }
  };

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
    setItemLength(inputRefs.current.length - 1);
  };

  const handleKeyDown = (event: any, index: number, i = 0) => {
    const currentInput = event.target;
    if (event.key === "Enter") {
      const currentIndex = Array.from(inputRefs.current).indexOf(currentInput);
      const nextIndex = currentIndex + 1;
      if (i === -1) {
        inputRefs.current[8].focus();
      } else if (index === 5 && i === 0) {
        if (inputRefs.current[9]) {
          inputRefs.current[9].focus();
        } else {
          inputRefs.current[6].focus();
        }
      } else if (currentIndex === itemLength) {
        inputRefs.current[6].focus();
      } else {
        if (nextIndex < inputRefs.current.length) {
          inputRefs.current[nextIndex].focus();
          event.preventDefault();
        }
      }
    }
  };

  const handleCheckType = (value: any) => {
    switch (true) {
      case value.pathname.includes("add"):
        setType("add");
        break;
      case value.pathname.includes("requisition"):
        setType("requisition");
        break;
      case value.pathname.includes("return"):
        setType("return");
        break;
      default:
        setType("unknown");
    }
  };

  const handleChangeRefNo = (value: any) => {
    setRefNo(value);
    setRefNoValue(value?.docNo);
    setItems(value?.inventoryDetail || []);
  };

  useEffect(() => {
    setIsLoading(true);
    handleCheckType(location);
    Promise.all([
      projectsStore.getAll(),
      housesStore.getAll(),
      workersStore.getAll(),
      productsStore.getAll(),
      inventoryStore.getAllRefNo(),
    ]).then((res) => {
      setProjectsList(res[0]);
      setHousesList(res[1]);
      setWorkersList(res[2]);
      setProductList(
        res[3].sort((a: any, b: any) => a.name.localeCompare(b.name))
      );
      setRefNoList(res[4]);
      setIsLoading(false);
    });
    if (id) {
      inventoryStore.get(id).then((res) => {
        setDocDate(dayjs(res.docDate));
        setRefNo(res.refNo?.id);
        setRefNoValue(res.refNo?.docNo);
        setDocNo(res?.docNo || "");
        setIsCancelled(res?.isCancelled || false);
        setItems(res?.inventoryDetail || []);
        const name: any = res?.worker?.firstName
          ? res?.worker?.firstName + " " + res?.worker?.lastName
          : "";
        setWorkerValue(name);
        setWorkerId(res?.worker?.id);
        setItemsOld(res?.inventoryDetail || []);
        setRemark(res?.remark || "");
      });
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const printRef = useRef(null);
  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/inventories")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={5.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>คลังวัสดุ</Typography>
            <Link component="button" onClick={() => navigate("/inventories")}>
              เบิก/คืนวัสดุ
            </Link>
            <Typography variant="h6" color="text.primary">
              {id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {id && (
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<PrintIcon />}
                >
                  พิมพ์ใบสั่งซื้อ
                </Button>
              )}
              content={() => printRef.current}
            />
          )}
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {((permission && permission.delete) ||
            authStore.user?.isSuperAdmin) &&
            id && (
              <Button
                disabled={isCancelled}
                variant="contained"
                color="error"
                startIcon={<CancelIcon />}
                onClick={() =>
                  setOpenConfirm({
                    open: true,
                    title: "ยกเลิกเอกสาร",
                    message: "คุณต้องการยกเลิกเอกสารนี้ใช่หรือไม่",
                    color: "error",
                    type: "cancel",
                  })
                }
              >
                ยกเลิกเอกสาร
              </Button>
            )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        {type === "add"
                          ? "ใบเพิ่มวัสดุ"
                          : type === "return"
                          ? "ใบคืนวัสดุ"
                          : "ใบเบิกวัสดุ"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="docNo"
                        label="เลขที่เอกสาร"
                        variant="outlined"
                        fullWidth
                        onChange={(event) => {
                          setDocNo(event.target.value);
                        }}
                        value={docNo}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        disabled={isCancelled || id ? true : false}
                        fullWidth
                        value={refNoValue}
                        onChange={(event: any, newValue) => {
                          handleChangeRefNo(newValue);
                        }}
                        disablePortal
                        options={refNoList}
                        sx={{ width: "100%" }}
                        renderOption={(props, option) => (
                          <li {...props}>{option.docNo}</li>
                        )}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option || "";
                          }
                          if (option.inputValue) {
                            return option.inputValue || "";
                          }
                          return option.docNo || "";
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="เลขที่อ้างอิง"
                            inputRef={addRef}
                            onKeyDown={(e) => handleKeyDown(e, 1)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"th"}
                      >
                        <DatePicker
                          disabled={isCancelled || id ? true : false}
                          label="วันที่เอกสาร"
                          value={docDate}
                          onChange={(newValue) => setDocDate(newValue)}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography>รายการวัสดุ</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0.5} mb={2}>
                    <Grid
                      item
                      xs={0.5}
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                    ></Grid>
                    <Grid
                      item
                      xs={0.5}
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                    >
                      <Typography>ลำดับ</Typography>
                    </Grid>
                    <Grid item xs={5.25} textAlign={"center"}>
                      <Typography>รายการ</Typography>
                    </Grid>
                    <Grid item xs={1.75} textAlign={"center"}>
                      <Typography>จำนวน</Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <Typography>หน่วย</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign={"center"}>
                      <Typography>หมายเหตุ</Typography>
                    </Grid>
                  </Grid>
                  {items.map((item: any, index: number) => {
                    return (
                      <Grid item xs={12} key={`i-${index.toString()}`}>
                        <Grid container spacing={0.5} alignItems={"center"}>
                          <Grid
                            item
                            xs={0.5}
                            mb={1.5}
                            justifyContent={"center"}
                            alignItems={"center"}
                            display={"flex"}
                          >
                            <IconButton
                              disabled={isCancelled || id ? true : false}
                              aria-label={`delete-${id}`}
                              size="small"
                              color="error"
                              onClick={() => handleRemoveItem(items[index].id)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={0.5}
                            mb={2}
                            justifyContent={"center"}
                            alignItems={"center"}
                            display={"flex"}
                          >
                            <Typography>{index + 1}</Typography>
                          </Grid>
                          <Grid item xs={5.25}>
                            <Grid container spacing={0.5}>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={isCancelled || id ? true : false}
                                  fullWidth
                                  size="small"
                                  disablePortal
                                  value={
                                    item.project?.name
                                      ? item.project?.name
                                      : item.project
                                      ? item.project
                                      : ""
                                  }
                                  options={projectsList}
                                  sx={{ width: "100%" }}
                                  renderOption={(props, option) => (
                                    <li {...props}>{option.name}</li>
                                  )}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option || "";
                                    }
                                    if (option.inputValue) {
                                      return option.inputValue || "";
                                    }
                                    return option.name || "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="โครงการ"
                                      inputRef={addRef}
                                      onKeyDown={(e) => {
                                        handleKeyDown(e, 0);
                                      }}
                                    />
                                  )}
                                  onChange={(e, value) => {
                                    if (value?.id) {
                                      housesStore
                                        .getByProjectId(value?.id)
                                        .then((res) => {
                                          setHousesList(res);
                                        });
                                    } else {
                                      housesStore.getAll().then((res) => {
                                        setHousesList(res);
                                      });
                                    }
                                    const newItems = [...items];
                                    newItems[index].project = value;
                                    setItems(newItems);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} mb={1}>
                                <Autocomplete
                                  disabled={isCancelled || id ? true : false}
                                  value={
                                    item.product?.name
                                      ? (item.product?.name || "") +
                                        " " +
                                        (item?.product?.color ||
                                        item?.product?.size
                                          ? "("
                                          : " ") +
                                        (item?.product?.color || "") +
                                        " " +
                                        (item?.product?.size || "") +
                                        (item?.product?.color ||
                                        item?.product?.size
                                          ? ")"
                                          : " ")
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    if (typeof newValue === "string") {
                                      setTimeout(() => {
                                        setOpenAddProductDialog(true);
                                        setDialogCreateProductValue(newValue);
                                      });
                                    } else if (
                                      newValue &&
                                      newValue.inputValue
                                    ) {
                                      setOpenAddProductDialog(true);
                                      setDialogCreateProductValue(
                                        newValue.inputValue
                                      );
                                    } else {
                                      setProductValue({
                                        ...productValue,
                                        [index]: newValue,
                                      });
                                      const newItems = [...items];
                                      newItems[index].product = {
                                        id: newValue?.id,
                                        name: newValue?.name,
                                        unit: newValue?.unit,
                                        totalQuantity: newValue?.totalQuantity,
                                      };
                                      newItems[index].name = newValue?.name;
                                      newItems[index].unit = newValue?.unit;
                                      setItems(newItems);
                                    }
                                  }}
                                  filterOptions={(options, params) => {
                                    const filtered = filterProduct(
                                      options,
                                      params
                                    );
                                    const uniqueOptions = filtered
                                      .reduce((unique, option) => {
                                        const optionLabel =
                                          typeof option === "string"
                                            ? option
                                            : option.name;
                                        return unique.has(optionLabel)
                                          ? unique
                                          : unique.set(optionLabel, option);
                                      }, new Map())
                                      .values();

                                    const result = [...uniqueOptions];

                                    if (params.inputValue !== "") {
                                      result.push({
                                        inputValue: params.inputValue,
                                        name: `สร้าง "${params.inputValue}"`,
                                      });
                                    }

                                    return result;
                                  }}
                                  id={`item-${index}-product-autocomplete`}
                                  options={productList}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    return option.name;
                                  }}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  renderOption={(props, option) => (
                                    <li {...props}>
                                      {option.name}{" "}
                                      {option.color || option.size
                                        ? `(${option.color || ""} ${
                                            option.size || ""
                                          })`
                                        : ""}
                                    </li>
                                  )}
                                  sx={{ width: "100%" }}
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField {...params} variant="outlined" />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5.75} mb={1}>
                            <Grid container spacing={0.5}>
                              <Grid item xs={12}>
                                <Autocomplete
                                  fullWidth
                                  size="small"
                                  disabled={isCancelled || id ? true : false}
                                  value={
                                    item.house?.name
                                      ? item.house?.name
                                      : item.house
                                      ? item.house
                                      : ""
                                  }
                                  disablePortal
                                  options={housesList}
                                  sx={{ width: "100%" }}
                                  renderOption={(props, option) => (
                                    <li {...props}>{option.name}</li>
                                  )}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option || "";
                                    }
                                    if (option.inputValue) {
                                      return option.inputValue || "";
                                    }
                                    return option.name || "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="บ้าน"
                                      inputRef={addRef}
                                      onKeyDown={(e) => handleKeyDown(e, 1)}
                                    />
                                  )}
                                  onChange={(e, value) => {
                                    const newItems = [...items];
                                    newItems[index].house = value;
                                    setItems(newItems);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3.5}>
                                <TextField
                                  id={`item-${index}-qty`}
                                  variant="outlined"
                                  fullWidth
                                  type="number"
                                  inputProps={{
                                    min: 0,
                                  }}
                                  value={
                                    item?.quantity
                                      ? parseFloat(item?.quantity)
                                      : ""
                                  }
                                  onChange={(e) => {
                                    const newItems = [...items];
                                    newItems[index].quantity = e.target.value;
                                    setItems(newItems);
                                  }}
                                  inputRef={addRef}
                                  onKeyDown={(e) => {
                                    handleKeyDown(e, 2 + index, index);
                                    if (e.key === "-") {
                                      e.preventDefault();
                                    }
                                  }}
                                  disabled={isCancelled || id ? true : false}
                                />
                              </Grid>
                              <Grid item xs={2.5}>
                                <TextField
                                  id={`item-${index}-unit`}
                                  variant="outlined"
                                  fullWidth
                                  value={item.unit || ""}
                                  inputProps={{
                                    min: 0,
                                  }}
                                  onChange={(e) => {
                                    const newItems = [...items];
                                    newItems[index].unit = e.target.value;
                                    setItems(newItems);
                                  }}
                                  inputRef={addRef}
                                  onKeyDown={(e) =>
                                    handleKeyDown(e, 3 + index, index)
                                  }
                                  disabled={isCancelled || id ? true : false}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  id={`item-${index}-remar`}
                                  variant="outlined"
                                  fullWidth
                                  value={item.remark || ""}
                                  inputProps={{
                                    min: 0,
                                  }}
                                  onChange={(e) => {
                                    const newItems = [...items];
                                    newItems[index].remark = e.target.value;
                                    setItems(newItems);
                                  }}
                                  inputRef={addRef}
                                  onKeyDown={(e) =>
                                    handleKeyDown(e, 3 + index, index)
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} mb={2}>
                            <Divider />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <Button
                      disabled={isCancelled || id ? true : false}
                      variant="outlined"
                      sx={{ width: "100%" }}
                      onClick={handleAddItem}
                      startIcon={<AddCircleIcon />}
                    >
                      เพิ่มรายการ
                    </Button>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography>หมายเหตุ</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="remark"
                              variant="outlined"
                              fullWidth
                              multiline
                              maxRows={8}
                              onChange={(event) => {
                                setRemark(event.target.value);
                              }}
                              value={remark}
                              inputRef={addRef}
                              onKeyDown={(e) => handleKeyDown(e, 6)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography>ผู้ทำรายการ</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              value={workerValue || ""}
                              disablePortal
                              sx={{ width: "100%" }}
                              onChange={async (event, newValue) => {
                                if (typeof newValue === "string") {
                                  setTimeout(() => {
                                    setOpenAddWorkerDialog(true);
                                    setDialogCreateWorkerValue(newValue);
                                  });
                                } else if (newValue && newValue.inputValue) {
                                  setOpenAddWorkerDialog(true);
                                  setDialogCreateWorkerValue(
                                    newValue.inputValue
                                  );
                                } else {
                                  newValue.name =
                                    (newValue?.firstName || "") +
                                      " " +
                                      (newValue?.lastName || "") || "";

                                  setWorkerValue(newValue.name);
                                  setWorkerId(newValue?.id);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filterWorker(options, params);
                                const uniqueOptions = filtered
                                  .reduce((unique, option) => {
                                    const optionLabel =
                                      typeof option === "string"
                                        ? option
                                        : option.name;
                                    return unique.has(optionLabel)
                                      ? unique
                                      : unique.set(optionLabel, option);
                                  }, new Map())
                                  .values();
                                const result = [...uniqueOptions];

                                if (params.inputValue !== "") {
                                  result.push({
                                    inputValue: params.inputValue,
                                    name: `สร้าง "${params.inputValue}"`,
                                  });
                                }

                                return result;
                              }}
                              id={`item-worker-autocomplete`}
                              options={workersList}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option || "";
                                }
                                if (option.inputValue) {
                                  return option?.inputValue || "";
                                }
                                return option?.name || "";
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              renderOption={(props, option) => (
                                <li {...props}>{option.name}</li>
                              )}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="ชื่อคนงาน"
                                  variant="outlined"
                                  inputRef={addRef}
                                  onKeyDown={(e) => {}}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Stack spacing={2} direction="row">
                              <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => navigate("/inventories")}
                              >
                                ยกเลิก
                              </Button>
                              {!id && (
                                <Button
                                  variant="contained"
                                  fullWidth
                                  onClick={() => {
                                    setOpenConfirm({
                                      open: true,
                                      title: "บันทึกเอกสาร",
                                      message:
                                        "คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่",
                                      color: "primary",
                                      type: "create",
                                    });
                                  }}
                                >
                                  บันทึก
                                </Button>
                              )}
                              {((permission && permission.edit) ||
                                authStore.user?.isSuperAdmin) &&
                                id && <Grid item xs={12}></Grid>}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogCreateWorker
        name={dialogCreateWorkerValue}
        open={openAddWorkerDialog}
        onClose={() => {
          setOpenAddWorkerDialog(false);
        }}
        onSave={(success) => {
          if (success) {
            workersStore.getAll().then((res) => {
              setWorkersList(res);
            });
          }
          setOpenAddWorkerDialog(false);
        }}
      />
      <DialogCreateProduct
        name={dialogCreateProductValue}
        open={openAddProductDialog}
        onClose={() => {
          setOpenAddProductDialog(false);
        }}
        onSave={(success) => {
          if (success) {
            productsStore.getAll().then((res) => {
              setProductList(
                res.sort((a: any, b: any) => a.name.localeCompare(b.name))
              );
            });
          }
          setOpenAddProductDialog(false);
        }}
      />
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "cancel") {
            handleCancel();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "confirm-duplicate") {
            handleSave();
          }
        }}
      />
      <Box style={{ display: "none" }}>
        <PrintInventory
          ref={printRef}
          title={
            type === "add"
              ? "ใบเพิ่มวัสดุ"
              : type === "return"
              ? "ใบคืนวัสดุ"
              : "ใบเบิกวัสดุ"
          }
          data={{
            items: items,
            docNo: docNo,
            docDate: docDate,
            refNo: refNoValue,
            workerValue: workerValue,
            remark: remark,
          }}
        />
      </Box>
    </Box>
  );
};
export default InventoryCreate;
