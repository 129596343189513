import { Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import authStore from "../../stores/AuthStore";
import housesStore from "../../stores/HousesStore";
import projectsStore from "../../stores/ProjectsStore";
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleBackdrop from "../../components/SimpleBackdrop";
import DialogConfirm from "../../components/DialogConfirm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const SettingHoueseCreate: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [project, setProject] = useState('');
    const [houeseName, setHouseName] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [projectData, setProjectData] = useState<any[]>([]);
    const [openConfirm, setOpenConfirm] = useState({
        open: false,
        title: '',
        message: '',
        color: 'primary' as ButtonProps['color'],
        type: 'create' as 'create' | 'edit' | 'delete'
    });
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/setting-houses');
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const addRef = (el: any) => {
        if (el && !inputRefs.current.includes(el)) {
            inputRefs.current.push(el);
        }
    };

    const handleKeyDown = (event: any, index: number) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index + 1 < inputRefs.current.length) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    useEffect(() => {
        setIsLoading(true);
        projectsStore.getAll().then((res) => {
            if (res.error) {
                enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
                setIsLoading(false);
            }
            setProjectData(res);
            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
            setIsLoading(false);
        });
        if (id) {
            housesStore.get(id).then((res) => {
                if (res.error) {
                    enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
                    setIsLoading(false);
                }
                setProject(res.project?.id);
                setHouseName(res.name);
                setDescription(res.description);
                setIsLoading(false);
            }).catch((error) => {
                console.error(error);
                enqueueSnackbar('ไม่พบข้อมูล', { variant: 'error' });
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeProject = (event: SelectChangeEvent) => {
        setProject(event.target.value as string);
    };

    const handleSave = () => {
        if (!id) {
            setIsLoading(true);
            housesStore.create({
                project: project ? project : null,
                name: houeseName,
                description: description,
                createBy: authStore.user?.id,
                createAt: new Date()
            }).then((res) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
                navigate(`/setting-house-edit/${res.id}`);
            });
        }
    };

    const handleEdit = () => {
        if (id) {
            setIsLoading(true);
            housesStore.update(id, {
                project: project ? project : null,
                name: houeseName,
                description: description,
                updateBy: authStore.user?.id,
                updateAt: new Date()
            }).then((res) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('แก้ไขข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                enqueueSnackbar('แก้ไขข้อมูลสำเร็จ', { variant: 'success' });
            });
        }
    };

    const handleDelete = () => {
        if (id) {
            setIsLoading(true);
            housesStore.update(id, {
                isActive: false,
                updateBy: authStore.user?.id,
                updateAt: new Date()
            }).then((res) => {
                setIsLoading(false);
                if (res.error) {
                    enqueueSnackbar('ลบข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
                navigate('/setting-houses');
            });
        }
    };


    return (
        <Box component={'div'}>
            <Grid container>
                <Grid item xs={0.5}>
                    <IconButton aria-label="delete" onClick={() => navigate('/setting-houses')}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Typography>การจัดการ</Typography>
                        <Link component="button" onClick={() => navigate('/setting-houses')}>บ้าน</Link>
                        <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={3} display={'flex'} justifyContent={'end'}>
                    {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
                        <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
                            open: true,
                            title: 'ลบข้อมูล',
                            message: 'คุณต้องการลบบ้านนี้ใช่หรือไม่',
                            color: 'error',
                            type: 'delete'
                        })}>ลบข้อมูล</Button>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={3} >
                                    <FormControl fullWidth>
                                        <InputLabel id="project-select-label">โครงการ</InputLabel>
                                        <Select
                                            labelId="project-select-label"
                                            id="project-select"
                                            value={project}
                                            label="โครงการ"
                                            onChange={handleChangeProject}
                                        >
                                            {projectData.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField id="outlined-basic" label="ชื่อบ้าน" variant="outlined" fullWidth
                                        onChange={(event) => setHouseName(event.target.value)} value={houeseName}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 0)}
                                    />
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="outlined-basic" label="รายละเอียด" variant="outlined" fullWidth multiline
                                        value={description}
                                        onChange={(event) => setDescription(event.target.value)}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 1)}
                                    />
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>
                                <Grid item xs={12} >
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={4} >
                                            <Stack spacing={2} direction="row">
                                                <Button variant="outlined" fullWidth onClick={() => navigate('/setting-houses')}>ยกเลิก</Button>
                                                {!id && <Button variant="contained" fullWidth onClick={() => {
                                                    setOpenConfirm({
                                                        open: true,
                                                        title: 'บันทึกเอกสาร',
                                                        message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                                                        color: 'primary',
                                                        type: 'create'
                                                    });
                                                }}>บันทึก</Button>}
                                                {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                                                    setOpenConfirm({
                                                        open: true,
                                                        title: 'แก้ไขเอกสาร',
                                                        message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                                                        color: 'primary',
                                                        type: 'edit'
                                                    });
                                                }}>แก้ไข</Button>}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === 'delete') {
                        handleDelete();
                    } else if (openConfirm.type === 'create') {
                        handleSave();
                    } else if (openConfirm.type === 'edit') {
                        handleEdit();
                    }
                }} />
        </Box>
    );
};

export default SettingHoueseCreate;
