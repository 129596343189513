/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Breadcrumbs, Button, Card, CardContent, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { useNavigate } from "react-router-dom";
import authStore from "../../stores/AuthStore";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DownloadTableExcel } from "react-export-table-to-excel";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ReactToPrint from "react-to-print";
import PrintProjectHouses from "../../components/Report/Pdf/PrintProjectHouses";
import ExportFileProjectHouses from "../../components/Report/Excel/ExportFileProjectHouses";

const Houses: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/houses');
  const [project, setProject] = useState('all');
  const [projectData, setProjectData] = useState<any[]>([]);
  const [houseName, setHouseName] = useState('');
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const printRef = useRef(null);
  const exportRef = useRef(null);
  const [sum, setSum] = useState(0);
  const handleChangeProject = (event: SelectChangeEvent) => {
    setProject(event.target.value as string);
  };

  const handleSearch = (pageNumber?: string, limitNumber?: string) => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    projectsStore.getAll().then((result) => {
      setProjectData(result);
      housesStore.getByCriteria({
        projectId: project, name: houseName, page: _page,
        limit: _limit
      }).then((result) => {
        setRows(result.data.sort((a: any, b: any) => a.project?.name?.localeCompare(b.project?.name || '')));
        setTotal(result.total);
        setSum(result.sum);
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>โครงการ</Typography>
        <Typography variant="h6" color="text.primary">บ้าน</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                <Grid item xs={4} >
                  <FormControl fullWidth>
                    <InputLabel id="productGroup-select-label">โครงการ</InputLabel>
                    <Select
                      labelId="productGroup-select-label"
                      id="productGroup-select"
                      value={project}
                      label="โครงการ"
                      onChange={handleChangeProject}
                    >
                      <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                      {projectData && projectData.length > 0 && projectData?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} >
                  <TextField id="outlined-basic" label="ชื่อบ้าน" variant="outlined" fullWidth
                    onChange={(event) => setHouseName(event.target.value)} value={houseName}
                  />
                </Grid>
                <Grid item xs={2} textAlign={'end'} pb={0.5} >
                  <Button variant="contained" onClick={() => handleSearch()} startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent={'end'}>
                    <Grid item xs={10}>
                      <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`project-houses-${dayjs().format('DD-MM-YYYY')}`}
                        sheet={`project-houses-${dayjs().format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <IconButton aria-label="print">
                              <PrintIcon />
                            </IconButton>
                          );
                        }}
                        content={() => {
                          return printRef.current;
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell width={200}>โครงการ</TableCell>
                          <TableCell>ชื่อบ้าน</TableCell>
                          <TableCell>ราคาขาย</TableCell>
                          <TableCell>รายละเอียด</TableCell>
                          <TableCell>สถานะ</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row: any) => {
                          let status: any = { name: 'ว่าง', color: 'default' };
                          if (row?.bookings?.length > 0) {
                            row?.bookings.forEach((item: any) => {
                              if (item?.sell) {
                                status.name = 'ขายแล้ว';
                                status.color = 'success';
                              }
                              else if (item?.status?.code === 'cash' || item?.status?.code === 'sold') {
                                status.name = item?.status?.name;
                                status.color = 'success';
                              } else if (item?.status?.code === 'send_data_bank' || item?.status?.code === 'bank_in_progress' || item?.status?.code === 'in_progress' || item?.status?.code === 'loaned') {
                                status.name = 'ติดจอง';
                                status.color = 'warning';
                              } else {
                                status.name = 'ว่าง';
                                status.color = 'default';
                              }
                            });
                          }
                          return (
                            <TableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.project?.name || ''}
                              </TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{parseFloat(row.sellPrice || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>{
                                <Chip label={status.name || ''} color={status.color || 'default'} size="small" />
                              }</TableCell>
                              <TableCell align="right">
                                <IconButton aria-label="edit" onClick={() => navigate(`/house-edit/${row.id}`)}>
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch('0', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box style={{ display: "none" }}>
        {<PrintProjectHouses ref={printRef} title="รายการบ้าน" data={rows ?? []}
          summary={sum} />}
      </Box>
      <Box style={{ display: "none" }}>
        {<ExportFileProjectHouses ref={exportRef} title="รายการบ้าน" data={rows ?? []}
          summary={sum} />}
      </Box>
    </Box>
  );
};

export default Houses;
