import { Box } from "@mui/material";
import React from 'react';
import WorkInProgress from "../../components/WorkInProgress";

const UserGroups: React.FC = () => {
    return (
        <Box>
            <WorkInProgress titile="UserGroups" />
        </Box>
    );
};

export default UserGroups;
