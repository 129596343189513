import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class SellStore {
  sells: any = {};
  gifts: any = {};
  cheques: any = {};
  constructor() {
    makeAutoObservable(this);
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/sells/${id}`);
      this.sells = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting sell", error);
      return {
        error: true,
        message: "An error occurred while getting sell",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/sells/criteria", criteria);
      this.sells = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting sells", error);
      return {
        error: true,
        message: "An error occurred while getting sells",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/sells", data);
      this.sells = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating sell", error);
      return {
        error: true,
        message: "An error occurred while creating sell",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/sells/${id}`, data);
      this.sells = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating sell", error);
      return {
        error: true,
        message: "An error occurred while updating sell",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/sells/${id}`);
      this.sells = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting sell", error);
      return {
        error: true,
        message: "An error occurred while deleting sell",
      };
    }
  }

  async createGift(data: any) {
    try {
      const response = await apiService.post("/sells/gift", data);
      this.gifts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating gift", error);
      return {
        error: true,
        message: "An error occurred while creating gift",
      };
    }
  }

  async updateGift(id: string, data: any) {
    try {
      const response = await apiService.put(`/sells/gift/${id}`, data);
      this.gifts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating gift", error);
      return {
        error: true,
        message: "An error occurred while updating gift",
      };
    }
  }

  async deleteGift(id: string) {
    try {
      const response = await apiService.delete(`/sells/gift/${id}`);
      this.gifts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting gift", error);
      return {
        error: true,
        message: "An error occurred while deleting gift",
      };
    }
  }

  async dashboard(data: any) {
    try {
      const response = await apiService.post(`/sells/dashboard`, data);
      this.sells = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting sells", error);
      return {
        error: true,
        message: "An error occurred while getting sells",
      };
    }
  }

  async createCheque(data: any) {
    try {
      const response = await apiService.post("/sells/cheque", data);
      this.cheques = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating cheque", error);
      return {
        error: true,
        message: "An error occurred while creating cheque",
      };
    }
  }

  async updateCheque(id: string, data: any) {
    try {
      const response = await apiService.put(`/sells/cheque/${id}`, data);
      this.cheques = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating cheque", error);
      return {
        error: true,
        message: "An error occurred while updating cheque",
      };
    }
  }

  async deleteCheque(id: string) {
    try {
      const response = await apiService.delete(`/sells/cheque/${id}`);
      this.cheques = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting cheque", error);
      return {
        error: true,
        message: "An error occurred while deleting cheque",
      };
    }
  }
}
const sellStore = new SellStore();
export default sellStore;
