import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/th";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useNavigate, useParams } from "react-router-dom";
import authStore from "../../stores/AuthStore";
import { enqueueSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DialogConfirm from "../../components/DialogConfirm";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import imagesStore from "../../stores/ImagesStore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import housesStore from "../../stores/HousesStore";
import projectsStore from "../../stores/ProjectsStore";
import landStore from "../../stores/LandStore";
import masterDataStore from "../../stores/MasterDataStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LandOfHouseDialog from "../../components/LandOfHouseDialog";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const LandCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const permission: any = authStore.user?.roles.find(
    (role: any) => role.menu.path === "/lands"
  );
  const [deedDate, setDeedDate] = useState<Dayjs | null>(dayjs());
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [housesList, setHousesList] = useState<any[]>([]);
  const [project, setProject] = useState<any>([]);
  const [house, setHouse] = useState<any>([]);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [deedNo, setDeedNo] = useState("");
  const [volumn, setVolumn] = useState("");
  const [landDistrict, setLandDistrict] = useState("");
  const [landProvince, setLandProvince] = useState("");
  const [page, setPage] = useState("");
  const [mapsheet, setMapsheet] = useState("");
  const [parcelNo, setParcelNo] = useState("");
  const [dealingFileNo, setDealingFileNo] = useState("");
  const [landSubDistrict, setLandSubDistrict] = useState("");
  const [rai, setRai] = useState<number | "">("");
  const [ngan, setNgan] = useState<number | "">("");
  const [squareWah, setSquareWah] = useState<number | "">("");
  const [landPrice, setLandPrice] = useState<number | "">("");
  const [registrationFee, setRegistrationFee] = useState<number | "">("");
  const [transferer, setTransferer] = useState("");
  const [transferee, setTransferee] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [village, setVillage] = useState("");
  const [nationality, setNationality] = useState("");
  const [provinceValue, setProvinceValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [subDistrictValue, setSubDistrictValue] = useState("");
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete" | "confirm-duplicate",
  });

  const [provinceData, setProvinceData] = useState<any>([]);
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);
  const [districtData, setDistrictData] = useState<any>([]);
  const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
  const [zipCode, setZipCode] = useState("");
  const [landSubDistrictOptions, setLandSubDistrictOptions] = useState<any>([]);
  const [landProvinceOptions, setLandProvinceOptions] = useState<any[]>([]);
  const [landDistrictOptions, setLandDistrictOptions] = useState<any>([]);
  const [landDistrictValue, setLandDistrictValue] = useState("");
  const [landProvinceValue, setLandProvinceValue] = useState("");
  const [landSubDistrictValue, setLandSubDistrictValue] = useState("");
  const [mortgageFee, setMortgageFee] = useState<number>(0);
  const [landOfHouse, setLandOfHouse] = useState<any>(null);
  const [openLandOfHouseDialog, setOpenLandOfHouseDialog] = useState(false);
  const [landOfHouseSelected, setLandOfHouseSelected] = useState<any>([]);
  const [landOfHouseSelectedOld, setLandOfHouseSelectedOld] = useState<any>([]);
  const deleteImagesChild: any = [];
  const [deedType, setDeedType] = useState("project");
  const [taxDate, setTaxDate] = useState<Dayjs | null>(dayjs());
  const [requestFee, setRequestFee] = useState<number | "">("");
  const [lgoFee, setLgoFee] = useState<number | "">("");
  const [totalFee, setTotalFee] = useState<number | "">("");
  const [personalTaxIncome, setPersonalTaxIncome] = useState<number | "">("");
  const [corporateIncomeTax, setCorporateIncomeTax] = useState<number | "">("");
  const [stampDuty, setStampDuty] = useState<number | "">("");
  const [specificBusinessTax, setSpecificBusinessTax] = useState<number | "">(
    ""
  );
  const [localIncome, setLocalIncome] = useState<number | "">("");
  const [totalTax, setTotalTax] = useState<number | "">("");
  const [grandTotalTax, setGrandTotalTax] = useState<number | "">("");
  const [remarkText, setRemarkText] = useState("");
  const [landTaxId, setLandTaxId] = useState("");
  const [landOption, setLandOption] = useState<any[]>([]);
  const [parent, setParent] = useState("");
  const handleSave = () => {
    try {
      setIsLoading(true);
      const data = {
        project: project && project?.id ? project : undefined,
        house: house && house?.id ? house : undefined,
        deedDate: deedDate?.format("YYYY-MM-DD"),
        deedNo: deedNo,
        volumn: volumn,
        landDistrict: landDistrict || undefined,
        landProvince: landProvince || undefined,
        page: page,
        parent: parent || undefined,
        mapsheet: mapsheet,
        parcelNo: parcelNo,
        dealingFileNo: dealingFileNo,
        landSubDistrict: landSubDistrict || undefined,
        rai: rai || undefined,
        ngan: ngan || undefined,
        squareWah: squareWah || undefined,
        landPrice: landPrice || undefined,
        registrationFee: registrationFee || undefined,
        transferer: transferer,
        transferee: transferee,
        subDistrict: subDistrict || undefined,
        province: province || undefined,
        district: district || undefined,
        zipCode: zipCode,
        address: address,
        village: village,
        nationality: nationality,
        mortgageFee: mortgageFee || undefined,
        createBy: authStore.user?.id,
        createAt: new Date(),
      };
      landStore
        .create(data)
        .then((res) => {
          // create landTax parent
          const _landTax = {
            land: res?.id || undefined,
            taxDate: taxDate?.format("YYYY-MM-DD"),
            requestFee: requestFee || undefined,
            lgoFee: lgoFee || undefined,
            totalFee: totalFee || undefined,
            personalTaxIncome: personalTaxIncome || undefined,
            corporateIncomeTax: corporateIncomeTax || undefined,
            stampDuty: stampDuty || undefined,
            specificBusinessTax: specificBusinessTax || undefined,
            localIncome: localIncome || undefined,
            totalTax: totalTax || undefined,
            grandTotalTax: grandTotalTax || undefined,
            remarkText: remarkText || undefined,
            createBy: authStore.user?.id,
            createAt: new Date(),
          };
          handleCreateLandTax(_landTax);
          handleFileUpload(res.id, "landParent");
          landOfHouseSelected.forEach((item: any) => {
            const _images = item.images;
            item.parent = res.id;
            item.createBy = authStore.user?.id;
            item.createAt = new Date();
            const _landTaxChild = item.landTax;

            if (item?.oldLand) {
              handleUpdateParent(item?.oldLand?.id, res?.id);
            } else {
              landStore.create(item).then((res) => {
                if (res.error) {
                  console.error("err", res.error);
                }
                // create landTax child
                handleFileUpload(res.id, "landChild", _images);
                _landTaxChild.land = res.id;
                _landTaxChild.createBy = authStore.user?.id;
                _landTaxChild.createAt = new Date();
                handleCreateLandTax(_landTaxChild);
              });
            }
          });
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          setIsLoading(false);
          navigate(`/land-edit/${res.id}`);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error(error);
    }
  };

  const handleCreateLandTax = (data: any) => {
    try {
      landStore.createLandTax(data).then((res) => {
        if (res.error) {
          console.error("err", res.error);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditLandTax = (data: any, landTaxId: string) => {
    try {
      landStore.updateLandTax(landTaxId, data).then((res) => {
        if (res.error) {
          console.error("err", res.error);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateParent = (landId: string, parentId: string) => {
    try {
      landStore
        .update(landId, {
          updateAt: new Date(),
          updateBy: authStore.user?.id,
          parent: parentId,
        })
        .then((res) => {
          if (res.error) {
            console.error("err", res.error);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  useMemo(() => {
    const result =
      parseFloat(requestFee.toString() || "0") +
      parseFloat(lgoFee.toString() || "0") +
      parseFloat(personalTaxIncome.toString() || "0") +
      parseFloat(corporateIncomeTax.toString() || "0") +
      parseFloat(stampDuty.toString() || "0") +
      parseFloat(specificBusinessTax.toString() || "0") +
      parseFloat(localIncome.toString() || "0");
    setGrandTotalTax(result);
    return result;
  }, [
    requestFee,
    lgoFee,
    personalTaxIncome,
    corporateIncomeTax,
    stampDuty,
    specificBusinessTax,
    localIncome,
  ]);

  useMemo(() => {
    const result =
      parseFloat(requestFee.toString() || "0") +
      parseFloat(lgoFee.toString() || "0");
    setTotalFee(result);
  }, [requestFee, lgoFee]);

  useMemo(() => {
    const result =
      parseFloat(personalTaxIncome.toString() || "0") +
      parseFloat(corporateIncomeTax.toString() || "0") +
      parseFloat(stampDuty.toString() || "0") +
      parseFloat(specificBusinessTax.toString() || "0") +
      parseFloat(localIncome.toString() || "0");
    setTotalTax(result);
  }, [
    personalTaxIncome,
    corporateIncomeTax,
    stampDuty,
    specificBusinessTax,
    localIncome,
  ]);

  const handleChangData = (e: ChangeEvent<HTMLInputElement>, type: string) => {
    const value: any = e.target?.value;
    const date: any = e;

    switch (type) {
      case "taxDate":
        setTaxDate(date);
        break;
      case "requestFee":
        setRequestFee(value);
        break;
      case "personalTaxIncome":
        setPersonalTaxIncome(value);
        break;
      case "lgoFee":
        setLgoFee(value);
        break;
      case "totalFee":
        setPersonalTaxIncome(value);
        break;
      case "corporateIncomeTax":
        setCorporateIncomeTax(value);
        break;
      case "stampDuty":
        setStampDuty(value);
        break;
      case "specificBusinessTax":
        setSpecificBusinessTax(value);
        break;
      case "localIncome":
        setLocalIncome(value);
        break;
      case "grandTotalTax":
        setGrandTotalTax(value);
        break;
      case "totalTax":
        setTotalTax(value);
        break;
      case "remarkText":
        setRemarkText(value);
        break;
    }
  };

  const handleEdit = () => {
    try {
      setIsLoading(true);
      if (id) {
        const data = {
          parent: parent || undefined,
          project: project && project?.id ? project : undefined,
          house: house && house?.id ? house : undefined,
          deedNo: deedNo,
          deedDate: deedDate?.format("YYYY-MM-DD"),
          volumn: volumn,
          landDistrict: landDistrict || undefined,
          landProvince: landProvince || undefined,
          page: page,
          mapsheet: mapsheet,
          parcelNo: parcelNo,
          dealingFileNo: dealingFileNo,
          landSubDistrict: landSubDistrict || undefined,
          rai: rai || undefined,
          ngan: ngan || undefined,
          squareWah: squareWah || undefined,
          landPrice: landPrice || undefined,
          registrationFee: registrationFee || undefined,
          transferer: transferer,
          transferee: transferee,
          subDistrict: subDistrict || undefined,
          province: province || undefined,
          district: district || undefined,
          zipCode: zipCode,
          address: address,
          village: village,
          nationality: nationality,
          mortgageFee: mortgageFee || undefined,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        };

        if (landTaxId) {
          // edit landTax parent
          const _landTaxEdit = {
            land: id || undefined,
            taxDate: taxDate?.format("YYYY-MM-DD"),
            requestFee: requestFee || undefined,
            lgoFee: lgoFee || undefined,
            totalFee: totalFee || undefined,
            personalTaxIncome: personalTaxIncome || undefined,
            corporateIncomeTax: corporateIncomeTax || undefined,
            stampDuty: stampDuty || undefined,
            specificBusinessTax: specificBusinessTax || undefined,
            localIncome: localIncome || undefined,
            totalTax: totalTax || undefined,
            grandTotalTax: grandTotalTax || undefined,
            remarkText: remarkText || undefined,
            updateBy: authStore.user?.id,
            updateAt: new Date(),
          };

          handleEditLandTax(_landTaxEdit, landTaxId);
        } else {
          // create landTax parent
          const _landTaxCreate = {
            land: id || undefined,
            taxDate: taxDate?.format("YYYY-MM-DD"),
            requestFee: requestFee || undefined,
            lgoFee: lgoFee || undefined,
            totalFee: totalFee || undefined,
            personalTaxIncome: personalTaxIncome || undefined,
            corporateIncomeTax: corporateIncomeTax || undefined,
            stampDuty: stampDuty || undefined,
            specificBusinessTax: specificBusinessTax || undefined,
            localIncome: localIncome || undefined,
            totalTax: totalTax || undefined,
            grandTotalTax: grandTotalTax || undefined,
            remarkText: remarkText || undefined,
            createBy: authStore.user?.id,
            createAt: new Date(),
          };
          handleCreateLandTax(_landTaxCreate);
        }
        landStore.update(id, data);
        if (landOfHouseSelected && landOfHouseSelected?.length > 0) {
          landOfHouseSelected.forEach((item: any) => {
            const _images = item.images;
            const _deleteImages = item.deleteImages;
            const _item = item;
            const _landTaxChild = {
              ...item.landTax,
              updateBy: authStore.user?.id,
              updateAt: new Date(),
            };
            const _oldLand = item.oldLand;
            delete _item?.images;
            delete _item?.deleteImages;
            delete _item?.landTax;
            delete _item.oldLand;

            if (_deleteImages && _deleteImages.length > 0) {
              handleDeleteFile(_deleteImages);
            }
            const findIndex = landOfHouseSelectedOld.findIndex(
              (old: any) => old.id === item.id
            );
            if (findIndex !== -1) {
              if (_oldLand) {
                handleUpdateParent(_oldLand?.id, id);
              } else {
                try {
                  _item.updateBy = authStore.user?.id;
                  _item.updateAt = new Date();
                  landStore.update(item.id, _item).then((res) => {
                    if (res.error) {
                      console.error("error");
                    } else {
                      if (_images && _images.length > 0) {
                        handleEditFileUpload(item.id, "landChild", _images);
                      }
                    }
                  });
                  // edit landTax child
                  if (_landTaxChild) {
                    handleEditLandTax(_landTaxChild, _landTaxChild.id);
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            } else {
              _item.parent = id;
              delete _item.images;

              if (_oldLand) {
                handleUpdateParent(_oldLand?.id, id);
              } else {
                try {
                  _item.createAt = new Date();
                  _item.createBy = authStore.user?.id;
                  landStore.create(_item).then((res) => {
                    if (res.id) {
                      if (_images && _images.length > 0) {
                        handleFileUpload(res.id, "landChild", _images);
                      }
                      if (_landTaxChild) {
                        handleCreateLandTax(_landTaxChild);
                      }
                    } else {
                      console.error("error");
                    }
                  });
                } catch (error) {
                  console.error(error);
                }
              }
            }
          });
        }
        const deleteLandOfHouse = landOfHouseSelectedOld.filter(
          (item: any) =>
            !landOfHouseSelected.some((_item: any) => _item.id === item.id)
        );
        if (deleteLandOfHouse && deleteLandOfHouse.length > 0) {
          deleteLandOfHouse.forEach((item: any) => {
            if (item.id) {
              landStore.update(item.id, {
                isDeleted: true,
                updateBy: authStore.user?.id,
                updateAt: new Date(),
              });
            }
          });
        }
        handleEditFileUpload(id, "landParent");
        setIsLoading(false);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error(error);
    }
  };
  const handleDelete = () => {
    try {
      setIsLoading(true);
      if (id) {
        landStore.update(id, {
          isDeleted: true,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        });
        if (landOfHouseSelected && landOfHouseSelected.length > 0) {
          landOfHouseSelected.forEach((item: any) => {
            if (item.id) {
              landStore.update(item.id, {
                isDeleted: true,
                updateBy: authStore.user?.id,
                updateAt: new Date(),
              });
            }
          });
        }
        enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
        setIsLoading(false);
        navigate("/lands");
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };
  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const handleKeyDown = (event: any, index: number, i = 0) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (i === -1) {
        inputRefs.current[37].focus();
      } else if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    }
  };

  const handleEditFileUpload = async (
    id: string,
    type: string,
    files?: any
  ) => {
    if (type === "landParent" && selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "land");
        formdata.append("refId", id);
        formdata.append("file", file);
        formdata.append("updateBy", authStore.user?.id);
        formdata.append(
          "updateAt",
          new Date(Date.now() + index * 1000).toISOString()
        );
        await imagesStore
          .upload(formdata)
          .catch((err) => console.error("err", err));
      });
    }
    if (type === "landChild" && files.length > 0) {
      files.forEach(async (file: any, index: number) => {
        if (!file.id) {
          const formdata = new FormData();
          formdata.append("refType", "land");
          formdata.append("refId", id);
          formdata.append("file", file);
          formdata.append("updateBy", authStore.user?.id);
          formdata.append(
            "updateAt",
            new Date(Date.now() + index * 1000).toISOString()
          );
          await imagesStore
            .upload(formdata)
            .catch((err) => console.error("err", err));
        }
      });
    }
    if (deleteImages.length > 0) {
      await deleteImages.forEach(async (item: any) => {
        await imagesStore
          .update(item.id, {
            updateBy: authStore.user?.id,
            updateAt: new Date(),
            isDeleted: true,
          })
          .catch((err) => console.error("err deleteImages", err));
      });
    }
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleFileUpload = (refId: string, type: string, files?: any) => {
    // upload image for parent land
    if (type === "landParent" && selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "land");
        formdata.append("refId", refId);
        formdata.append("file", file);
        formdata.append("createBy", authStore.user?.id);
        formdata.append(
          "createAt",
          new Date(Date.now() + index * 1000).toISOString()
        );

        await imagesStore
          .upload(formdata)
          .catch((err) => console.error("err", err));
      });
    }
    // upload image for child land
    if (type === "landChild" && files?.length > 0) {
      files.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "land");
        formdata.append("refId", refId);
        formdata.append("file", file);
        formdata.append("createBy", authStore.user?.id);
        formdata.append(
          "createAt",
          new Date(Date.now() + index * 1000).toISOString()
        );
        await imagesStore
          .upload(formdata)
          .catch((err) => console.error("err", err));
      });
    }
  };

  const handleAddLandOfHouse = () => {
    setOpenLandOfHouseDialog(true);
  };

  const handleRemoveItem = (id: string) => {
    setLandOfHouseSelected(
      landOfHouseSelected.filter((item: any) => item.id !== id)
    );
  };

  const handleDeleteFile = async (files?: any) => {
    try {
      if (files.length > 0) {
        files.forEach(async (file: any) => {
          try {
            imagesStore.delete(file.id);
          } catch (error) {
            console.error(error);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeDeedType = (event: ChangeEvent<HTMLInputElement>) => {
    setDeedType(event.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      try {
        setIsLoading(true);
        landStore.get(id).then((res: any) => {
          setProject(res.project);
          setHouse(res.house);
          setDeedNo(res.deedNo);
          setDeedDate(dayjs(!res.deedDate ? new Date() : dayjs(res.deedDate)));
          setVolumn(res.volumn);
          setLandDistrict(res.landDistrict);
          setLandDistrictValue(res.landDistrict);
          setLandProvince(res.landProvince);
          setLandProvinceValue(res.landProvince);
          setPage(res.page);
          setMapsheet(res.mapsheet);
          setParcelNo(res.parcelNo);
          setDealingFileNo(res.dealingFileNo);
          setLandSubDistrict(res.landSubDistrict);
          setLandSubDistrictValue(res.landSubDistrict);
          setRai(res.rai);
          setNgan(res.ngan);
          setSquareWah(res.squareWah);
          setLandPrice(res.landPrice);
          setRegistrationFee(res.registrationFee);
          setTransferer(res.transferer);
          setTransferee(res.transferee);
          setSubDistrict(res.subDistrict);
          setSubDistrictValue(res.subDistrict);
          setProvince(res.province);
          setProvinceValue(res.province);
          setDistrict(res.district);
          setDistrictValue(res.district);
          setAddress(res.address);
          setVillage(res.village);
          setNationality(res.nationality);
          setImages(res.images);
          setZipCode(res.zipCode);
          setMortgageFee(res.mortgageFee);
          const childs = [...res.childLand];
          setLandOfHouseSelectedOld(res.childLand);
          setLandOfHouseSelected(childs);
          if (res.house !== null && res?.childLand?.length === 0) {
            setDeedType("house");
            setParent(res.parent);
          }
          setTaxDate(
            dayjs(
              !res.landTax?.taxDate ? new Date() : dayjs(res.landTax?.taxDate)
            )
          );
          setLandTaxId(res.landTax?.id || undefined);
          setRequestFee(res.landTax?.requestFee || "");
          setLgoFee(res.landTax?.personalTaxIncome || "");
          setTotalFee(res.landTax?.totalFee || "");
          setPersonalTaxIncome(res.landTax?.personalTaxIncome || "");
          setCorporateIncomeTax(res.landTax?.corporateIncomeTax || "");
          setTotalTax(res.landTax?.totalTax || "");
          setStampDuty(res.landTax?.stampDuty || "");
          setSpecificBusinessTax(res.landTax?.specificBusinessTax || "");
          setLocalIncome(res.landTax?.localIncome || "");
          setGrandTotalTax(res.landTax?.grandTotalTax || "");
          setRemarkText(res.landTax?.remarkText || "");
          if (res.project && res.project?.id) {
            landStore.getByProjectId(res.project?.id).then((res: any) => {
              setLandOption(res);
            });
          }
        });
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    }

    Promise.all([
      masterDataStore.getAllProvince(),
      masterDataStore.getAllAmphur(),
      masterDataStore.getAllTambon(),
      projectsStore.getAll(),
      housesStore.getAll(),
      landStore.getAll(),
    ]).then((res) => {
      setSubDistrictData(res[2]);
      setDistrictData(res[1]);
      setProvinceData(res[0]);
      setProjectsList(res[3]);
      setHousesList(res[4]);
      setLandOption(res[5]);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton aria-label="delete" onClick={() => navigate("/lands")}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>โครงการ</Typography>
            <Link component="button" onClick={() => navigate("/lands")}>
              ที่ดิน
            </Link>
            <Typography variant="h6" color="text.primary">
              {id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {((permission && permission.delete) ||
            authStore.user?.isSuperAdmin) &&
            id && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() =>
                  setOpenConfirm({
                    open: true,
                    title: "ลบเอกสาร",
                    message: "คุณต้องการลบเอกสารนี้ใช่หรือไม่",
                    color: "error",
                    type: "delete",
                  })
                }
              >
                ลบเอกสาร
              </Button>
            )}
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography mt={1} mb={1}>
                      โฉนดที่ดิน
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          label="เลขที่"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 0);
                          }}
                          id={`deedNo-${id}`}
                          type="text"
                          variant="outlined"
                          value={deedNo}
                          onChange={(e) => setDeedNo(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="เล่มที่"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 1);
                          }}
                          id={`volumn-${id}`}
                          type="text"
                          variant="outlined"
                          value={volumn}
                          onChange={(e) => setVolumn(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="หน้า"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 2);
                          }}
                          id={`page-${id}`}
                          type="text"
                          variant="outlined"
                          value={page}
                          onChange={(e) => setPage(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={landDistrictValue}
                          onChange={(e: any, value: any) => {
                            if (value?.id && value?.id !== -1) {
                              setLandDistrict(value?.id);
                              setLandDistrictValue(value?.nameTh);
                            }
                          }}
                          disablePortal
                          options={landDistrictOptions}
                          sx={{ width: "100%" }}
                          renderOption={(props, option: any) => (
                            <li {...props}>{option?.nameTh}</li>
                          )}
                          getOptionLabel={(option: any) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="อำเภอ"
                              inputRef={addRef}
                              helperText="กรุณากรอกอย่างน้อย 3 ตัวอักษร"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue && inputValue.length >= 3) {
                                  // Filter options based on user input
                                  const filteredOptions = districtData.filter(
                                    (district: any) =>
                                      district.nameTh.includes(inputValue)
                                  );
                                  const filteredProvinceOptions =
                                    filteredOptions.map(
                                      (district: any) => district.province
                                    );
                                  setLandDistrictOptions(filteredOptions);
                                  setLandProvinceOptions(
                                    filteredProvinceOptions
                                  );
                                } else {
                                  setLandDistrictOptions([
                                    {
                                      id: -1,
                                      nameTh:
                                        "กรุณากรอกอำเภออย่างน้อย 3 ตัวอักษร",
                                    },
                                  ]);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                                handleKeyDown(e, 3);
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={landProvinceValue}
                          onChange={(e: any, value) => {
                            if (value?.id) {
                              setLandProvince(value?.id);
                              setLandProvinceValue(value?.nameTh);
                            }
                          }}
                          options={landProvinceOptions}
                          sx={{ width: "100%" }}
                          renderOption={(props, option) => (
                            <li {...props}>{option.nameTh}</li>
                          )}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="จังหวัด"
                              inputRef={addRef}
                              onKeyDown={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                                handleKeyDown(e, 4);
                              }}
                            />
                          )}
                          disabled={
                            landProvinceOptions.length > 0 ? false : true
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography mb={1}>ตำแหน่งที่ดิน</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          label="ระวาง"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 5);
                          }}
                          id={`mapsheet-${id}`}
                          type="text"
                          variant="outlined"
                          value={mapsheet}
                          onChange={(e) => setMapsheet(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="เลขที่ดิน"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 6);
                          }}
                          id={`parcelNo-${id}`}
                          type="text"
                          variant="outlined"
                          value={parcelNo}
                          onChange={(e) => setParcelNo(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="หน้าสำรวจ"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 7);
                          }}
                          id={`dealingFileNo-${id}`}
                          type="text"
                          variant="outlined"
                          value={dealingFileNo}
                          onChange={(e) => setDealingFileNo(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={landSubDistrictValue}
                          onChange={(e: any, value: any) => {
                            if (value?.id) {
                              setLandSubDistrict(value?.id);
                              setLandSubDistrictValue(value?.nameTh);
                            }
                          }}
                          disablePortal
                          options={landSubDistrictOptions}
                          sx={{ width: "100%" }}
                          renderOption={(props, option: any) => (
                            <li {...props}>{option.nameTh}</li>
                          )}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="ตำบล"
                              inputRef={addRef}
                              helperText="กรุณากรอกอย่างน้อย 3 ตัวอักษร"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue && inputValue.length >= 3) {
                                  const filteredOptions =
                                    landSubDistrictOptions.length > 0
                                      ? landSubDistrictOptions.filter(
                                          (subDistrict: any) =>
                                            subDistrict.nameTh.includes(
                                              inputValue
                                            )
                                        )
                                      : subDistrictData.filter(
                                          (subDistrict: any) =>
                                            subDistrict.nameTh.includes(
                                              inputValue
                                            )
                                        );
                                  setLandSubDistrictOptions(filteredOptions);
                                } else {
                                  setLandSubDistrictOptions([
                                    {
                                      id: -1,
                                      nameTh:
                                        "กรุณากรอกตำบลอย่างน้อย 3 ตัวอักษร",
                                    },
                                  ]);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                                handleKeyDown(e, 8);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography mt={1} mb={1}>
                      เนื้อที่ดิน
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          label="ไร่"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 9);
                          }}
                          id={`rai-${id}`}
                          type="number"
                          variant="outlined"
                          value={rai ? parseFloat(rai.toString()) : ""}
                          onChange={(e) => setRai(parseFloat(e.target.value))}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="งาน"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 10);
                          }}
                          id={`ngan-${id}`}
                          type="number"
                          variant="outlined"
                          value={ngan ? parseFloat(ngan.toString()) : ""}
                          onChange={(e) => setNgan(parseFloat(e.target.value))}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="ตร.วา"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 11);
                          }}
                          id={`squareWah-${id}`}
                          variant="outlined"
                          value={
                            squareWah ? parseFloat(squareWah.toString()) : ""
                          }
                          onChange={(e) =>
                            setSquareWah(parseFloat(e.target.value))
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography mt={1} mb={1}>
                      ข้อมูลการจดทะเบียนสิทธิ
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={deedType}
                            onChange={handleChangeDeedType}
                          >
                            <FormControlLabel
                              value="project"
                              control={<Radio />}
                              label="โฉนดที่ดินโครงการ"
                            />
                            <FormControlLabel
                              value="house"
                              control={<Radio />}
                              label="โฉนดที่ดินบ้าน"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={"th"}
                        >
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              label="วันที่ออกโฉนด"
                              value={deedDate}
                              onChange={(newValue) => setDeedDate(newValue)}
                              sx={{ width: "100%" }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      {deedType === "project" && (
                        <Grid item xs={9}>
                          <DemoContainer
                            components={["Autocomplete", "Autocomplete"]}
                          >
                            <Autocomplete
                              disablePortal
                              value={project}
                              options={projectsList}
                              sx={{ width: "100%" }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.name}</li>
                              )}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option || "";
                                }
                                if (option.inputValue) {
                                  return option.inputValue || "";
                                }
                                return option.name || "";
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="โครงการ" />
                              )}
                              onChange={(e, value) => {
                                if (value?.id) {
                                  housesStore
                                    .getByProjectId(value?.id)
                                    .then((res) => {
                                      setHousesList(res);
                                    });
                                } else {
                                  housesStore.getAll().then((res) => {
                                    setHousesList(res);
                                  });
                                }
                                setProject(value);
                              }}
                            />
                          </DemoContainer>
                        </Grid>
                      )}
                      {deedType === "house" && (
                        <>
                          <Grid item xs={4.5}>
                            <DemoContainer
                              components={["Autocomplete", "Autocomplete"]}
                            >
                              <FormControl fullWidth>
                                <Autocomplete
                                  id="landOption"
                                  options={landOption} //ข้อมูลที่ดินทั้งหมด
                                  getOptionLabel={(option) =>
                                    option?.deedNo || ""
                                  } //เลือกวิธีดึงข้อมูลโดยดึงข้อมูลของ
                                  value={parent} //หาค่าที่ให้ตรงกับ id
                                  onChange={(e, newValue: any) => {
                                    if (newValue) {
                                      setParent(newValue);
                                      if (newValue?.project) {
                                        setProject(newValue?.project);
                                      }
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="โฉนดโครงการ"
                                    />
                                  )}
                                />
                              </FormControl>
                            </DemoContainer>
                          </Grid>
                          <Grid item xs={4.5}>
                            <DemoContainer
                              components={["Autocomplete", "Autocomplete"]}
                            >
                              <Autocomplete
                                disablePortal
                                value={project}
                                options={projectsList}
                                sx={{ width: "100%" }}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.name}</li>
                                )}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option || "";
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue || "";
                                  }
                                  return option.name || "";
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="โครงการ" />
                                )}
                                onChange={(e, value) => {
                                  if (value?.id) {
                                    housesStore
                                      .getByProjectId(value?.id)
                                      .then((res) => {
                                        setHousesList(res);
                                      });
                                  } else {
                                    housesStore.getAll().then((res) => {
                                      setHousesList(res);
                                    });
                                  }
                                  setProject(value);
                                }}
                              />
                            </DemoContainer>
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              value={house}
                              disablePortal
                              options={housesList}
                              sx={{ width: "100%" }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.name}</li>
                              )}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return option || "";
                                }
                                if (option.inputValue) {
                                  return option.inputValue || "";
                                }
                                return option.name || "";
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="บ้าน" />
                              )}
                              onChange={(e, value: any) => {
                                setHouse(value);
                              }}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={3}>
                        <TextField
                          label="ผู้ให้สัญญา"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 12);
                          }}
                          id={`transferer-${id}`}
                          type="text"
                          variant="outlined"
                          value={transferer}
                          onChange={(e) => setTransferer(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="สัญชาติ"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 13);
                          }}
                          id={`nationality-${id}`}
                          type="text"
                          variant="outlined"
                          value={nationality}
                          onChange={(e) => setNationality(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="บ้านเลขที่"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 14);
                          }}
                          id={`address-${id}`}
                          type="text"
                          variant="outlined"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="หมู่ที่"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 15);
                          }}
                          id={`village-${id}`}
                          type="text"
                          variant="outlined"
                          value={village}
                          onChange={(e) => setVillage(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={provinceValue}
                          id="province"
                          onChange={(e: any, value: any) => {
                            if (value?.id) {
                              setProvince(value?.id);
                              setProvinceValue(value?.nameTh);
                              const _districtOptions = districtData.filter(
                                (district: any) =>
                                  district?.province?.id === value?.id
                              );
                              setDistrictOptions(_districtOptions);
                            }
                          }}
                          disablePortal
                          options={provinceData}
                          sx={{ width: "100%" }}
                          renderOption={(props: any, option: any) => (
                            <li {...props}>{option?.nameTh}</li>
                          )}
                          getOptionLabel={(option: any) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="จังหวัด" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={districtValue}
                          id="district"
                          onChange={(e: any, value: any) => {
                            setDistrict(value);
                            setDistrictValue(value?.nameTh);
                            if (value?.id) {
                              const _subDistrictOptions =
                                subDistrictData.filter(
                                  (subDistrict: any) =>
                                    subDistrict?.amphur?.id === value?.id
                                );
                              setSubDistrictOptions(_subDistrictOptions);
                            }
                          }}
                          disablePortal
                          options={districtOptions}
                          sx={{ width: "100%" }}
                          renderOption={(props: any, option: any) => (
                            <li {...props}>{option?.nameTh}</li>
                          )}
                          getOptionLabel={(option: any) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="อำเภอ" />
                          )}
                          disabled={districtOptions.length > 0 ? false : true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={subDistrictValue ?? ""}
                          id="district"
                          onChange={(e: any, value: any) => {
                            if (value?.id) {
                              setSubDistrict(value?.id);
                              setSubDistrictValue(value?.nameTh);
                            }
                            if (value?.zipCode) {
                              setZipCode(value?.zipCode);
                            }
                          }}
                          disablePortal
                          options={subDistrictOptions}
                          sx={{ width: "100%" }}
                          renderOption={(props: any, option: any) => (
                            <li {...props}>{option.nameTh}</li>
                          )}
                          getOptionLabel={(option: any) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="ตำบล" />
                          )}
                          disabled={
                            subDistrictOptions.length > 0 ? false : true
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="รหัสไปรษณีย์"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 16);
                          }}
                          id={`district-${id}`}
                          type="text"
                          variant="outlined"
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="ผู้รับสัญญา"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 17);
                          }}
                          id={`transferee-${id}`}
                          type="text"
                          variant="outlined"
                          value={transferee}
                          onChange={(e) => setTransferee(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="มูลค่าที่ดิน"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 18);
                          }}
                          id={`landPrice-${id}`}
                          type="number"
                          variant="outlined"
                          value={
                            landPrice ? parseFloat(landPrice.toString()) : ""
                          }
                          onChange={(e) =>
                            setLandPrice(parseFloat(e.target.value))
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="ค่าโอนที่ดิน"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 19);
                          }}
                          id={`registrationFee-${id}`}
                          type="number"
                          variant="outlined"
                          value={
                            registrationFee
                              ? parseFloat(registrationFee.toString())
                              : ""
                          }
                          onChange={(e) =>
                            setRegistrationFee(parseFloat(e.target.value))
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="ค่าจดจำนอง"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 20);
                          }}
                          id={`registrationFee-${id}`}
                          type="number"
                          variant="outlined"
                          value={
                            mortgageFee
                              ? parseFloat(mortgageFee.toString())
                              : ""
                          }
                          onChange={(e) =>
                            setMortgageFee(parseFloat(e.target.value))
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {deedType === "project" && (
                    <>
                      <Grid item xs={12} display={"flex"} alignItems={"center"}>
                        <Typography>ข้อมูลโฉนดบ้าน</Typography>
                        <IconButton onClick={handleAddLandOfHouse}>
                          <AddCircleIcon color="primary" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={0.5}
                            justifyContent={"center"}
                            alignItems={"center"}
                            display={"flex"}
                          ></Grid>
                          <Grid
                            item
                            xs={0.5}
                            justifyContent={"center"}
                            alignItems={"center"}
                            display={"flex"}
                          >
                            <Typography>ลำดับ</Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography>บ้าน</Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography>ที่อยู่</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>เลขที่โฉนด</Typography>
                          </Grid>
                          <Grid item xs={1.55}>
                            <Typography>เนื้อที่</Typography>
                          </Grid>
                          <Grid item xs={1.5}>
                            <Typography>มูลค่าที่ดิน</Typography>
                          </Grid>
                          <Grid item xs={0.75}></Grid>
                        </Grid>
                      </Grid>
                      {landOfHouseSelected &&
                        landOfHouseSelected.length > 0 &&
                        landOfHouseSelected
                          .sort((a: any, b: any) =>
                            dayjs(a.createAt).diff(b.createAt)
                          )
                          .map((item: any, index: number) => (
                            <Grid item xs={12}>
                              <Grid container spacing={2} alignItems={"end"}>
                                <Grid
                                  item
                                  xs={0.5}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  display={"flex"}
                                >
                                  <IconButton
                                    aria-label={`delete-${item.id}`}
                                    size="small"
                                    color="error"
                                    onClick={() => handleRemoveItem(item.id)}
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                </Grid>
                                <Grid
                                  item
                                  xs={0.5}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  display={"flex"}
                                >
                                  <Typography>{index + 1}</Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                  <Typography>
                                    {item?.house?.name || ""}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                  <Typography>
                                    {item?.address || ""} หมู่ที่
                                    {item?.village || "-"} ต.
                                    {item?.subDistrict?.nameTh || "-"} อ.
                                    {item?.district?.nameTh || "-"} จ.
                                    {item?.province?.nameTh || "-"}{" "}
                                    {item?.zipCode}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>{item?.deedNo || "-"}</Typography>
                                </Grid>
                                <Grid item xs={1.55}>
                                  <Typography>
                                    {item?.rai ? parseFloat(item?.rai) : "-"}{" "}
                                    ไร่{" "}
                                    {item?.ngan ? parseFloat(item?.ngan) : "-"}{" "}
                                    งาน{" "}
                                    {item?.squareWah
                                      ? parseFloat(item?.squareWah)
                                      : "-"}{" "}
                                    ตร.วา
                                  </Typography>
                                </Grid>
                                <Grid item xs={1.5}>
                                  <Typography>
                                    {item?.landPrice
                                      ? parseFloat(item?.landPrice)
                                      : "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={0.75}>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      setLandOfHouse([]);
                                      setLandOfHouse(item);
                                      setOpenLandOfHouseDialog(true);
                                    }}
                                  >
                                    <KeyboardArrowRightIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                    </>
                  )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography mt={1} mb={1}>
                      ข้อมูลภาษีและค่าธรรมเนียมที่ดิน
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={"th"}
                        >
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              label="วันที่จ่ายภาษี"
                              value={taxDate}
                              onChange={(newValue: any) => {
                                handleChangData(newValue, "taxDate");
                              }}
                              sx={{ width: "100%" }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="ค่าคำขอ"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 21);
                          }}
                          id={`requestFee-${id}`}
                          type="number"
                          variant="outlined"
                          value={
                            requestFee ? parseFloat(requestFee.toString()) : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "requestFee")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="ค่าธรรมเนียม อปท."
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 22);
                          }}
                          id={`lgoFee-${id}`}
                          type="number"
                          variant="outlined"
                          value={lgoFee ? parseFloat(lgoFee.toString()) : ""}
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "lgoFee")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="รวมค่าธรรมเนียม"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 23);
                          }}
                          id={`totalFee-${id}`}
                          variant="outlined"
                          value={
                            totalFee ? parseFloat(totalFee.toString()) : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "totalFee")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="ภาษีเงินได้บุคคลธรรมดา"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 24);
                          }}
                          id={`personalTaxIncome-${id}`}
                          variant="outlined"
                          value={
                            personalTaxIncome
                              ? parseFloat(personalTaxIncome.toString())
                              : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "personalTaxIncome")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="ภาษีเงินได้นิติบุคคล"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 25);
                          }}
                          id={`corporateIncomeTax-${id}`}
                          variant="outlined"
                          value={
                            corporateIncomeTax
                              ? parseFloat(corporateIncomeTax.toString())
                              : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "corporateIncomeTax")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="ค่าอากรแสตมป์"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 26);
                          }}
                          id={`stampDuty-${id}`}
                          variant="outlined"
                          value={
                            stampDuty ? parseFloat(stampDuty.toString()) : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "stampDuty")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="ภาษีธุรกิจเฉพาะ"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 27);
                          }}
                          id={`specificBusinessTax-${id}`}
                          variant="outlined"
                          value={
                            specificBusinessTax
                              ? parseFloat(specificBusinessTax.toString())
                              : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "specificBusinessTax")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="รายได้ส่วนท้องถิ่น"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 28);
                          }}
                          id={`localIncome-${id}`}
                          variant="outlined"
                          value={
                            localIncome
                              ? parseFloat(localIncome.toString())
                              : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "localIncome")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <TextField
                          label="รวมทั้งสิ้นค่าภาษีอากร"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 29);
                          }}
                          id={`totalTax-${id}`}
                          variant="outlined"
                          value={
                            totalTax ? parseFloat(totalTax.toString()) : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "totalTax")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} mt={1}>
                        <TextField
                          label="รวมทั้งสิ้นค่าธรรมเนียมและค่าภาษีอากร"
                          type="number"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 30);
                          }}
                          id={`grandTotalTax-${id}`}
                          variant="outlined"
                          value={
                            grandTotalTax
                              ? parseFloat(grandTotalTax.toString())
                              : ""
                          }
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "grandTotalTax")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <TextField
                          label="หมายเหตุ"
                          type="text"
                          inputRef={addRef}
                          inputProps={{
                            min: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 31);
                          }}
                          id={`remarkText-${id}`}
                          variant="outlined"
                          value={remarkText ?? ""}
                          onChange={(newValue: any) =>
                            handleChangData(newValue, "remarkText")
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>เอกสาร</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          component="label"
                          variant="outlined"
                          startIcon={<UploadFileIcon />}
                        >
                          เลือกไฟล์
                          <VisuallyHiddenInput
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleFileChange}
                          />
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          รองรับไฟล์นามสกุล .jpg, .jpeg, .png
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {selectedFiles.length > 0 && (
                          <Box component={"div"} mt={1}>
                            {selectedFiles.map((file: any, index: number) => (
                              <Grid
                                item
                                xs={12}
                                key={`sf-${index.toString()}`}
                                mt={1}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    xs={0.5}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    display={"flex"}
                                  >
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => handleFileRemove(index)}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11.5}>
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                      width="500"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {images && (
                          <Box component={"div"} mt={1}>
                            {images.map((file: any, index: number) => (
                              <Grid
                                item
                                xs={12}
                                key={`im-${index.toString()}`}
                                mt={1}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    xs={0.5}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    display={"flex"}
                                  >
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() =>
                                        handleFileRemoveImageList(file)
                                      }
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11.5}>
                                    <img
                                      src={`${apiUrl}/uploads/${file.name}`}
                                      alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                      width="500"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={4}>
                        <Grid item xs={12} mt={2}>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="outlined"
                              fullWidth
                              onClick={() => navigate("/lands")}
                            >
                              ยกเลิก
                            </Button>
                            {!id && (
                              <Button
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                  setOpenConfirm({
                                    open: true,
                                    title: "บันทึกข้อมูล",
                                    message:
                                      "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
                                    color: "primary",
                                    type: "create",
                                  });
                                }}
                              >
                                บันทึก
                              </Button>
                            )}
                            {((permission && permission.edit) ||
                              authStore.user?.isSuperAdmin) &&
                              id && (
                                <Button
                                  variant="contained"
                                  fullWidth
                                  onClick={() => {
                                    setOpenConfirm({
                                      open: true,
                                      title: "แก้ไขข้อมูล",
                                      message:
                                        "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
                                      color: "primary",
                                      type: "edit",
                                    });
                                  }}
                                >
                                  แก้ไข
                                </Button>
                              )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <LandOfHouseDialog
        open={openLandOfHouseDialog}
        item={landOfHouse || {}}
        house={housesList}
        project={projectsList}
        province={provinceData}
        subDistrict={subDistrictData}
        district={districtData}
        projectId={project?.id || ""}
        landOption={landOption}
        onClose={() => {
          setLandOfHouse([]);
          setOpenLandOfHouseDialog(false);
        }}
        onSelected={(item: any) => {
          if (item.id) {
            const index = landOfHouseSelected.findIndex(
              (itemSelect: any) => itemSelect.id === item.id
            );
            if (index > -1) {
              landOfHouseSelected[index] = item;
            } else {
              landOfHouseSelected.push(item);
            }
          } else {
            landOfHouseSelected.push(item);
          }
          deleteImagesChild.push(item.deleteImages);
          setOpenLandOfHouseDialog(false);
          setLandOfHouse([]);
        }}
      />
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};
export default LandCreate;
