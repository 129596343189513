import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class WarehousesStore {
  warehouses: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  async getBycriteria(criteria: any) {
    try {
      const response = await apiService.post("/products/warehouses/criteria", criteria);
      this.warehouses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting warehouses", error);
      return {
        error: true,
        message: "An error occurred while getting warehouses",
      };
    }
  }

  async getDetailById(id: string) {
    try {
      const response = await apiService.get(`/products/warehouse-detail/${id}`);
      this.warehouses = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting warehouse detail by id", error);
      return {
        error: true,
        message: "An error occurred while getting warehouse detail by id",
      };
    }
  }

}
const warehousesStore = new WarehousesStore();
export default warehousesStore;
