import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class BookingStore {
  bookings: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/bookings/${id}`);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting booking", error);
      return {
        error: true,
        message: "An error occurred while getting booking",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/bookings/criteria", criteria);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting bookings", error);
      return {
        error: true,
        message: "An error occurred while getting bookings",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/bookings", data);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating booking", error);
      return {
        error: true,
        message: "An error occurred while creating booking",
      };
    }
  }

  async createBookingBank(data: any) {
    try {
      const response = await apiService.post("/bookings/booking-bank", data);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating booking bank", error);
      return {
        error: true,
        message: "An error occurred while creating booking bank",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/bookings/${id}`, data);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating booking", error);
      return {
        error: true,
        message: "An error occurred while updating booking",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/bookings/${id}`);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting booking", error);
      return {
        error: true,
        message: "An error occurred while deleting booking",
      };
    }
  }

  async deleteBookingBank(id: string) {
    try {
      const response = await apiService.delete(`/bookings/booking-bank/${id}`);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting booking bank", error);
      return {
        error: true,
        message: "An error occurred while deleting booking bank",
      };
    }
  }

  async getByHouseId(id: string) {
    try {
      const response = await apiService.get(`/bookings/house/${id}`);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting booking", error);
      return {
        error: true,
        message: "An error occurred while getting booking",
      };
    }
  }

  async getByStatusForSell() {
    try {
      const response = await apiService.get(`/bookings/status/sell`);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting booking", error);
      return {
        error: true,
        message: "An error occurred while getting booking",
      };
    }
  }

  async dashboard(data: any) {
    try {
      const response = await apiService.post(`/bookings/dashboard`, data);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting bookings", error);
      return {
        error: true,
        message: "An error occurred while getting bookings",
      };
    }
  }

  async dashboardCanceled(data: any) {
    try {
      const response = await apiService.post(`/bookings/dashboard/canceled`, data);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting bookings", error);
      return {
        error: true,
        message: "An error occurred while getting bookings",
      };
    }
  }

  async dashboardCustomer(data: any) {
    try {
      const response = await apiService.post(`/bookings/dashboard/customer`, data);
      this.bookings = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting bookings", error);
      return {
        error: true,
        message: "An error occurred while getting bookings",
      };
    }
  }

}
const bookingStore = new BookingStore();
export default bookingStore;
