import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import dayjs from "dayjs";
import 'dayjs/locale/th';


interface PrintUsersProps {
    title?: string;
    rows: any[];
}

const PrintUsers = React.forwardRef((props: PrintUsersProps, ref) => {

    return (
        <Box ref={ref} sx={{
            margin: 0,
            width: "100%",
            borderCollapse: "collapse",
            textAlign: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
            '&@page': {
                size: 'A4 portrait',
            },
            overflow: 'hidden',
            fontSize: 8,
            display: 'block',
            pageBreakBefore: 'auto',
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
                            <TableHead>
                                <TableCell colSpan={9} sx={{ textAlign: 'center', }}>
                                    <Typography fontWeight={'bold'}>{props.title}</Typography>
                                </TableCell>
                                <TableRow>
                                    <TableCell width={10}>ลำดับ</TableCell>
                                    <TableCell width={300} align="center">ชื่อ-นามสกุล</TableCell>
                                    <TableCell width={200} align="center" >ชื่อผู้ใช้งาน</TableCell>
                                    <TableCell width={100} align="center">ตำแหน่ง</TableCell>
                                    <TableCell width={30} align="center">บันทึก</TableCell>
                                    <TableCell width={30} align="center">แก้ไข</TableCell>
                                    <TableCell ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.rows.length > 0 && props.rows.map((row: any, index: number) => (
                                    <TableRow key={row?.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell component="th" scope="row">{row.firstName + ' ' + row.lastName}</TableCell>
                                        <TableCell>{row.username || ''}</TableCell>
                                        <TableCell>{row.userGroup?.name || ''}</TableCell>
                                        <TableCell><span style={{ fontSize: 8 }}>
                                            <Box>{<b>{row.createBy?.name || ''}</b>}</Box>{row.createAt ? dayjs(row.createAt).format('DD/MM/YYYY HH:mm') : ''}</span></TableCell>
                                        <TableCell><span style={{ fontSize: 8 }}>
                                            <Box>{<b>{row.updateBy?.name || ''}</b>}</Box>{row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}</span></TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
});

export default PrintUsers;