import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class UsersStore {
    users: any[] = [];
    construnctor() {
        makeAutoObservable(this);
    }
    async getAll() {
        try {
            const response = await apiService.get("/users");
            this.users = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting users:", error);
            return {
                error: true,
                message: "An error occurred while getting users",
            };
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/users/${id}`);
            this.users = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting a user:", error);
            return {
                error: true,
                message: "An error occurred while getting a user",
            };
        }
    }

    async getByCriteria(object: any) {
        try {
            const response = await apiService.post("/users/criteria", object);
            this.users = response;
            return response;
        } catch (error) {
            console.error("An error occurred while getting user criteria:", error);
            return {
                error: true,
                message: "An error occurred while getting user criteria",
            };
        }
    }
    async create(data: any) {
        try {
            const response = await apiService.post("/auth/register", data);
            this.users = response;
            return response;
        } catch (error) {
            console.error("An error occurred while creating user:", error);
            return {
                error: true,
                message: "An error occurred while creating user",
            };
        }

    }

    async update(id: string, data: any) {
        try {
            const response = await apiService.put(`/users/${id}`, data);
            this.users = response;
            return response;
        } catch (error) {
            console.error("An error occurred while updating user:", error);
            return {
                error: true,
                message: "An error occurred while updating user",
            };
        }

    }
}

const usersStore = new UsersStore();
export default usersStore;