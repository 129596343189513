import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class MasterDataStore {
  provinces: any = [];
  amphures: any = [];
  tambons: any = [];
  banks: any = [];
  reasonsType: any = [];
  constructor() {
    makeAutoObservable(this);
  }
  async getAllProvince() {
    try {
      const response = await apiService.get("/province");
      this.provinces = response;
      return response;
    } catch (err) {
      console.error("An error occurred while getting province:", err);
      return {
        error: true,
        message: "An error occurred while getting province",
      };
    }
  }

  async getAmphureByProvinceId(id: string) {
    try {
      const response = await apiService.get(`/amphur/province/${id}`);
      this.amphures = response;
      return response;
    } catch (err) {
      console.error("An error occurred while getting province:", err);
      return {
        error: true,
        message: "An error occurred while getting province",
      };
    }
  }

  async getTambonByAmphureId(id: string) {
    try {
      const response = await apiService.get(`/tambon/amphur/${id}`);
      this.tambons = response;
      return response;
    } catch (err) {
      console.error("An error occurred while getting province:", err);
      return {
        error: true,
        message: "An error occurred while getting province",
      };
    }
  }

  async getAllAmphur() {
    try {
      const response = await apiService.get("/amphur");
      this.provinces = response;
      return response;
    } catch (err) {
      console.error("An error occurred while getting amphurs:", err);
      return {
        error: true,
        message: "An error occurred while getting amphurs",
      };
    }
  }

  async getAllTambon() {
    try {
      const response = await apiService.get("/tambon");
      this.provinces = response;
      return response;
    } catch (err) {
      console.error("An error occurred while getting tambons:", err);
      return {
        error: true,
        message: "An error occurred while getting tambons",
      };
    }
  }

  async getBanksAll() {
    try {
      const response = await apiService.get("/banks");
      this.banks = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting banks:", error);
      return {
        error: true,
        message: "An error occurred while getting banks",
      };
    }
  }

  async getContractTypeAll() {
    try {
      const response = await apiService.get("/contract-types");
      return response;
    } catch (error) {
      console.error("An error occurred while getting contract types:", error);
      return {
        error: true,
        message: "An error occurred while getting contract types",
      };
    }
  }
  async getReasonsTypeAll() {
    try {
      const response = await apiService.get("/reasons-type");
      this.reasonsType = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting reasons type:", error);
      return {
        error: true,
        message: "An error occurred while getting reasons type",
      };
    }
  }

}

const masterDataStore = new MasterDataStore();
export default masterDataStore;