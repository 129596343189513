import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class ReceiptStore {
  receipts: any = null;
  receiptDetails: any = null;
  construnctor() {
    makeAutoObservable(this);
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/receipts/${id}`);
      this.receipts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting receipts", error);
      return {
        error: true,
        message: "An error occurred while getting receipts",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post("/receipts", data);
      this.receipts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating receipts:", error);
      return {
        error: true,
        message: "An error occurred while creating receipts",
      };
    }

  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/receipts/${id}`, data);
      this.receipts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating receipts:", error);
      return {
        error: true,
        message: "An error occurred while creating receipts",
      };
    }

  }


  async delete(id: string) {
    try {
      const response = await apiService.delete(`/receipts/${id}`);
      this.receipts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleteing receipts:", error);
      return {
        error: true,
        message: "An error occurred while deleteing receipts",
      };
    }

  }

  async createReceiptDetails(data: any) {
    try {
      const response = await apiService.post("/receipts/receipt-details", data);
      this.receiptDetails = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating receipt details", error);
      return {
        error: true,
        message: "An error occurred while creating receipt details",
      };
    }

  }

  async updateReceiptDetails(id: string, data: any) {
    try {
      const response = await apiService.put(`/receipts/receipt-details/${id}`, data);
      this.receiptDetails = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating receipt details", error);
      return {
        error: true,
        message: "An error occurred while creating receipt details",
      };
    }

  }


  async deletereceiptDetails(id: string) {
    try {
      const response = await apiService.delete(`/receipts/receipt-details/${id}`);
      this.receiptDetails = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleteing receipt details", error);
      return {
        error: true,
        message: "An error occurred while deleteing receipt details",
      };
    }

  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post("/receipts/criteria", criteria);
      this.receipts = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting receipts", error);
      return {
        error: true,
        message: "An error occurred while getting receipts",
      };
    }
  }
}

const receiptStore = new ReceiptStore();
export default receiptStore;