import { makeAutoObservable } from "mobx";
import apiService from "../services/ApiService";

class ProductCategoriesStore {
  productCategories: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/product-categories");
      this.productCategories = response;
      return response;
    } catch (error) {
      console.error(
        "An error occurred while getting productCategories:",
        error
      );
      return {
        error: true,
        message: "An error occurred while getting productCategories",
      };
    }
  }

  async get(id: string) {
    try {
      const response = await apiService.get(`/product-categories/${id}`);
      this.productCategories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productCategory:", error);
      return {
        error: true,
        message: "An error occurred while getting productCategory",
      };
    }
  }

  async getByName(name: string) {
    try {
      const response = await apiService.get(`/product-categories/name/${name}`);
      this.productCategories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productCategory:", error);
      return {
        error: true,
        message: "An error occurred while getting productCategory",
      };
    }
  }

  async getByNameLike(name: string) {
    try {
      const response = await apiService.get(
        `/product-categories/name/like/${name}`
      );
      this.productCategories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productCategory:", error);
      return {
        error: true,
        message: "An error occurred while getting productCategory",
      };
    }
  }

  async getByCriteria(criteria: any) {
    try {
      const response = await apiService.post(
        `/product-categories/criteria`, criteria
      );
      this.productCategories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while getting productCategory:", error);
      return {
        error: true,
        message: "An error occurred while getting productCategory",
      };
    }
  }

  async create(data: any) {
    try {
      const response = await apiService.post(`/product-categories`, data);
      this.productCategories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while creating productCategory:", error);
      return {
        error: true,
        message: "An error occurred while creating productCategory",
      };
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await apiService.put(`/product-categories/${id}`, data);
      this.productCategories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while updating productCategory:", error);
      return {
        error: true,
        message: "An error occurred while updating productCategory",
      };
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/product-categories/${id}`);
      this.productCategories = response;
      return response;
    } catch (error) {
      console.error("An error occurred while deleting productCategory:", error);
      return {
        error: true,
        message: "An error occurred while deleting productCategory",
      };
    }
  }
}

const productCategoriesStore = new ProductCategoriesStore();
export default productCategoriesStore;
